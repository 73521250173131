/*******************************************************************************************
//  * File Name                : MovingImages
//  * File Directory           : src/components/general/MovingImages
//  * Module                   : MovingImages
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Contents
//  * Used In				   : Create List 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   MovingImages        -   MovingImages for displaying / fetching data from database
 * #How to call:
 *    <MovingImages/>
 *
 *******************************************************************************************/
// react library
import { useEffect, useState } from "react";
import api from '../../utility/Endpoints';

// mui components
//material ui library/components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

//images for moving banner
import BackgroundImage from "../../assets/backgroundImage/pattern.jpg";
// import images from "../../utility/Home/SectionScroll.json";

const MovingImages = () => {
    let daily_image_path = process.env.REACT_APP_DAILY_MOVING_PATH || "https://staging-api.zagana.com/uploads/moving_image/"
    //useStates
    const [scroll, setScroll] = useState(0);// data / value for scroll
    const [images, setImages] = useState([]);// data / value for scroll

    const [screen, setScreen] = useState(window.innerWidth);
    let isMobile = screen <= 767

    const FetchMovingImages = () => {
        api.movingImages.get() // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                console.table(result)
                setImages(result)
            })
            .catch(error => {
                // globalStates.isLoading.set(false)
                console.log(error);
            })
    }
    useState(() => {
        FetchMovingImages()
    }, [])

    const styles = {
        banner: {
            overflow: "hidden",
            margin: `50px 0px auto `,
            height: "850px",
            position: 'relative',
            width: (screen - 7),
            // backgroundImage: `url(${BackgroundImage})`
            background: "#00FFFF"
        },
        collection: {
            // width: "100%",
            width: isMobile ? '1150px' : (screen - 7),
            transform: "translateX(-50%)",
            position: "absolute",
            left: "50%",
            height: "700px",
            zIndex: "0"
        }
    }
    useEffect(() => {
        //line 29-37, event scroll to get the current/height scroll of the browser
        const handleScroll = () => {
            setScroll(window.scrollY)
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // transformX = x-axis
    // transformY = y-axis
    // transformDeg = degree
    // src = img src
    // top = top position
    // right = right position
    // toPosition = where the image should move
    //usage below
    // translate3d(transformX, transformY, z-axis/0) rotate(transformDeg) - definition / usage
    // translate3d(0px, -2.985px, 0px) rotate(-1.393deg)  - actual

    const imageSet = images.map((image, index) => {
        let data = image
        let x = 0,
            y = 0,
            deg = 0,
            transform = "";
        data.transformX = 0;
        data.transformY = 0;
        if (data.toPosition === "left") { // toPosition = where the image should move
            let newScrollVal = parseFloat(scroll) / 3;
            x = parseFloat(data.transformX) - parseFloat(newScrollVal);
            y = parseFloat(data.transformY) - parseFloat(newScrollVal);
            deg = parseFloat(data.transformDeg) - parseFloat(newScrollVal);
            transform = "translate3d(" + x + "px," + y + "px, 0px) rotate(" + deg + "deg)";
        }
        else if (data.toPosition === "center") { // toPosition = where the image should move
            let newScrollVal = parseFloat(scroll) / 3;
            x = 0;
            y = (parseFloat(data.transformY) + parseFloat(newScrollVal)) + 5;
            deg = parseFloat(data.transformDeg) + parseFloat(newScrollVal);
            transform = "translate3d(" + x + "px," + y + "px, 0px) rotate(" + deg + "deg)";
        }
        else { // toPosition = where the image should move - move to right
            let newScrollVal = parseFloat(scroll) / 3;
            x = parseFloat(data.transformX) + parseFloat(newScrollVal);
            y = parseFloat(data.transformY) + parseFloat(newScrollVal);
            deg = parseFloat(data.transformDeg) + parseFloat(newScrollVal);
            transform = "translate3d(" + x + "px," + y + "px, 0px) rotate(" + deg + "deg)";
        }
        return (
            < img key={index} src={`${daily_image_path}${data.src}`} style={{ width: data.width || '10%', transform: transform, top: data.top, right: data.right, left: data.left }} id={`imgs-${index}`} />
        )
    })

    return (
        <Box>
            <Grid
                container
                spacing={0}
            >
                <div
                    className="banner"
                    style={styles.banner}
                >
                    <div style={styles.collection}>
                        {imageSet}
                    </div>
                </div>
            </Grid >
        </Box >
    );
}

export default MovingImages;