import React, { useEffect, useState } from 'react';
import { useState as useHookState } from "@hookstate/core";
import ConstantStyles from "../../../utility/ConstantStyle.json";

//material ui library/components
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Unstable_Grid2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


//api endpoints
import api from "../../../utility/Endpoints";

//import from general folder
import GlobalStates from "../../../utility/GlobalStates";
//const defaultImage = `https://scontent.fmnl17-1.fna.fbcdn.net/v/t39.30808-6/295432466_583897046697296_7947127860492652402_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeGOJU3LIGrKW5uTgiH1Euf80-U8IW-1FbXT5Twhb7UVtVVa_nhvg_yUn2x2P4tQyBi7iLFmwSoSBPiZNbaqb_gq&_nc_ohc=wmNSwXOFQZsAX_CY7Uq&_nc_ht=scontent.fmnl17-1.fna&oh=00_AfCatGERp951GJrAJdSx90xUnw-JLo0MWJIwVL5n68YhwQ&oe=637949D8`

const styles = {
    imageContainer: {
        borderRadius: ConstantStyles["default-border-radius"],
    }, productTextStrong: {
        fontWeight: ConstantStyles['bold']
    },
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"],
    },
    header: {
        fontWeight: ConstantStyles['bold'],
        marginBottom: '30px',
        padding: '12px',
        fontSize: '25px',
        color: '#A2F9F9',
        background: ConstantStyles['purple'],
        borderRadius: '10px'
    }
}
const OrderHistoryTab = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    let customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
    const customerId = customerInfo['customer_id']
    const [expanded, setExpanded] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const orders = [
        {   
            order_id:1,
            order_number: "DLY0000001",
            status: "Pending",
            date_created: "10/07/1998",
            date_schedule: "10/07/1998"
        },
        {   
            order_id:2,
            order_number: "DLY0000002",
            status: "Pending",
            date_created: "10/07/1998",
            date_schedule: "10/07/1998"
        },
        {   
            order_id:3,
            order_number: "DLY0000003",
            status: "Delivered",
            date_created: "10/07/1998",
            date_schedule: "10/07/1998"
        },
    ]
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        api.customerOrderList(customerId) // function from endpoint
            .then(response => {
                console.log(response.data.order)
                let orders = response.data.order
                orders.forEach(element => {
                    let subtotal = 0
                    element.cart.forEach(item => {
                        subtotal+=( item.item_qty * item.item_price )
                    })
                    element.subTotal = subtotal
                });
                setOrderList(response.data.order)
            })
            .catch(error => {
                console.log(error);
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
            })
    },[])
    return (
        <React.Fragment>
            <h3 style={styles.header}>Order History</h3>
            <Grid container item md={12} lg={12} className="register-container" spacing={{ xs: 1, md: 1 }}>
                {
                    orderList.map((order, index) => {
                        return <React.Fragment>
                            <Grid item md={6}>
                                <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                            <b style={{color: ConstantStyles['purple']}}>ORDER #:</b> {order.order_number}
                                        </Typography>
                                        <Typography sx={{ width: '33%',color: 'text.secondary' }}> <b style={{color: ConstantStyles['purple']}}>STATUS:</b> {order.status}</Typography>
                                        {/* <Button sx={{ color: 'text.secondary', float: 'right', background: ConstantStyles['purple'] }}> <b style={{color: ConstantStyles['teal']}}>Re Order</b></Button> */}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container>
                                            <Grid item md={6}>
                                                <b style={{color: ConstantStyles['purple']}}>Order Date:</b> {order.date_created}
                                            </Grid>
                                            <Grid item md={6}>
                                                <b style={{color: ConstantStyles['purple']}}>Scheduled Date: </b> {order.date_schedule}
                                            </Grid>
                                            <Grid item md={6}>
                                                <b style={{color: ConstantStyles['purple']}}>Payment Method: </b> Cash On Delivery
                                            </Grid>
                                            {/* <Grid item md={6}>
                                                Pay Status: </b> 
                                                {order?.shipping_address[0]?.name}
                                            </Grid> */}
                                            <Grid item md={8}>
                                                <b style={{color: ConstantStyles['purple']}}>Receiver's Name: </b>
                                                {order?.shipping_address[0]?.name}
                                            </Grid>
                                            <Grid item md={12}>
                                                <b style={{color: ConstantStyles['purple']}}>Shipping Address: </b>
                                                {order?.shipping_address[0]?.street} {order?.shipping_address[0]?.brgy} {order?.shipping_address[0]?.city}
                                            </Grid>
                                            <Grid item md={12}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Products</TableCell>
                                                            <TableCell align="right">Quantity</TableCell>
                                                            <TableCell align="right">Price</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            order?.cart?.map((item, index) => { 
                                                                return (
                                                                    <TableRow
                                                                        key={`${item.name}-${index}`}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell align="left">
                                                                            <b style={{color: ConstantStyles['purple']}}>{item.item_name}</b>
                                                                            {
                                                                                (Object.keys(item?.item_modifier).sort((a, b) => {
                                                                                    if (Array.isArray(item?.item_modifier[b])) {
                                                                                        return -1;
                                                                                    }
                                                                                    return 0;
                                                                                }))?.map((row, index) => {
                                                                                    let modifier = item?.item_modifier[row]
                                                                                    if (Array.isArray(modifier)) {
                                                                                        return <p style={{margin:'0',marginLeft:'25px'}}> 
                                                                                            <p style={{margin:'0', fontWeight:'bold'}}>{row}:</p>
                                                                                            {
                                                                                                modifier.map((row, index) => {
                                                                                                    return (
                                                                                                        <p style={{margin:'0',marginLeft:'50px'}}>
                                                                                                            {row.modifier_name}
                                                                                                        </p>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </p>
                                                                                    } else {
                                                                                        return (
                                                                                            <p style={{margin:'0',marginLeft:'25px'}}>
                                                                                                {modifier.modifier_name}
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                })
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell align="right">{item.item_qty}</TableCell>
                                                                        <TableCell align="right">PHP {item.item_price}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                        <TableRow
                                                            // key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell rowSpan={4} />
                                                            <TableCell align="left">Subtotal</TableCell>
                                                            <TableCell align="right">PHP {order?.subTotal}</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            // key={row.name}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left">Shipping Fee</TableCell>
                                                            <TableCell align="right">PHP {order?.shipping_fee}</TableCell>
                                                        </TableRow>
                                                        {
                                                            order.discount_amount > 0 &&
                                                            <TableRow
                                                                // key={row.name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell align="left">Discount</TableCell>
                                                                <TableCell align="right">PHP {order?.discount_amount}</TableCell>
                                                            </TableRow>
                                                        }
                                                        <TableRow
                                                            // key={row.name}
                                                            
                                                        >
                                                            <TableCell align="left">Total</TableCell>
                                                            <TableCell align="right" style={{fontWeight:'bold', color:ConstantStyles.purple}}>PHP {order?.total_amount}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                        
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </React.Fragment>
                    })
                }
            </Grid>
        </React.Fragment>
    );

}


export default OrderHistoryTab;