const INTIAL_STATE = {
    orderList: []
}


const OrderListReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case "SET_ORDER_LIST":
            return {
                ...state,
                orderList: action.orders
            };

        default:
            return state;
    }

};

export default OrderListReducer;