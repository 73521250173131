const INTIAL_STATE = {
    address: {}
}

const AddressReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_ADDRESS':
            return {
                ...state,
                address: action.address
            }
        case 'UPDATE_VALUE':
            return {
                ...state,
                address: { ...state.address, [action.row]: action.value }
            }

        default:
            return state;
    }

};

export default AddressReducer;