import React, { useEffect, useState } from 'react'
import GlobalStates from '../../utility/GlobalStates'
import { useHookstate } from '@hookstate/core'

import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Grid, LinearProgress } from '@mui/material'
import { Box } from '@mui/system'

const styles = {
    historyTable: {
        display: 'flex',
        height: "50vh",
        width: '100%',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            whiteSpace: 'normal',
            textOverflow: 'normal',
            lineHeight: '15px'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },
    }
}
const HistoryTable = (props) => {
    const { historyColumns, historyData } = props
    const [data, setData] = useState([])
    const globalStates = useHookstate(GlobalStates) 

    useEffect(() => {
        setData(historyData)
    }, [historyData])

    const CustomToolbar = () => {
        return (
            <Grid container
                sx={{
                    p: 1,
                    pb: 0
                }}
            >
                <Grid item xs={6}>
                    <GridToolbarQuickFilter />
                </Grid>
            </Grid>
        );
    }

  return (
    <div>
        <Box sx={styles.historyTable}>
            <DataGrid
                columns={historyColumns}
                rows={data}
                disableSelectionOnClick
                loading={globalStates.isLoading.get()}
                components={{ 
                    Toolbar: CustomToolbar, 
                    LoadingOverlay: LinearProgress, 
                }}
            />  
        </Box>
    </div>
  )
}

export default HistoryTable