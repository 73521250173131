import React from 'react'
import PropTypes from "prop-types";

import { Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmButton = (props) => {
    const { functions, close, btnLoad, btnName, margin } = props
    return (
        <React.Fragment>
            <Box sx={{display: 'flex', justifyContent:'flex-end', margin: margin }}>
                <LoadingButton
                    onClick={close} 
                    sx={{marginRight: 1}} 
                    size="small"
                    loadingPosition="start"
                    disabled={btnLoad}
                    startIcon={<CloseIcon /> }
                    >
                    Close
                </LoadingButton>
                {
                    functions ?
                        
                    <LoadingButton
                        size="small"
                        onClick={functions}
                        loadingPosition="start"
                        loading={btnLoad}
                        variant="contained" 
                        startIcon={<SaveIcon /> }
                        >
                        {btnName || 'Save'}
                    </LoadingButton> :
                    
                    <LoadingButton
                        size="small"
                        type="submit"
                        loadingPosition="start"
                        loading={btnLoad}
                        variant="contained" 
                        startIcon={<SaveIcon /> }
                        >
                        {btnName || 'Save'}
                    </LoadingButton>
                }
            </Box>
        </React.Fragment>
    )
}

ConfirmButton.propTypes = {
    close:PropTypes.func,
    btnLoad:PropTypes.bool,
    margin: PropTypes.any
};
ConfirmButton.defaultProps = {
    btnLoad: false,
    margin: 0,
    btnName: 'Save'
};
export default ConfirmButton