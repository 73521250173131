// react imports
import React, {useState} from 'react';
import PropTypes from "prop-types";
import moment from "moment";

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHookstate } from '@hookstate/core';
import { Button, Grid, LinearProgress, Paper } from '@mui/material';

// MUI icons
import SaveIcon from '@mui/icons-material/Save';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// api access
import api from '../../../../utility/Endpoints';

// import global states
import GlobalStates from '../../../../utility/GlobalStates';

// reusable components
import { useEffect } from 'react';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';

import SelectAll from '../../../shared/SelectAll';

// styles
const style = {
    paper: {
        borderColor: 'hsl(0, 0%, 80%)',
        borderRadius: '4px',
        borderStyle: 'solid',
        borderWidth: '1px',
        boxShadow: 'unset'
    },
    inputDate: {
        border: 'none',
        padding: '2px 1px 2px 3px'
    },
    DataGrid: {
        display: 'flex',
        height: "85vh",
        width: '100%',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            whiteSpace: 'normal',
            textOverflow: 'normal',
            lineHeight: '15px'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },

    },
}

const CreateSale = (props) => {
    const globalStates = useHookstate(GlobalStates) // globalstate variable

    const [product, setProduct] = useState([])
    const [branch, setBranch] = useState([])
    const [renderBranch, setRenderBranch] = useState([])
    const [branchSelected, setBranchSelected] = useState([])
    const [selectionModel, setSelectionModel] = useState([])
    const [checkedData, setCheckedData] = useState([])
    const [startDate, setStartDate] = useState('') // start date for on sale
    const [endDate, setEndDate] = useState('') // end date for on sale
    const dateNow = new Date().toISOString().slice(0, 10) // gettinng date now for filtering start date
    const minDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD').toString()
    
    useEffect(() => {
        getProduct()
        getBranch()
    }, [])

    const alert = (props) => {
        const { open, severity, message } = props
        globalStates.openAlert.set(open)
        globalStates.statusAlert.set(severity)
        globalStates.msgAlert.set(message)
    }

    const offLoad = () => {
        globalStates.isLoading.set(false)
        setInterval(() => {
            globalStates.isLoading.set(false)
        }, 1000)
    }

    const getBranch = () => {
        api.branch.getBranch()
        .then((response) => {
            console.log(response);
            const result = response.data.result
            const render = result.map( b => {
                return {
                    value: b.branch_id,
                    label: b.name,
                }
            })
            
            setBranch(result)
            setRenderBranch(render)
        })
        .catch((error) => {
            console.log(error.message);
        })
    }
    const getProduct = () => {
        globalStates.isLoading.set(true)
        api.product.getAllProduct()
        .then((prodResponse) => {
            api.onSale.getOnSale()
            .then((onSaleResponse) => {
                const products = prodResponse.data
                const onsale = onSaleResponse.data.result
                let i = 0, filterProducts = []
                for (i; i < products.length; i++) {
                    const prod = products[i]
                    const onSale = onsale.filter( os => os.sku === prod.sku)
                    if (onSale.length === 0) {
                        filterProducts.push({
                            id: prod.product_id,
                            name: prod.name,
                            sku: prod.sku,
                            price: prod.price,
                            salePrice: 0
                        })
                    }
                }
                setProduct(filterProducts)
                offLoad()
            })
            .catch((error) => {
                console.log(error.message);
                offLoad()
            })
        })
        .catch((error) => {
            console.log(error.message);
            offLoad()
        })
    }

    const onOptionChange = selected => {
        // set options selected on select 
        setBranchSelected(selected)
    };
    
    const onChangeStartDate = (e) => {
        //getting start date
        setStartDate(e.target.value)
        //removing value from end date
        document.getElementById("endDate").value = ""
    }

    const onChangeEndDate = (e) => setEndDate(e.target.value)
    
    const onClickSave = async (e) => {
        e.preventDefault()
        //checking if start date and end date has been set
        if (startDate === '' || endDate === '') {
            alert({
                open: true,
                severity: 'error',
                message:'Please select date'
            })

        } else if (new Date(startDate) > new Date(endDate)) {
            alert({
                open: true,
                severity: 'error',
                message:'End date must not below Start Date'
            })
        } else {
            const salePriceValidation = checkedData.filter((data) => {
                return data.value === 0
            })
            if(salePriceValidation.length > 0){
                console.log(salePriceValidation);
                salePriceValidation.map(data => {
                    setSelectionModel(selectionModel.filter(id => id !== data.id))
                })
                alert({
                    open: true,
                    severity: 'error',
                    message:'Sale price must not be equal to 0'
                })
            } else{
                const payload = checkedData.map((el) => {
                    const setBranch= branch.map(data => {
                        const opts = branchSelected.filter( opt => opt.value === data.branch_id)
                        if (opts.length > 0) {
                            return (
                                {
                                    branch_id: `${data.branch_id}`,
                                    product_status: "1",
                                    branch_name: data.name,
                                    abbreviation: data.abbreviation
                                }
                            )
                        }else{
                            return (
                                {
                                    branch_id: `${data.branch_id}`,
                                    product_status: "0",
                                    branch_name: data.name,
                                    abbreviation: data.abbreviation
                                }
                            )
                        }
                    })
                    return (
                        {
                            id: el.id,
                            salePrice: el.value,
                            startDate: startDate,
                            endDate: endDate,
                            branch: JSON.stringify(setBranch)
                        }
                    )
                })
                    console.log("-------------------- payload");
                    console.log(payload);
                    api.onSale.create(payload)
                    .then((response) => {
                        console.log(response);
                        window.location.href = '/admin-backend/onSale'
                    })
                    .catch((error) => {
                        console.log(error.message);
                        globalStates.openAlert.set(true)
                        globalStates.statusAlert.set('error')
                        globalStates.msgAlert.set(error.message)
                    })
            }
        }
    }
    
    // setting up columns
    const columns = [
        {
            field: "id", // fetching from map
            headerName: "ID",
            flex: 0.2,
            minWidth: 100,
            hide: true
        },
        {
            field: "sku",
            headerName: "SKU",
            flex: 0.2,
            minWidth: 100,
            editable: false
        },
        {
            field: "name",
            headerName: "Product name",
            flex: 0.3,
            minWidth: 100,
            editable: false
        },
        {
            field: "price",
            headerName: "Original Price",
            flex: 0.3,
            minWidth: 100,
            editable: false
        },
        {
            field: "salePrice",
            headerName: "Sale Price",
            flex: 0.3,
            minWidth: 100,
            editable: true,
            type: 'number',
            preProcessEditCellProps: (params) => {
                const { id, props, row } = params
                let hasError = false
                if (props.value > row.price) {
                    hasError = true
                    globalStates.openAlert.set(true)
                    globalStates.statusAlert.set('error')
                    globalStates.msgAlert.set('Sale price is greater than Original price')
                    setInterval(() => {
                        globalStates.openAlert.set(false)
                    }, 2000)
                } else {
                    const newChecked = checkedData.filter( d => d.id !== id )
                    const newRow = {...row, value: props.value}
                    if (props.value !== 0 && props.value !== null) {
                        const filter = selectionModel.filter(selectedId => selectedId === id )
                        if (filter.length === 0) setSelectionModel([...selectionModel, id])
                        setCheckedData([...newChecked, newRow])
                    }else{
                        setSelectionModel(selectionModel.filter(selectedId => selectedId !== id))
                        setCheckedData(checkedData.filter(data => data.id !== id))
                    }

                }
                return { ...params.props, error: hasError }
            }
        }
    ]
    const CustomToolbar = () => {
        return (
            <Grid container
                sx={{
                    p: 1,
                    pb: 0
                }}
            >
                <Grid item xs={4}>
                    <GridToolbarQuickFilter />
                </Grid>
                <Grid 
                    xs={4}
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                    >
                </Grid>
                <Grid item xs={4} sx={{display: 'flex', justifyContent: 'end'}}>
                    <Button 
                        size='small' 
                        onClick={onClickSave} 
                        variant="contained" 
                        startIcon={<SaveIcon   />}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        );
    }

    
    return (
        <Box sx={{marginLeft: "6%",marginRight: "3%", marginTop: "5%"}}>
            <Box width={325} margin={"0px 0px 20px 0px"} sx={{display: 'flex', margin: '0px 0px 20px 0px', width: '100%'}}>
                <Box sx={{width: '30%', marginRight: '10px'}}>
                    <Typography >Select Branch: </Typography>
                    <SelectAll
                        options={renderBranch}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={true}
                        onChange={onOptionChange}
                        selectionModel={selectionModel}
                        allowSelectAll={true}
                        value={branchSelected}
                    />
                </Box>
                <Box sx={{width: '30%'}}>
                    <Typography >Sale Date: </Typography>
                    <Paper className='p-1' style={style.paper}>
                        <input type="date" id="startDate" style={style.inputDate} min={dateNow} onChange={onChangeStartDate} /> - <input type="date" id="endDate" style={style.inputDate} min={minDate} onChange={onChangeEndDate} />
                    </Paper>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'flex-end', width: '40%', alignItems: 'flex-end'}}>
                    <Button  
                        color="secondary"
                        sx={{height: '38px', color: "gray"}}
                        size='small' 
                        variant="outlined" 
                        href='/admin-backend/onsale'
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                </Box>
            </Box>
            <Box sx={style.DataGrid}>
                <DataGrid
                    checkboxSelection={true}
                    columns={columns}
                    rows={product}
                    disableSelectionOnClickonSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                      }}
                    loading={globalStates.isLoading.get()}
                    selectionModel={selectionModel}
                    components={{ 
                        Toolbar: CustomToolbar, 
                        LoadingOverlay: LinearProgress, 
                    }}
                />  
            </Box>  
        </Box>
    );
}


export default CreateSale