// react library
import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from "react";

//api endpoints

//Global States, call funtion
import ConstantStyles from "../../../utility/ConstantStyle.json";
import GlobalStates from "../../../utility/GlobalStates";

// mui components
//material ui library/components
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';


import PropTypes from "prop-types";
import { PatternFormat } from 'react-number-format';
//style starts here
const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
      color: ConstantStyles['purple'],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ConstantStyles['teal'],
    },
    '& .MuiOutlinedInput-root': {
        color: ConstantStyles['purple'],
        '& fieldset': {
            borderColor: ConstantStyles['purple'],
        },
        '&:hover fieldset': {
            borderColor: ConstantStyles['teal'],
        },
        '&.Mui-focused fieldset': {
            borderColor: ConstantStyles['teal'],
        },
    },
  });
//import from general folder
const numberFormat = '(+63)###-###-####'
const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
        <PatternFormat 
            format={numberFormat} 
            allowEmptyFormatting 
            mask="_" 
            {...other}
            getInputRef={ref}
            onValueChange={(values) => 
                onChange({
                    target: {
                    name: props.name,
                    formattedValue:values.formattedValue,
                    value: values.value,
                    },
                })
            }
        />
    );
});

  
NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};
  
  
const FormattedField = (props) => {
    const rowId = props.row
    const field = props.field
    const variant = props.variant
    const fwidth = props.fwidth
    const type = field.type
    const fieldsValue = props.fieldsValue
    const setFieldsValue = props.setFieldsValue
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [error, setError] = useState(field?.error)
    const [errorMsg, setErrorMsg] = useState(field?.errorMsg)
    const [onLoad, setOnLoad] = useState(true)
    const [value, setValue] = useState('');
    const [nonFormattedValue, setNonFormattedValue] = useState('')
    const currentValue = props.currentValue || ''
    
    // update error status if changes
    useEffect(()=>{
        setError(field.error)
    },[props.errorTriggered])

    const handleChange = (event) => {
        console.log(event)
        if(!onLoad){
            let err = false
            if((event.target.value).length<10){
                setError(true)
                err = true
            }else{
                setError(false)
            }
            setValue(event.target.formattedValue);
            setNonFormattedValue(event.target.value)
            let newField = fieldsValue
            setFieldsValue({
                ...newField,
                [rowId]: {
                    field: field?.field,
                    type: field?.type,
                    errorMsg:errorMsg,
                    defValue:field.defValue,
                    formatted: field?.formatted,
                    value: event.target.value,
                    error: err
                }
            })
        }else{
            setOnLoad(false)
        }
    };
    
    return (
        <CssTextField 
        id={`formatted-input${rowId}`}
        name={`formatted-input${rowId}`}
        value={currentValue||value}
        error={error}
        helperText={error&&errorMsg}
        onChange={(e)=>handleChange(e)}
        onBlur={(e)=>handleChange(e)}
        InputProps={{
            inputComponent: NumberFormatCustom, //call formatter 
        }}
        variant={variant}
        fullWidth={fwidth}
        size="small"/>
        // <TextField
        // />
    );
}

FormattedField.propTypes = {
    field: PropTypes.object,
    type: PropTypes.string,
    variant: PropTypes.string,
    fwidth: PropTypes.bool
};
FormattedField.defaultProps = {
    field: {},
    type: "text",
    variant: "outlined",
    fwidth: false,
}

export default FormattedField;