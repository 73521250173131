import { useState as useHookState } from "@hookstate/core";
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import defaultImage from '../../../assets/logo/default.webp';
import ConstantStyles from '../../../utility/ConstantStyle.json';
import { cartLogs } from '../../../utility/Endpoints';
import GlobalStates from "../../../utility/GlobalStates";
import { addItem } from '../../actions';

const CartRecommended = (props) => {
    const dispatch = useDispatch()
    const globalStates = useHookState(GlobalStates); // call globalstates
    const { cart, recommendedList } = props
    const [items, setItems] = useState([])
    let isMobile = window.innerWidth <= 640
    let toggleSide = isMobile ? "bottom" : "right"

    useEffect(() => {
        const products = recommendedList?.filter((product) => {

            if (cart.length > 0)
                return cart.some((item) => product?.sku !== item?.item_sku)
            else
                return product
        })
        console.log(products)
        setItems(products)
    }, [cart, recommendedList])

    const handleClick = (product) => {
        let selectedModifier = product.preselected_modifier
        const sorted = Object.keys(selectedModifier)
            .sort()
            .reduce((accumulator, key) => {
                accumulator[key] = selectedModifier[key];
                return accumulator;
            }, {});
        const modifierPrice = Number(sorted["UPSIZE"]?.price || 0) + Number(sorted["SUGAR LEVEL"]?.price || 0) + Number(sorted["MILK ALTERNATIVES"]?.price || 0)
        const upgradePrice = Number(sorted["UPGRADES"]?.map((upgrades) => (upgrades.price))?.reduce((partialSum, a) => partialSum + a, 0) || 0)

        const insertCart = {
            item_id: product.product_id,
            item_name: product.name,
            item_sku: product.sku,
            item_qty: 1,
            item_price: Number(product.price) + Number(modifierPrice) + Number(upgradePrice),
            original_price: product.price,
            item_image: product.image_src,
            item_category: product.category,
            item_modifier: sorted,
            item_remarks: ''
        }
        console.table(insertCart)
        const payloadLogs = {
            item: insertCart,
            action: 'added to cart',
            accesskey: 'sample accesskey',
            userEmail: 'useremail@email.com'
        }


        window.gtag("event", "add_to_cart", {
            currency: "PHP",
            value: Number(product.price) + Number(modifierPrice) + Number(upgradePrice),
            items: [
                {
                    item_id: product.product_id,
                    item_name: product.name,
                    currency: "PHP",
                    item_category: product.category[0],
                    item_category2: product.category[1],
                    item_category3: product.category[2],
                    item_category4: product.category[3],
                    item_category5: product.category[4],
                    item_modifier: sorted,
                    price: Number(product.price) + Number(modifierPrice) + Number(upgradePrice),
                    original_price: product.price,
                    quantity: 1
                }
            ]
        });

        globalStates.snackBarMsg.set(`${product.name} Added`);
        globalStates.snackBarStatus.set(true);
        cartLogs(payloadLogs)
        dispatch(addItem(insertCart));
    }

    return (
        <React.Fragment>
            <Box sx={{ background: ConstantStyles['white-color'], height: '150px', padding: '10px' }}>
                <div style={{ margin: 'auto', display: 'inline-block' }}>
                    <h6>Recommendations: </h6>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'auto', display: 'flex', width: isMobile ? '96%' : '92%' }} >
                        {items.map((product) => (
                            <Box key={`recommended-${product.product_id}`} sx={{ width: '80px', marginRight: '10px' }} onClick={() => handleClick(product)}>
                                <Box sx={{ width: '80px', height: '70px' }}>
                                    <img
                                        src={product?.image_src ? process.env.REACT_APP_HOST + product?.image_src : defaultImage}
                                        width='100%'
                                        style={{ borderRadius: '25px' }} />
                                </Box>
                                <div style={{
                                    display: 'inline-block',
                                    textAlign: 'center',
                                    fontSize: '10px',
                                    lineHeight: '1',
                                    width: '80px',
                                    wordWrap: 'break-word',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                    {product.name}
                                </div>
                            </Box>
                        )
                        )}
                    </div>
                </div>
            </Box>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        productList: state.products.productList,
        recommendedList: state.recommended.productList,
        cart: state.cart.CartList
    };
}

export default connect(mapStateToProps)(CartRecommended);