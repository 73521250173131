// react imports
import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useHookstate } from '@hookstate/core';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Button } from '@mui/material';

// MUI icons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

// utility
import GlobalStates from '../../../../utility/GlobalStates';
import api from '../../../../utility/Endpoints';
import ConfirmButton from '../../../shared/ConfirmButton';

// styles
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    }
};

const CreateModal = (props) => {
    const globalStates = useHookstate(GlobalStates) // globalstate variable
    const { refresh, users } = props // data from parent component 

    const [level, setLevel] = useState('');
    const [duplicate, setDuplicate] = useState('') // error message
    const [open, setOpen] = useState(false)  // variable for modal
    const [btnLoad, setBtnLoad] = useState(false) // loading button on process
    
    useEffect(() => {
        setDuplicate('')
    }, [open])

    const handleClose = () => setOpen(false) // close modal
    const handleOpen = () => setOpen(true); // open modal

    const handleChange = (event) => {
        setLevel(event.target.value);
      };

    const handleSubmit = (e) => { // submit form
        e.preventDefault()
        setBtnLoad(true)
        const body = {
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            email: e.target.email.value,
            password: e.target.password.value,
            user_level: e.target.user_level.value,
        }

        if (duplicate === '') {
            api.user.create(body)
            .then((res) => {
                    setBtnLoad(false)
                    setOpen(false)
                    refresh()
            })
            .catch((error) => {
                // alert error
                console.log(error);
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
            })
        } else {
            setBtnLoad(false)
        }

    }

    const handleChangeEmail = (e) => { // onChange text branch
        e.preventDefault()

        const typed = e.target.value

        // filter duplicate branch
        const filterUser = users.filter( user => user.email === typed)
        
        if (filterUser.length > 0) {
            setDuplicate("Branch already exist")
        }else{
            setDuplicate('')
        }
    }

    return (
        <div>
            <Button 
                size='small' 
                onClick={handleOpen} 
                variant="contained" 
                startIcon={<AddCircleIcon />}>
                Create
            </Button>
            <Modal
                open={open}
                sx={{padding: 0, margin: 0}}
            >   
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{margin: 1.5}}>
                        Create User
                    </Typography>
                    <hr style={{marginTop: 1, marginBottom: 1}}/>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{padding: 2}} >
                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        First Name:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='first_name' size='small' label="First Name" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        Last Name:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='last_name' size='small' label="Last Name" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        Email:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField type='email' onChange={handleChangeEmail} name='email' size='small' label="Email" sx={{ width: '100%'}} variant="outlined" required/>
                                    <FormHelperText sx={{display : duplicate ? '': 'none', color: 'red'}}>{duplicate}</FormHelperText>
                                </Box>
                            </Box>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        Password:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField type='password' autoComplete='off' name='password' size='small' label="Password" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        User Level:
                                    </Typography>
                                </Box>
                                <Box width={"60%"} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <FormControl sx={{ minWidth: 120, width: '100%' }}  size="small">
                                        <InputLabel id="demo-simple-select-helper-label">User level</InputLabel>
                                        <Select
                                            value={level}
                                            label="User level"
                                            name='user_level'
                                            onChange={handleChange}
                                            required
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                            <MenuItem value={'Super Admin'}>Super Admin</MenuItem>
                                            <MenuItem value={'Hub Officer'}>Hub Officer</MenuItem>
                                            <MenuItem value={'Sales and Marketing'}>Sales and Marketing</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Box>


                        <Box sx={{width: '100%'}}>
                            <hr style={{marginTop: 1, marginBottom: 1}}/>
                            <ConfirmButton 
                                margin={1}
                                close={handleClose} 
                                btnLoad={btnLoad} 
                            />
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

CreateModal.propTypes = {
    messages:PropTypes.object,
    run:PropTypes.func,
    btnLoad:PropTypes.bool,
};
CreateModal.defaultProps = {
    messages:{},
    run: undefined,
    btnLoad: false,
};

export default CreateModal