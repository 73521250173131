import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

import React, { useState, useEffect  } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from "../../utility/GlobalStates";

import CustomerLogin from '../modules/Login/CustomerLogin';
import Register from '../modules/Register/Register';

//logo
import DailyLogo from "../../assets/logo/daily-logo.png";

export default function SimpleBottomNavigation() {
    const globalStates = useHookState(GlobalStates); // call globalstates
    let customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
    const [value, setValue] = useState('1');
    const [drawerValue, setDrawerValue] = useState(0);
    const [item, setItem] = useState()
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [screen, setScreen] = useState(window.innerWidth);
    let isMobile = screen <= 767
    let toggleSide = isMobile ? "bottom" : "right"

    //   const handleUserDrawer = (anchor, open, event, item) => {

    //   }

    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleUserDrawer = (anchor, open, event, item) => {
        if (customerInfo === null) {
            if (
                event &&
                event.type === 'keydown' &&
                (event.key === 'Tab' || event.key === 'Shift')
            ) {
                return;
            }
            setState({ ...state, [anchor]: open });
            if (item)
                setItem(item)
        } else {
            window.location.href = `/account`
        }
    }


    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    const onClickHome = () => {
        window.location.href = '/'
    }
    const onClickMenu = () => {
        window.location.href = '/menu/all'
    }
    const onClickLocation = () => {
        window.location.href = '/storelocator'
    }
    const onClickFranchise = () => {
        window.location.href = '/franchise'
    }
    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: '999' }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setDrawerValue(newValue);
                }}
            >
                <BottomNavigationAction label="" icon={<HomeIcon />} onClick={onClickHome} />
                <BottomNavigationAction label="" icon={<LocalCafeIcon />} onClick={onClickMenu} />
                <BottomNavigationAction label="" icon={<AddBusinessIcon />} onClick={onClickFranchise} />
                <BottomNavigationAction label="" icon={<LocationOnIcon />} onClick={onClickLocation} />
            </BottomNavigation>
            <SwipeableDrawer
                sx={{
                    height: window.innerHeight,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        height: window.innerHeight,
                        boxSizing: 'border-box',
                    },
                }}
                disableBackdropTransition={true}
                anchor={toggleSide}
                open={state[toggleSide]}
                onClose={() => handleUserDrawer(toggleSide, false)}
                onOpen={() => handleUserDrawer(toggleSide, true)}
            >
                {
                    customerInfo === null ?

                        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <div style={{ textAlign: "center" }}>
                                    <img src={DailyLogo} width="180" />
                                </div>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="login and register" centered>
                                            <Tab label="Login" value="1" />
                                            <Tab label="Register" value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1"><CustomerLogin setvalue={setValue} /></TabPanel>
                                    <TabPanel sx={{ p: "5px 0px" }} value="2"><Register /></TabPanel>
                                </TabContext>
                            </Box>
                        </Typography> :
                        ""
                }
            </SwipeableDrawer>
        </Paper>

    );
}