import React, {useState} from 'react';
import PropTypes from "prop-types";

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useHookstate } from '@hookstate/core';
import { Alert, IconButton, Button, TextField, FormHelperText } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { ControlPointDuplicateRounded, PhotoCamera } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import GlobalStates from '../../../../utility/GlobalStates';
import api from '../../../../utility/Endpoints';
import ConfirmButton from '../../../shared/ConfirmButton';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    }
};

const CreateModal = (props) => {
    const globalStates = useHookstate(GlobalStates)
    const { refresh, id, data } = props // data from parent component 
    const [open, setOpen] = useState(false)
    const [fileName, setFileName] = useState('No File Chosen')
    const [fileUpload, setFileUpload] = useState()
    const [btnLoad, setBtnLoad] = useState(false)
    const [duplicate, setDuplicate] = useState('') // error message
    
    useEffect(() => {
        setDuplicate('')
    }, [open])

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const file = (e) => {
        setFileName(e.target.files[0].name || "")
        setFileUpload(e.target.files[0] || "")
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setBtnLoad(true)

        const category_name = e.target.category_name.value
        
        const formData = new FormData();
        formData.append('image', fileUpload);
        const paths = {
            dir: __dirname,
            path: '../../../../../public/uploads/image'
        }

        if (duplicate === '') {
            if (fileUpload) {
                api.category.upload_image(formData, paths)
                .then((response) => {
                    const filePath = response.data.result
                    const body = {
                        name: category_name,
                        path: filePath
                    }
                    api.category.create(body)
                    .then((res) => {
                        if (res.data.error) {
                            setBtnLoad(false)
                        }else{
                            refresh()
                            setOpen(false)
                            setBtnLoad(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        setBtnLoad(false)
                        globalStates.openAlert.set(true)
                        globalStates.statusAlert.set('error')
                        globalStates.msgAlert.set(err.message)
                        setInterval(() => {
                            globalStates.openAlert.set(false)
                        }, 2000)
                    })
                })
                .catch((error) => {
                    console.log(error.message);
                    setBtnLoad(false)
                    globalStates.openAlert.set(true)
                    globalStates.statusAlert.set('error')
                    globalStates.msgAlert.set(error.message)
                    setInterval(() => {
                        globalStates.openAlert.set(false)
                    }, 2000)
                })
            } else {
                const body = {
                    name: category_name,
                    path: ''
                }
                api.category.create(body)
                .then((res) => {
                    if (res.data.error) {
                        setBtnLoad(false)
                    }else{
                        refresh()
                        setOpen(false)
                        setBtnLoad(false)
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    setBtnLoad(false)
                    globalStates.openAlert.set(true)
                    globalStates.statusAlert.set('error')
                    globalStates.msgAlert.set(error.message)
                    setInterval(() => {
                        globalStates.openAlert.set(false)
                    }, 2000)
                })
            }
        } else {
            setBtnLoad(false)
        }

    }

    const handleChange = (e) => {
        e.preventDefault()

        const typed = e.target.value

        const filterCategory = data.filter( category => category.name === typed)
        
        if (filterCategory.length > 0) {
            setDuplicate("Branch already exist")
        }else{
            setDuplicate('')
        }
    }
    return (
        <div>
            <Button 
                size='small' 
                onClick={handleOpen} 
                variant="contained" 
                startIcon={<AddCircleIcon />}>
                Category
            </Button>
            <Modal
                open={open}
                sx={{padding: 0, margin: 0}}
            >   
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{margin: 1.5}}>
                        Create Category
                    </Typography>
                    <hr style={{marginTop: 1, marginBottom: 1}}/>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{padding: 2}} >
                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        Category name:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='category_name' onChange={handleChange} size='small' label="Category" placeholder='Recommended' sx={{ width: '100%'}} variant="outlined" required/>
                            
                                    <FormHelperText sx={{display : duplicate ? '': 'none', color: 'red'}}>{duplicate}</FormHelperText>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <Box>
                                    <Typography>Image :</Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <IconButton size="large" color="primary" aria-label="upload picture" component="label">
                                            <input
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                name='category_image'
                                                type="file"
                                                hidden
                                                accept=".jpg , .png"
                                                onChange={file}
                                            />
                                            <PhotoCamera size="large" />
                                        </IconButton>
                                            <Typography sx={{color: 'black', fontSize: '13px'}} align='center'>
                                                {fileName}
                                            </Typography>
                                    </Box>
                                    <i style={style.warning}>Kindly upload 32x32 px</i>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{width: '100%'}}>
                            <hr style={{marginTop: 1, marginBottom: 1}}/>
                            <ConfirmButton 
                                margin={1}
                                close={handleClose} 
                                btnLoad={btnLoad} />
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

export default CreateModal