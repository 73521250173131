import React, {useState} from 'react';
import PropTypes from "prop-types";
import { useHookstate } from '@hookstate/core';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import OutlinedInput  from '@mui/material/OutlinedInput';
import Container from '@mui/material/Container';
import { Alert, IconButton, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { PhotoCamera } from '@mui/icons-material';

//Global States, call funtion
import ConstantStyles from "../../../../utility/ConstantStyle.json";
import GlobalStates from '../../../../utility/GlobalStates';
import api,{ CreateProduct } from '../../../../utility/Endpoints';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const DeleteProductModal = (props) => {
    const theme = useTheme();
    const globalStates = useHookstate(GlobalStates)
    // let { modifiers, categoryList } = props
    const [fileName, setFileName] = useState('No File Chosen')
    const [errMsg, setErrMsg] = useState('')
    const [btnLoad, setBtnLoad] = useState(false)
    const [fileUpload, setFileUpload] = useState()
    let item = JSON.parse(globalStates.selectedProduct.get())
    // const [info, setInfo] = useState(item)
    // useEffect(() => {
    //     setInfo(item)
    // },[item])
    const file = (e) => {
        setFileName(e.target.files[0].name || "")
        setFileUpload(e.target.files[0] || "")
    }
    const handleClose = () => globalStates.modalOpenDelete.set(false);

    const handleSubmit = (e) => {
        e.preventDefault()
        globalStates.confirmed.set(true)
        // setBtnLoad(true)
        // const formData = new FormData();
        // formData.append('image', fileUpload);
        // const paths = {
        //     dir: __dirname,
        //     path: '../../../../../public/uploads/image'
        // }
        // // api.category.upload_image(formData, paths)
        // console.log(fileUpload)
        // let selectedModifiers = []
        // modifiers.forEach(element => {
        //     let checked = e.target[`checkBox${element.group_name.replace(/ /g, '')}`].checked
        //     if (checked)
        //         selectedModifiers.push({
        //             id: element.group_id,
        //         })
        // });
        // const body = {
        //     name: e.target.textFieldName.value,
        //     sku: e.target.textFieldSKU.value,
        //     price: parseFloat(e.target.textFieldPrice.value),
        //     description: e.target.textFieldDescription.value,
        //     modifiers: JSON.stringify(selectedModifiers),
        //     category: JSON.stringify(selectedCategory)
        //     // path: filePath
        // }
        // Object.keys(body).forEach(key => {
        //     formData.append(key, body[key]);
        // });
        // console.log(body)
        // CreateProduct(formData)
        // .then((res) => {
        //     globalStates.modalOpenDelete.set(false)
        //     setBtnLoad(false)
        // })
        // .catch((err) => {
        //     console.log(err.response.data.message)
        //     setErrMsg(err.response.data.message)
        //     setBtnLoad(false)
        // })
    }
    return (
        <div>
            <Modal
                open={globalStates.modalOpenDelete.get()}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{padding: 0, margin: 0}}
            >   
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{margin: 1.5}}>
                        Archive Product
                    </Typography>
                    <hr style={{marginTop: 1, marginBottom: 1}}/>
                    <Box sx={{ padding: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2}}>
                                <Alert sx={{width: "100%"}} severity="error" hidden={errMsg ? false : true}>{errMsg}</Alert>
                            </Box>
                            <Container fixed>
                                Are you sure you want to archive <span style={{color: ConstantStyles["purple"]}}>{item?.name}</span>?
                            </Container>
                            <Box sx={{width: '100%'}}>
                                <Box sx={{display: 'flex', justifyContent:'flex-end', padding: 1.5}}>
                                    <LoadingButton
                                        onClick={() => { globalStates.modalOpenDelete.set(false); globalStates.confirmed.set(false)}} 
                                        sx={{marginRight: 1}} 
                                        size="small"
                                        type="submit"
                                        loadingPosition="start"
                                        // disabled={btnLoad}
                                        startIcon={<CloseIcon /> }
                                        >
                                        No
                                    </LoadingButton>
                                    <LoadingButton
                                        onClick={() => globalStates.confirmed.set(true)} 
                                        size="small"
                                        type="submit"
                                        loadingPosition="start"
                                        loading={btnLoad}
                                        variant="contained" 
                                        startIcon={<SaveIcon /> }
                                        >
                                        Yes
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

DeleteProductModal.propTypes = {
    messages:PropTypes.object,
    run:PropTypes.func,
    btnLoad:PropTypes.bool,
};
DeleteProductModal.defaultProps = {
    messages:{},
    run: undefined,
    btnLoad: false,
};

export default DeleteProductModal