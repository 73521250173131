
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : FAQs
 * File Directory           : src/components/Misc/FAQs
 * Module                   : Frequently Asked Queations
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : To view the Frequently Asked Questions with Zagana
 * Used In				    : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   FAQs        -   component for FAQs
 *
 * #How to call:
 *    <FAQs />
 *
 *******************************************************************************************/

// react library
import { useState as useHookState } from "@hookstate/core";
import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
//Global States, call funtion
import GlobalStates from "../../../utility/GlobalStates";

const styles = {
    box: {
        border: "2px solid #6700B9",
    },
    header: {
        backgroundColor: 'white',
        borderBottom: "none",
        transition: 'background 1000ms',
        color: "#6700B9",
        // fontFamily: 'RadnikaRegular'
    },
    card: {
        backgroundColor: 'white',
        color: "#6700B9",
        borderBottom: "none",
        transition: 'background 1000ms',
    },
    headerBG: {
        'backgroundColor': '#facc02',
        'position': 'fixed',
        'zIndex': '2'
    },
    greenText: {
        color: "rgb(36, 177, 75)",
        cursor: "pointer",
    },
    closeBtn: {
        width: '8%',
        float: 'right',
        textAlign: 'left'
    }
}
function FAQs() {
    const globalStates = useHookState(GlobalStates); // call globalstates

    const details = [
        {
            questionTitle: "What makes your coffee strong?",
            answer: "We are using a unique, handcrafted coffee technique that brings out the strong and smoky flavor of our coffee!"
        },
        {
            questionTitle: "What type of coffee are you using?",
            answer: "Our coffee is a blend of high-quality Robusta and Arabica beans, sourced both locally and internationally."
        },
        {
            questionTitle: "Where can I buy your coffee?",
            answer: <><b>Online: </b>You can order your coffee on this website by clicking <b><a href="/menu/all" style={{ all: "unset", cursor: "pointer" }} >here</a></b>. We can also deliver through Foodpanda and GrabFood. We are currently available in 46 locations nationwide. <br />
                <b>IN-STORE:</b> Order Coffee from one of our (11) Grab & Go store in the Philippines! <br />
                Check out the completelist of available branches and its operating hours in the Philippines.<br />
            </>
        },
        {
            questionTitle: "Are you available for special events or pop-ups",
            answer: <> For sure! Send us more details at <a>hello@dailybyboscoffee.com</a> so we can start planning together.
            </>
        },
        {
            questionTitle: "How to franchise Daily by Bo’s Coffee?",
            answer: (<>Thanks for your interest in growing the brand with us! Click <b> <a href="/franchise" style={{ all: "unset", cursor: "pointer" }} >here</a> </b>to know more about Daily’s franchising opportunities.</>)
        }
    ]
    const [hidden, setHidden] = useState([true, true, true, true, true, true])
    const [refresh, setRefresh] = useState(true)
    const [screen, setScreen] = useState(window.innerWidth);
    let isMobile = screen <= 767
   //Photos
    const starburst = `/banner/Bullet starburst teal.png`
    const dropdown = `/banner/Dropdown arrow-10.png`

    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function clickCard(category) {
        let tempHidden = hidden;
        tempHidden[category] = !hidden[category]
        setHidden(tempHidden)
        setRefresh(!refresh)
    }

    console.log(isMobile)
    return (
        <React.Fragment>
            <div className="container p-3 mt-2">
                <div className="row" style={{ 'paddingBottom': '20%' }}>
                    <div className="col-xs-1 col-lg-2" />
                    <div className="col-xs-10 col-lg-8" >
                        {details.map((key, i) => {
                            return (
                                <Card key={key.questionTitle} onClick={() => clickCard(i)} style={styles.box}>
                                    <Card.Header as="h6" style={styles.header}>
                                        <div className="row">
                                            <div className="col-1">
                                                <img
                                                    alt={`starburst-teal`}
                                                    src={starburst}
                                                    style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        marginRight: "5px"
                                                    }}
                                                />
                                            </div>
                                            <div className={isMobile ? "col-9" : "col-10"}>
                                               <b> {key.questionTitle}</b>
                                            </div>
                                            <div className="col-1">
                                                <img
                                                    alt={`dropdown`}
                                                    src={dropdown}
                                                    style={{
                                                        width: "30px",
                                                        height: "30px",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body hidden={hidden[i]} style={styles.card}>
                                        <Card.Text>
                                            {key['answer']}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            )
                        })}
                    </div>
                    <div className="col-xs-1 col-lg-2" />
                </div>
            </div>
        </React.Fragment >
    )
}

export default FAQs;