const INTIAL_STATE = {
    categoryList: []
}

const CategoryReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_CATEGORY':
            return {
                ...state,
                categoryList: action.category
            }

        default:
            return state;
    }

};

export default CategoryReducer;