import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHookstate } from '@hookstate/core';
import GlobalStates from '../../../../utility/GlobalStates';

import api from '../../../../utility/Endpoints';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import ConstantStyles from "../../../../utility/ConstantStyle.json";
import MovingBannerModal from './MovingBannerModal'

const MovingBannerList = () => {
    const globalStates = useHookstate(GlobalStates)
    let daily_image_path = process.env.REACT_APP_DAILY_MOVING_PATH || "https://staging-api.zagana.com/uploads/moving_image/"
    const [images, setImages] = useState([]);// data / value for scroll
    const [selectedImage, setSelectedImage] = useState({})
    const FetchMovingImages = () => {
        api.movingImages.get() // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                console.table(result)
                setImages(result)
            })
            .catch(error => {
                // globalStates.isLoading.set(false)
                console.log(error);
            })
    }
    useState(() => {
        FetchMovingImages()
    }, [])

    const itemImage = (props) => {
        const itemImage = props.row.src; // declare itemName from props
        return (
            <div id={`itemImage${props.row.id}`}>
                <img src={`${daily_image_path}${itemImage}`}  width="60px"/>
            </div>
        );
    }

    const renderActions = (props) => {
    return (
      <div>
          <Button
            style={{ float: "right" }}
                onClick={
                    () => {
                        globalStates.modalOpenCreate.set(true)
                        setSelectedImage(props.row)
                    }
                }
            value={props.row.id}
            >
                <EditIcon/>
          </Button>
      </div>
    );
    }

    let columns = [
        { field: 'id', headerName: 'id', type: 'text', minWidth: 120, editable: false },
        { field: 'src', headerName: 'Image', type: 'text', minWidth: 120, renderCell: itemImage},
        { field: 'top', headerName: 'Top Position', type: 'text', minWidth: 120, editable: false },
        { field: 'right', headerName: 'Right Position', type: 'text', minWidth: 120, editable: false },
        { field: 'transformDeg', headerName: 'Rotate', type: 'text', minWidth: 120, editable: false },
        { field: 'toPosition', headerName: 'Move to', type: 'text', minWidth: 120, editable: false },
        { field: 'width', headerName: 'Width', type: 'text', minWidth: 120, editable: false },
        { field: 'actions', headerName: 'Actions', type: 'text', minWidth: 120, editable: false, renderCell: renderActions },
    ]

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                color: 'text.secondary',
                },
                '& .textPrimary': {
                color: 'text.primary',
                },
            }}
        >
            <DataGrid
                rows={images}
                columns={columns}
                // pageSize={5}
                // rowsPerPageOptions={[5]}
            />
            <MovingBannerModal selectedImage={selectedImage} FetchMovingImages={FetchMovingImages} />
        </Box>
  );
}
export default MovingBannerList;