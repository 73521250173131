// react library
import React, { useEffect, useState } from "react";

//api endpoints

//Global States, call funtion
import ConstantStyles from "../../../utility/ConstantStyle.json";

// mui components
//material ui library/components
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
// import helperText from '@mui/material/helperText';
import FormHelperText from '@mui/material/FormHelperText';


import PropTypes from "prop-types";
//import from general folder

const RadioOption = (props) => {
    const rowId = props.row
    const field = props.field
    const fieldsValue = props.fieldsValue
    const setFieldsValue = props.setFieldsValue
    const type = field?.type
    const [error, setError] = useState(field?.error)
    const [errorMsg, setErrorMsg] = useState(field?.errorMsg)
    const [value, setValue] = useState('');

    // update error status if changes
    useEffect(()=>{
      setError(field.error)
    },[field.error])

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setError(false);
        let newField = fieldsValue
        setFieldsValue({
            ...newField,
            [rowId]: {
                field: field?.field,
                type: type,
                errorMsg:errorMsg,
                formatted: field?.formatted,
                value: event.target.value,
                error:false
            }
        })
    };
    return (
        <FormControl error={error} variant="standard">
          {/* <FormLabel id="demo-error-radios">Pop quiz: MUI is...</FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-error-radios"
            name="quiz"
            value={value}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="Male" control={<Radio  size="small"/>} label="Male" sx={{color: ConstantStyles['purple']}}/>
            <FormControlLabel value="Female" control={<Radio  size="small"/>} label="Female" sx={{color: ConstantStyles['purple']}}/>
            <FormControlLabel value="Prefer not to say" control={<Radio  size="small"/>} label="Prefer not to say"  sx={{color: ConstantStyles['purple']}}/>
          </RadioGroup>
          {
            error && <FormHelperText>{errorMsg}</FormHelperText>
          }
          {/* <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
            Check Answer
          </Button> */}
        </FormControl>
    );
}

RadioOption.propTypes = {
  field: PropTypes.object,
  type: PropTypes.string,
  variant: PropTypes.string,
  fwidth: PropTypes.bool
};
RadioOption.defaultProps = {
  field: {},
  type: "text",
  variant: "outlined",
  fwidth: false,
}

export default RadioOption;