const INTIAL_STATE = {
    createdOrder: {},
    payment: {}
}

const OrderReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'CREATED_ORDER':
            return {
                ...state,
                createdOrder: action.order
            }
        case 'ADD_PAYMENT':
            return {
                ...state,
                payment: action.payment
            }
        default:
            return state;
    }

};

export default OrderReducer;