import React, {useState} from 'react';
import PropTypes from "prop-types";
import { useHookstate } from '@hookstate/core';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import OutlinedInput  from '@mui/material/OutlinedInput';
import Container from '@mui/material/Container';
import { Alert, IconButton, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { PhotoCamera } from '@mui/icons-material';

import GlobalStates from '../../../../utility/GlobalStates';
import api,{ updateMovingImage } from '../../../../utility/Endpoints';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const MovingBannerModal = (props) => {
    const theme = useTheme();
    const globalStates = useHookstate(GlobalStates)
    let daily_image_path = process.env.REACT_APP_DAILY_MOVING_PATH || "https://staging-api.zagana.com/uploads/moving_image/"
    let { selectedImage, FetchMovingImages } = props
    const [fileName, setFileName] = useState('No File Chosen')
    const [errMsg, setErrMsg] = useState('')
    const [btnLoad, setBtnLoad] = useState(false)
    const [fileUpload, setFileUpload] = useState()
    const [moveToPosition, setMoveToPosition] = useState(selectedImage.toPosition)
    const [alertHidden, setAlertHidden] = useState(true)
    const [alertMsg, setAlertMsg] = useState("")
    const [alertSeverity, setAlertSeverity] = useState("success")

    useEffect(() => {
        setMoveToPosition(selectedImage.toPosition)
    },[selectedImage])
    const file = (e) => {
        setFileName(e.target.files[0].name || "")
        setFileUpload(e.target.files[0] || "")
    }
    const handleClose = () => globalStates.modalOpenCreate.set(false);
    console.log(selectedImage)
    const handleSubmit = (e) => {
        e.preventDefault()
        setBtnLoad(true)
        const formData = new FormData();
        formData.append('image', fileUpload);
        const paths = {
            dir: __dirname,
            path: '../../../../../public/uploads/image'
        }
        const body = {
            id: selectedImage.id,
            top: e.target.textFieldTop.value,
            right: e.target.textFieldRight.value,
            rotate: e.target.textFieldTransformDeg.value,
            width: e.target.textFieldWidth.value,
            to_place: moveToPosition,
            oldSrc: selectedImage.src,
            // path: filePath
        }
        Object.keys(body).forEach(key => {
            formData.append(key, body[key]);
        });
        updateMovingImage(formData, selectedImage.src)
        .then((res) => {
            globalStates.openAlert.set(true);
            setAlertHidden(false)
            setAlertMsg("Image Updated Successfully")
            setAlertSeverity("success")
            globalStates.snackBarMsg.set('Image Updated Successfully');
            FetchMovingImages()
            setTimeout(() => {
                setAlertHidden(true)
                globalStates.modalOpenCreate.set(false)
                // window.location.href = window.location.pathname;
            },1500);
            setBtnLoad(false)
        })
        .catch((err) => {
            console.log(err.response.data.message)
            setAlertHidden(false)
            setAlertSeverity("danger")
            setAlertMsg(err.response.data.message)
            setErrMsg(err.response.data.message)
            setBtnLoad(false)
        })
    }

    const handleChange = (event) => {
        setMoveToPosition(event.target.value)
    }

    return (
        <div>
            <Modal
                open={globalStates.modalOpenCreate.get()}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{padding: 0, margin: 0}}
            >   
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{margin: 1.5}}>
                        Update Image
                    </Typography>
                    <hr style={{marginTop: 1, marginBottom: 1}}/>
                    <Box sx={{ padding: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2}}>
                                <Alert sx={{width: "100%"}} severity="error" hidden={errMsg ? false : true}>{errMsg}</Alert>
                                <Alert variant="filled" hidden={alertHidden} severity={alertSeverity}>{alertMsg}</Alert>
                            </Box>
                            <Container fixed>
                                <Grid container spacing={2} sx={{ padding:'10px'}}>
                                    <Grid item md={4}>
                                        Image:
                                    </Grid>
                                    <Grid container item md={8}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <IconButton size="large" color="primary" aria-label="upload picture" component="label">
                                                <input
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    name='category_image'
                                                    type="file"
                                                    hidden
                                                    accept=".jpg , .png"
                                                    onChange={file}
                                                />
                                                <PhotoCamera size="large" />
                                            </IconButton>
                                                <Typography sx={{color: 'black', fontSize: '13px'}} align='center'>
                                                {fileName}
                                                <img src={`${daily_image_path}${selectedImage.src}`}  width="60px"/>
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item md={4}>
                                        Top Position:
                                    </Grid>
                                    <Grid item md={8}>
                                        <TextField fullWidth size="small" id="textFieldTop" name="textFieldTop" variant="outlined" required defaultValue={selectedImage.top}/>
                                    </Grid>
                                    <Grid item md={4}>
                                        Right Position:
                                    </Grid>
                                    <Grid item md={8}>
                                        <TextField fullWidth size="small" id="textFieldRight" name="textFieldRight" variant="outlined" required defaultValue={selectedImage.right}/>
                                    </Grid>
                                    <Grid item md={4}>
                                        Rotate:
                                    </Grid>
                                    <Grid item md={8}>
                                        <TextField fullWidth type="number" 
                                        InputProps={{ inputProps: { min: 0, max: 360 } }} size="small" id="textFieldTransformDeg" name="textFieldTransformDeg" variant="outlined" required defaultValue={selectedImage.transformDeg}/>
                                    </Grid>
                                    <Grid item md={4}>
                                        Move to: <br/>(left, center, right)
                                    </Grid>
                                    <Grid item md={8}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={moveToPosition}
                                            label="Age"
                                            onChange={handleChange} 
                                            sx={{width:"100%"}}
                                        >
                                            <MenuItem value={"left"}>Left</MenuItem>
                                            <MenuItem value={"center"}>Center</MenuItem>
                                            <MenuItem value={"right"}>Right</MenuItem>
                                        </Select>
                                        {/* <TextField fullWidth size="small" id="textFieldToPlace" name="textFieldToPlace" variant="outlined" required defaultValue={selectedImage.toPosition}/> */}
                                    </Grid>
                                    <Grid item md={4}>
                                        Width:
                                    </Grid>
                                    <Grid item md={8}>
                                        <TextField fullWidth size="small" id="textFieldWidth" name="textFieldWidth" variant="outlined" required defaultValue={selectedImage.width}/>
                                    </Grid>
                                </Grid>
                            </Container>
                            <Box sx={{width: '100%'}}>
                                <hr style={{marginTop: 1, marginBottom: 1}}/>
                                <Box sx={{display: 'flex', justifyContent:'flex-end', padding: 1.5}}>
                                    <LoadingButton
                                        onClick={() => globalStates.modalOpenCreate.set(false)} 
                                        sx={{marginRight: 1}} 
                                        size="small"
                                        type="submit"
                                        loadingPosition="start"
                                        // disabled={btnLoad}
                                        startIcon={<CloseIcon /> }
                                        >
                                        Close
                                    </LoadingButton>
                                    <LoadingButton
                                        size="small"
                                        type="submit"
                                        loadingPosition="start"
                                        loading={btnLoad}
                                        variant="contained" 
                                        startIcon={<SaveIcon /> }
                                        >
                                        Save
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

MovingBannerModal.propTypes = {
    messages:PropTypes.object,
    run:PropTypes.func,
    btnLoad:PropTypes.bool,
};
MovingBannerModal.defaultProps = {
    messages:{},
    run: undefined,
    btnLoad: false,
};

export default MovingBannerModal