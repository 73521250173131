/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : Footer
 * File Directory           : src/components/Shared/Footer
 * Module                   : All
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : Footer for the website
 * Used In				          : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Footer        -   component for Footer for the whole website
 *
 * #How to call:
 *    <Footer />
 *
 *******************************************************************************************/
import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from "react";
import DailyLogo from "../../assets/logo/daily-logo.png";
import api from "../../utility/Endpoints";
import GlobalStates from "../../utility/GlobalStates";
import foodPanda from "../../../src/assets/logo/foodPanda.png"
import grab from "../../../src/assets/logo/grab.png"


const styles = {
  backgroundColor: {
    backgroundColor: "#00FFFF",
    fontSize: '14px',
    position: 'relative',
    width: '100%',
    margin: '0px',
    padding: '0px'
  },
  buttonDesign: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
    outline: "inherit"
  },
  greenText: {
    color: "rgb(36, 177, 75)",
    cursor: "pointer",
  }
};

function Footer() {
  const globalStates = useHookState(GlobalStates); // call globalstates
  const [showFooter, setShowFooter] = useState(true);
  const fb_logo_img = `/fb.svg`
  const linkedin_logo_img = `/linkedin.svg`
  const ig_logo_img = "ig.svg"
  const [textLink, setTextLink] = useState([])
  const [iconLink, setIconLink] = useState([])
  const [content, setContent] = useState({})
  const [screen, setScreen] = useState([window.innerWidth]);
  let isMobile = screen <= 767

  const fp_logo = `/banner/FP logo.png`;
  const grab_logo = `/banner/Grab logo.png`;
  const starburst_white = "/banner/Footer icon.png"
  useEffect(() => {
    fetchLinks()
    fetchFooter()
  }, [])

  useEffect(() => {
    const handleResize = () => {
        setScreen(window.innerWidth)
    };

    //is loadingset global state
    setTimeout(() => {
    }, 400);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);


  const fetchLinks = () => {
    api.links.fetchLink()
      .then((response) => {
        const result = response.data.map(item => {
          return {
            id: item.id,
            title: item.link_title,
            link: item.link,
            link_icon: item.link_icon,
            link_type: item.link_type,
          }
        })

        const iconLinks = result.filter(item => item.link_type === 'icon').map(item => {
          return {
            img: <img src={item.link_icon ? process.env.REACT_APP_HOST + item.link_icon : fb_logo_img} alt={item.title} width="100%" />,
            link: item.link,
            title: item.title
          }
        })
        const textLinks = result.filter(item => item.link_type === 'text')
        setTextLink(textLinks)
        setIconLink(iconLinks)
      })
      .catch((error) => {
        console.log(error.message);
      })
  }

  const fetchFooter = () => {
    api.footer.fetch()
      .then((response) => {
        const result = response.data
        const tempData = result.map(item => {
          return {
            id: item.id,
            site_logo: item.site_logo,
            site_logo_alt: item.site_logo_alt,
            site_description: item.site_description,
            site_copyright: item.site_copyright,
            footer_headers: JSON.parse(item.footer_headers),
          }
        })
        if (result.length > 0) {
          setContent(tempData[0])
        }
      })
  }

  const details = [
    {
      title: "Menu",
      link: "/menu/all"
    },
    {
      title: "About Us",
      link: "/about-us"
    },
    {
      title: "Franchise",
      link: "/franchise"
    },
    {
      title: "Privacy Policy",
      link: "/"
    },
    {
      title: "Terms & Conditions",
      link: "/"
    }
  ]

  const connectLinks = [
    {
      img: <img src={fb_logo_img} alt="Facebook Link" width="100%" />,
      link: "https://facebook.com/zagana.com.ph/",
      title: "facebook"
    },
    {
      img: <img src={ig_logo_img} alt="Instagram Link" width="100%" />,
      link: "https://www.instagram.com/zagana.com.ph/",
      title: "insta"
    },
    {
      img: <img src={linkedin_logo_img} alt="Linkedin Link" width="100%" />,
      link: "https://www.linkedin.com/company/zagana-inc/",
      title: "linkedin"
    },
  ]
  // const download = [
  //   {
  //     img: <img src={`https://res.cloudinary.com/dw5axbz64/image/upload/v1663835827/icons/playstore_v5jnrq.png`} alt="Download App" width="100%" />,
  //     link: "https://play.google.com/store/search?q=zagana&c=apps",
  //     title: "dowload app"
  //   }
  // ]

  function redirectLink(link) {
    window.location.href = link;
  }

  return (
    <footer id="footer" style={{ padding: 0, marginBottom: isMobile ? '50px' : 'unset' }}>
      {showFooter ?
        <div className="row footer_ container-fluid" style={styles.backgroundColor}>
          <div className={isMobile ? "col-6" : "col-4"}>
            <ul className="list-unstyled">
              <li key={1} className="pb-1 pt-4">
                <h4>
                  <img src={content?.site_logo ? process.env.REACT_APP_HOST + content.site_logo : DailyLogo} alt="Footer Logo" width={isMobile ? "75%" : "75%"} id="daily-logo-footer" />
                </h4>
              </li>
            </ul>
          </div>
          {isMobile ?  <div className={"col-1"} /> : null}

          {isMobile ? null :
            <div className={isMobile ? "col-12" : "col-3"} >
              <ul className="list-unstyled">
                <li key={4} className={isMobile ? null : "pb-1 pt-4"}>
                  <h4>
                    {/* <strong> {content?.footer_headers?.column2 || "Sitemap"} </strong> */}
                  </h4>
                </li>
                {textLink.map(item => {
                  // if (item.title !== "Contact Us")
                  return (
                    <a href={item.link} key={item.title} id={item.title} style={{ fontSize: "1rem", color: "#6700B9", textDecoration: "none" }}>
                      <li key={5} className="pb-1" role="button">
                        {item.title}
                      </li>
                    </a>
                  )
                  // else return (
                  //   <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                  //     <button style={styles.buttonDesign}>Contact Us</button>
                  //   </OverlayTrigger>
                  // )
                })}
              </ul>
            </div>
          }
          <div className={isMobile ? "col-5" : "col-2"}>
            <ul className="list-unstyled">
              <li key={6} className={isMobile ? "pt-4" : "pt-4"}>
                <h4 style={{ fontFamily: 'Bounce-haus-Sans', color: "#6700B9" }}>
                  <strong> {content?.footer_headers?.column3 || "Order Now!"} </strong>
                </h4>
              </li>
              {/* <li key={7}>
                <div className="row pt-2">
                  {iconLink.map(key => {
                    return (
                      <div className=" col-sm-2"
                        onClick={() => {
                          redirectLink(key.link)
                        }} key={key['title']}
                        id={key['title']} role="button">
                        {key['img']}
                      </div>
                    )
                  })}
                </div>
              </li> */}
            </ul>
            <ul className="list-unstyled">
              {/* <li key={6}>
                <h4>
                  <strong> Download our app! </strong>
                </h4>
              </li> */}
              <li key={6} className="pb-1">
                <img alt="food_panda" src={fp_logo} style={{ width: isMobile ? "50px" : "80px", cursor: "pointer" }} onClick={() => { window.location.href = 'https://www.foodpanda.ph/chain/cw6ug/bos-coffee-daily-1' }} />

                <img alt="grab" src={grab_logo} style={{ width: isMobile ? "50px" : "80px", cursor: "pointer" }} onClick={() => { window.location.href = 'https://bit.ly/3FZIiVl' }} />

              </li>
              <li key={7}>
                <div className="row pt-2">
                  {/* {download.map(key => {
                    return (
                      <div className="col-4 col-sm-2"
                        onClick={() => {
                          redirectLink(key.link)
                          const payload = {
                            user: user,
                            changes: key['title'],
                            type: 'Click / Visit: ' + key['title'],
                            date: `NOW()`
                          }
                          storeFrontLogs(payload)
                        }} key={key['title']}
                        id={key['title']} role="button">
                        {key['img']}
                      </div>
                    )
                  })} */}
                </div>
              </li>
            </ul>
          </div>
          {isMobile ? null :
            <div className={isMobile ? "col-6" : "col-2"} >
              <img alt="footer icon" src={starburst_white} style={{ width: isMobile ? "80%" : "80%" }} />

            </div>
          }

        </div>
        : null
      }
    </footer>
  );
}

export default Footer;
