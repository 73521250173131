import React, { useState } from 'react';
import ConstantStyles from "../../../utility/ConstantStyle.json";

import gridSize from "./CardGridSize.json";
//material ui library/components
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import defaultImage from '../../../assets/logo/default.webp';
//const defaultImage = `https://scontent.fmnl17-1.fna.fbcdn.net/v/t39.30808-6/295432466_583897046697296_7947127860492652402_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeGOJU3LIGrKW5uTgiH1Euf80-U8IW-1FbXT5Twhb7UVtVVa_nhvg_yUn2x2P4tQyBi7iLFmwSoSBPiZNbaqb_gq&_nc_ohc=wmNSwXOFQZsAX_CY7Uq&_nc_ht=scontent.fmnl17-1.fna&oh=00_AfCatGERp951GJrAJdSx90xUnw-JLo0MWJIwVL5n68YhwQ&oe=637949D8`

const styles = {
    imageContainer: {
        borderRadius: ConstantStyles["default-border-radius"],
    }, productTextStrong: {
        fontWeight: ConstantStyles['bold']
    },
    card: {
        borderRadius: '30px'
    }
}

const Card = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: window.innerWidth <= 640 ? theme.spacing(1) : '15px'
}));

const ProductCard = (props) => {

    const [productDetails, setProductDetails] = useState(props?.item || {});
    const image_url = productDetails.image_src ? process.env.REACT_APP_HOST + productDetails.image_src : defaultImage

    return (
        <React.Fragment>
            <Card className="itemCard" style={styles.card}>
                <Grid
                    container
                    item
                    columnSpacing={4}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <Grid
                        item
                        xs={gridSize[props.viewType].image.sm}
                        sm={gridSize[props.viewType].image.sm}
                        md={gridSize[props.viewType].image.md}
                        lg={gridSize[props.viewType].image.lg}
                    >
                        <div className='imageContainer'>
                            <img
                                alt={`product-${props.index}`}
                                src={image_url}
                                width="100%"
                                style={styles.imagepPrev}
                            />
                        </div>
                    </Grid>
                    <Grid item
                        xs={gridSize[props.viewType].details.sm}
                        sm={gridSize[props.viewType].details.sm}
                        md={gridSize[props.viewType].details.md}
                        lg={gridSize[props.viewType].details.lg}
                    >
                        <div className='itemDetails'>
                            <Typography variant="h5" style={styles.productTextStrong} className='itemName'>{productDetails?.name}</Typography>
                            <Typography
                                variant="body1"
                                display={
                                    props.viewType === "recommended" ?
                                        { xs: "none", lg: "block" }
                                        :
                                        {}
                                }
                                className='itemDescription mb-0'
                            >
                                {productDetails.description}
                            </Typography>
                            <Typography variant="body1" className="itemPrice">PHP {(productDetails?.price?.toFixed(2))?.toLocaleString()}</Typography>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </React.Fragment>
    );


}


export default ProductCard;