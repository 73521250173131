import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHookstate } from '@hookstate/core';
import GlobalStates from '../../../../utility/GlobalStates';

import api from '../../../../utility/Endpoints';
// MUI imports
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import OutlinedInput  from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ImageIcon from '@mui/icons-material/Image';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGrid, GridRowModes, GridActionsCellItem, GridRenderEditCellParams } from '@mui/x-data-grid';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProductTable = ({ data, modifiers, CustomToolbar, categoryList }) => {
    const theme = useTheme();
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let branchId = userInfo[0].branchId || 1
    const globalStates = useHookstate(GlobalStates)
    const [rows, setRows] = useState(data);
    const [rowModesModel, setRowModesModel] = useState({});
    const [errMsg, setErrMsg] = useState('')
    let confirmed = globalStates.confirmed.get()

    useEffect(()=>{
        FetchProducts()
    },[])

    useEffect(() => {
        if (globalStates.modalOpenDelete.get() === true && confirmed) {
            let item = JSON.parse(globalStates.selectedProduct.get())
            globalStates.confirmed.set(false)
            // api archive function
        }
    },[confirmed])



    const FetchProducts = () => {
        api.product.fetch_list(branchId) // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                setRows(result)
            })
            .catch(error => {
                globalStates.isLoading.set(false)
                console.log(error);
            })
    }

    //On View Row - render category column
    const RenderViewCategory = (props) => {
        const { id, value, api, colDef, field, row } = props;
        return (
            <div style={{ width: '100%' }}>
                {value.toString()}
            </div>
        )
    }

    //On Edit Row - render category column
    const RenderEditSelect = (props) => {
        const { id, value=[], api, colDef, field, row } = props;
        const [selectedCategory, setSelectedCategory] = useState(value||[]);
        const [valueState, setValueState] = useState(value||[]);
        //handling onchange value of the textarea
        const handleCategoryChange = useCallback((event) => {
            const {
            target: { value },
            } = event;
            setSelectedCategory(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
            );
            const newValue = typeof value === 'string' ? value.split(',') : value;
            setValueState(newValue);
            api.setEditCellValue({ id, field, value: newValue }, event);
        },
            [api, field, id]
        );
        function getStyles(name, personName, theme) {
            return {
              fontWeight:
                personName.indexOf(name) === -1
                  ? theme.typography.fontWeightRegular
                  : theme.typography.fontWeightMedium,
            };
        }
        // rendering Select
        return (
            <div style={{ width: '100%' }}>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    input={<OutlinedInput  id="selectCategory" name="selectCategory" fullWidth size="small" required/>}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {categoryList.map((categoryName) => (
                        <MenuItem
                        key={categoryName}
                        value={categoryName}
                        style={getStyles(categoryName, selectedCategory, theme)}
                        >
                        {categoryName}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        );
    }
    
    const RenderAvailabilityToggle = (props) => {
        const { id, value, api, colDef, field, row } = props;
        const [valueState, setValueState] = useState(value===1?true:false);
        const [anchorEl, setAnchorEl] = useState() || null;
        const [checked, setChecked] = useState(value===1?true:false);

        // setting position of the popper
        const handleRef = (el) => {
            setAnchorEl(el);
        };

        //handling onchange value of the textarea
        const handleChange = useCallback((event) => {
            const newValue = event.target.checked;
            setValueState(newValue);
            setChecked(event.target.checked);
            api.setEditCellValue({ id, field, value: newValue }, event);
            row.status = newValue ? 1 : 0
            handleChangeStatus(id,row.status)
        },
            [api, field, id]
        );
        // rendering textarea
        return (
            <div>
                <div
                    ref={handleRef}
                    // style={style.marginTop150}
                />
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </div>
        );
    }
    // table columns
    let initialColumn = [
        { field: 'sku', headerName: 'SKU', type: 'text', minWidth: 120, editable: false },
        { field: 'name', headerName: 'Name', minWidth: 150, editable: true,
        },
        {
            field: 'price',
            headerName: 'Price',
            type: 'number',
            minWidth: 90,
            editable: true,
        },
        {
            field: 'category',
            headerName: 'Category',
            type: 'text',
            minWidth: 200,
            editable: true,
            renderCell:  (params) => {
                return <RenderViewCategory {...params} /> //textarea component
            },
            renderEditCell: (params) => {
                return <RenderEditSelect {...params} /> //textarea component
            },  
        },
        {
            field: 'description',
            headerName: 'Description',
            type: 'text',
            minWidth: 140,
            editable: true,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 100,
            renderCell:  (params) => {
                return <RenderAvailabilityToggle {...params} /> //textarea component
            },
            renderEditCell:  (params) => {
                return <RenderAvailabilityToggle {...params} /> //textarea component
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            minWidth: 100,
            cellClassName: 'actions',
            getActions: (props) => {
                const {id, row} = props
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                return [
                    <GridActionsCellItem
                        icon={<ImageIcon />}
                        label="ImageUpdate"
                        className="textPrimary"
                        onClick={() => { globalStates.modalOpenUpdate.set(true); globalStates.selectedProduct.set(JSON.stringify(row)) }}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    onClick={handleSaveClick(id, row.mode)}
                    />,
                    <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id, row.mode)}
                    color="inherit"
                    />,
                ];
                }

                return [
                <GridActionsCellItem
                    icon={<ImageIcon />}
                    label="ImageUpdate"
                    className="textPrimary"
                    onClick={() => { globalStates.modalOpenUpdate.set(true); globalStates.selectedProduct.set(JSON.stringify(row)) }}
                    color="inherit"
                />,
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Edit"
                    className="textPrimary"
                    onClick={handleEditClick(id, row.mode)}
                    color="inherit"
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={handleDeleteClick(id, row.mode)}
                    color="inherit"
                />,
                ];
            }
        }
    ]

    // column modifiers 
    modifiers.forEach(element => {
        initialColumn.splice(4, 0, {
            field: (element.group_name).replace(/ /g, ''),
            headerName: element.group_name,
            minWidth: 90,
            type: 'boolean',
            editable: true,
        })
    });

    // handle action on row start edit
    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    // handle action on row end edit
    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    // handle action on click edit button
    const handleEditClick = (id, rowMode) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    // handle action on click save button
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    // handle action on click delete button
    const handleDeleteClick = (id) => () => {
        globalStates.confirmed.get(false)
        globalStates.modalOpenDelete.set(true)
        globalStates.selectedProduct.set(JSON.stringify(rows.filter((row) => row.id === id)[0]))
        // setRows(rows.filter((row) => row.id !== id));
    };

    // handle action on click cancel button
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        handleUpdateItem(newRow)
        return updatedRow;
    };

    //Save Updated Item
    const handleChangeStatus = (id, status) => {
        const body = {
            id: id,
            status: status,
            branchId
        }
        api.product.updateBranchStatus(body)
        .then((res) => {
            console.log(res)
            globalStates.openAlert.set(true);
            globalStates.snackBarMsg.set(`Product Status Updated Successfully`);
        })
        .catch((err) => {
            // console.log(err.response.data.message)
            setErrMsg(err.response.data.message)
            // setErrMsg(err.response.data.message)
            // setBtnLoad(false)
        })
        
    }

    //Save Updated Item
    const handleUpdateItem = (newRow) => {
        // column modifiers 
        let modifierList = []
        modifiers.forEach(element => {
            if (newRow[(element.group_name).replace(/ /g, '')])
                modifierList.push({
                    id:element.group_id
                })
            
        })
        const body = {
            id: newRow.id,
            name: newRow.name,
            sku: newRow.sku,
            price: parseFloat(newRow.price),
            description: newRow.description,
            modifiers: JSON.stringify(modifierList),
            category: JSON.stringify(newRow.category)
        }
        api.product.updateInfo(body)
        .then((res) => {
            console.log(res)
            globalStates.openAlert.set(true);
            globalStates.snackBarMsg.set('Item Updated Successfully');
        })
        .catch((err) => {
            // console.log(err.response.data.message)
            setRowModesModel({ ...rowModesModel, [newRow.id]: { mode: GridRowModes.Edit } });  
            setErrMsg(err.response.data.message)
            // setErrMsg(err.response.data.message)
            // setBtnLoad(false)
        })
        
    }

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                color: 'text.secondary',
                },
                '& .textPrimary': {
                color: 'text.primary',
                },
            }}
        >
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2}}>
                <Alert sx={{width: "100%"}} severity="error" hidden={errMsg ? false : true}>{errMsg}</Alert>
            </Box>
            {
                
                    !globalStates.isLoading.get() &&
                <DataGrid
                    rows={rows}
                    columns={initialColumn}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    experimentalFeatures={{ newEditingApi: true }}
                    loading={globalStates.isLoading.get()}
                    components={{ 
                        Toolbar: CustomToolbar, 
                        LoadingOverlay: LinearProgress, 
                    }}
                />
            }
        </Box>
    );
}

export default ProductTable;