import { useState as useHookState } from "@hookstate/core";
import * as React from 'react';
import { useEffect, useState } from "react";

//api endpoints
import api from "../../../utility/Endpoints";

//Global States, call funtion
import ConstantStyles from "../../../utility/ConstantStyle.json";
import GlobalStates from "../../../utility/GlobalStates";

//material ui library/components
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

// mui components grid, cards
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

//created components
import InputAutocomplete from '../../shared/InputAutocomplete';

//style starts here
const styles = {
    spacing: {
        width: "80%",
        margin: "10px auto",
        display: "block"
    },
    defaultHeight: {
        minHeight: "100vh",

    },
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"]
    },
    backGround : {
        backgroundColor: 'white',
        width : '100%'
    }
}


// options for font selection
const fonts = [
    { label: 'RadnikaMedium', value: "RadnikaMedium" },
    { label: 'RadnikaRegular', value: "RadnikaRegular" },
    { label: 'PoppinsItalic', value: "PoppinsItalic" }
];


const Announcement = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    //useStates
    const [id, setId] = useState(0);// data / value for announcement title
    const [announcement, setAnnouncement] = useState("");// data / value for announcement title
    const [show, setShow] = useState(0);// data / value for announcement title
    const [background, setBackground] = useState("#ffffff");// data / value for background
    const [fontColor, setFontColor] = useState("#000000");// data / value for font color
    const [fontSection, setFontSection] = useState("");// data / value for font color


    useEffect(() => {
        getAnnouncement(); // call function
    }, []);

    const getAnnouncement = () => {
        api.announcement.get() // function from endpoint
            .then(response => {
                globalStates.isLoading.set(true); // loading, false
                let result = response.data.result; // get result from api
                let attributes = JSON.parse(result[0].attribute)
                setId(result[0].id)
                setFontSection(attributes.fontSection);
                setBackground(attributes.background);
                setFontColor(attributes.fontColor);
                setAnnouncement(result[0].announcement);
                setShow(result[0].status);
            })
            .catch(error => {
                console.log(error);
            })
    } // fetch / get list from endpoints

    const updateAnnouncement = () => {

        let payload = {
            announcement: announcement,
            status: show,
            user: globalStates.userEmail.get(),
            attributes: {
                background: background,
                fontColor: fontColor,
                fontSection: fontSection
            }
        }
        if (announcement !== "") {
            api.announcement.put(id, payload)
                .then(response => {
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("success");
                    globalStates.msgAlert.set("Success: Updated Successfully!");
                })
                .catch(error => {
                    globalStates.statusAlert.set("error");
                    globalStates.msgAlert.set(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                    }, 2000);
                });
        }
        else {
            globalStates.openAlert.set(true);
            globalStates.statusAlert.set("error");
            globalStates.msgAlert.set("Required: Please fillup all required Fields");
        }

    }

    return (

        <Grid
            container
            spacing={0}
            style={styles.defaultHeight}
        >

            <Grid item xs={12}>
                <Card
                    style={styles.spacing}>
                    <CardContent>
                        <strong>Set up: Announcement</strong>
                    </CardContent>
                    <form style={styles.spacing} >
                        <Grid container item xs={12} md={12} lg={12} spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Announcement"
                                    variant="outlined"
                                    size="small"
                                    required
                                    sx={{ width: '100%' }}
                                    value={announcement}
                                    onChange={(e) => {
                                        setAnnouncement(e.target.value)
                                    }}
                                />
                            </Grid>
                            {/* font + background settings */}
                            <Grid item xs={4} md={4} lg={4}>
                                <InputAutocomplete
                                    options={fonts}
                                    value={fontSection}
                                    title="Font Section"
                                    onChange={(e) => {
                                        setFontSection(e.target.innerHTML)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>

                                <TextField
                                    id="outlined-basic"
                                    label="Font Color"
                                    type="color"
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: '100%' }}
                                    value={fontColor}
                                    defaultValue={fontColor}
                                    onChange={(e) => {
                                        setFontColor(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} md={4} lg={4}>

                                <TextField
                                    id="outlined-basic"
                                    label="Background Color"
                                    type="color"
                                    variant="outlined"
                                    size="small"
                                    sx={{ width: '100%' }}
                                    value={background}
                                    defaultValue={background}
                                    onChange={(e) => {
                                        setBackground(e.target.value)
                                    }}
                                />

                            </Grid>
                            {/* radio button */}

                            <Grid item xs={12} md={12} lg={12}>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    value={show}
                                    onChange={(e) => {
                                        setShow(e.target.value)
                                    }}
                                >
                                    <FormControlLabel
                                        value="1"
                                        control={<Radio
                                            size="small" />}
                                        label="Show"
                                        size="small"
                                    />
                                    <FormControlLabel
                                        value="0"
                                        control={<Radio
                                            size="small" />}
                                        label="Hide"
                                        size="small"
                                    />
                                </RadioGroup>
                            </Grid>
                            {/* button */}
                            <Grid item xs={12} md={12} lg={12}>
                                <input type="button" value="Save Change(s)" className="btn float-end mt-2" style={styles.btnPurple} onClick={updateAnnouncement} />
                            </Grid>
                        </Grid>
                    </form>
                </Card>
            </Grid>
        </Grid>
    )

}

export default Announcement;