import React, {useState} from 'react';
import { useHookstate } from '@hookstate/core';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Alert, FormHelperText, IconButton, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { PhotoCamera } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';

import GlobalStates from '../../../../utility/GlobalStates';
import api from '../../../../utility/Endpoints';
import ConfirmButton from '../../../shared/ConfirmButton';
import { useEffect } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: "5px",
  boxShadow: 24,
  warning: {
    fontSize: "13px"
  },
  textField: {
    width: "100%"
  },
  requiredWarning: {
    width: "100%",
  }
};

const EditModal = (props) => {
  const globalStates = useHookstate(GlobalStates)
  const { refresh, id, category, data } = props // data from parent component 
  const [fileName, setFileName] = useState('No File Chosen') // image filename
  const [fileUpload, setFileUpload] = useState() // image file 
  const [btnLoad, setBtnLoad] = useState(false) // loading button trigger
  const [open, setOpen] = useState(false) // modal trigger
  const [duplicate, setDuplicate] = useState('') // error message
    
  useEffect(() => { // set default data onload
    setDuplicate('')
  }, [open])

  const handleClose = () => setOpen(false) // close modal
  const handleOpen = () => setOpen(true); // open modal

  const file = (e) => { // file from input
    setFileName(e.target.files[0].name || "")
    setFileUpload(e.target.files[0] || "")
  }
  const handleSubmit = (e) => { // submit 
    e.preventDefault()
    setBtnLoad(true)
    const formData = new FormData();
    const category_name = e.target.category_name.value
    const paths = {
      dir: __dirname,
      path: '../../../../../public/uploads/image'
    }
    formData.append('image', fileUpload);

    if (duplicate === '') { // no duplicate category name proceed
      api.category.upload_image(formData, paths)
      .then((response) => {
        const filePath = response.data.result
        const body = {
          id: data.id,
          name: category_name,
          old_name: data.name,
          status: data.status,
          path : filePath
        }
        api.category.update(body)
        .then((res) => {
          if (res.data.error) {
            setBtnLoad(false)
          }else{
              globalStates.modalOpenUpdate.set(false)
              refresh()
              handleClose()
              setBtnLoad(false)
          }
        })
        .catch((err) => {
          console.log(err);
          setBtnLoad(false)
          globalStates.openAlert.set(true)
          globalStates.statusAlert.set('error')
          globalStates.msgAlert.set(err)
          setInterval(() => {
              globalStates.openAlert.set(false)
          })
        })
      })
      .catch((error) => {
        console.log(error);
        setBtnLoad(false)
        globalStates.openAlert.set(true)
        globalStates.statusAlert.set('error')
        globalStates.msgAlert.set(error)
        setInterval(() => {
            globalStates.openAlert.set(false)
        })
      })
    } else {
      setBtnLoad(false)
    }

  }

  const handleChange = (e) => { // onChange category value
    e.preventDefault()

    const typed = e.target.value
    const filterCategory = category.filter( category => category.name === typed && category.name !== data.name)
    
    // set duplicate
    if (filterCategory.length > 0) {
        setDuplicate("Branch already exist")
    }else{
        setDuplicate('')
    }
}
  return (
    <div>
      <LoadingButton 
          size="small"
          color="success"
          id={id}
          loadingPosition="start"
          onClick={handleOpen}
          startIcon={<EditIcon />}
          variant="outlined"
          >
          Edit
      </LoadingButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{padding: 0, margin: 0}}
      >   
        <Box sx={style}>
          <Typography align='center' variant='h5' sx={{margin: 1.5}}>
              Update Category
          </Typography>
          <hr style={{marginTop: 1, marginBottom: 1}}/>
          <form onSubmit={handleSubmit}>
            <Box sx={{padding: 2}} >
              <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                <Box>
                  <Typography>
                    Category name:
                  </Typography>
                </Box>
                <Box width={"60%"}>
                    <TextField onChange={handleChange} defaultValue={data.name} name='category_name' size='small' label="Category" sx={{ width: '100%'}} variant="outlined" required/>
                
                    <FormHelperText sx={{display : duplicate ? '': 'none', color: 'red'}}>{duplicate}</FormHelperText>
                </Box>
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                  <Typography>Image :</Typography>
                </Box>
                <Box width={"60%"}>
                  <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <IconButton size="large" color="primary" aria-label="upload picture" component="label">
                      <input
                        style={{
                            cursor: 'pointer'
                        }}
                        name='category_image'
                        type="file"
                        hidden
                        accept=".jpg , .png"
                        onChange={file}
                      />
                      <PhotoCamera size="large" />
                    </IconButton>
                    <Typography sx={{color: 'black', fontSize: '13px'}} align='center'>
                      {fileName}
                    </Typography>
                  </Box>
                  <i style={style.warning}>Kindly upload 32x32 px</i>
                </Box>
              </Box>
            </Box>
            <Box sx={{width: '100%'}}>
              <hr style={{marginTop: 1, marginBottom: 1}}/>
              <ConfirmButton 
                margin={1}
                close={handleClose} 
                btnLoad={btnLoad} />
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default EditModal