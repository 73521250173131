import { useState as useHookState } from "@hookstate/core";
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import ConstantStyles from '../../../utility/ConstantStyle.json';
import api from '../../../utility/Endpoints';
import GlobalStates from '../../../utility/GlobalStates';
import { addAddress, selectedAddress, setShippingFee } from "../../actions";

const styles = {
    selected: {
        display: 'inline-block',
        width: '255px',
        p: 2,
        m: "5px",
        position: 'relative',
        border: `2px solid ${ConstantStyles['purple']}`,
        cursor: 'pointer',
        borderRadius: '30px'
    },
    card: {
        display: 'inline-block',
        width: '255px',
        p: 2,
        m: "5px",
        position: 'relative',
        cursor: 'pointer',
        borderRadius: '30px'
    }
}


const AddressList = (props) => {
    const { store, isUpdate, checkout } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [address, setAddress] = useState([])
    const [selected, setSelected] = useState()
    const customer = JSON.parse(localStorage.getItem('customerInfo'));
    const isGuestSignedIn = localStorage.getItem('guestSignIn') === 'true';
    const isCustomerSignedIn = localStorage.getItem('customerSignedIn') === 'true';
    const dispatch = useDispatch()

    useEffect(() => {

        if (isCustomerSignedIn && !isGuestSignedIn) {
            api.address.addressList({ city: store.city, customer: customer.customer_id, type: checkout.type })
                .then(response => {
                    const result = response.data.result || []
                    result.forEach(address => {
                        if (address.status === '2') {
                            const selected = [{
                                address_id: address.id,
                                name: address.name,
                                number: address.house,
                                street: address.street,
                                brgy: address.brgy,
                                city: address.city,
                                postal_code: address.areaCode,
                                phone: address.phone
                            }]
                            dispatch(selectedAddress(selected))
                            dispatch(setShippingFee(150))
                            setSelected(address.id)
                        }
                    });
                    setAddress(result)
                })
                .catch(error => {
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("error");
                    globalStates.msgAlert.set(error.message);
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                        globalStates.isLoading.set(true); // loading
                    }, 3000);
                });
        } else {
            const guestAddress = JSON.parse(localStorage.getItem('guestAddress'))
            if (guestAddress[0]?.type === checkout.type) {
                const selected = [{
                    address_id: guestAddress[0].address_id,
                    name: guestAddress[0].name,
                    number: guestAddress[0].number,
                    street: guestAddress[0].street,
                    brgy: guestAddress[0].brgy,
                    city: guestAddress[0].city,
                    postal_code: guestAddress[0].postal_code,
                    phone: guestAddress[0].phone
                }]
                dispatch(selectedAddress(selected))
                const address = [{
                    id: guestAddress[0].address_id,
                    name: guestAddress[0].name,
                    house: guestAddress[0].number,
                    street: guestAddress[0].street,
                    brgy: guestAddress[0].brgy,
                    city: guestAddress[0].city,
                    areaCode: guestAddress[0].postal_code,
                    phone: guestAddress[0].phone,
                    type: checkout.type
                }]
                setAddress(address)
                setSelected(address[0].id)
            } else {
                const address = []
                setAddress(address)
            }
        }
    }, [isUpdate, store, checkout.type])

    const onClickIcon = (e, data) => {
        e.stopPropagation()
        dispatch(addAddress(data))
        globalStates.openAddressDrawer.set(true)
        globalStates.addressType.set('edit')
    }

    const onClickCard = (e, address) => {

        const { id } = e.target
        const selected = [{
            address_id: address.id,
            name: address.name,
            number: address.house,
            street: address.street,
            brgy: address.brgy,
            city: address.city,
            areaCode: address.areaCode,
            phone: address.phone
        }]
        dispatch(selectedAddress(selected))
        dispatch(setShippingFee(100))
        setSelected(parseInt(id))
        if (isGuestSignedIn) {
            const guestAddress = JSON.parse(localStorage.getItem('guestAddress'))
            dispatch(selectedAddress(guestAddress))
            setSelected(parseInt(id))
        } else {
            const payload = { customer_id: customer.customer_id, city_id: store.city, address_id: id }
            api.address.selectedAddress(payload)
                .then(response => {
                    if (response.data.result === 'success') {
                        globalStates.openAlert.set(true);
                        globalStates.statusAlert.set("success");
                        globalStates.msgAlert.set('Address changed');
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                        globalStates.isLoading.set(true); // loading
                    }, 3000);
                });
        }
    }

    return (
        <React.Fragment>
            <Box sx={{ width: "100%", minHeight: checkout.type === "Delivery" ? "160px" : "100px", overflow: 'auto', whiteSpace: 'nowrap' }}>
                {address?.map((address, index) => {
                    address.addressInfo = address.house + ", " + address.street + ", " + address.brgy + ", " + address.city + ", " + address.areaCode

                    return (
                        <React.Fragment key={index}>
                            <Card id={address.id} sx={selected === address.id ? styles.selected : styles.card} onClick={(e) => onClickCard(e, address)}>
                                {selected === address.id ?
                                    <CheckCircleOutlineIcon id={address.id} sx={{ position: 'absolute', top: "5px", right: "10px", color: ConstantStyles['purple'] }} />
                                    :
                                    null
                                }
                                <Grid container sx={{ width: '100%', pointerEvents: 'none' }} spacing={0.5}>
                                    <Grid item xs={12}>
                                        <strong>{address.name}</strong>
                                    </Grid>
                                    {checkout.type === "Delivery" ?
                                        < Grid item xs={12} sx={{ whiteSpace: 'normal', height: '65px', lineHeight: '18px' }}>
                                            <span>{_.get(address, ['addressInfo'], '').length >= 77 ? `${address.addressInfo.substring(0, 77)}...` : address.addressInfo} </span>
                                        </Grid>
                                        : null
                                    }
                                    <Grid item xs={12} sx={{ whiteSpace: 'normal' }}>
                                        <strong>{address.phone} </strong>
                                    </Grid>
                                    {checkout.type === "Delivery" ?
                                        null :
                                        <Grid item xs={12} sx={{ whiteSpace: 'normal' }}>
                                            <strong>{store.branchName} </strong>

                                        </Grid>}
                                </Grid>
                                <button style={{ position: 'absolute', bottom: "7px", right: "10px", border: 'none', background: 'unset' }} onClick={(e) => onClickIcon(e, address)}>
                                    <BorderColorOutlinedIcon />
                                </button>
                            </Card>
                        </React.Fragment>
                    )
                })}
            </Box >
        </React.Fragment >
    )
}

const mapStateToProps = (state) => {
    return {
        store: state.store.selectedStore,
        isUpdate: state.update,
        checkout: state.checkout,
    }
}
export default connect(mapStateToProps)(AddressList);