import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
    return (
        <React.Fragment>
            <div className="container" style={{minHeight : '45vw'}}>
                <div className="row text-center justify-content-center">
                    <strong>PAGE NOT FOUND</strong>
                    <Link to="/" >Home</Link>
                </div>
            </div>
        </React.Fragment>
    )


}

export default Page404;