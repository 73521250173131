const INTIAL_STATE = {
    search_value: ''
}

const SearchReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SEARCH_PRODUCT':
            return {
                ...state,
                search_value: action.value
            };
        default:
            return state;
    }

};

export default SearchReducer;