// react library
import { useState as useHookState } from "@hookstate/core";
import { useEffect } from "react";
import { useState } from "react";
import React from "react";

import GoogleMapReact from 'google-map-react';
import DailyLogo from "../../../assets/logo/daily-logo.png";


//material ui library/components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';


const SimpleMap = () => {
    const [selectedCenter, setSelectedCenter] = useState({ lat: 14.5499344, lng: 121.0251539 });
    const [showInfoWindow, setShowInfoWindow] = useState(true)


    useEffect(() => {
        document.title = 'Location';
    },
        [selectedCenter])


    const AnyReactComponent = () => <>
        <img src={DailyLogo} width="100" />
    </>;

    const defaultProps = {
        center: { lat: 14.5499344, lng: 121.0251539 },
        zoom: 11
    };

    const markers = [
        { hours: <>Mon-Sun: 10:00AM - 9:00PM</>, region: "Luzon", name: "SM Southmall", address: "(Unit CT-005N) Level 1, Entertainment Mall, SM Southmall, Alabang–Zapote Rd, Almanza, Las Piñas, 1750 Metro Manila", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 14.4336617, lng: 121.0106475 },
        {
            hours: <>Mon-Thu: 10:00AM - 9:00PM<br />
                Fri-Sun: 10:00AM - 10:00PM </>, region: "Luzon", name: "One Ayala", address: "Unit D-220, 2nd Floor (near MRT entrance), One Ayala Bldg., One Ayala Ave. Cor. EDSA, Brgy. San Lorenzo, Makati City", direction: "https://maps.app.goo.gl/Ysc5FUKGvQB9EmZD7", lat: 14.5499344, lng: 121.0251539
        },
        { hours: <>Mon-Fri: 7:00AM - 11:00PM <br /> Sat-Sun: 10:00AM - 10:00PM  </>, region: "Luzon", name: "Uptown Mall", address: "4th floor, Uptown Mall BGC, 9th Ave, Taguig", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 14.5563983, lng: 121.0540172 },
        { hours: <>Mon-Fri: 7:00 AM - 10:00PM <br /> Sat: 7:00AM - 4:00PM <br /> Sun: Closed</>, region: "Luzon", name: "Three World Square", address: "24 Upper McKinley Rd, Taguig, 1634 Metro Manila", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 14.5345735, lng: 121.0498721 },
        { hours: <>Mon-Sun: 8:30AM - 5:30PM </>, region: "Luzon", name: "San Juan", address: "614 General S. De Jesus, San Juan, 1500 Metro Manila", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 14.6030389, lng: 121.0192578 },
        { hours: <>Mon-Fri: </>, region: "Luzon", name: "SM San Lazaro", address: "GF SM City San Lazaro Felix Huertas St. cor. Lacson St. Brgy. 350 Zone 35 Sta Cruz Manila 1003", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 14.6178909, lng: 120.984693 },
        {
            hours: <>Mon-Fri: 7:00AM - 9:00PM <br />
                Sat-Sun: 7:00AM - 10:00PM </>, region: "Luzon", name: "UP Town Center", address: "Phase 1, Level 1, U.P. Town Center, Katipunan Ave, Diliman, Quezon City, 1108 Metro Manila", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 14.6496849, lng: 121.0749201
        },
        { hours: <>Mon-Sun: 10:00AM - 10:00PM </>, region: "Luzon", name: "SM North Edsa", address: "CT-202, 2nd Floor, SM City North EDSA, Corner Epifanio Delos Santos Ave., Brgy. Sto. Cristo, Quezon City", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 14.6561114, lng: 121.0248359 },
        { hours: <>Mon-Sun: 7:00am-9:00pm </>, region: "Cebu", name: "Visayas Medical Center", address: "8V4V+MR6, Visagas Community Medical Center Mixed-Use Bldg, Osmeña Blvd, Cebu City, 6000 Cebu", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 10.3066721, lng: 123.8945043 },
        { hours: <> </>, region: "Cebu", name: "Il Corso Lifemalls", address: "7V7G+RQ2, Il corso, central piazza, Cebu City", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 10.2645019, lng: 123.8706598 },
        {
            hours: <>Mon- Thurs: Sun: 10AM - 9PM <br />
                Fri-Sat: 10AM - 10PM </>, region: "Cebu", name: "SM Seaside", address: "CT-003, Lower Ground Floor (in front of Jollibee), SM Seaside, Cebu City", direction: "https://maps.app.goo.gl/y2rfwvsJY4Q1MBzA7", lat: 10.2645072, lng: 123.8743223
        },
    ];

    return (
        // Important! Always set the container height explicitly
        <Box style={{ borderRadius: "0px", color: "#FFF", paddingBottom: "2%", paddingTop: "10%" }}>
            <Grid container>
                <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>

                    <div style={{ height: '100vh', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyBiOZECETDDJ9Lquto7CnkWLpcDYgP4nEI" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                            center={selectedCenter}
                        >
                            {markers.map((marker, ind) => {
                                console.log(marker.lat, marker.lng)
                                return (
                                    <AnyReactComponent
                                        lat={marker.lat}
                                        lng={marker.lng}
                                    />
                                )
                            })}

                        </GoogleMapReact>
                    </div>
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                    <List
                        sx={{
                            width: '100%',
                            paddingLeft: "5%",
                            //                            maxWidth: 360,
                            bgcolor: 'background.paper',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: "100vh",
                            '& ul': { padding: 0 },
                        }}
                        subheader={<li />}
                    >
                        {markers.map((marker, ind) => {
                            return (
                                <>
                                    <li key={`section-${ind}`}>
                                        <ul>
                                            {ind === 0 || ind === 8 ? <ListSubheader style={{ color: "#000", fontSize: "20px", padding: "0px" }}>{marker.region}</ListSubheader> : null}

                                            <ListItemText style={{ color: "#A9A9A9" }}
                                                primary={marker.name}
                                                secondary={
                                                    <React.Fragment>
                                                        <Typography
                                                            sx={{ display: 'inline' }}
                                                            component="span"
                                                            variant="body2"
                                                        >
                                                           Operating Hours: {marker.hours}<br/><br/>
                                                           Address: <i>  {marker.address}</i>
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                onClick={() => {
                                                    setSelectedCenter({ lat: marker.lat, lng: marker.lng })
                                                }}
                                            />
                                        </ul>
                                    </li>
                                    <Divider variant="inset" component="li" />
                                </>
                            )
                        })}
                    </List>

                </Grid>
            </Grid>
        </Box >
    );
}

export default SimpleMap;