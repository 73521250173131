import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

import { useState as useHookState } from "@hookstate/core";
import ConstantStyles from '../../../utility/ConstantStyle.json';
import FormattedField from '../Register/FormattedField';

import { connect, useDispatch } from 'react-redux';
import api from '../../../utility/Endpoints';
import GlobalStates from '../../../utility/GlobalStates';
import { isUpdate, selectedAddress } from '../../actions';

const styles = {
    title: {
        fontSize: '22px',
        textAlign: 'center',
        margin: '15px'
    },
    label: {
        fontSize: '16px'
    },
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"],
    },
    gridButton: {
        margin: "10px 0px"
    }
}

const defaultFields = {
    "fullName": {
        field: "Full Name",
        type: "text",
        errorMsg: "Please enter your Full Name",
        value: '',
        error: false
    },
    "number": {
        field: "Phone Number",
        type: "text",
        formatted: true,
        errorMsg: "Format Should be (+63)XXX XXX XXXX",
        value: '',
        error: false
    }
}

const PickUpForm = (props) => {
    const { checkout, store } = props
    const [fields, setFields] = useState(defaultFields)
    const globalStates = useHookState(GlobalStates); // call globalstates
    const fieldList = Object.keys(fields)
    const customer = JSON.parse(localStorage.getItem('customerInfo'))
    const isCustomerSignIn = localStorage.getItem('customerSignedIn') === 'true'
    const isGuestSignIn = localStorage.getItem('guestSignIn') === 'true' || false
    const dispatch = useDispatch()

    const onBlurInput = (event, newvalue) => {
        let { value, id, dataset } = event.target
        if (id.includes('barangay') || id === '') {
            value = newvalue
            if (value === undefined) {
                setFields({ ...fields, 'barangay': { field: 'Barangay', type: "select", errorMsg: "Please select barangay", error: true } })
            } else {
                setFields({ ...fields, 'barangay': { field: 'Barangay', type: "select", errorMsg: "Please select barangay", value: value, error: false } })
            }
        } else {
            if (value.replace(/ /g, '') === '') {
                setFields({ ...fields, [id]: { field: dataset.field, type: dataset.type, errorMsg: dataset.error, error: true } })
            } else {
                setFields({ ...fields, [id]: { field: dataset.field, type: dataset.type, errorMsg: dataset.error, value: value, error: false } })

            }
        }
    }


    const onClickAdd = () => {
        let isError = false
        fieldList?.forEach((row) => {
            if (fields[row].value === '') {
                isError = true
            } else if (fields[row] && fields[row].error) {
                isError = true
            }
        })

        if (!isError) {
            let body = {}
            body.type = checkout.type
            body.city = store.city
            body.customerId = customer.customer_id
            fieldList.forEach((row) => {
                body[row] = fields[row].value
            })

            if (isGuestSignIn && !isCustomerSignIn) {
                const selected = [{
                    address_id: 0,
                    name: body.fullName,
                    number: '',
                    street: '',
                    brgy: '',
                    city: '',
                    postal_code: '',
                    phone: body.number,
                    type: checkout.type
                }]
                localStorage.setItem('guestAddress', JSON.stringify(selected))
                dispatch(selectedAddress(selected))
                dispatch(isUpdate())
                if (globalStates.isMobile.get()) {
                    globalStates.openAddressDrawer.set(false)
                } else {
                    globalStates.openAddressModal.set(false)
                }
            } else {
                api.address.addAddress(body)
                    .then(response => {
                        const result = response.data.result || []
                        if (result === 'success') {
                            globalStates.openAddressDrawer.set(false)
                            globalStates.openAlert.set(true);
                            globalStates.statusAlert.set("success");
                            globalStates.msgAlert.set('Successfully added');
                            dispatch(isUpdate())
                        }
                    })
                    .catch(error => {
                        globalStates.openAlert.set(true);
                        globalStates.statusAlert.set("error");
                        globalStates.msgAlert.set(error.message);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            globalStates.openAlert.set(false);
                            globalStates.isLoading.set(true); // loading
                        }, 3000);
                    });
            }
        }
    }

    return (
        <React.Fragment>
            <Box sx={{ padding: '0px 15px', width: '100%' }}>
                <Grid container spacing={1}>
                    {fieldList?.map((row) => (
                        <React.Fragment key={row}>
                            <Grid item xs={12}>
                                <label style={styles.label}>{fields[row].field}</label>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    fields[row].formatted ?
                                        <FormattedField
                                            row={row}
                                            field={fields[row]}
                                            type={fields[row].type}
                                            fieldsValue={fields}
                                            errorTriggered={fields[row].error}
                                            setFieldsValue={setFields}
                                            fwidth={true}
                                        />
                                        :
                                        <TextField
                                            id={row}
                                            error={fields[row].error}
                                            helperText={fields[row].error ? fields[row].errorMsg : null}
                                            inputProps={{
                                                "data-field": fields[row].field,
                                                "data-type": fields[row].type,
                                                "data-error": fields[row].errorMsg
                                            }}
                                            fullWidth
                                            onBlur={onBlurInput}
                                            size="small"
                                        />
                                }
                            </Grid>
                        </React.Fragment>
                    ))}
                    <Grid item xs={12} sx={styles.gridButton}>
                        <Button variant="contained" size="large" sx={{ width: "100%" }} onClick={onClickAdd}>ADD</Button>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        store: state.store.selectedStore,
        checkout: state.checkout,
    }
}

export default connect(mapStateToProps)(PickUpForm); 