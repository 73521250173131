// react library
import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

// //api endpoints

//Global States, call funtion
import ConstantStyles from "../../utility/ConstantStyle.json";
import { GetRecommended } from "../../utility/Endpoints";
import GlobalStates from "../../utility/GlobalStates";
import { setRecommendedProducts } from "../actions";

// mui components
//material ui library/components
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

//import from general folder
import HomeBanner from '../general/HomeBanner';
import MovingImages from '../general/MovingImages';
import Section from '../general/Section';
import CategoryCarousel from './Category/CategoryCarousel';
import CategoryNavbar from "./Category/CategoryNavbar";

const styles = {
    homeBody: {
        paddingTop: 0,
        marginBottom: '56px',
        flexDirection: 'column'
    }
}

const Home = ({ store }) => {
    const dispatch = useDispatch()
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [screen, setScreen] = useState([window.innerWidth]);
    let isMobile = screen <= 1000
    const [productList, setProductList] = useState([]);

    const spanish_latte_img = `/banner/FP-Spanish-Latte-Landscape.jpg`
    const matcha_latte_img = `/banner/Matcha 3.png`

    useEffect(() => {
        fetchRecommendedProducts()
        document.title = 'Home';

    }, [])

    const fetchRecommendedProducts = () => {
        GetRecommended(1).then(response => {
            let result = response.data; // get result from api
            setProductList(result)
            dispatch(setRecommendedProducts(result))
        })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchStaticPage = () => {
        return (
            <React.Fragment>
                <Box
                    style={{ backgroundColor: ConstantStyles['purple'], borderRadius: "0px", color: "#FFF", paddingBottom: "2%" }}>
                    <Grid container>
                        {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                        <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                            <img
                                style={{ paddingTop: isMobile ? "40px" : "", marginTop: isMobile ? "1%" : "15%", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                                width={"100%"}
                                src={spanish_latte_img} />

                        </Grid>
                        {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}

                        {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}
                        <Grid item xl={4} lg={4} md={12} sm={10} xs={10} style={{ paddingLeft: isMobile ? "" : "5%", paddingTop: isMobile ? "2%" : "15%" }}>
                            {isMobile ? <br /> : null}
                            <h1 style={{ color: ConstantStyles['teal'], textAlign: "center", fontFamily: 'Bounce-haus-Sans' }}>Spanish Latte</h1>
                            We're all about redefining the coffee experience! Enjoy the newest addition to our coffee favorites – Iced Spanish Latte! Visit our stores or have them delivered to you through Foodpanda and Grabfood!​ </Grid>
                        {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}

                    </Grid>
                </Box>
                <Box
                    style={{ backgroundColor: ConstantStyles['purple'], borderRadius: "0px", color: "#FFF", paddingBottom: "5%" }}>
                    <Grid container>

                        {screen <= 1000 ? <Grid item sm={12} xs={12} md={12}>
                            <img
                                style={{ paddingTop: isMobile ? "40px" : "", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                                width={"100%"}
                                src={matcha_latte_img} />
                        </Grid>
                            : null}
                        {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}
                        {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                        <Grid item xl={4} lg={4} md={10} sm={10} xs={10} style={{ paddingLeft: isMobile ? "" : "1%", paddingRight: isMobile ? "2%" : "5%", paddingTop: isMobile ? "2%" : "10%" }}>
                            {isMobile ? <br /> : null}

                            <h1 style={{ color: ConstantStyles['teal'], textAlign: "center", fontFamily: 'Bounce-haus-Sans' }}>Iced Matcha</h1>
                            Discover matcha perfection with our latest lineup! Whether you're on the go or craving indulgence, we have something for every matcha lover. Our Iced Dirty Match combines
                            matcha's eathiness with the boldness of our robust coffee, served over ice for a refreshing kick. For creamy dreamers, our Matcha Shake offers a velvety, matcha infused milkshake that's both soothing and rich. And for a sweet twist, try our Strawberry Matcha Shake, where ripe
                            strawberries blend harmoniously with matcha.
                        </Grid>
                        {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}
                        {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}

                        {screen <= 1000 ? null :
                            < Grid item xl={5} lg={5} md={12} >
                                <img
                                    style={{ paddingTop: isMobile ? "40px" : "", marginTop: "15%", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                                    width={"100%"}
                                    src={matcha_latte_img} />
                            </Grid>
                        }
                        {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}

                    </Grid>
                </Box>

            </React.Fragment >
        )
    }

    return (
        <Box>
            <Grid
                container
                spacing={0}
                style={styles.homeBody}

            >
                <MovingImages />
                <Section />
                <Box sx={{ display: 'flex', justifyContent: 'center', margin: '0', alignItems: 'flex-end' }}>
                    <HomeBanner />
                    {/* <Box sx={{ margin: '2rem auto', position: 'absolute' }}>
                        <Button
                            variant="contained"
                            sx={{
                                padding: isMobile ? 2 : "1rem",
                                width: isMobile ? '10rem' : '14rem',
                                borderRadius: 15,
                                fontSize: isMobile ? '1rem' : '2rem',
                                color: ConstantStyles['teal'],
                                fontFamily: 'Bounce-haus-Sans'
                            }}
                            href='/menu/all'
                        >
                            Buy Coffee
                        </Button>
                    </Box> */}
                </Box>
                {fetchStaticPage()}
                <Grid item md={12}>
                    <Section show={[0]} />
                </Grid>
                <Grid item md={12} sx={{ margin: '30px 0px', }}>
                    {isMobile ?
                        <div className="pt-3 mb-4">
                            <CategoryNavbar boxSize={113} show={true} />
                        </div>
                        :
                        <div className="pt-3 mb-4">
                            <CategoryNavbar boxSize={113} />
                        </div>
                    }
                    <CategoryCarousel />
                </Grid>
                <Grid item md={12}>
                    <Section hide={[0]} />
                </Grid>
            </Grid >
        </Box >
    );
}

const mapStateToProps = (state) => {
    return {
        store: state.store.selectedStore
    }
}

export default connect(mapStateToProps)(Home);