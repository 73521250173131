import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from 'react';
import ConstantStyles from "../../../utility/ConstantStyle.json";

//material ui library/components
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';


//api endpoints
import api from "../../../utility/Endpoints";

//import from general folder
import GlobalStates from "../../../utility/GlobalStates";
import FormattedField from '../Register/FormattedField';
import InputField from '../Register/InputField';
//const defaultImage = `https://scontent.fmnl17-1.fna.fbcdn.net/v/t39.30808-6/295432466_583897046697296_7947127860492652402_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeGOJU3LIGrKW5uTgiH1Euf80-U8IW-1FbXT5Twhb7UVtVVa_nhvg_yUn2x2P4tQyBi7iLFmwSoSBPiZNbaqb_gq&_nc_ohc=wmNSwXOFQZsAX_CY7Uq&_nc_ht=scontent.fmnl17-1.fna&oh=00_AfCatGERp951GJrAJdSx90xUnw-JLo0MWJIwVL5n68YhwQ&oe=637949D8`

const styles = {
    imageContainer: {
        borderRadius: ConstantStyles["default-border-radius"],
    }, productTextStrong: {
        fontWeight: ConstantStyles['bold']
    },
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"],
    },
    header: {
        fontWeight: ConstantStyles['bold'],
        marginBottom: '30px',
        padding: '12px',
        fontSize: '25px',
        color: '#A2F9F9',
        background: ConstantStyles['purple'],
        borderRadius: '10px'
    }
}

const defaultFields = {
    "first_name": {
        field: "First Name",
        type: "text",
        errorMsg: "Please enter your First Name"
    },
    "last_name": {
        field: "Last Name",
        type: "text",
        errorMsg: "Please enter your Last Name"
    },
    "birth_date": {
        field: "Birthdate",
        type: "date",
        errorMsg: "Please enter a valid date"
    },
    "email_address": {
        field: "Email Address",
        type: "email",
        errorMsg: "Please enter your Email",
        emailExistMsg: "Email already exist"
    },
    "phone_number": {
        field: "Phone Number",
        type: "text",
        errorMsg: "Format Should be (+63)XXX XXX XXXX",
        formatted: true

    },
}
const passwordFields = {
    "currentPassword": {
        field: "Current Password",
        type: "password",
        errorMsg: "Password should be atleast 8 characters"
    },
    "password": {
        field: "New Password",
        type: "password",
        errorMsg: "Password should be atleast 8 characters"
    },
    "confirmPassword": {
        field: "Confirm Password",
        type: "password",
        errorMsg: "Password do not match"
    }
}
const Card = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
    padding: window.innerWidth <= 640 ? theme.spacing(1) : '15px'
}));

const MyProfileTab = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    let customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
    const customerId = customerInfo['customer_id']
    const [fields, setFields] = useState(defaultFields)
    const [pwFields, setPWFields] = useState(passwordFields)
    const fieldList = Object.keys(fields)
    const pwFieldList = Object.keys(pwFields)
    const announcement = localStorage.getItem('announcement') === "1"
    const [value, setValue] = React.useState(1);
    const [errorTriggered, setErrorTriggered] = useState(false)
    const [submitUpdateInfoBtn, setSubmitUpdateInfoBtn] = useState(false);
    useEffect(() => {
        let cloneField = fields
        fieldList.forEach((row, index) => {
            cloneField[row].defValue=customerInfo[row]
            cloneField[row].error=false
        })
    },[])

    const handleUpdateInfoClick = () => {
        // console.table(fields)
        // if(emptyError)
        let errCount = 0;
        let cloneField = fields
        fieldList.forEach((row, index) => {
            // console.table(fields[row])
            // console.log(fields[row].error)
            if(fields[row] && fields[row].error === false){

            }else{
                console.log("ERROR")
                errCount=1
                cloneField[row].error=true
            }
        })
            console.log(fields)
        setFields(cloneField)
        if (errCount === 0) {
            setSubmitUpdateInfoBtn(true)
            const cloneHolder = JSON.parse(JSON.stringify(fields))
            let change = {}
            fieldList.forEach((row, index) => {
                if(cloneHolder[row].value)
                    if (cloneHolder[row].defValue !== cloneHolder[row].value)
                        change[row]=cloneHolder[row]
            })
            const body = {
                ...change
            }
            console.log(body)
            
            fieldList.forEach((row, index) => {
                if (body[row]) {
                    delete body[row].error
                    delete body[row].field
                    delete body[row].type 
                    delete body[row].errorMsg
                    delete body[row].formatted
                }
            })
            //api submit
            api.customerUpdateInfo.post(customerId,body) // function from endpoint
                .then(response => {
                    // globalStates.isLoading.set(true);
                    let data = {
                        customer_id  : customerId,
                        email_address  : change.email_address?change.email_address['value']:customerInfo.email_address,
                        first_name  : change.first_name?change.first_name['value']:customerInfo.first_name,
                        last_name  : change.last_name?change.last_name['value']:customerInfo.last_name,
                        birth_date  : change.birth_date?change.birth_date['value']:customerInfo.birth_date,
                        phone_number  : change.phone_number?change.phone_number['value']:customerInfo.phone_number,
                        gender  : customerInfo.gender
                    }
                    console.log(data)
                    localStorage.setItem('customerSignedIn', true);
                    localStorage.setItem('customerInfo', (data ?? null) === null ? null : JSON.stringify(data));
                    window.location.href = window.location.pathname;
                })
                .catch(error => {
                    console.log(error.response?.data?.code);
                    if(error.response?.data?.code==="ER_DUP_ENTRY"){
                        fields['emailAddress'].emailExist=true
                        setErrorTriggered(!errorTriggered)

                    }
                    // globalStates.openAlert.set(true);
                    // globalStates.statusAlert.set("error");
                    // globalStates.msgAlert.set("Error: Failed to log in, please try again later.");
                })
                .finally(() => {
                    // setTimeout(() => {
                    //     globalStates.openAlert.set(false);
                        // globalStates.isLoading.set(true); // loading
                    // }, 3000);
                });
        }else{
            setErrorTriggered(!errorTriggered)
        }
    }

    const handleUpdatePasswordClick = () => {
        // console.table(fields)
        // if(emptyError)
        let errCount = 0;
        let cloneField = pwFields
        pwFieldList.forEach((row, index) => {
            // console.table(pwFields[row])
            // console.log(pwFields[row].error)
            if(pwFields[row] && pwFields[row].error === false){

            }else{
                console.log("ERROR")
                errCount=1
                cloneField[row].error=true
            }
        })
            console.log(pwFields)
        setPWFields(cloneField)
        if (errCount === 0) {
            setSubmitUpdateInfoBtn(true)
            const cloneHolder = JSON.parse(JSON.stringify(pwFields))
            let change = {}
            pwFieldList.forEach((row, index) => {
                if(cloneHolder[row].value)
                    if (cloneHolder[row].defValue !== cloneHolder[row].value)
                        change[row]=cloneHolder[row]
            })
            const body = {
                ...change
            }
            console.log(body)
            
            pwFieldList.forEach((row, index) => {
                if (body[row]) {
                    delete body[row].error
                    delete body[row].field
                    delete body[row].type 
                    delete body[row].errorMsg
                    delete body[row].formatted
                }
            })
            //api submit
            api.customerUpdatePassword.post(customerId,body) // function from endpoint
                .then(response => {
                    if (response.data?.result === "Password Incorrect") {
                        globalStates.openAlert.set(true);
                        globalStates.statusAlert.set("error");
                        globalStates.msgAlert.set("Invalid Current Password");
                    }
                    else {
                        window.location.href = window.location.pathname;
                    }
                })
                .catch(error => {
                    console.log(error.response?.data?.code);
                    if(error.response?.data?.code==="ER_DUP_ENTRY"){
                        pwFields['emailAddress'].emailExist=true
                        setErrorTriggered(!errorTriggered)

                    }
                    // globalStates.openAlert.set(true);
                    // globalStates.statusAlert.set("error");
                    // globalStates.msgAlert.set("Error: Failed to log in, please try again later.");
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                        globalStates.isLoading.set(true); // loading
                    }, 3000);
                });
        }else{
            setErrorTriggered(!errorTriggered)
        }
    }
    return (
        <React.Fragment>
            <h3 style={styles.header}>Information</h3>
            <Grid container item md={12} lg={12} className="register-container" spacing={1}>
                <Grid container item md={6} style={globalStates.isMobile.get()?{marginBottom:"40px"}:{}}>
                    {
                    fieldList.map((row, index) => 
                        (<React.Fragment>
                            <Grid
                                key={`col1-${index}`}
                                item
                                xs={3}
                                columnSpacing={4}
                            >
                                {fields[row].field}
                            </Grid>
                            <Grid
                                key={`col2-${index}`}
                                item
                                xs={8}
                            >
                            {
                                // INPUT FIELDS EITHER FORMATTED OR NON - FORMATTED INPUT
                                fields[row].formatted ? 
                                <FormattedField
                                    row={row}
                                    field={fields[row]}
                                    type={fields[row].type}
                                    fieldsValue={fields}
                                    errorTriggered={errorTriggered}
                                    setFieldsValue={setFields}
                                    fwidth={true}
                                    currentValue={customerInfo[row]}
                                />
                                    : 
                                <InputField
                                    row={row}
                                    field={fields[row]}
                                    fieldsList={fields}
                                    setFields={setFields}
                                    fwidth={true}
                                    currentValue={customerInfo[row]}
                                />
                            }
                            </Grid>
                        </React.Fragment>)
                        )
                    }
                    <input type="button" 
                        value="Update Info" 
                        className="btn float-end mt-2" 
                        style={styles.btnPurple} 
                        // disabled={submitSignUpBtn}   
                        onClick={handleUpdateInfoClick}
                    />
                </Grid>
                <Grid container item md={6}>
                    {
                    pwFieldList.map((row, index) => 
                        (<React.Fragment>
                            <Grid
                                key={`col1-${index}`}
                                item
                                xs={3}
                                columnSpacing={0}
                            >
                                {pwFields[row].field}
                            </Grid>
                            <Grid
                                key={`col2-${index}`}
                                item
                                xs={8}
                            >
                            {
                                // INPUT FIELDS EITHER FORMATTED OR NON - FORMATTED INPUT
                                pwFields[row].formatted ? 
                                <FormattedField
                                    row={row}
                                    field={pwFields[row]}
                                    type={pwFields[row].type}
                                    fieldsValue={pwFields}
                                    errorTriggered={errorTriggered}
                                    setFieldsValue={setPWFields}
                                    fwidth={true}
                                    currentValue={customerInfo[row]}
                                />
                                    : 
                                <InputField
                                    row={row}
                                    field={pwFields[row]}
                                    fieldsList={pwFields}
                                    setFields={setPWFields}
                                    fwidth={true}
                                    currentValue={customerInfo[row]}
                                />
                            }
                            </Grid>
                        </React.Fragment>)
                        )
                    }
                    <input type="button" 
                        value="Update Password" 
                        className="btn float-end mt-2" 
                        style={styles.btnPurple} 
                        // disabled={submitSignUpBtn}   
                        onClick={handleUpdatePasswordClick}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );

}


export default MyProfileTab;