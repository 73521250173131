import { createTheme } from "@mui/material/styles";
import ConstantStyles from "./ConstantStyle.json";

// resource - https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette.warning
const theme = createTheme({

  palette: {
    primary: {
      main: ConstantStyles.purple
    },
    secondary: {
      main: ConstantStyles.teal
    },
    warning: {
      main: ConstantStyles.mustard,
      dark: ConstantStyles.mustard,
      contrastText: ConstantStyles["white-color"]
    },
    error: {
      main: ConstantStyles.red
    },
    cancel: {
      main: ConstantStyles.white,
      contrastText: ConstantStyles.teal,
      dark: "rgba(0, 0, 0, 0.1)"
    }
  },
  typography: {
      fontFamily: "Radnika-Regular",
      fontSize: 14
  },
});

export default theme;