const INTIAL_STATE = {
    shipping_address: [],
    type: 'Delivery',
    paymentMethod: '',
    deliveryTime: {},
    shipping_fee: 0,
    totalAmount: 0,
    subTotalAmount: 0,
    discount: [{
        discountName: '',
        discountAmount: 0,
        type: '',
        isDiscounted: false
    }]
}

const CheckoutReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SHIPPING_ADDRESS':
            return {
                ...state,
                shipping_address: action.selected
            }
        case 'PAYMENT_METHOD':
            return {
                ...state,
                paymentMethod: action.selected
            }
        case 'DELIVERY_TIME':
            return {
                ...state,
                deliveryTime: action.selected
            }
        case 'SUBTOTAL':
            return {
                ...state,
                subTotalAmount: action.amount
            }
        case 'SHIPPING_FEE':
            return {
                ...state,
                shipping_fee: action.amount
            }
        case 'TOTAL_AMOUNT':
            return {
                ...state,
                totalAmount: action.amount
            }
        case 'ADD_DISCOUNT':
            return {
                ...state,
                discount: action.discount
            }
        case 'ORDER_TYPE': {
            return {
                ...state,
                type: action.order_type
            }
        }
        default:
            return state;
    }

};

export default CheckoutReducer;