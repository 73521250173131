import { useState as useHookState } from "@hookstate/core";
import React, { useEffect } from 'react';
import GlobalStates from '../../../utility/GlobalStates';

import Box from '@mui/material/Box';
import { connect } from "react-redux";
import DesktopCheckoutPage from './DesktopCheckoutPage';
import MobileCheckoutPage from './MobileCheckoutPage';
const announcement = localStorage.getItem('announcement') === "1"

const CheckoutPage = (props) => {
    const { cart } = props
    const totalCartPrice = cart.map( item => item.item_price ).reduce((a, b) => a + b, 0)
    const globalStates = useHookState(GlobalStates); // call globalstates
    useEffect(() => {
        window.gtag("event", "begin_checkout", {
            currency: "PHP",
            value: totalCartPrice,
            items: cart.map((index, item) => {
                return {
                    item_id: item.item_id,
                    item_sku: item.item_sku,
                    item_name: item.item_name,
                    currency: "PHP",
                    index: index,
                    item_modifier: item.item_modifier,
                    price: item.original_price,
                    quantity: item.item_qty
                }
            })
          });
    }, [])

    return (
        <React.Fragment>
            <Box sx={{ marginTop: `${announcement ? '130px' : '85px'}` }}>
                {globalStates.isMobile.get() ?
                    <MobileCheckoutPage />
                    :
                    <DesktopCheckoutPage />
                }
            </Box>
        </React.Fragment>
    )

}



const mapStateToProps = (state) => {
    return {
        cart: state.cart.CartList
    }
}

export default connect(mapStateToProps)(CheckoutPage);