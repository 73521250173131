import React, {useState} from 'react';
import PropTypes from "prop-types";
import { useHookstate } from '@hookstate/core';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import OutlinedInput  from '@mui/material/OutlinedInput';
import Container from '@mui/material/Container';
import { Alert, IconButton, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { PhotoCamera } from '@mui/icons-material';

import GlobalStates from '../../../../utility/GlobalStates';
import api,{ CreateProduct } from '../../../../utility/Endpoints';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CreateProductModal = (props) => {
    const theme = useTheme();
    const globalStates = useHookstate(GlobalStates)
    let { modifiers, categoryList } = props
    const [fileName, setFileName] = useState('No File Chosen')
    const [errMsg, setErrMsg] = useState('')
    const [btnLoad, setBtnLoad] = useState(false)
    const [fileUpload, setFileUpload] = useState()
    const file = (e) => {
        setFileName(e.target.files[0].name || "")
        setFileUpload(e.target.files[0] || "")
    }
    const handleClose = () => globalStates.modalOpenCreate.set(false);

    const [selectedCategory, setSelectedCategory] = React.useState([]);

    function getStyles(name, personName, theme) {
        return {
          fontWeight:
            personName.indexOf(name) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
        };
    }
      
    const handleCategoryChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedCategory(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setBtnLoad(true)
        const formData = new FormData();
        formData.append('image', fileUpload);
        const paths = {
            dir: __dirname,
            path: '../../../../../public/uploads/image'
        }
        // api.category.upload_image(formData, paths)
        let selectedModifiers = []
        modifiers.forEach(element => {
            let checked = e.target[`checkBox${element.group_name.replace(/ /g, '')}`].checked
            if (checked)
                selectedModifiers.push({
                    id: element.group_id,
                })
        });
        const body = {
            name: e.target.textFieldName.value,
            sku: e.target.textFieldSKU.value,
            price: parseFloat(e.target.textFieldPrice.value),
            description: e.target.textFieldDescription.value,
            modifiers: JSON.stringify(selectedModifiers),
            category: JSON.stringify(selectedCategory)
            // path: filePath
        }
        Object.keys(body).forEach(key => {
            formData.append(key, body[key]);
        });
        CreateProduct(formData)
        .then((res) => {
            globalStates.modalOpenCreate.set(false)
            globalStates.openAlert.set(true);
            globalStates.snackBarMsg.set('Item Created Successfully');
            setTimeout(() => {
                window.location.href = window.location.pathname;
            },3000);
            setBtnLoad(false)
        })
        .catch((err) => {
            console.log(err.response.data.message)
            setErrMsg(err.response.data.message)
            setBtnLoad(false)
        })
    }
    return (
        <div>
            <Modal
                open={globalStates.modalOpenCreate.get()}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{padding: 0, margin: 0}}
            >   
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{margin: 1.5}}>
                        Create Product
                    </Typography>
                    <hr style={{marginTop: 1, marginBottom: 1}}/>
                    <Box sx={{ padding: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2}}>
                                <Alert sx={{width: "100%"}} severity="error" hidden={errMsg ? false : true}>{errMsg}</Alert>
                            </Box>
                            <Container fixed>
                                <Grid container spacing={2}>
                                    <Grid item md={4}>
                                        Product Name:
                                    </Grid>
                                    <Grid item md={8}>
                                        <TextField fullWidth size="small" id="textFieldName" name="textFieldName" variant="outlined" required/>
                                    </Grid>
                                    <Grid item md={4}>
                                        SKU:
                                    </Grid>
                                    <Grid item md={8}>
                                        <TextField fullWidth size="small" id="textFieldSKU" name="textFieldSKU" variant="outlined" required/>
                                    </Grid>
                                    <Grid item md={4}>
                                        Category:
                                    </Grid>
                                    <Grid item md={8}>
                                        <Select
                                            labelId="demo-multiple-chip-label"
                                            id="demo-multiple-chip"
                                            multiple
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                            input={<OutlinedInput  id="selectCategory" name="selectCategory" fullWidth size="small" required/>}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {categoryList.map((categoryName) => (
                                                <MenuItem
                                                key={categoryName}
                                                value={categoryName}
                                                style={getStyles(categoryName, selectedCategory, theme)}
                                                >
                                                {categoryName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item md={4}>
                                        Price:
                                    </Grid>
                                    <Grid item md={8}>
                                        <TextField fullWidth size="small" id="textFieldPrice" name="textFieldPrice" type="number" steps="0.1" variant="outlined" required/>
                                    </Grid>
                                    <Grid item md={4}>
                                        Description:
                                    </Grid>
                                    <Grid item md={8}>
                                        <TextField fullWidth size="small" id="textFieldDescription" name="textFieldDescription" multiline rows={4} variant="outlined" required/>
                                    </Grid>
                                    <Grid item md={12}>
                                        Modifiers:
                                    </Grid>
                                    <Grid container item md={12}>
                                        {
                                            modifiers.map((modifier, i) =>
                                                (
                                                    <Grid item md={6}>
                                                    <FormControlLabel control={<Checkbox id={`checkBox${modifier.group_name.replace(/ /g, '')}`} name={`checkBox${modifier.group_name.replace(/ /g, '')}`} />} label={modifier.group_name || "modifier group"} />
                                                    </Grid>
                                                )
                                            )
                                        }
                                    </Grid>
                                    <Grid item md={4}>
                                        Image:
                                    </Grid>
                                    <Grid container item md={8}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <IconButton size="large" color="primary" aria-label="upload picture" component="label">
                                                <input
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    name='category_image'
                                                    type="file"
                                                    hidden
                                                    accept=".jpg , .png"
                                                    onChange={file}
                                                />
                                                <PhotoCamera size="large" />
                                            </IconButton>
                                                <Typography sx={{color: 'black', fontSize: '13px'}} align='center'>
                                                    {fileName}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Container>
                            <Box sx={{width: '100%'}}>
                                <hr style={{marginTop: 1, marginBottom: 1}}/>
                                <Box sx={{display: 'flex', justifyContent:'flex-end', padding: 1.5}}>
                                    <LoadingButton
                                        onClick={() => globalStates.modalOpenCreate.set(false)} 
                                        sx={{marginRight: 1}} 
                                        size="small"
                                        type="submit"
                                        loadingPosition="start"
                                        // disabled={btnLoad}
                                        startIcon={<CloseIcon /> }
                                        >
                                        Close
                                    </LoadingButton>
                                    <LoadingButton
                                        size="small"
                                        type="submit"
                                        loadingPosition="start"
                                        loading={btnLoad}
                                        variant="contained" 
                                        startIcon={<SaveIcon /> }
                                        >
                                        Save
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

CreateProductModal.propTypes = {
    messages:PropTypes.object,
    run:PropTypes.func,
    btnLoad:PropTypes.bool,
};
CreateProductModal.defaultProps = {
    messages:{},
    run: undefined,
    btnLoad: false,
};

export default CreateProductModal