import { useHookstate } from '@hookstate/core';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../utility/Endpoints';
import GlobalStates from '../../../../utility/GlobalStates';

const ModifierStatus = () => {
    const globalStates = useHookstate(GlobalStates);
    const params = useParams()
    const user = JSON.parse(localStorage.getItem('userInfo'))[0]
    const [branch, setBranch] = useState([])
    const [selectedBranch, setSelectedBranch] = useState(parseInt(params.id))
    const [modifierGroup, setModifierGroup] = useState([]);
    const [modiferOptions, setmodifierOptions] = useState([]);
    const [modifierOOS, setModifierOOS] = useState([])
    const [update, setUpdate] = useState(0)
    const [expanded, setExpanded] = useState('');



    useEffect(() => {
        getModifierGroup()
        getModifierOptions()
        setSelectedBranch(parseInt(params.id))
        if (parseInt(params.id) === 0)
            getBranch()
    }, [])

    useEffect(() => {
        getModifierOOS()
    }, [update])

    const handleChange = (e) => {
        setExpanded(expanded === e ? false : e)
    };

    const handleChangeStatus = (e) => {
        const now = Date.now()
        setUpdate(now)
        const id = e.target.id
        const isChecked = e.target.checked
        const body = {
            modifierId: id,
            branchId: selectedBranch,
            groupId: null
        }
        if (isChecked) {
            if (selectedBranch !== 0) {
                api.modifier.on(body)
                setUpdate(now)

            } else {
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set('Please select branch');
                setTimeout(() => {
                    globalStates.openAlert.set(false);
                }, 3000);
            }
        } else {
            if (selectedBranch !== 0) {
                api.modifier.off(body)
                setUpdate(now)
            } else {
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set('Please select branch');
                setTimeout(() => {
                    globalStates.openAlert.set(false);
                }, 3000);
            }
        }
    }

    const handleChangeBranch = (e, newValue) => {
        if (newValue)
            setSelectedBranch(newValue)
        else
            setSelectedBranch(0)
    }

    const getModifierGroup = async () => {
        api.modifier.group()
            .then((response) => {
                setModifierGroup(response.data.result ?? [])
            }).catch((error) => {
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set(error.message);
            }).finally(() => {
                setTimeout(() => {
                    globalStates.openAlert.set(false);
                }, 3000);
            });
    }
    const getModifierOptions = async () => {
        api.modifier.options()
            .then((response) => {
                setmodifierOptions(response.data.result ?? [])
            }).catch((error) => {
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set(error.message);
            }).finally(() => {
                setTimeout(() => {
                    globalStates.openAlert.set(false);
                }, 3000);
            });
    }
    const getModifierOOS = async () => {
        if (selectedBranch !== undefined)
            api.modifier.oos(selectedBranch)
                .then((response) => {
                    setModifierOOS(response.data.result ?? [])
                }).catch((error) => {
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("error");
                    globalStates.msgAlert.set(error.message);
                }).finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                    }, 3000);
                });
    }
    const getBranch = async () => {
        api.branch.getBranch()
            .then((response) => {
                const result = response.data.result
                let branchOptions = result.map((el) => {
                    return {
                        id: el.branch_id,
                        label: el.name
                    }
                })
                setBranch(branchOptions)
            }).catch((error) => {
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set(error.message);
            }).finally(() => {
                setTimeout(() => {
                    globalStates.openAlert.set(false);
                }, 3000);
            });
    }
    return (
        <React.Fragment>
            <Container
                sx={{
                    height: '100vh',
                    width: '100%',
                    overflow: 'auto',
                    padding: '30px 7vw'

                }}
            >
                <h4 style={{ padding: '0 5vw' }}>Change Modifier Status</h4>
                {parseInt(params.id) !== 0 ?
                    < h5 style={{ margin: '0 5vw' }}>Branch : {user?.branch_name}</h5>
                    :
                    <React.Fragment>
                        <Typography sx={{ fontSize: '13px', fontWeight: 'bold', margin: '0 5vw' }}>Branch:</Typography>
                        <Autocomplete
                            disablePortal
                            id="combo-box"
                            options={branch}
                            sx={{ width: 300, margin: '0 5vw' }}
                            onChange={(e, newvalue) => handleChangeBranch(e, newvalue?.id)}
                            size="small"
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </React.Fragment>
                }
                <Box sx={{ p: '2vw 5vw' }}>
                    <Box sx={{ width: '100%' }} >
                        {modifierGroup?.map((modifier) => (
                            <Grid container sx={{ marginBottom: '10px' }}>
                                <Grid item xs={12}>
                                    {modifier.group_name}
                                </Grid>
                                <Grid item xs={12} sx={{ border: '1px solid gray', p: 1, borderRadius: '5px' }}>
                                    {modiferOptions.map((option) => (
                                        option.group_id === modifier.group_id &&
                                        <Grid container >
                                            <Grid item xs={11}>
                                                {option.name}
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Switch
                                                    checked={!(modifierOOS.map((option) => option.modifier_id)).includes(option.modifier_id)}
                                                    id={option.modifier_id}
                                                    onChange={handleChangeStatus}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                </Box>
            </Container>
        </React.Fragment >
    )

}


export default ModifierStatus;