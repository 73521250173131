/*******************************************************************************************
//  * File Name                : CreateMultipleContent
//  * File Directory           : src/components/modules/CreateMultipleContent
//  * Module                   : CreateMultipleContent
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : Create Content 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   CreateMultipleContent        -   Landing page for multiple contents 
 * #How to call:
 *    <CreateMultipleContent/>
 *
 *******************************************************************************************/
// react library
import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
import GlobalStates from "../../../utility/GlobalStates";

// import endpoints and styles
import api from "../../../utility/Endpoints";
import ConstantStyles from "../../../utility/ConstantStyle.json";
//logo endpoints
import DailyLogo from "../../../assets/logo/daily-logo.png";

//mui components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const styles={
    floatRight: {
        float: "right"
    },
    floatLeft:{
        float: "left"
    }
}
const MultipleContents = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    let hash_url = window.location.hash,
        section_id = hash_url.replace(/#/g, "");
    //useStates
    const [list, setList] = useState([]);
    const [length, setLength] = useState(0);
    useEffect(() => {
        getContents(); // call function
    }, []);

    const getContents = () => {
        api.contents.getMultiple(section_id) // function from endpoint
            .then(response => {
                globalStates.isLoading.set(true); // loading, false
                let result = response.data; // get result from api
                let contents = result.map(col => {
                    return {
                        section_id: col.section_id ?? "-",
                        content_id: col.content_id ?? "-",
                        title: col.title ?? "-",
                        type: col.type ?? "-",
                        sort: col.sort ?? "-",
                        status: col.status ?? "-",
                        text: col.text ?? "-",
                        attribute: col.attribute ?? "-",
                        image: col.image ?? "-",
                        button: col.button ?? "-"
                    }
                });
                let images = JSON.parse(contents[0].image);
                setList(contents);
                setLength(contents.length);
            })
            .catch(error => {
                console.log(error);
            })
    }

    //render, this can be a sharaeable component
    const renderItem = list.map((row, index) => {
        let images = JSON.parse(row.image);
        let attributes = JSON.parse(row.attribute);
        let inputBgColor = attributes.background === "" ? ConstantStyles['purple'] : attributes.background; // 
        // let name = item.category_name;
        setTimeout(() => {
            document.getElementById("preview" + index).innerHTML = row.text || ""; // loads data

        }, 200);
        return (
            <Grid item xs={6} key={index} >
                <Card sx={{ background: inputBgColor, borderRadius: attributes.inputRadius }}>
                    <CardMedia
                        component="img"
                        alt="Daily Logo"
                        height="100%"
                        image={images.imageUpload || DailyLogo}
                    />
                    <CardContent >
                        <div id={`preview${index}`}>
                        </div>
                    </CardContent>
                    <CardActions>
                        &nbsp;
                        <a  href={`update-content?Multiple#${row.content_id}`} > Modify this section </a>
                    </CardActions>
                </Card>
            </Grid>
        );

    });

    return (
        <Box sx={{ flexGrow: 1, m: 2, p: "20px" }}>
            <h2>Create Multiple Section</h2>
            <a href={`list-of-contents`} style={styles.floatLeft}>Back to List</a>
            {/* if greater less than 2 na pa ung content open pa ung add contents */}
            {length < 2 ?
                <a href={`create-content?multiple#${section_id}`} style={styles.floatRight}>Add Content/s</a>
                : null}
            <br />
            <Grid container spacing={5} sx={{ marginTop: "10px" }}>
                {renderItem}
            </Grid>
        </Box>
    );
}
export default MultipleContents;