import React, {useState} from 'react';
import PropTypes from "prop-types";

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useHookstate } from '@hookstate/core';
import { IconButton, Button, TextField, DialogTitle, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import GlobalStates from '../../../../utility/GlobalStates';
import ConstantStyles from "../../../../utility/ConstantStyle.json";
import api from '../../../../utility/Endpoints';

import ConfirmButton from '../../../shared/ConfirmButton';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import { selectOptional, selectRequired } from '../../../actions';
import { connect } from 'react-redux';


  
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    },
    container: {
        width: 500
    },
    groupRequired: {
        backgroundColor: "rgba(31, 239, 239, 0.1)",
        margin: '10px 0px',
        padding: '10px'
    }, groupOpt: {
        margin: '10px 0px',
        padding: '10px'
    }, gridAlignRight: {
        textAlign: 'right'
    }, groupChecked: {
        backgroundColor: "rgba(31, 239, 239, 0.1)",
        margin: '10px 0px',
        padding: '10px',
        border: ConstantStyles['default-border'],
        borderColor: ConstantStyles['red']
    }
};


function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

const AddItemModal = (props) => {
    const globalStates = useHookstate(GlobalStates)
    const { refresh, orders, shipping_fee, discount ,order_number, load, hide } = props // data from parent component 
    const [open, setOpen] = useState(false)
    const [product, setProduct] = useState([])
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState('')
    const [selectedData, setSelectedData] = useState({})
    const [groupModifier, setGroupModifier] = useState(selectedData?.modifier_group || []);
    const [errorMsg, setErrorMsg] = useState([])
    const [price, setPrice] = useState(0.00)
    const [qty, setQty] = useState(1)
    const [selectedModifier, setSelectedModifier] = useState({});
    const loading = open && options.length === 0;
    const user = JSON.parse(localStorage.getItem('userInfo'))

    useEffect(() => {
        getProduct()
    }, [])

    useEffect(() => {
        const filterProds = product.filter( p => p.name === selected)
        setPrice(filterProds[0]?.price || 0)
        setSelectedData(filterProds[0])
        setGroupModifier(filterProds[0]?.modifier_group)
    }, [selected, qty])

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
        await sleep(1e3); // For demo purposes.
            if (active) {
                setOptions([...product]);
            }
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
          setOptions([]);
        }
    }, [open]);

    const checkError = () => {
        let error = []
        groupModifier.forEach((group, index) => {
            if (group?.selection_range_min > 0 && selectedModifier[group.modifier_group_name] === undefined) {
                error = [...error, group.modifier_group_name]
            }
            if ((groupModifier.length - 1) === index) {
                setErrorMsg(error)
                if (error.length > 0) {
                    window.location.hash = error[0]?.replace(/ /g, '-').toLowerCase()
                }
            }
            if (Array.isArray(selectedModifier[group.modifier_group_name])) {
                selectedModifier[group.modifier_group_name].sort((a, b) => {
                    var textA = a.modifier_name.toUpperCase();
                    var textB = b.modifier_name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
            }
        })
        return error
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const getProduct = () => {
        api.product.fetch_list(1)
        .then((response) => {
            // console.log(response);
            const result = response.data
            setProduct(result)
        })
        .catch((error) => {
            console.log(error.message);
        })
    }

    // console.log(selectedData);

    const handleAdd = (e) => {
        e.preventDefault()
        if (selected) {
            checkError()
            let error = checkError()
            if (error.length === 0) {
                const sorted = Object.keys(selectedModifier)
                    .sort()
                    .reduce((accumulator, key) => {
                        accumulator[key] = selectedModifier[key];
                        return accumulator;
                    }, {});
                const modifierPrice = Number(sorted["UPSIZE"]?.price || 0) + Number(sorted["SUGAR LEVEL"]?.price || 0) + Number(sorted["MILK ALTERNATIVES"]?.price || 0)
                const upgradePrice = Number(sorted["UPGRADES"]?.map((upgrades) => (upgrades.price))?.reduce((partialSum, a) => partialSum + a, 0) || 0)

                const insertCart = {
                    item_id: selectedData?.id,
                    item_name: selectedData.name,
                    item_sku: selectedData.sku,
                    item_qty: qty,
                    item_price: Number(price) + Number(modifierPrice) + Number(upgradePrice),
                    original_price: price,
                    item_image: selectedData.image_src,
                    item_category: selectedData.category,
                    item_modifier: sorted,
                    item_remarks: ''
                }

                const filterDuplicate = orders?.filter( item =>  
                    (item.item_id === insertCart.item_id) && 
                    (item.item_modifier === insertCart.item_modifier) && 
                    (item.item_qty === insertCart.item_qty) && 
                    (item.item_price === insertCart.item_price) )
            
                const order = orders.concat([insertCart])
                const total_price = order?.map( item => item.item_price * item.item_qty ).reduce((a, b) => a + b, 0)
                const total_amount = (total_price + shipping_fee) - discount
                const payload = {
                    cart: JSON.stringify(order),
                    total_amount: total_amount < 1 ? 0 : total_amount,
                    order_number: order_number,
                    product: selectedData.name,
                    sku: selectedData.sku,
                    email: user[0]?.email,
                    before: orders.length,
                    after: order.length
                }
                api.fulfillment.addItem(payload)
                .then((response) => {
                    refresh()
                })
                .catch((error) => {
                    console.log(error.message);
                })
            }
        }
    }

    return (
        <div key={order_number}>
            <Button 
                onClick={handleOpen} 
                hidden={hide}
                startIcon={<AddCircleIcon />}
            >
                Add item
            </Button>
            
            <BootstrapDialog
                open={open}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    Add item
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Box sx={style.container}>
                        <Box>
                            <Autocomplete
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name}
                                options={options}
                                loading={loading}
                                onChange={e => setSelected(e.target.textContent)}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    label="Products"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                        ),
                                    }}
                                    />
                                )}
                            />
                        </Box>
                        <Box sx={{display: selected ? '' : 'none'}}>
                            <Box sx={{marginTop: '10px', display: selectedData?.modifier_group?.length === 0 ? 'none' : ''}}>
                                <Typography>Modifiers</Typography>
                                {/*  START OF LOOPING LIST OF MODIFIER GROUP  */}
                                {selectedData && selected ?
                                    groupModifier?.map((group, index) => {
                                        const id = group?.modifier_group_name?.replace(/ /g, '-').toLowerCase()
                                        return (
                                            <div key={index} id={id} style={group?.selection_range_min > 0 ? errorMsg.includes(group?.modifier_group_name) ? style.groupChecked : style.groupRequired : style.groupOpt}>  {/* Change color background based on either required or not required  */}
                                                <Grid container >
                                                    <Grid item xs={9} sm={9} md={9} lg={9}>
                                                        <Typography variant="subtitle1" style={style.productTextStrong}>{group?.modifier_group_name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} sm={3} md={3} lg={3} style={style.gridAlignRight}>   {/* add required text if required  */}
                                                        {
                                                            group?.selection_range_min > 0 ?
                                                                errorMsg.includes(group?.modifier_group_name) ?
                                                                    <i style={{ fontSize: '11px', color: ConstantStyles['red'] }}> this field is required </i>
                                                                    :
                                                                    <i style={{ fontSize: '9px' }}>REQUIRED</i>
                                                                :
                                                                null
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {/* START LOOPING OF MODIFIER OPTIONS  */}
                                                {
                                                    group?.selection_range_max !== 1 ?
                                                        <FormGroup> {/* IF 2 OR MORE MODIFIER OPTIONS CAN BE SELECTED *CHECKBOX* */}
                                                            {
                                                                group?.modifiers.map((option, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <Grid container >
                                                                            <Grid item xs={9} sm={9} md={9} lg={9}>
                                                                                <FormControlLabel control={<Checkbox id="checkbox" onChange={
                                                                                    (e) => selectOptional(
                                                                                        e,
                                                                                        group?.modifier_group_name,
                                                                                        option?.price,
                                                                                        option?.modifier_name,
                                                                                        selectedModifier,
                                                                                        setSelectedModifier
                                                                                    )}
                                                                                />} label={option?.modifier_name} />
                                                                            </Grid>
                                                                            <Grid item xs={3} sm={3} md={3} lg={3} style={style.gridAlignRight}>
                                                                                <FormLabel id="demo-radio-buttons-group-label">{option?.price > 0 ? `+P${(option?.price)?.toFixed(2)}` : 'Free'}</FormLabel>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </FormGroup>
                                                        :
                                                        <RadioGroup
                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                            defaultValue="female"
                                                            id="radio-box"
                                                            name="radio-buttons-group"
                                                            onClick={(e) => selectRequired(e, group?.modifier_group_name, groupModifier, selectedModifier, setSelectedModifier)}
                                                        > {/* IF ONLY 1 MODIFIER OPTION CAN BE SELECTED *RADIO BUTTON* */}
                                                            {
                                                                group?.modifiers.map(option => (
                                                                    <React.Fragment>
                                                                        <Grid container >
                                                                            <Grid item xs={9} sm={9} md={9} lg={9}>
                                                                                <FormControlLabel value={option?.modifier_id} control={<Radio />} label={option?.modifier_name} />
                                                                            </Grid>
                                                                            <Grid item xs={3} sm={3} md={3} lg={3} style={style.gridAlignRight}>
                                                                                <FormLabel id="demo-radio-buttons-group-label">{option?.price > 0 ? `+P${(option?.price)?.toFixed(2)}` : 'Free'}</FormLabel>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                ))
                                                            }
                                                        </RadioGroup>
                                                }
                                            </div>
                                        )
                                    })
                                    :
                                    null
                                }
                            </Box>
                            <Box sx={{marginTop: '10px'}}>
                                <Typography>Quantity</Typography>
                                <TextField type='number' sx={{width: '100%'}} onChange={e => setQty(e.target.value)} value={qty} label="Quantity" variant="outlined" />
                            </Box>
                            <Box sx={{marginTop: '10px'}}>
                                <Typography>Price</Typography>
                                <TextField type='number' sx={{width: '100%'}} onChange={e => setPrice(e.target.value)} value={price} label="Price" variant="outlined" />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ConfirmButton btnName={'Add'} functions={handleAdd} close={handleClose} btnLoad={load} />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        cartList: state.cart.CartList
    };
}

export default connect(mapStateToProps)(AddItemModal)