import { useState as useHookState } from "@hookstate/core";
import React from 'react';
import ConstantStyles from "../../../utility/ConstantStyle.json";

//material ui library/components
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Unstable_Grid2';


//api endpoints

//import from general folder
import GlobalStates from "../../../utility/GlobalStates";
import MyAddressTab from './MyAddressTab';
import MyProfileTab from './MyProfileTab';
import OrderHistoryTab from './OrderHistoryTab';
//const defaultImage = `https://scontent.fmnl17-1.fna.fbcdn.net/v/t39.30808-6/295432466_583897046697296_7947127860492652402_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeGOJU3LIGrKW5uTgiH1Euf80-U8IW-1FbXT5Twhb7UVtVVa_nhvg_yUn2x2P4tQyBi7iLFmwSoSBPiZNbaqb_gq&_nc_ohc=wmNSwXOFQZsAX_CY7Uq&_nc_ht=scontent.fmnl17-1.fna&oh=00_AfCatGERp951GJrAJdSx90xUnw-JLo0MWJIwVL5n68YhwQ&oe=637949D8`

const styles = {
    imageContainer: {
        borderRadius: ConstantStyles["default-border-radius"],
    }, productTextStrong: {
        fontWeight: ConstantStyles['bold']
    },
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"],
    },
}

const defaultFields = {
    "first_name": {
        field: "First Name",
        type: "text",
        errorMsg: "Please enter your First Name"
    },
    "last_name": {
        field: "Last Name",
        type: "text",
        errorMsg: "Please enter your Last Name"
    },
    "birth_date": {
        field: "Birthdate",
        type: "date",
        errorMsg: "Please enter a valid date"
    },
    "email_address": {
        field: "Email Address",
        type: "email",
        errorMsg: "Please enter your Email",
        emailExistMsg: "Email already exist"
    },
    "phone_number": {
        field: "Phone Number",
        type: "text",
        errorMsg: "Format Should be (+63)XXX XXX XXXX",
        formatted: true

    },
}
const passwordFields = {
    "currentPassword": {
        field: "Current Password",
        type: "password",
        errorMsg: "Password should be atleast 8 characters"
    },
    "password": {
        field: "New Password",
        type: "password",
        errorMsg: "Password should be atleast 8 characters"
    },
    "confirmPassword": {
        field: "Confirm Password",
        type: "password",
        errorMsg: "Password do not match"
    }
}
const Card = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: window.innerWidth <= 640 ? theme.spacing(1) : '15px'
}));

const AccountView = (props) => {
    console.log(window.location.hash)
    const globalStates = useHookState(GlobalStates); // call globalstates
    let customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
    const guestSignIn = localStorage.getItem('guestSignIn') === "true"
    const customerId = customerInfo['customer_id']
    const announcement = localStorage.getItem('announcement') === "1"
    const [value, setValue] = React.useState((window.location.hash).replace('#', '') || 'ProfileTab');
    const handleChange = (event, newValue) => {
        setValue(newValue);
        window.location.hash = `${newValue}`
    };

    return (
        <React.Fragment>
            <Box
                sx={{ flexGrow: 1, background: "#f7f7f7", minHeight: '100vh', marginTop: `${announcement ? '130px' : '85px'}` }}
                id='product-container'
            >
                <TabContext value={value}>
                    <Grid container md={12} lg={12} >
                        <Grid item md={2}>
                            <Tabs
                                orientation={globalStates.isMobile.get() ? "horizontal" : 'vertical'}
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 0, borderColor: 'divider' }}
                            >
                                {guestSignIn ? null :
                                    <Tab label="My Profile" value={'ProfileTab'} />
                                }
                                <Tab label="My Orders" value={'orders'} />
                                {guestSignIn ? null :
                                    <Tab label="My Address" value={'address'} />
                                }
                            </Tabs>
                        </Grid>
                        <Grid item md={10}>
                            {guestSignIn ?
                                null :
                                <TabPanel value={'ProfileTab'}>
                                    <MyProfileTab />
                                </TabPanel>
                            }
                            <TabPanel value={'orders'}>
                                <OrderHistoryTab />
                            </TabPanel>
                            {guestSignIn ?
                                null :
                                <TabPanel value={'address'}>
                                    <MyAddressTab />
                                </TabPanel>
                            }
                        </Grid>
                    </Grid>
                </TabContext>
            </Box>
        </React.Fragment>
    );

}


export default AccountView;