import { useState as useHookState } from "@hookstate/core";
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import moment from "moment";
import Paymongo from 'paymongo';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PaymongoLogo from '../../../assets/logo/paymongo.png';
import api from "../../../utility/Endpoints";
import GlobalStates from "../../../utility/GlobalStates";
import { addPayment } from "../../actions";


const DebitCredit = (props) => {
    const { order } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY);
    const [email, setEmail] = useState('')
    const [cardName, setCardName] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [cvc, setCVC] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [errorMsgYear, setErrorMsgYear] = useState('')
    const [errorMsgMonth, setErrorMsgMonth] = useState('')
    const [errorYear, setErrorYear] = useState(false)
    const [errorMonth, setErrorMonth] = useState(false)
    const [errorDetails, setErrorDetails] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const yearNow = moment().format('YY')
    const monthNow = moment().format('MM')
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (email !== '' && cardName !== '' && cardNumber !== '' && month !== '' && year !== '' && cvc !== '') {
            setDisableSubmit(false)
        } else if (year !== '' && year < parseInt(yearNow)) {
            setErrorYear(true)
            setErrorMsgYear('Invalid')
        } else if ((month !== '' && month < parseInt(monthNow)) || (month !== '' && (month > 12 || month <= 0))) {
            setErrorMonth(true)
            setErrorMsgMonth('Invalid')

        } else {
            setErrorDetails(false)
            setErrorYear(false)
            setErrorMonth(false)
            setErrorMsgYear('')
            setErrorMsgMonth('')
            setDisableSubmit(true)
        }

    }, [email, cardName, cardNumber, month, year, cvc])

    const onClickSubmit = async () => {
        setDisableSubmit(true)
        const payload = {
            data: {
                attributes: {
                    type: 'card',
                    details: {
                        card_number: `${cardNumber}`,
                        exp_month: parseInt(month),
                        exp_year: parseInt(year),
                        cvc: `${cvc}`
                    }
                }
            }
        }
        const data = {
            data: {
                attributes: {
                    amount: parseInt(order.createdOrder.totalAmount * 100),
                    currency: 'PHP',
                    payment_method_allowed: ['card'],
                    description: 'DAILY ORDER ' + order.createdOrder.order_number
                }
            }
        }
        try {
            const response = await paymongo.paymentMethods.create(payload);
            const id = response.data.id
            const result = await paymongo.paymentIntents.create(data);
            const details = {
                data: {
                    attributes: {
                        payment_method: id
                    }
                }
            }
            const results = await paymongo.paymentIntents.attach(result.data.id, details);
            const paymentStatus = results.data.attributes.status
            console.log(paymentStatus)
            if (paymentStatus === "awaiting_next_action") {
                const details = {
                    payId: result.data.id,
                    orderId: order.createdOrder.order_id,
                    paymentMethod: "Debit/Credit",
                }
                await api.payment.create(details)
                    .then(() => {
                        const data = {
                            id: result.data.id,
                            redirect: results.data.attributes.next_action.redirect.url
                        }
                        dispatch(addPayment(data))
                        history.push('/paymongo/debit-credit/next-action')
                    })
            } else if (paymentStatus === "succeeded") {

                const details = {
                    payId: result.data.id,
                    orderId: order.createdOrder.order_id,
                    paymentMethod: "Debit/Credit",
                }
                await api.payment.create(details)
                    .then((response) => {
                        if (response.status === 200) {
                            window.location.href = '/thank-you'
                            const data = {
                                id: result.data.id,
                                redirect: results.data.attributes.next_action.redirect.url
                            }
                            dispatch(addPayment(data))
                        }
                    })
            }
        } catch (err) {
            console.log(err.sub_code)
            if (err.detail === "details.exp_year must be at least this year or no later than 50 years from now." && err.code === "parameter_invalid") {
                setErrorMsg('Year should be atleast this year or no later than 50 years from now')
                setErrorDetails(true)
            } else if (err.detail === "details.card_number format is invalid." && err.code === "parameter_format_invalid") {
                setErrorMsg('Card Number Format is invalid')
                setErrorDetails(true)
            } else if (err.detail === "details.cvc should be numeric." && err.code === "parameter_format_invalid") {
                setErrorMsg('CVC should be numeric')
                setErrorDetails(true)
            } else if (err.detail === "The value for details.cvc cannot be more than 3 characters." && err.code === "parameter_above_maximum") {
                setErrorMsg('CVC cannot be more than 3 characters.')
                setErrorDetails(true)
            }
            if (err.sub_code === "card_expired") {
                setErrorMsg("Please enter a different card or choose another payment method")
                setErrorDetails(true)
            }
            else if (err.sub_code === "cvc_invalid") {
                setErrorMsg("Correct the CVC/CVN before re-submitting")
                setErrorDetails(true)
            }
            else if (err.sub_code === "generic_decline") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "fraudalent") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "insufficient_funds") {
                setErrorMsg("Please enter a different card or choose another payment method")
                setErrorDetails(true)
            }
            else if (err.sub_code === "processor_blocked") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "lost_card") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "stolen_card") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "processor_unavailable") {
                setErrorMsg("Kindly wait for a few minutes before re-trying or you may enter a different card or choose another payment method")
                setErrorDetails(true)
            }
            else if (err.sub_code === "blocked") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)

            } else if (err.sub_code === "acquirer_declined") {
                setErrorMsg("The card type used is not allowed. Please use another card or try a different payment method.")
                setErrorDetails(true)
            }
        } finally {
            setDisableSubmit(false)
        }
    }

    return (
        <React.Fragment>
            <div className={globalStates.isMobile.get() ? `container` : `container p-5`} style={globalStates.isMobile.get() ? { marginTop: '150px' } : { marginTop: '120px' }}>
                <div className="justify-content-center m-auto" style={{ width: '320px', padding: '15px', border: '2px solid #6700B9', borderRadius: '20px' }}>
                    <Grid container spacing={1.2}>
                        <Grid item xs={12}>
                            <img className="img img-fluid p-5 pb-3 pt-3" alt='paymongo_logo' src={PaymongoLogo} />
                        </Grid>
                        <Grid item xs={12}>
                            {errorDetails ?
                                <div className="alert alert-danger p-2" role="alert">
                                    <span>{errorMsg}</span>
                                </div>
                                :
                                ''
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <label>Email</label>
                            <TextField
                                fullWidth
                                type="input"
                                size="small"
                                defaultValue={email}
                                value={email}
                                required
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Card Name</label>
                            <TextField
                                fullWidth
                                type="input"
                                size="small"
                                defaultValue={cardName}
                                value={cardName}
                                required
                                onChange={(e) => {
                                    setCardName(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <label>Card Number</label>
                            <TextField
                                fullWidth
                                type="number"
                                size="small"
                                defaultValue={cardNumber}
                                value={cardNumber}
                                required
                                onChange={(e) => {
                                    setCardNumber(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <label>Expiry</label>
                            <Grid container>
                                <Grid item xs={5} >
                                    <TextField
                                        fullWidth
                                        type="number"
                                        size="small"
                                        label="MM"
                                        variant="standard"
                                        defaultValue={month}
                                        value={month}
                                        error={errorMonth}
                                        helperText={errorMsgMonth}
                                        required
                                        onChange={(e) => {
                                            setMonth(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ pt: 2 }}>
                                    <span style={{ fontSize: '15px', marginTop: '35px' }}>/</span>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        size="small"
                                        label="YY"
                                        error={errorYear}
                                        variant="standard"
                                        defaultValue={year}
                                        helperText={errorMsgYear}
                                        value={year}
                                        required
                                        onChange={(e) => {
                                            setYear(e.target.value)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <label className='mb-2'>CVC</label>
                            <TextField
                                fullWidth
                                type="input"
                                size="small"
                                defaultValue={cvc}
                                value={cvc}
                                required
                                onChange={(e) => {
                                    setCVC(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Button
                                sx={{
                                    color: 'white',
                                    text: 'center',
                                    background: '#6700B9',
                                    width: '100%',
                                    height: '40px',
                                    fontSize: '18px',
                                    ':hover': { backgroundColor: '#6700B9' },
                                    ':disabled': {
                                        background: 'unset',
                                    }
                                }}
                                disabled={disableSubmit}
                                onClick={onClickSubmit}
                            >
                                SUBMIT
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </React.Fragment>
    )


}
const mapStateToProps = (state) => {
    return {
        order: state.order,
    }
}

export default connect(mapStateToProps)(DebitCredit);