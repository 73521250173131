
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from "react";
import { useState } from 'react';
import { useEffect } from 'react';
import siteLoader from "../../assets/Loading GIF.gif";

const LoadingModal = ({ loading }) => {
    const [isOpen, setIsOpen] = useState(true)
    const [screen, setScreen] = useState([window.innerWidth]);
    let isMobile = screen <= 767
    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        if (loading === false) {
            setTimeout(() => {
                setIsOpen(false)
            }, 500);
        }
    }, [loading])
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        outline: 'unset !important',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? 340 : 550,
        bgcolor: 'transparent',
        border: '2px solid transparent',
        borderRadius: '10px',
        overflow: 'auto',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '20px',
        pointerEvents: 'none'
    };
    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={isOpen}
                // sx={{pointerEvents:'none'}}
                // onClose={handleClose}
                disableEnforceFocus
                closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            //     timeout: 500,
            // }}
            >
                <Box id="loginModal" sx={style} >
                    <img src={siteLoader} alt="About Us" width='70%' style={{
                        borderRadius: '100px',
                        margin: 'auto',
                        display: 'block'
                    }}
                    />
                </Box>
            </Modal>
        </React.Fragment >
    );
};

export default LoadingModal;