import { useState as useHookState } from "@hookstate/core";
import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { connect } from 'react-redux';
import CartIcon from '../../../assets/icons/Cart.png';
import ConstantStyles from '../../../utility/ConstantStyle.json';
import GlobalStates from '../../../utility/GlobalStates';
import CartCard from './CartCard';
import CartRecommended from './CartRecommended';
import EmptyCart from './EmptyCart';

const Cart = (props) => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    const isSignedIn = localStorage.getItem('customerSignedIn') === 'true'
    const guestSignIn = localStorage.getItem('guestSignIn') === 'true'

    const { cart, cartstate, setcartstate } = props


    const onClickPlaceOrder = () => {
        if ((window.location.href).includes('checkout')) {
            if (isSignedIn || guestSignIn) {
                if (globalStates.isMobile.get()) {
                    setcartstate({ ...cartstate, ['bottom']: false });
                } else {
                    setcartstate({ ...cartstate, ['right']: false });
                }
            } else {
                if (globalStates.isMobile.get()) {
                    setcartstate({ ...cartstate, ['bottom']: false });
                } else {
                    setcartstate({ ...cartstate, ['right']: false });
                }
                globalStates.showLoginModal.set(true)
            }
        } else {
            if (isSignedIn || guestSignIn) {
                window.location.pathname = '/checkout'
            } else {
                window.location.hash = 'checkout'
                setcartstate({ ...cartstate, ['right']: false });
                globalStates.showLoginModal.set(true)
            }
        }
    }



    return (
        <React.Fragment>
            <Box sx={{
                backgroundColor: '#ffffff',
                position: 'fixed',
                padding: "10px 5px",
                width: "100%",
                fontSize: "20px",
                color: ConstantStyles['purple'],
                zIndex: '999'
            }}>
                <img alt='cart_icon' src={CartIcon} /> Items in cart ({cart?.map((item) => (item.item_qty)).reduce((partialSum, a) => partialSum + a, 0)})
            </Box>
            {cart.length > 0 ?
                <CartCard />
                :
                <EmptyCart />
            }

            <Box sx={{
                backgroundColor: '#ffffff',
                position: 'fixed',
                fontSize: "20px",
                bottom: '0px',
                zIndex: '999',
                width: globalStates.isMobile.get() ? '100%' : '350px'
            }}>
                <Grid container>
                    <Grid item xs={12} md={12} sx={{ marginBottom: '10px' }}>
                        <CartRecommended />
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ height: '100px', backgroundColor: ConstantStyles['teal'], padding: '12px' }}>
                        <Grid container rowSpacing={1.2}>
                            <Grid item
                                xs={6}
                                md={6}
                                lg={6}
                                sx={{ fontWeight: ConstantStyles['bold'] }}
                            >
                                TOTAL
                            </Grid>
                            <Grid item
                                xs={6}
                                md={6}
                                lg={6}
                                sx={{ textAlign: 'end', fontWeight: ConstantStyles['bold'] }}
                            >
                                PHP {(cart?.map((item) => ((item.item_price).toFixed(2) * (item.item_qty).toFixed(2))).reduce((partialSum, a) => partialSum + a, 0)).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Button
                                    sx={{
                                        color: 'white',
                                        padding: '5px 35px 5px 35px',
                                        background: ConstantStyles['purple'],
                                        width: '100%',
                                        height: '36px',
                                        borderRadius: '20px',
                                        ':hover': { backgroundColor: ConstantStyles['purple'] },
                                        ':disabled': {
                                            backgroundColor: ConstantStyles['white-color']
                                        }

                                    }}
                                    disabled={cart.length === 0}
                                    onClick={onClickPlaceOrder}
                                >
                                    PLACE ORDER
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )

}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.CartList
    }
}

export default connect(mapStateToProps)(Cart);