import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import ConstantStyles from "../../../utility/ConstantStyle.json";
import { cartLogs } from '../../../utility/Endpoints';
import { addItem } from '../../actions';
//material ui library/components
import { useState as useHookState } from "@hookstate/core";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import defaultImage from '../../../assets/logo/default.webp';

import GlobalStates from '../../../utility/GlobalStates';
import { onChangeSpecialInstruction, selectOptional, selectRequired, TriggerQtyClick } from '../../actions';

// const defaultImage = `https://d1sag4ddilekf6.azureedge.net/compressed_webp/items/PHITE20220830072619042072/detail/menueditor_item_eed3a594f161472985cd0680df451439_1666566692484852972.webp`

const styles = {
    image: {
        borderRadius: '0px 0px 110px 110px',
        objectFit: "cover",
        height: "380px"
    }, productTextStrong: {
        fontWeight: ConstantStyles['bold'],
        color: ConstantStyles['purple']
    }, productName: {
        fontWeight: ConstantStyles['bold'],
        color: ConstantStyles['purple']
    }, productTextSemiStrong: {
        fontWeight: ConstantStyles['semi-bold']
    }, productDescription: {
        fontSize: '14px'
    }, groupRequired: {
        backgroundColor: "rgba(31, 239, 239, 0.1)",
        margin: '10px 0px',
        padding: '10px'
    }, groupOpt: {
        margin: '10px 0px',
        padding: '10px'
    }, gridAlignRight: {
        textAlign: 'right',
        pointerEvent: 'none'
    }, groupChecked: {
        backgroundColor: "rgba(31, 239, 239, 0.1)",
        margin: '10px 0px',
        padding: '10px',
        border: ConstantStyles['default-border'],
        borderColor: ConstantStyles['red']
    }
}

const ProductView = (props) => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    const { item, index, setstate, state } = props
    const [productDetails, setProductDetails] = useState(item || {});
    let isMobile = window.innerWidth <= 640
    const [groupModifier, setGroupModifier] = useState(productDetails?.modifier_group || []);
    const [productQuantity, setProductQuantity] = useState(1);
    const [selectedModifier, setSelectedModifier] = useState({});
    const [specialInstruction, setSpecialInstruction] = useState('');
    let toggleSide = isMobile ? "bottom" : "right"
    const [errorMsg, setErrorMsg] = useState([])
    const dispatch = useDispatch()
    const history = useHistory()
    const image_url = productDetails?.image_src ? process.env.REACT_APP_HOST + productDetails?.image_src : defaultImage
    useEffect(() => {
        setProductDetails(item)
        setGroupModifier(item?.modifier_group)
    }, [item, selectedModifier])


    const checkError = () => {
        let error = []
        groupModifier.forEach((group, index) => {
            if (group?.selection_range_min > 0 && (selectedModifier[group.modifier_group_name] === undefined || selectedModifier[group.modifier_group_name].modifier_name === '')) {
                error = [...error, group.modifier_group_name]
            }
            if ((groupModifier.length - 1) === index) {
                setErrorMsg(error)
                if (error.length > 0) {
                    window.location.hash = error[0]?.replace(/ /g, '-').toLowerCase()
                }
            }
            if (Array.isArray(selectedModifier[group.modifier_group_name])) {
                selectedModifier[group.modifier_group_name].sort((a, b) => {
                    var textA = a.modifier_name.toUpperCase();
                    var textB = b.modifier_name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
            }
        })
        return error
    }

    const AddToCart = () => {
        checkError()
        let error = checkError()
        console.log(error)
        if (error.length === 0) {
            const sorted = Object.keys(selectedModifier)
                .sort()
                .reduce((accumulator, key) => {
                    accumulator[key] = selectedModifier[key];
                    return accumulator;
                }, {});
            const modifierPrice = Number(sorted["UPSIZE"]?.price || 0) + Number(sorted["SUGAR LEVEL"]?.price || 0) + Number(sorted["MILK ALTERNATIVES"]?.price || 0)
            const upgradePrice = Number(sorted["UPGRADES"]?.map((upgrades) => (upgrades.price))?.reduce((partialSum, a) => partialSum + a, 0) || 0)

            const insertCart = {
                item_id: productDetails.product_id,
                item_name: productDetails.name,
                item_sku: productDetails.sku,
                item_qty: productQuantity,
                item_price: Number(productDetails.price) + Number(modifierPrice) + Number(upgradePrice),
                original_price: productDetails.price,
                item_image: productDetails.image_src,
                item_category: productDetails.category,
                item_modifier: sorted,
                item_remarks: specialInstruction
            }
            const payloadLogs = {
                item: insertCart,
                action: 'added to cart',
                accesskey: 'sample accesskey',
                userEmail: 'useremail@email.com'
            }


            window.gtag("event", "add_to_cart", {
                currency: "PHP",
                value: Number(productDetails.price) + Number(modifierPrice) + Number(upgradePrice),
                items: [
                    {
                        item_id: productDetails.product_id,
                        item_name: productDetails.name,
                        currency: "PHP",
                        item_category: productDetails.category[0],
                        item_category2: productDetails.category[1],
                        item_category3: productDetails.category[2],
                        item_category4: productDetails.category[3],
                        item_category5: productDetails.category[4],
                        item_modifier: sorted,
                        price: Number(productDetails.price) + Number(modifierPrice) + Number(upgradePrice),
                        original_price: productDetails.price,
                        quantity: productQuantity
                    }
                ]
            });

            cartLogs(payloadLogs)
            dispatch(addItem(insertCart));
            setProductDetails()
            setSelectedModifier({})
            history.push(window.location.pathname)
            setstate({ ...state, [toggleSide]: false });
        }
    }

    return (
        <React.Fragment>
            {selectedModifier ?
                <Grid item xs={12} md={12} lg={12}>
                    <div style={styles.imageContainer}>
                        <img
                            alt={`product-${index}`}
                            src={image_url}
                            width="100%"
                            style={styles.image}
                        />
                    </div>
                    <div id='productDetails' style={{ padding: '15px', minHeight: '43.5vh', marginBottom: '60px' }}>
                        <Grid container >
                            <Grid item xs={7} sm={7} md={7} lg={7}>
                                <Typography variant="h6" style={styles.productName}>{productDetails?.name}</Typography>
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} lg={5} style={styles.gridAlignRight}>
                                <Typography variant="h6" style={styles.productTextSemiStrong}>PHP {(productDetails?.price?.toFixed(2))?.toLocaleString()}</Typography>
                            </Grid>
                        </Grid>
                        <p className='mb-0' style={{ fontSize: '14px', width: globalStates.isMobile.get() ? '320px' : '400px' }}>{productDetails?.description}</p>
                        {/*  START OF LOOPING LIST OF MODIFIER GROUP  */}
                        {productDetails ?
                            groupModifier?.map((group, index) => {
                                const id = group?.modifier_group_name?.replace(/ /g, '-').toLowerCase()
                                return (
                                    <div key={index} id={id} style={group?.selection_range_min > 0 ? errorMsg.includes(group?.modifier_group_name) ? styles.groupChecked : styles.groupRequired : styles.groupOpt}>  {/* Change color background based on either required or not required  */}
                                        <Grid container >
                                            <Grid item xs={9} sm={9} md={9} lg={9}>
                                                <Typography variant="subtitle1" style={styles.productTextStrong}>{group?.modifier_group_name}</Typography>
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} lg={3} style={styles.gridAlignRight}>   {/* add required text if required  */}
                                                {
                                                    group?.selection_range_min > 0 ?
                                                        errorMsg.includes(group?.modifier_group_name) ?
                                                            <i style={{ fontSize: '11px', color: ConstantStyles['red'] }}> this field is required </i>
                                                            :
                                                            <i style={{ fontSize: '9px' }}>REQUIRED</i>
                                                        :
                                                        null
                                                }
                                            </Grid>
                                        </Grid>
                                        {/* START LOOPING OF MODIFIER OPTIONS  */}
                                        {
                                            group?.selection_range_max !== 1 ?
                                                <FormGroup> {/* IF 2 OR MORE MODIFIER OPTIONS CAN BE SELECTED *CHECKBOX* */}
                                                    {
                                                        group?.modifiers.map((option, index) => (
                                                            <React.Fragment key={index}>
                                                                <Grid container >
                                                                    <Grid item xs={9} sm={9} md={9} lg={9}>
                                                                        <FormControlLabel control={<Checkbox id="checkbox" onChange={
                                                                            (e) => {
                                                                                selectOptional(
                                                                                    e,
                                                                                    group?.modifier_group_name,
                                                                                    option?.price,
                                                                                    option?.modifier_name,
                                                                                    selectedModifier,
                                                                                    setSelectedModifier
                                                                                )
                                                                            }
                                                                        }
                                                                        />} label={option?.modifier_name} />
                                                                    </Grid>
                                                                    <Grid item xs={3} sm={3} md={3} lg={3} style={styles.gridAlignRight}>
                                                                        <FormLabel id="demo-radio-buttons-group-label">{option?.price > 0 ? `+P${(option?.price)?.toFixed(2)}` : 'Free'}</FormLabel>
                                                                    </Grid>
                                                                </Grid>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </FormGroup>
                                                :
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue="female"
                                                    id="radio-box"
                                                    name="radio-buttons-group"
                                                    onClick={(e) => { selectRequired(e, group?.modifier_group_name, groupModifier, selectedModifier, setSelectedModifier); console.log('click') }}
                                                > {/* IF ONLY 1 MODIFIER OPTION CAN BE SELECTED *RADIO BUTTON* */}
                                                    {
                                                        group?.modifiers.map(option => (
                                                            <React.Fragment>
                                                                <Grid container >
                                                                    <Grid item xs={9} sm={9} md={9} lg={9}>
                                                                        <FormControlLabel value={option?.modifier_id} control={<Radio />} label={option?.modifier_name} />
                                                                    </Grid>
                                                                    <Grid item xs={3} sm={3} md={3} lg={3} style={styles.gridAlignRight}>
                                                                        <FormLabel id="demo-radio-buttons-group-label">{option?.price > 0 ? `+P${(option?.price)?.toFixed(2)}` : 'Free'}</FormLabel>
                                                                    </Grid>
                                                                </Grid>
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </RadioGroup>
                                        }
                                    </div>
                                )
                            })
                            :
                            null
                        }
                        <div style={styles.groupOpt}>
                            <Grid container >
                                <Grid item xs={9} sm={9} md={9} lg={9}>
                                    <Typography variant="subtitle1" style={styles.productTextStrong}>Special Instruction</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label=""
                                        style={{ width: '100%', border: `1px solid ${ConstantStyles['purple']}`, borderRadius: '10px' }}
                                        multiline
                                        rows={2}
                                        onChange={(e) => onChangeSpecialInstruction(e, setSpecialInstruction)}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <footer style={{ position: 'fixed', bottom: '0px', background: ConstantStyles['teal'], width: "100%", height: "71px", padding: '19px 28px 0px 28px', display: 'flex' }}>
                        <Button
                            sx={{ float: 'left', background: 'transparent', height: '36px' }}
                            onClick={() => TriggerQtyClick('-', productQuantity, setProductQuantity)}
                        >
                            -
                        </Button>
                        <TextField id="standard-basic" variant="standard" sx={{ float: 'left', width: '24px', height: '36px', border: 0 }} value={productQuantity} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'center', fontWeight: ConstantStyles['bold'], color: ConstantStyles['purple'] } }} />
                        <Button
                            sx={{ float: 'left', background: 'transparent', height: '36px' }}
                            onClick={() => TriggerQtyClick('+', productQuantity, setProductQuantity)}
                        >
                            +
                        </Button>
                        <Button
                            sx={{ float: 'right', color: ConstantStyles['teal'], padding: '5px 35px 5px 35px', background: ConstantStyles['purple'], width: isMobile ? '210px' : '194px', height: '36px', borderRadius: '30px' }}
                            onClick={() => AddToCart()}
                        >
                            ADD TO CART
                        </Button>
                    </footer>
                </Grid>
                : null
            }
        </React.Fragment >
    );


}

const mapStateToProps = (state) => {
    return {
        cartList: state.cart.CartList
    };
}

export default connect(mapStateToProps)(ProductView);