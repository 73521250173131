// react imports
import { useHookstate } from '@hookstate/core';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";

// MUI imports
import ArchiveIcon from '@mui/icons-material/Archive';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Switch from '@mui/material/Switch';
import { Box } from '@mui/system';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';

import api from '../../../../utility/Endpoints';

import GlobalStates from '../../../../utility/GlobalStates';
import { setCategory } from '../../../actions';
import CreateModal from './CreateModal';
import EditModal from './EditModal';

import ConfirmationModal from '../../../shared/ConfirmationModal';

// api acces

// utility

// components


const style = {
    DataGrid: {
        display: 'flex',
        height: "85vh",
        width: '100%',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            whiteSpace: 'normal',
            textOverflow: 'normal',
            lineHeight: '15px'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },

    },
}

const Category = () => {
    const globalStates = useHookstate(GlobalStates) // globalstate variable
    const [categoryList, setCategoryList] = useState([]) // list of category
    const [refresh, setRefresh] = useState(false) // loading trigger
    const [btnLoad, setBtnLoad] = useState(false) // button loading trigger
    const [selectedData, setSelectedData] = useState({}) // selected data for confirmation modal
    const [messages, setMessages] = useState({}) // messages for confirmation modal
    const [toRun, setToRun] = useState('') // what type of function is to run
    
    const dispatch = useDispatch()

    useEffect(() => {
        FetchCategory() // fetching category
    }, [refresh])
    
    const refreshData = () => {  // refresh data table
        setRefresh(!refresh)
    }

    const FetchCategory = () => { // function to fetch category
        globalStates.isLoading.set(true)
        api.category.fetch_category() // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                const category = result.map( item => {
                    return {
                        id: item.category_id,
                        name: item.name,
                        path: item.image_path,
                        status: item.status,
                        load: false
                    }
                })
                setCategoryList(category)
                dispatch(setCategory(result))
                globalStates.isLoading.set(false)
                setInterval(() => {
                    globalStates.isLoading.set(false)
                }, 1000)
            })
            .catch(error => {
                console.log(error);
                globalStates.isLoading.set(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error)
            })
    }

    const disableCategory = (e) => { // set data for disable category and open confirmation
        e.preventDefault()
        globalStates.modalOpenConfirm.set(true)
        const id = e.target.dataset.id
        const name = e.target.name

        setToRun('disable')
        
        const msg = {
            title: "Disable category ",
            message: `Are you sure you want to disable category : ${name}`,
            note: "note: When a category is disabled, all the products that belong to the category will not appear on the storefront.",
        }
        setMessages(msg)
        setSelectedData({id: id})
    }

    
    const onChangeStatus = (e) => { // set data for switch status category and open confirmation modal
        globalStates.modalOpenConfirm.set(true)
        const id = e.target.id
        const name = e.target.name
        const status = e.target.checked ? 1 : 0

        setToRun('changeStatus')

        const body = {
            id: id,
            name: name,
            status: status
        }
        const msg = {
            title: "Change category status",
            message: `Are you sure you want to change status of : ${name}`,
            note: "note: When a category is disabled, all the products that belong to the category will not appear on the storefront.",
        }
        setMessages(msg)
        setSelectedData(body)
    }

    const isToRun = () => { // functions to run after confirming
        setBtnLoad(true)
        if (toRun === 'disable') {
            api.category.disable(selectedData?.id)
            .then((response) => {
                setBtnLoad(false)
                globalStates.modalOpenConfirm.set(false)
                refreshData()
            })
            .catch((error) => {
                console.log(error);
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error)
            })
        } else if (toRun === 'changeStatus') {
            api.category.changeStatus(selectedData)
            .then((response) => {
                setBtnLoad(false)
                globalStates.modalOpenConfirm.set(false)
                refreshData()
            })
            .catch((error) => {
                console.log(error);
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error)
            })
        }
    }

    const statusChange = (props) => { // render switch component in data grid
        const status = props.row.status 
        const name = props.row.name 
        const id = props.row.id 
        return (
            <>
                <Switch
                    checked={status === 1 ? true : false}
                    id={id}
                    name={name}
                    onChange={onChangeStatus}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </>
        )
    }

    // setting up columns
    const columns = [
        {
            field: "id", // fetching from map
            headerName: "Category ID", // table header
            flex: 0,
            minWidth: 80
        },
        {
            field: "name",
            headerName: "Category Name",
            flex: 0.3,
            minWidth: 100
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0,
            minWidth: 100,
            renderCell: statusChange
        },
        {
            field: "actions",
            headerName: "Action Buttons",
            flex: 0.2,
            minWidth: 100,
            type: 'actions',
            getActions: (params) => [
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={6}>
                            <EditModal 
                                refresh={refreshData} 
                                id={params.id} 
                                data={params.row}
                                category={categoryList} 
                            />
                                
                        </Grid>
                        <Grid item xs={6}>
                            <LoadingButton
                                size="small"
                                color="error"
                                data-id={params.id}
                                name={params.row.name}
                                sx={{span: {pointerEvents: 'none'}}}
                                onClick={disableCategory}
                                loadingPosition="start"
                                startIcon={<ArchiveIcon />}
                                variant="outlined"
                                >
                                Disable
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ],
        }
    ]

    const CustomToolbar = () => { // data grid header components
        return (
            <Grid container
                sx={{
                    p: 1,
                    pb: 0
                }}
            >
                <Grid item xs={6}>
                    <GridToolbarQuickFilter />
                </Grid>
                <Grid 
                    xs={6}
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                    >
                        <CreateModal refresh={refreshData} data={categoryList} />
                </Grid>
            </Grid>
        );
    }

    return (
        <Box sx={{marginLeft: "6%",marginRight: "3%", marginTop: "5%"}}>
            <Box sx={style.DataGrid}>
                <DataGrid
                    columns={columns}
                    rows={categoryList}
                    disableSelectionOnClick
                    loading={globalStates.isLoading.get()}
                    components={{ 
                        Toolbar: CustomToolbar, 
                        LoadingOverlay: LinearProgress, 
                    }}
                />   
                <ConfirmationModal 
                    messages={messages} 
                    load={btnLoad} 
                    run={() => isToRun()} />   
            </Box>  
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        category: state.category.categoryList
    }
}

export default connect(mapStateToProps)(Category);