import React from "react"; // import react 
import { Route } from "react-router-dom"; // import route and redirect library
const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
        // route component
        <Route
            // props
            {...rest}
            render={props => {
                // check if the user is signed in

                // components to show
                return <Component {...props} />
            }}
        />
    )
}
export default ProtectedRoute