
const INTIAL_STATE = 0

const UpdateReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {

        case 'UPDATE':
            const time = Date.now()
            return time

        default:
            return state;
    }

};

export default UpdateReducer;