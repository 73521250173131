const INTIAL_STATE = {
    productList: []
}


const ProductListReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'PRODUCT_LIST':
            return {
                ...state,
                productList: action.list
            };
        case 'UPDATE_PRICE':
            return {
                ...state,
                productList: action.list.map((el) => el.id === action.id ? { ...el, salePrice: action.value } : el)
            };
        case 'UPDATE_RECEIVED_LIST':
            return {
                ...state,
                productList: action.list.map((el) => el.id === action.id ? { ...el, receivedBuying: action.value,  adjustedTotal : action.value * el.cost } : el)
            };

        default:
            return state;
    }

};

export default ProductListReducer;