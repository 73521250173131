import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import ConstantStyles from "../../../utility/ConstantStyle.json";
import { getItem, GetProduct } from "../../../utility/Endpoints";
import GlobalStates from "../../../utility/GlobalStates";
import { setProducts } from "../../actions";
import gridSize from "./CardGridSize.json";
import ClassicCard from './ClassicCard';
import ProductView from './ProductView';

import defaultImage from '../../../assets/logo/default.webp';

const announcement = localStorage.getItem('announcement') === "1"
//const defaultImage = `https://d1sag4ddilekf6.azureedge.net/compressed_webp/items/PHITE20220830072619042072/detail/menueditor_item_eed3a594f161472985cd0680df451439_1666566692484852972.webp`
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const styles = {
    image: {
        borderRadius: '0px 0px 110px 110px',
        objectFit: "cover",
        height: "100%"
    }, productTextStrong: {
        fontWeight: ConstantStyles['bold']
    }, productTextSemiStrong: {
        fontWeight: ConstantStyles['semi-bold']
    }, productDescription: {
        fontSize: '14px'
    }, groupRequired: {
        backgroundColor: "rgba(31, 239, 239, 0.1)",
        margin: '10px 0px',
        padding: '10px'
    }, groupOpt: {
        margin: '10px 0px',
        padding: '10px'
    }, gridAlignLeft: {
        textAlign: 'left'
    }, groupChecked: {
        backgroundColor: "rgba(31, 239, 239, 0.1)",
        margin: '10px 0px',
        padding: '10px',
        border: ConstantStyles['default-border'],
        borderColor: ConstantStyles['red']
    },
    imageContainer: {
        padding: '25px',
        height: '100%'
    }
}
const ProductPage = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    let isMobile = window.innerWidth <= 640
    let toggleSide = isMobile ? "bottom" : "right"
    const params = useParams()
    const [item, setItem] = useState()
    const [productList, setProductList] = useState([]);
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        fetchItem()
        FetchProduct()
    }, [])

    const fetchItem = () => {
        const payload = { branch: params.branch, sku: params.sku }
        getItem(payload) // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                setItem(result[0])
            })
            .catch(error => {
                console.log(error);
            })
    }

    const FetchProduct = () => {
        GetProduct() // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                let filteredResult = result?.filter((item) => item.sku !== params.sku)
                setProductList(filteredResult)
                dispatch(setProducts(result))
            })
            .catch(error => {
                console.log(error);
            })
    }

    const toggleDrawer = (anchor, open, event, item) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
        if (item)
            setItem(item)
    }


    return (
        <Box
            sx={{ flexGrow: 1, p: "20px", background: "#f7f7f7", marginTop: `${announcement ? '125px' : '85px'}`, minHeight: '80vh' }}
            id='product-page-container'
        >

            <Grid container>
                <Grid item xs={12}>
                    <Item>
                        <Grid container sx={{ minHeight: '450px' }}>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}

                            >
                                <div style={styles.imageContainer}>
                                    <img
                                        alt={`product-${item?.product_id}`}
                                        src={item?.image_src ?? defaultImage}
                                        width="70%"
                                        style={styles.image}
                                    />
                                </div>
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                            >
                                <Grid
                                    item xs={12} sm={12} md={12} lg={12} style={styles.gridAlignLeft}>
                                    <Typography variant="h6" style={styles.productTextStrong}>{item?.name}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} style={styles.gridAlignLeft}>
                                    <Typography variant="subtitle2" style={styles.productTextSemiStrong}>PHP {(item?.price?.toFixed(2))?.toLocaleString()}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ minHeight: '100px' }}>
                                    <pre className='mb-0' style={styles.productDescription}>{item?.description}</pre>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Button
                                        sx={{
                                            float: 'left',
                                            color: 'white',
                                            padding: '5px 35px 5px 35px',
                                            background: ConstantStyles['purple'],
                                            width: '20%',
                                            height: '36px',
                                            ':hover': { backgroundColor: ConstantStyles['purple'] },
                                            ':disabled': {
                                                backgroundColor: ConstantStyles['white-color']
                                            }
                                        }}
                                        onClick={(event) => toggleDrawer(toggleSide, true, event)}
                                    >
                                        ADD
                                    </Button>
                                </Grid>
                            </Grid >
                        </Grid>
                    </Item>
                </Grid>
            </Grid>
            <h5 style={{ marginTop: '25px' }}>Related Products:</h5>
            <Box sx={{ width: '100%', p: '20px', marginTop: '25px' }}>
                <Grid container md={12} lg={12} spacing={{ xs: 1, md: 2 }} rows={{ xs: 4, sm: 6, md: 12 }}>
                    {
                        productList.map((product, i) =>
                        (
                            product.category.some((category) => item.category.includes(category)) ?
                                <Grid
                                    key={i + 100}
                                    item
                                    xs={gridSize['classic'].grid.sm}
                                    sm={gridSize['classic'].grid.sm}
                                    md={gridSize['classic'].grid.md}
                                    lg={gridSize['classic'].grid.lg}
                                    columnSpacing={4}
                                    onClick={(event) => toggleDrawer(toggleSide, true, event, product)}
                                >
                                    {
                                        <ClassicCard item={product} index={i} viewType={'classic'} />
                                    }
                                </Grid>
                                : null
                        )
                        )
                    }
                </Grid>
            </Box>
            <SwipeableDrawer
                sx={{
                    height: window.innerHeight,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        height: window.innerHeight,
                        boxSizing: 'border-box',
                    },
                }}
                disableBackdropTransition={true}
                anchor={toggleSide}
                open={state[toggleSide]}
                onClose={() => toggleDrawer(toggleSide, false)}
                onOpen={() => toggleDrawer(toggleSide, true)}
            >
                <ProductView item={item} index={1} state={state} setstate={setState} />
            </SwipeableDrawer>
        </Box >
    )

}

export default ProductPage;