import React, { useEffect } from 'react'
import { useState } from 'react'
import { Routes, Route, useParams } from 'react-router-dom';
import { useHookstate } from '@hookstate/core';
import PropTypes from "prop-types";

import { Box } from '@mui/system'
import { Button, FormControl, FormHelperText, Grid, MenuItem, Select, Typography } from '@mui/material'

import ConfirmationModal from '../../../shared/ConfirmationModal'
import GlobalStates from '../../../../utility/GlobalStates';
import api from '../../../../utility/Endpoints';

import { Alert, IconButton, TextField, DialogTitle, Menu } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SaveIcon from '@mui/icons-material/Save';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogContentText from '@mui/material/DialogContentText';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import HistoryTable from '../../../shared/HistoryTable';
import AddItemModal from './AddItemModal';
import moment from 'moment';

const styles = {
    upperContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px',
        container: {
            display: 'flex',
            borderBottom: '1px solid gray',
            paddingBottom: '5px'
        }
    },
    mainContent: {
        padding: '10px',
        container: {
            display: 'flex',
        },
        shippingDetails: {
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            marginBottom: '5px'
        },
        floatRight:{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: 'auto'
        },
        flexColumn: {
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        }
    },
    itemTable: {
        display: 'flex',
        height: "50vh",
        width: '100%',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            whiteSpace: 'normal',
            textOverflow: 'normal',
            lineHeight: '15px'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },
    }
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
function BootstrapDialogTitle(props) {
const { children, onClose, ...other } = props;

return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
    {children}
    {onClose ? (
        <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
            position: 'absolute',
            right: 8,
            color: (theme) => theme.palette.grey[500],
        }}
        >
        <CloseIcon />
        </IconButton>
    ) : null}
    </DialogTitle>
);
}

BootstrapDialogTitle.propTypes = {
children: PropTypes.node,
onClose: PropTypes.func.isRequired,
};

const ShowModifier = (props) => {
    const { data } = props
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)

    const modifiers = Object.keys(data.modifiers)

    const modifiers_amount = data.modifiers[`UPSIZE`]?.price || 0 + data.modifiers[`SUGAR LEVEL`]?.price || 0 + data.modifiers[`MILK ALTERNATIVES`]?.price || 0
    const upgrades_amount = data.modifiers[`UPGRADES`]?.map(item => item.price).reduce((a, b) => a + b, 0)

    // number formatter with comma and fixed 2 decimal
    const comma = (num) => {
        const toDec = Math.floor(100 * num) / 100
        const format = toDec.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return format
    }
    return (
        <Box key={data.id}>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <IconButton onClick={e => setOpen(true)}><VisibilityIcon/></IconButton>
            </Box>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                Item Details
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Typography 
                    component='div'
                    gutterBottom
                >
                    <Typography 
                        variant='h6'
                        sx={{
                            fontWeight: 'bold',
                            lineHeight: 'normal'
                        }}
                    >
                        {data.name} ({data.qty}x)
                    </Typography>
                    <Typography>
                        {data.sku}
                    </Typography>
                </Typography>
                <Typography gutterBottom>
                    <Typography>
                        Modifiers:
                    </Typography>
                    <Typography 
                        component='div'
                    >
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Modifiers</TableCell>
                                        <TableCell align="right">Modifier name</TableCell>
                                        <TableCell align="right">Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {modifiers.map((value, index) => {
                                        const modifier_data = data.modifiers[value]
                                        // console.log(modifier_data);
                                        if (value === 'UPGRADES') {
                                            return (
                                                
                                                <TableRow
                                                    key={value}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {value}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {modifier_data.map( data => 
                                                        (
                                                            <Typography>
                                                                {data.modifier_name}
                                                            </Typography>
                                                        ))
                                                        }
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {modifier_data.map( data => 
                                                        (
                                                            <Box>
                                                                <Typography>
                                                                    {data.price}
                                                                </Typography>
                                                            </Box>
                                                        ))
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }else{
                                            return (
                                                
                                                <TableRow
                                                    key={value}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {value}
                                                    </TableCell>
                                                    <TableCell align="right">{modifier_data.modifier_name}</TableCell>
                                                    <TableCell align="right">₱{comma(modifier_data.price)}</TableCell>
                                                </TableRow>
                                            )
                                        }
                                    })}
                                            
                                    <TableRow
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            Total
                                        </TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right">₱{comma(Number(modifiers_amount || 0) + Number(upgrades_amount || 0))}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Typography>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                Back
                </Button>
            </DialogActions>
            </BootstrapDialog>
        </Box>
    )
}

const EditItem = (props) => {
    const { data, cartList, refresh, order_number, shipping_fee, discount } = props
    const [open, setOpen] = React.useState(false);
    const [remarks, setRemarks] = useState('')
    const [price, setPrice] = useState(0)
    const [qty, setQty] = useState(0)
    const user = JSON.parse(localStorage.getItem('userInfo'))

    useEffect(() => {
        setRemarks(data.remarks)
        setPrice(data.price)
        setQty(data.qty)
    }, [data])

    const handleClickOpen = () => setOpen(true);
    
    const handleClose = () => setOpen(false);
    

    const handleSave = () => {
        const newCart = cartList.filter((cart, index) => index !== data.id)

        const newItem = [{
            item_id: data.item_id,
            item_modifier: data.modifiers,
            item_name: data.name,
            item_price: Number(price),
            item_qty: Number(qty),
            item_remarks: remarks || '',
            item_sku: data.sku,
            original_price: Number(data.original_price),
        }]

        const mergeItems = newItem.concat(newCart)
        const total_amount = (mergeItems.map(item => Number(item.item_price) * Number(item.item_qty)).reduce((a, b) => a + b, 0) + Number(shipping_fee)) - Number(discount)

        api.fulfillment.editItem({
            order_number: order_number,
            before: `Remarks:${data.remarks}, Quantity:${data.qty}, Price:${data.price}`,
            after: `Remarks:${remarks}, Quantity:${qty}, Price:${price}`,
            sku: data.sku,
            cart: JSON.stringify(mergeItems),
            total_amount:  total_amount,
            email: user[0].email
        })
        .then((response)=> {
            refresh()
        })
        .catch((error) => {
            console.log(error.message);
        })
        .finally(() => {
            handleClose()
        })

    }
  
    return (
      <div>
        <IconButton color='warning' onClick={handleClickOpen}>
            <EditIcon/>
        </IconButton>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Item</DialogTitle>
          <DialogContent sx={{width: '500px'}}>
            <TextField
              autoFocus
              defaultValue={data.remarks}
              margin="dense"
              rows={4}
              id="name"
              label="Remarks"
              multiline
              fullWidth
              onChange={e => setRemarks(e.target.value)}
            />
            <TextField
              defaultValue={data.qty}
              margin="dense"
              id="name"
              label="Quantity"
              type="number"
              fullWidth
              variant="standard"
              onChange={e => setQty(e.target.value)}
            />
            
            <TextField
              defaultValue={data.price}
              margin="dense"
              id="name"
              label="Price"
              type="number"
              fullWidth
              variant="standard"
              onChange={e => setPrice(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={e => handleSave()}>Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

const OrderPage = (props) => {
    const { id }= useParams()
    const globalStates = useHookstate(GlobalStates) 
    const [status, setStatus] = useState('')
    const [selectedBranch, setSelectedBranch] = useState('')
    const [courier, setCourier] = useState('1')
    const [data, setData] = useState({})
    const [orders, setOrders] = useState([])
    const [history, setHistory] = useState([])
    const [branchList, setBranchList] = useState([])
    const [shippingFee, setShippingFee] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [cartList, setCartList] = useState([])
    const [selectedData, setSelectedData] = useState({}) // selected data for confirmation modal
    const [toRun, setToRun] = useState('') // what type of function is to run
    const [btnLoad, setBtnLoad] = useState(false) // button loading trigger
    const [messages, setMessages] = useState({}) // messages for confirmation modal
    const [subTotal, setSubTotal] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const user = JSON.parse(localStorage.getItem('userInfo'))


    useEffect(() => {
        getOrder()
        fetchBranch()
        getHistory()
    }, [status, refresh])

    const getOrder = () => {
        globalStates.isLoading.set(true)
        api.fulfillment.getOrderByOrderNumber(id)
        .then((response) => {
            const result = response.data.result
            const order = result?.map( item => {
                return {
                    id: item.order_id,
                    order_number: item.order_number,
                    branch_id: item.branch_id,
                    branch: item.branch_name,
                    abbreviation: item.abbreviation,
                    customer_info: JSON.parse(item.customer_info),
                    shipping_address: JSON.parse(item.shipping_address)[0],
                    shipping_fee: item.shipping_fee,
                    cart: JSON.parse(item.cart),
                    total_amount: item.total_amount,
                    discount_amount: item.discount_amount,
                    discount_name: item.discount_name,
                    payment_method: item.payment_method,
                    date_created: item.date_created,
                    date_schedule: item.date_schedule,
                    status: item.status,
                }
            })
            const renderCart = order[0]?.cart.map((c, index) => {
                return {
                    id: index,
                    item_id: c.item_id,
                    category: c.item_category,
                    qty: c.item_qty,
                    sku: c.item_sku,
                    name: c.item_name,
                    price: Number(c.item_price),
                    original_price: c.original_price,
                    modifiers: c.item_modifier,
                    total_amount: Number(c.item_price * c.item_qty),
                    remarks: c.item_remarks,
                }
            })

            const subTotal = renderCart.map( c => c.total_amount ).reduce((a, b) => a + b, 0)
            setSubTotal(subTotal);
            setOrders(renderCart)
            setCartList(order[0]?.cart)
            setSelectedBranch(order[0].branch_id)
            setStatus(order[0].status)
            setData(order[0])
            setShippingFee(order[0].shipping_fee)
            setDiscount(order[0].discount_amount)
        })
        .catch((error) => {
            console.log(error.message);
        })
        .finally(() => {
            loadOff()
        })
    }

    const getHistory = () => {
        api.fulfillment.getHistory({order_number: id})
        .then((response) => {
            // console.log(response);
            const result = response.data.result
            const historyData = result.map( h => {
                return {
                    id: h.history_id,
                    movement: h.history_movement,
                    admin: h.admin,
                    from: h.old_value,
                    to: h.new_value,
                    date: h.history_date
                }
            })
            setHistory(historyData)
        })
        .catch((error) => {
            console.log(error.message);
        })
    }

    const fetchBranch = () => {
        api.branch.getBranch() // function from endpoint
            .then(response => {
                let result = response.data.result; // get result from api
                const data = result?.map( item => {
                    const sched = item.time
                    return {
                        id: item.branch_id,
                        value: item.branch_id,
                        label: item.name,
                        name: item.name,
                        abbreviation: item.abbreviation,
                        address: item.address,
                        phone: item.phone,
                        admin: parseInt(item.admin),
                        owned_by_bos: parseInt(item.owned_by_bos),
                        latitude: item.latitude,
                        longitude: item.longitude,
                        monday: sched.monday,
                        tuesday: sched.tuesday,
                        wednesday: sched.wednesday,
                        thursday: sched.thursday,
                        friday: sched.friday,
                        saturday: sched.saturday,
                        sunday: sched.sunday,
                        status: parseInt(item.status),
                    }
                })
                setBranchList(data)
            })
            .catch(error => {
                console.log(error);
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
            })
    }
    
    // number formatter with comma and fixed 2 decimal
    const comma = (num) => {
        const toDec = Math.floor(100 * num) / 100
        const format = toDec.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        return format
    }

    const load = () => setRefresh(!refresh)

    const loadOff = () => {
        globalStates.isLoading.set(false)
        setInterval(() => {
            globalStates.isLoading.set(false)
        }, 1000)
    }

    const onChangeStatus = (e) => {
        const newStatus = e.target.value 

        globalStates.modalOpenConfirm.set(true)

        setToRun('changeStatus')
        
        const msg = {
            title: "Change order status",
            message: `Are you sure you want to change status to "${newStatus}"`,
        }
        setMessages(msg)
        setSelectedData({
            order_number: id,
            status: newStatus,
            old_status: status,
            discount_name: data.discount_name && data.discount_amount ? data.discount_name : '',
            email: user[0].email
        })
    }

    const isToRun = () => { // functions to run after confirming
        setBtnLoad(true)
        if (toRun === 'changeStatus') {
            api.fulfillment.updateStatus(selectedData)
            .then((response) => {
                setBtnLoad(false)
                globalStates.modalOpenConfirm.set(false)
                setStatus(selectedData.status)
                load()
            })
            .catch((error) => {
                console.log(error);
                setBtnLoad(false)
                alert({
                    open: true,
                    severity: 'error',
                    message: error.message
                })
            })
        }
        if (toRun === 'changeBranch') {
            api.fulfillment.changeBranch(selectedData)
            .then((response) => {
                setBtnLoad(false)
                globalStates.modalOpenConfirm.set(false)
                load()
            })
            .catch((error) => {
                console.log(error);
                setBtnLoad(false)
                alert({
                    open: true,
                    severity: 'error',
                    message: error.message
                })
            })
            .finally(() => {
                setSelectedBranch(selectedData.branch_id)
            })
        }
    }

    const updateDiscount = () => {
        const total = (data.discount_amount + data.total_amount) - discount
        const body = {
            order_number: id,
            before: data.discount_amount,
            after: discount,
            email: user[0].email,
            newTotal: total < 1 ? 0 : total
        }

        api.fulfillment.updateDiscount(body)
        .then((response) => {
            load()
        })
        .catch((error) => {
            console.log(error.message);
        })
    }

    const handleChangeBranch = (e) => {
        e.preventDefault()
        const branch_id = e.target.value
        const oldBranch = branchList.filter(branch => branch.id === selectedBranch)[0]
        const newBranch = branchList.filter(branch => branch.id === branch_id)[0]

        globalStates.modalOpenConfirm.set(true)

        setToRun('changeBranch')
        
        const msg = {
            title: "Change Branch",
            message: `Are you sure you want to change Branch to "${newBranch.name}"`,
        }
        setMessages(msg)
        setSelectedData({
            order_number: id,
            branch_id: branch_id,
            new_branch: newBranch.name,
            old_branch: oldBranch.name,
            email: user[0].email
        })
    }

    // setting up columns
    const productColumns = [
        {
            field: "id", // fetching from map
            hide: true
        },
        {
            field: "item_id",
            hide: true
        },
        {
            field: "name",
            headerName: "Item",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "sku",
            headerName: "SKU",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "price",
            headerName: "Price",
            flex: 0.3,
            minWidth: 50,
        },
        {
            field: "modifiers",
            headerName: "Modifiers",
            flex: 0,
            minWidth: 10,
            renderCell: (params) => {
                return <ShowModifier data={params.row} />
            }
        },
        {
            field: "remarks",
            headerName: "Item remarks",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "qty",
            headerName: "Quantity",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "total_amount",
            headerName: "Total amount",
            flex: 0.3,
            minWidth: 50
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 0.3,
            minWidth: 100,
            type: 'actions',
            hide: data.status === 'Order Fulfilled' ? true : false ,
            getActions: (params) => [
                <React.Fragment>
                    <EditItem  data={params.row} cartList={cartList} refresh={load} order_number={id} shipping_fee={shippingFee} discount={discount} />
                    <IconButton
                        color="error"
                        data-id={params.id}
                        name={params.row.name}
                        sx={{span: {pointerEvents: 'none'}}}
                        onClick={() => {
                            console.log(params.row);
                            console.log(orders);
                            const newOrder = orders
                                            .filter( order => 
                                                    order.id !== params.row.id 
                                                )
                                            .map( order => {
                                                return {
                                                    item_id: order.item_id,
                                                    item_name: order.name,
                                                    item_sku: order.sku,
                                                    item_qty: order.qty,
                                                    item_price: order.price,
                                                    original_price: order.price,
                                                    item_image: order.image_src,
                                                    item_category: order.category,
                                                    item_modifier: order.modifiers,
                                                    item_remarks: orders.remarks
                                                }
                                            })
                            // console.log(newOrder);
                            const payload = {
                                cart: JSON.stringify(newOrder),
                                order_number: data.order_number,
                                total_amount: newOrder.map( order => order.item_price ).reduce((a, b) => a + b, 0) + shippingFee,
                                email: user[0].email,
                                before: orders.length,
                                after: newOrder.length,
                                product: params.row.name
                            }

                            api.fulfillment.removeItem(payload)
                            .then((response) => {
                                console.log(response);
                                load()
                            })
                            .catch((error) => {
                                console.log(error.message);
                            })

                        }}
                        >
                        <DisabledByDefaultIcon/>
                    </IconButton>
                </React.Fragment>
            ],
        }
    ]
    // setting up columns
    const historyColumns = [
        {
            field: "id", // fetching from map
            hide: true
        },
        {
            field: "movement",
            headerName: "Movement",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "from",
            headerName: "From",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "to",
            headerName: "To",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "date",
            headerName: "Date & Time",
            flex: 0.3,
            minWidth: 50,
        },
        {
            field: "admin",
            headerName: "Admin",
            flex: 0.3,
            minWidth: 100,
        },
    ]

    const CustomToolbar = () => {
        return (
            <Grid container
                sx={{
                    p: 1,
                    pb: 0
                }}
            >
                <Grid item xs={6}>
                    <GridToolbarQuickFilter />
                </Grid>
                <Grid 
                    xs={6}
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                    >
                        <AddItemModal hide={data.status === 'Order Fulfilled'? true : false} refresh={e => load()} shipping_fee={shippingFee} discount={discount} order_number={data.order_number} orders={cartList} />
                </Grid>
            </Grid>
        );
    }

    const date_schedule = data?.date_schedule?.split(" ")[0]
    const time_schedule = data?.date_schedule?.split(" ")[4] + data?.date_schedule?.split(" ")[5] 
    const scheduled_date = moment(date_schedule + ' ' + time_schedule, 'DD-MM-YYYY hh:mma').format("YYYY-MM-DD hh:mma")
    const current_date_time = moment().format("YYYY-MM-DD hh:mma")

    console.log(data);
    const late = scheduled_date < current_date_time

    return (
        <Box sx={{marginLeft: "6%",marginRight: "3%", marginTop: "5%"}}>
            <Grid container>
                <Grid item xs={12} sx={styles.upperContent.container}>
                    <Grid item xs={4} sx={styles.upperContent}>
                        <Typography sx={{fontSize: '20px', lineHeight: 'normal'}}>Order #:</Typography>
                        <Typography sx={{fontSize: '1.5rem', fontWeight: 'bold', lineHeight: 'normal'}}>{id}</Typography>
                    </Grid>
                    <Grid item xs={4} sx={styles.upperContent}>
                        <Typography><AccessTimeFilledIcon color="disabled" />{data.date_created}</Typography>
                        <Typography sx={{display: 'flex', alignItems: 'center'}}><CalendarMonthIcon color='disabled'/> {data.date_schedule}
                            <Typography align='center' sx={{display: late ? '' : 'none', fontSize: '15px', backgroundColor: 'red', width: '100px', borderRadius: '5px', color: 'white'}}>
                                LATE
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={styles.upperContent}>
                        <FormControl sx={{ minWidth: 120, width: '100%', padding: '0px 10px 0px 0px' }}>
                            <Select
                            sx={{width: '100%', margin: '0px 10px 0px 0px'}}
                            size='small'
                            value={status}
                            onChange={onChangeStatus}
                            disabled={data.status === 'Order Fulfilled' ? true : false }
                            >
                                <MenuItem value={"To Fulfill"}>To fulfill</MenuItem>
                                <MenuItem value={"For Payment"}>For payment</MenuItem>
                                <MenuItem value={"Ready To Ship"}>Ready to ship</MenuItem>
                                <MenuItem value={"Order Fulfilled"}>Order fulfilled</MenuItem>
                                <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={styles.mainContent.container}>
                    <Grid item xs={8} sx={styles.mainContent.container}>
                        <Grid container>
                            <Grid item xs={6} sx={styles.mainContent.container}> 
                                <Box sx={styles.mainContent}>
                                    <PersonPinIcon color="disabled" fontSize='large'/>
                                </Box>
                                <Box sx={styles.mainContent}>
                                    <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                        Customer
                                    </Typography>
                                    <Typography>
                                        {data.customer_info?.first_name + " " + data.customer_info?.last_name} <br/>
                                        {data.customer_info?.email_address} <br/>
                                        {data.customer_info?.phone_number} <br/>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sx={styles.mainContent.container}>
                                <Box sx={styles.mainContent}>
                                    <FmdGoodIcon color="disabled" fontSize='large'/>
                                </Box>
                                <Box sx={styles.mainContent}>
                                    <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                        Deliver to
                                    </Typography>
                                    <Typography>
                                        {data.shipping_address?.name} <br/>
                                        {data.shipping_address?.phone} <br/>
                                        {data.shipping_address?.street + " " + data.shipping_address?.brgy + " "+ data.shipping_address?.city }
                                    </Typography>
                                </Box>

                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={styles.itemTable}>
                                    <DataGrid
                                        columns={productColumns}
                                        rows={orders}
                                        disableSelectionOnClick
                                        loading={globalStates.isLoading.get()}
                                        components={{ 
                                            Toolbar: CustomToolbar, 
                                            LoadingOverlay: LinearProgress, 
                                        }}
                                    />  
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid item sx={styles.mainContent.container}> 
                                    <Box sx={styles.mainContent}>
                                        <PersonPinIcon color="disabled" fontSize='large'/>
                                    </Box>
                                    <Box sx={styles.mainContent}>
                                        <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                            History
                                        </Typography>
                                    </Box>
                                </Grid>
                                <HistoryTable historyColumns={historyColumns} historyData={history} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid item xs={12} sx={styles.mainContent.container}>
                            <Box sx={styles.mainContent}>
                                <LocalShippingRoundedIcon color="disabled" fontSize='large'/>
                            </Box>
                            <Box sx={styles.mainContent.flexColumn}>
                                <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                    Shipping details
                                </Typography>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography>Branch: </Typography>
                                    <Select
                                    disabled={data.status === 'Order Fulfilled' ? true : false }
                                    sx={styles.mainContent.floatRight}
                                    size='small'
                                    value={selectedBranch}
                                    onChange={handleChangeBranch}
                                    >
                                        {
                                            branchList.map( b => {
                                                return <MenuItem value={b.value}>{b.label}</MenuItem>
                                            })
                                        }
                                        
                                    </Select>
                                </Box>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography>Courier: </Typography>
                                    <Select
                                    disabled={data.status === 'Order Fulfilled' ? true : false }
                                    sx={styles.mainContent.floatRight}
                                    size='small'
                                    value={courier}
                                    onChange={e => setCourier(e.target.value)}
                                    >
                                        <MenuItem value={'1'}>Sample 1</MenuItem>
                                        <MenuItem value={'2'}>Sample 2</MenuItem>
                                        <MenuItem value={'3'}>Sample 3</MenuItem>
                                        <MenuItem value={'4'}>Sample 4</MenuItem>
                                        <MenuItem value={'5'}>Sample 5</MenuItem>
                                    </Select>
                                </Box>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography>Courier fee: </Typography>
                                    <Box sx={{width: '250px'}}>----</Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={styles.mainContent.container}>
                            <Box sx={styles.mainContent}>
                                <CreditCardRoundedIcon color="disabled" fontSize='large'/>
                            </Box>
                            <Box sx={styles.mainContent.flexColumn}>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                        Payment method
                                    </Typography>
                                    <Button hidden={data.status === "Order Fulfilled" ? true : false} >Refund</Button>
                                </Box>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography>Method:</Typography>
                                    <Box sx={styles.mainContent.floatRight}>{data.payment_method}</Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={styles.mainContent.container}>
                            <Box sx={styles.mainContent}>
                                <PaidRoundedIcon color="disabled" fontSize='large'/>
                            </Box>
                            <Box sx={styles.mainContent.flexColumn}>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography variant='h6' sx={{fontWeight: 'bold'}}>
                                        Summary
                                    </Typography>
                                </Box>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography>Sub-total:</Typography>
                                    <Box sx={styles.mainContent.floatRight}>₱{comma(subTotal)}</Box>
                                </Box>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography>Shipping fee:</Typography>
                                    <Box sx={styles.mainContent.floatRight}>₱{comma(data.shipping_fee)}</Box>
                                </Box>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography>Discount {data.discount_name ? `(${data.discount_name})` : ''}:</Typography>
                                    {/* <Box sx={styles.mainContent.floatRight}>- ₱{comma(data.discount_amount)}</Box> */}
                                    {
                                        data.status === 'To Fulfill' ?
                                        <Box sx={styles.mainContent.floatRight}>- ₱ 
                                            <TextField size='small' value={discount} onChange={e => setDiscount(e.target.value)} sx={{width: '100px'}} type='number'/> 
                                                <Button size='small' onClick={updateDiscount}><SaveIcon /></Button> 
                                        </Box>
                                        :
                                        <Box sx={styles.mainContent.floatRight}>- ₱{comma(discount)}</Box>
                                    }
                                </Box>
                                <hr/>
                                <Box sx={styles.mainContent.shippingDetails}>
                                    <Typography sx={{fontWeight: 'bold'}}>Total amount:</Typography>
                                    <Box sx={styles.mainContent.floatRight}>
                                        <Typography sx={{fontWeight: 'bold'}}>₱{comma(data.total_amount)}</Typography> 
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ConfirmationModal 
                messages={messages} 
                load={btnLoad} 
                run={() => isToRun()} />  
        </Box>
    )
}

export default OrderPage