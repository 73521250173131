// react components
import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GlobalStates from "./utility/GlobalStates";
import ThemeStyles from "./utility/ThemeStyles";
import api from "./utility/Endpoints"


import { connect, useDispatch } from "react-redux";
import { setCategory } from "./components/actions";
// css 
import './App.scss';

// mui components
import { ThemeProvider } from "@mui/material";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';

import ConstantStyles from "./utility/ConstantStyle.json";
//created component 
import AnnouncementHeader from './components/general/Announcement';
import BottomNavbar from './components/general/BottomNavbar';
import Navbar from './components/general/Navbar';
import CustomAlertfrom from './components/shared/CustomAlert';

//products
import ProductList from "./components/modules/Product/ProductList";
import ProductPage from "./components/modules/Product/ProductPage";

//page router
import Home from './components/modules/Home';
import ProtectedRoute from "./components/modules/ProtectedRoute";
import Page404 from "./components/shared/Page404";
import Page from "./components/modules/Page/Page";
import PageList from "./components/modules/Backend/Page/PageList";
import PageSectionList from "./components/modules/Backend/Page/PageSectionList";
import CreatePageContent from "./components/modules/Backend/Page/CreatePageContent";
import CreateMultiplePageContent from "./components/modules/Backend/Page/CreateMultiplePageContent";
// content router
import ContentsList from "./components/modules/Contents/ContentsList";
import CreateContent from "./components/modules/Contents/CreateContent";

// announcement router
import Announcement from "./components/modules/Announcement/Announcement";

//checkout
import CheckoutPage from "./components/modules/Checkout/CheckoutPage";
import Failed from "./components/modules/Checkout/Failed";
import ThankYouPage from "./components/modules/Checkout/ThankYouPage";
import DebitCredit from "./components/modules/Payment/DebitCredit";
import NextAction from "./components/modules/Payment/NextAction";

// import UpdateContent from "./components/modules/Contents/UpdateContent-unused";
import CreateMultipleContent from "./components/modules/Contents/CreateMultipleContent";

// login router
import Login from "./components/modules/Login/Login";
// register router
import Register from "./components/modules/Register/Register";
// admin back-end
import Branch from "./components/modules/Backend/Branch/Branch";
import AdminCategory from "./components/modules/Backend/Category/Category";
import OrderList from "./components/modules/Backend/Fulfillment/OrderList";
import ModifierStatus from "./components/modules/Backend/Modifier/ModifierStatus";
import CreateSale from "./components/modules/Backend/OnSale/CreateSale";
import OnSale from "./components/modules/Backend/OnSale/OnSale";
import AdminProduct from "./components/modules/Backend/Product/Products";
import User from "./components/modules/Backend/User/User";
import MovingBannerList from "./components/modules/Backend/MovingBanner/MovingBannerList";

import OrderPage from "./components/modules/Backend/Fulfillment/OrderPage";
import AccountView from "./components/modules/CustomerAccount/AccountView";

//Misc router
import AboutUs from "./components/modules/Misc/AboutUs";
import Franchise from "./components/modules/Misc/Franchise";
import { GetCategoryList } from "./utility/Endpoints";
import Location from "./components/modules/Misc/Location"

//
import Footer from './components/general/Footer';

//sidebar
import Grid from '@mui/material/Grid';
import Sidebar from "./components/general/Sidebar";
import BackendFooter from "./components/modules/Backend/Contents/Footer/Footer";
import Banner from "./components/modules/Backend/Contents/Banner/Banner";

const App = () => {

  const ref = useRef(null)
  const dispatch = useDispatch()
  const globalStates = useHookState(GlobalStates); // call globalstates
  const [theme, setTheme] = useState(ThemeStyles);
  const [pages, setPages] = useState([])
  let openSnackbar = globalStates.snackBarStatus.get(); // style ng header for margin-top 
  let snackbarMsg = globalStates.snackBarMsg.get() // style ng header for margin-top 
  let signedIn = localStorage.getItem('isSignedIn') === 'true'// set localStorage data to boolean
  let user = localStorage.getItem("userInfo");
  let userParse = JSON.parse(user) || [{ "user_id": 0, "email": "" }];
  let userID = userParse[0]?.user_id || 0;
  globalStates.userEmail.set(userParse[0]?.email || "");
  let pathName = window.location.pathname;
  const [screen, setScreen] = useState(window.innerWidth);
  let isMobile = screen <= 767

  const [refresh, setRefresh] = useState([])
  
  useEffect(() => {
    const handleResize = () => {
        setScreen(window.innerWidth)
    };

    //is loadingset global state
    setTimeout(() => {
    }, 400);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);



  useEffect(() => {
    FetchPage()
    FetchCategory()
  }, [screen])


  // useEffect(() => {
    

  //   // FetchCategory(); // call function

  // }, [screen]);


  useEffect(() => {
    const handleResize = () => {
      setScreen(window.innerWidth)
      // is mobile set global state
      if (screen <= 767) {
        globalStates.isMobile.set(true)
        setRefresh(!refresh)
      }
      else {
        globalStates.isMobile.set(false)
        setRefresh(!refresh)
      }
    };

        //is loadingset global state
        setTimeout(() => {
          globalStates.isLoading.set(true);
        }, 400);
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const FetchCategory = () => {
    GetCategoryList() // function from endpoint
      .then(response => {
        let result = response.data; // get result from api
        dispatch(setCategory(result))
      })
      .catch(error => {
        console.log(error);
      })
  }


  const FetchPage = () => {
    api.page.get()
      .then(response => {
        const pagesList = response.data
        const tempPage = pagesList.map((el) => {
          return {
            id: el.page_id,
            title: el.title
          }
        })
        setPages(tempPage)
      })
      .catch(error => {
        console.log(error);
      })
  }

  console.log("hello world", window.innerWidth)
  return (
    <>
      <ThemeProvider theme={theme}>
        {globalStates.openAlert.get() ?
          //show alert
          <CustomAlertfrom sx={{ position: 'fixed' }} in={globalStates.openAlert.get()} close={() => globalStates.openAlert.set(false)} severity={globalStates.statusAlert.get()} msg={globalStates.msgAlert.get()} />
          :
          null
        }
        {
          // <Spinner animation="grow" variant="info" />
          <BrowserRouter>
            <Box>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
                <Snackbar
                  open={openSnackbar}
                  key={'top' + 'center'}
                  onClose={() => {
                    globalStates.snackBarStatus.set(false);
                  }}
                  sx={{
                    bottom: '50% !important',
                    left: 'unset !important',
                    right: 'unset !important',
                    opacity: '0.8'
                  }}
                  autoHideDuration={1500} >
                  <Alert
                    onClose={() => {
                      globalStates.snackBarStatus.set(false);
                    }}
                    sx={{
                      width: '100%',
                      borderRadius: '50px',
                      border: `1px solid ${ConstantStyles['teal']}`,
                      backgroundColor: `${ConstantStyles['purple']} !important`,
                      color: ConstantStyles['teal']
                    }}>
                    {snackbarMsg}
                  </Alert>
                </Snackbar>
              </div>
              {!pathName.includes("admin-backend") ?
                <>
                  <Navbar />
                  <AnnouncementHeader />
                </>
                :
                null
              }


              {userID === 0 || userID === "0" ?
                // storefront
                <Switch>
                  {/* routes when user is not yet signed in
                    * - meaning nito kahit asang page sya, didisplay nya pa din ung login component
                   */}

                  <ProtectedRoute path='/admin-backend/*' exact component={Login} />
                  <ProtectedRoute path='/admin-backend/login' exact component={Login} />
                  <ProtectedRoute path='/' exact component={Home} />
                  <ProtectedRoute path='/home' exact component={Home} />
                  <ProtectedRoute path='/product-page/:sku/:branch' exact component={ProductPage} />
                  <ProtectedRoute path='/menu/:category' exact component={ProductList} />
                  <ProtectedRoute path='/checkout' exact component={CheckoutPage} />
                  <ProtectedRoute path='/account' exact component={AccountView} />
                  <ProtectedRoute path='/thank-you' exact component={ThankYouPage} />
                  <ProtectedRoute path='/payment-failed' exact component={Failed} />
                  <ProtectedRoute path='/payment/debit-credit' exact component={DebitCredit} />
                  <ProtectedRoute path='/paymongo/debit-credit/next-action' exact component={NextAction} />
                  <ProtectedRoute path='/franchise' exact component={Franchise} />
                  <ProtectedRoute path='/about-us' exact component={AboutUs} />
                  <ProtectedRoute path='/storelocator' exact component={Location} />
                  {pages.map((el) => {
                    const link = el.title.toLowerCase().replace(/\s/g, "-")
                    return (
                      < ProtectedRoute path={`/${link}/:id`} exact component={Page} />
                    )
                  })}
                  <Route component={Page404} />

                </Switch>

                :
                <Switch>
                  {/* routes when user is not yet signed in
                  * - meaning nito kahit asang page sya, didisplay nya pa din ung login component
                 */}
                  {/* <ProtectedRoute path='/admin-backend/*' exact component={Login} />
                  <ProtectedRoute path='/admin-backend/login' exact component={Login} /> */}


                  {/* this routes for admin user */}
                  <Grid container>
                    {pathName.includes("admin-backend") &&
                      <Grid item lg={2} xl={2} md={2}>
                        < Sidebar
                          opened={true}
                          collapsed={false}
                        />
                      </Grid>}
                    <Grid item xl={pathName.includes("admin-backend") ? 10 : 12} lg={pathName.includes("admin-backend") ? 10 : 12} md={pathName.includes("admin-backend") ? 10 : 12} sm={pathName.includes("admin-backend") ? 10 : 12} xs={pathName.includes("admin-backend") ? 10 : 12}>
                      <ProtectedRoute path='/admin-backend/category' exact component={AdminCategory} />
                      <ProtectedRoute path='/admin-backend/branch' exact component={Branch} />
                      <ProtectedRoute path='/admin-backend/fulfillment/All' exact component={OrderList} />
                      <ProtectedRoute path='/admin-backend/fulfillment/To-fulfill' exact component={OrderList} />
                      <ProtectedRoute path='/admin-backend/fulfillment/For-payment' exact component={OrderList} />
                      <ProtectedRoute path='/admin-backend/fulfillment/Ready-to-ship' exact component={OrderList} />
                      <ProtectedRoute path='/admin-backend/fulfillment/Order-fulfilled' exact component={OrderList} />
                      <ProtectedRoute path='/admin-backend/fulfillment/Cancelled' exact component={OrderList} />
                      <ProtectedRoute path='/admin-backend/fulfillment/view/:id' exact component={OrderPage} />
                      <ProtectedRoute path='/admin-backend/onSale' exact component={OnSale} />
                      <ProtectedRoute path='/admin-backend/add-onsale' exact component={CreateSale} />
                      {/* <ProtectedRoute path='/admin-backend/modifier' exact component={ModifierList} /> */}
                      <ProtectedRoute path='/admin-backend/modifier-status/:id' exact component={ModifierStatus} />

                      {/* Footer content backend */}
                      <ProtectedRoute path='/admin-backend/Footer' exact component={BackendFooter} />
                      <ProtectedRoute path='/admin-backend/Banner' exact component={Banner} />


                      {/* this routes for admin user */}
                      <ProtectedRoute path='/' exact component={Home} />
                      <ProtectedRoute path='/home' exact component={Home} />
                      <ProtectedRoute path='/menu/:category' exact component={ProductList} />
                      <ProtectedRoute path='/product-page/:sku/:branch' exact component={ProductPage} />
                      <ProtectedRoute path='/checkout' exact component={CheckoutPage} />
                      <ProtectedRoute path='/thank-you' exact component={ThankYouPage} />
                      <ProtectedRoute path='/register' exact component={Register} />
                      <ProtectedRoute path='/payment-failed' exact component={Failed} />
                      <ProtectedRoute path='/payment/debit-credit' exact component={DebitCredit} />
                      <ProtectedRoute path='/paymongo/debit-credit/next-action' exact component={NextAction} />
                      <ProtectedRoute path='/franchise' exact component={Franchise} />
                      <ProtectedRoute path='/account' exact component={AccountView} />
                      <ProtectedRoute path='/about-us' exact component={AboutUs} />
                      <ProtectedRoute path='/storelocator' exact component={Location} />

                      {pages.map((el) => {
                        const link = el.title.toLowerCase().replace(/\s/g, "-")
                        return (
                          < ProtectedRoute path={`/${link}/:id`} exact component={Page} />
                        )
                      })}

                      {/* contents / section routes start */}
                      <ProtectedRoute path='/admin-backend/list-of-contents' exact component={ContentsList} />
                      <ProtectedRoute path='/admin-backend/create-content' exact component={CreateContent} />
                      <ProtectedRoute path='/admin-backend/update-content' exact component={CreateContent} />
                      <ProtectedRoute path='/admin-backend/create-multiple-content' exact component={CreateMultipleContent} />
                      <ProtectedRoute path='/admin-backend/product' exact component={AdminProduct} />
                      <ProtectedRoute path='/admin-backend/user' exact component={User} />
                      <ProtectedRoute path='/admin-backend/moving-banner' exact component={MovingBannerList} />
                      {/* contents / section routes end */}

                      {/* announcement routes start */}
                      <ProtectedRoute path='/admin-backend/announcement' exact component={Announcement} />
                      {/* announcement routes end */}

                      <ProtectedRoute path='/admin-backend/list-of-page' exact component={PageList} />
                      <ProtectedRoute path='/admin-backend/manage-page-section' exact component={PageSectionList} />
                      <ProtectedRoute path='/admin-backend/create-page-content/:name' exact component={CreatePageContent} />
                      <ProtectedRoute path='/admin-backend/update-page-content/:name' exact component={CreatePageContent} />
                      <ProtectedRoute path='/admin-backend/create-multiple-page-content/:name' exact component={CreateMultiplePageContent} />

                    </Grid>
                  </Grid>
                  <Route component={Page404} />


                </Switch>
              }
              {!pathName.includes("admin-backend") &&
                isMobile && !window.location.href.includes('checkout') && <BottomNavbar />
              }

            </Box>
            {!pathName.includes("admin-backend") &&
              !window.location.pathname.includes('checkout') &&
              <Footer />
            }

          </BrowserRouter>
        }
      </ThemeProvider >
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    category: state.category.categoryList
  }
}
export default connect(mapStateToProps)(App);