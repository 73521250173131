import { useState as useHookState } from "@hookstate/core";
import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import CartIcon from '../../assets/icons/Cart.png';
import ConstantStyles from '../../utility/ConstantStyle.json';
import GlobalStates from '../../utility/GlobalStates';

const styles = {
    buttonDesktop: {
        width: '40%',
        background: ConstantStyles['teal'],
        borderRadius: '30px',
        border: `2px solid ${ConstantStyles['purple']}`,
        color: ConstantStyles['purple'],
        ':hover': { backgroundColor: ConstantStyles['teal'] },
    },
    buttonMobile: {
        width: '80%',
        background: ConstantStyles['teal'],
        borderRadius: '30px',
        border: `2px solid ${ConstantStyles['purple']}`,
        color: ConstantStyles['purple'],
        ':hover': { backgroundColor: ConstantStyles['teal'] },
    },
    bold: {
        fontWeight: ConstantStyles['bold']
    },
    desktop: {
        width: '100%',
        textAlign: 'center',
        position: 'fixed',
        bottom: '20px'
    },
    mobile: {
        width: '100%',
        textAlign: 'center',
        position: 'fixed',
        bottom: '70px'
    }
}


const CheckoutButtonFixed = (props) => {
    const { cart } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    const isSignedIn = localStorage.getItem('customerSignedIn') === 'true'
    const guestSignIn = localStorage.getItem('guestSignIn') === 'true'


    const onClickButton = () => {
        if (isSignedIn || guestSignIn) {
            window.location.pathname = '/checkout'
        } else {
            window.location.hash = 'checkout'
            globalStates.showLoginModal.set(true)
        }
    }

    return (
        <React.Fragment>
            <Box sx={globalStates.isMobile.get() ? styles.mobile : styles.desktop}>
                <Button sx={globalStates.isMobile.get() ? styles.buttonMobile : styles.buttonDesktop} onClick={onClickButton}>
                    <Grid container>
                        <Grid item xs={6}>
                            <span style={styles.bold}>
                                <img alt='cart_logo' src={CartIcon} /> {cart?.map((item) => (item.item_qty)).reduce((partialSum, a) => partialSum + a, 0)} Item/s
                            </span>
                        </Grid>
                        <Grid item xs={6}>
                            <span style={styles.bold}>
                                Place Order
                            </span>
                        </Grid>
                    </Grid>
                </Button>
            </Box>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.CartList
    }
}

export default connect(mapStateToProps)(CheckoutButtonFixed);