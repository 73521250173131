import { useState as useHookState } from "@hookstate/core";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ConstantStyles from '../../../utility/ConstantStyle.json';
import api from '../../../utility/Endpoints';
import GlobalStates from "../../../utility/GlobalStates";
import { setDiscountRedux } from '../../actions';
const styles = {
    disabled: {
        p: 0.6,
        cursor: 'pointer',
        marginBottom: '10px',
        backgroundColor: ConstantStyles['white-color'],
        color: 'darkgray'
    },
    available: {
        p: 0.6,
        cursor: 'pointer',
        marginBottom: '10px',
    },
    selected: {
        p: 0.6,
        cursor: 'pointer',
        marginBottom: '10px',
        backgroundColor: '#A2F9F9',
    }
}


const Discount = (props) => {
    const { checkout, cart } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [code, setCode] = useState('')
    const [selectedDiscount, setSelectedDiscount] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [error, setError] = useState(false)
    const [isApplied, setIsApplied] = useState(false)
    const [loading, setLoading] = useState(false)
    const [publicList, setPublicList] = useState([])
    const [privateList, setPrivateList] = useState([])
    const [discountList, setDiscountList] = useState([])
    const customer = JSON.parse(localStorage.getItem('customerInfo'))
    const dispatch = useDispatch()

    const onKeyUpDiscount = (e) => {
        setIsApplied(false)
        setError(false)
        setErrorMsg('')
        globalStates.discountCode.set(e.target.value)
        return (
            setCode(e.target.value)
        )
    }

    useEffect(async () => {
        await api.discount.publicList(customer.customer_id)
            .then((response) => {
                let result = response.data.result
                const list = result.map(el => {
                    if ((checkout.subTotalAmount + checkout.shipping_fee) >= el.minimum) {
                        if (checkout.discount[0].type === 'COMPOUND') {
                            if (el.combination_type === 'SINGLE') {
                                el.disabled = true
                            } else {
                                el.disabled = false
                            }
                        } else if (checkout.discount[0].type === 'SINGLE') {
                            if (el.combination_type === 'COMPOUND') {
                                el.disabled = true
                            } else {
                                el.disabled = false
                            }
                        } else if (checkout.discount[0].type === '') {
                            el.disabled = false
                        }
                    } else {
                        el.disabled = true
                    }
                    return el
                });
                setPublicList(list)
                setDiscountList(list)
            })
            .catch((err) => {
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set(error.message);
            })

    }, [checkout.shipping_fee])


    useEffect(() => {
        const selected = checkout.discount.map((el) => el.discountName)
        const updateList = discountList.map((el) => {
            if ((checkout.subTotalAmount + checkout.shipping_fee) >= el.minimum) {
                if (checkout.discount[0].type === 'COMPOUND') {
                    if (el.combination_type === 'SINGLE') {
                        el.disabled = true
                    } else {
                        el.disabled = false
                    }
                } else if (checkout.discount[0].type === 'SINGLE') {
                    if (el.combination_type === 'COMPOUND') {
                        el.disabled = true
                    } else {
                        el.disabled = false
                    }
                } else if (checkout.discount[0].type === '') {
                    el.disabled = false
                }
            } else {
                el.disabled = true
            }
            return el
        })
        setPublicList(updateList)
        setSelectedDiscount(selected)
    }, [checkout, checkout.discount, cart, isApplied])

    useEffect(() => {
        if (globalStates.discountCode.get() === '') {
            const payload = [{
                discountName: '',
                discountAmount: 0,
                type: '',
                isDiscounted: false
            }]
            dispatch(setDiscountRedux(payload))
        }
    }, [isApplied, code])

    const onClickDiscount = (discount) => {
        if (!discount.disabled) {
            if (!selectedDiscount.includes(discount.code)) {
                if (checkout.discount[0].type === 'SINGLE' || checkout.discount[0].type === '') {
                    setErrorMsg('');
                    let payload = [{
                        discountName: discount.code,
                        discountAmount: discount.amount,
                        type: discount.combination_type,
                        isDiscounted: true
                    }]
                    dispatch(setDiscountRedux(payload))
                } else {
                    if (!selectedDiscount.includes(discount.code)) {
                        let addedDiscount = {
                            discountName: discount.code,
                            discountAmount: discount.amount,
                            type: discount.combination_type,
                            isDiscounted: true
                        }
                        const removeBlank = checkout.discount.filter((el) => el.discountName !== '')
                        let payload = [...removeBlank, addedDiscount]
                        dispatch(setDiscountRedux(payload))
                    }
                }
            } else {
                let removedSelected = checkout.discount.filter((el) => discount.code !== el.discountName)
                if (removedSelected.length > 0) {
                    removedSelected = removedSelected
                } else {
                    removedSelected = [{
                        discountName: '',
                        discountAmount: 0,
                        type: '',
                        isDiscounted: false
                    }]
                }
                dispatch(setDiscountRedux(removedSelected))
            }

        }
    }
    const applyDiscount = () => {
        addDiscount()
    }

    const addDiscount = async () => {
        setIsApplied(true)
        setLoading(true)
        await api.discount.addDiscount({ code: globalStates.discountCode.get(), userId: customer.customer_id, totalAmount: checkout.totalAmount })
            .then(response => {
                let data = response.data
                if (!response.data.error) {
                    let result = data.result[0]
                    const payload = [{
                        discountName: result.code,
                        discountAmount: result.amount,
                        type: result.combination_type,
                        isDiscounted: true
                    }]
                    const list = discountList.map((el) => el.code)
                    if (!list.includes(result.code)) {
                        const updateList = data.result.concat(discountList)
                        setDiscountList(updateList)
                    }
                    dispatch(setDiscountRedux(payload))
                } else {
                    let result = data.result
                    const payload = [{
                        discountName: '',
                        discountAmount: 0,
                        type: '',
                        isDiscounted: false
                    }]
                    dispatch(setDiscountRedux(payload))
                    setError(data.error)
                    setErrorMsg(result.error)
                }

            })
            .catch(error => {
                setError(true);
                setErrorMsg(error.message);
                setIsApplied(false)
            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false); // loading
                    setIsApplied(false)
                }, 1000);
            });
    }

    return (
        <React.Fragment>
            <Grid container sx={{ width: '100%' }} rowSpacing={2} columnSpacing={1}>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        error={error}
                        color="primary"
                        focused
                        helperText={errorMsg}
                        defaultValue={globalStates.discountCode.get()}
                        value={globalStates.discountCode.get()}
                        label='PROMO OR DISCOUNT CODE'
                        onChange={onKeyUpDiscount}
                        size="small"
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button
                        variant="contained"
                        disabled={isApplied}
                        onClick={applyDiscount}
                        sx={{ ':disabled': { background: ConstantStyles['purple'], color: 'white' }, borderRadius: '30px', padding: '8px' }}
                    >
                        {loading ?
                            <Box sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <CircularProgress color={'inherit'} size={24} />
                            </Box>
                            :
                            'APPLY'
                        }
                    </Button>
                </Grid>

            </Grid>
            <div style={globalStates.isMobile.get() ? { marginTop: '15px', padding: '5px', height: window.innerHeight - 200, overflowY: 'auto' } : { height: '250px', overflowY: 'auto', marginTop: '15px', padding: '5px' }}>
                <Grid container >
                    <Grid item xs={12}>

                        {discountList.filter((el) => el.combination_type === 'SINGLE').length > 0 ?
                            <React.Fragment>
                                <div style={{ marginBottom: '10px', padding: '8px', width: '100%', borderRadius: '5px', background: ConstantStyles['purple'], color: '#A2F9F9' }}>
                                    <strong>SINGLE</strong>
                                </div>
                                {discountList?.map((discount, index) => {
                                    if (discount.combination_type === 'SINGLE')
                                        return (
                                            <Card key={index} sx={discount.disabled ? styles.disabled : selectedDiscount.includes(discount.code) ? styles.selected : styles.available} onClick={() => onClickDiscount(discount)}>
                                                <Box sx={{ width: '100%', position: 'relative' }}>
                                                    {selectedDiscount.includes(discount.code) ?
                                                        <div style={{ position: 'absolute', top: '0', right: '0px', color: ConstantStyles['purple'] }} >
                                                            < CheckCircleIcon />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <strong>{discount.code + " ( PHP " + discount.amount + " OFF" + ")"}</strong><br />
                                                    <span style={globalStates.isMobile.get() ? { fontSize: '12px' } : { fontSize: '14px' }}>Avail this promo with the minimum purchase of PHP {discount.minimum}</span>
                                                </Box>
                                            </Card>
                                        )
                                })}
                            </React.Fragment>
                            :
                            null
                        }
                        <br />
                        {discountList.filter((el) => el.combination_type === 'COMPOUND').length > 0 ?
                            <React.Fragment>
                                <div style={{ marginBottom: '10px', padding: '8px', width: '100%', borderRadius: '5px', background: ConstantStyles['purple'], color: '#A2F9F9' }}>
                                    <strong >COMPOUND</strong>
                                </div>
                                {discountList?.map((discount, index) => {
                                    if (discount.combination_type === 'COMPOUND')
                                        return (
                                            <Card key={index} sx={discount.disabled ? styles.disabled : selectedDiscount.includes(discount.code) ? styles.selected : styles.available} onClick={() => onClickDiscount(discount)}>
                                                <Box sx={{ width: '100%', position: 'relative' }}>
                                                    {selectedDiscount.includes(discount.code) ?
                                                        <div style={{ position: 'absolute', top: '0px', right: '0px', color: ConstantStyles['purple'] }} >
                                                            < CheckCircleIcon />
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    <strong>{discount.code + " ( PHP " + discount.amount + " OFF" + ")"}</strong><br />
                                                    <span style={globalStates.isMobile.get() ? { fontSize: '12px' } : { fontSize: '14px' }}>Avail this promo with the minimum purchase of PHP {discount.minimum}</span>
                                                </Box>
                                            </Card>
                                        )
                                })}
                            </React.Fragment>
                            :
                            null
                        }

                    </Grid>
                </Grid>
            </div>
        </React.Fragment >
    )

}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.CartList,
        checkout: state.checkout
    }
}

export default connect(mapStateToProps)(Discount);