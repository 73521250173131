import { useState as useHookState } from "@hookstate/core";
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import ConstantStyles from "../../../utility/ConstantStyle.json";
import GlobalStates from "../../../utility/GlobalStates";
import SelectCityForm from './SelectStoreForm';

const styles = {
    box: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        borderRadius: '15px 15px 15px 15px',
        boxShadow: 24,
        ':focus-visible': {
            outline: 'none !important'
        }
    },
    title: {
        background: 'white',
        Height: '10px',
        padding: '10px',
        borderBottom: ConstantStyles['default-border'],
        boxShadow: '0 3px 10px rgb(0 0 0 / 0,2)',
        borderRadius: '15px 15px 0px 0px',
        position: 'fixed',
        zIndex: '999',
        width: '100%'
    },

};

const SelectStoreModal = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [open, setOpen] = useState(globalStates.openStoreModal.get());

    useEffect(() => {
        setOpen(globalStates.openStoreModal.get())
    }, [globalStates.openStoreModal.get()])

    const handleClose = () => {
        setOpen(false)
        globalStates.openStoreModal.set(false)
    };

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={open}>
                    <Box sx={styles.box}>
                        <SelectCityForm />
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment>
    )

}

export default SelectStoreModal;