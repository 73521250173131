import React from 'react'
import api from '../../utility/Endpoints'
import { useEffect, useState } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ConstantStyles from "../../utility/ConstantStyle.json";


const PageListMenu = (props) => {
    const { first, last } = props
    const [list, setList] = useState([])
    const [selected, setSelected] = useState('')

    useEffect(() => {
        getList()
    }, [])


    const getList = () => {
        api.page.get()
            .then(response => {
                setList(response.data.slice(first, last))
            }).catch(error => {
                console.log(error);
            })

    }

    const handleChange = (event) => {
        const page = event.target.value.split('-')
        setSelected(event.target.value);
        window.location.pathname = `${page[1].toLowerCase().replace(/ /g, '-')}/${page[0]}`

    };





    return (
        <React.Fragment>
            {
                list.map((data, index) => (
                    <Box key={index} sx={{ display: { xs: 'block', sm: 'unset' } }}>
                        <Button

                            style={{
                                color: ConstantStyles['purple']
                            }}
                            value={`${data.page_id}-${data.title}`}
                            onClick={handleChange}
                        >
                            {data.title}
                        </Button>
                    </Box>
                ))
            }
        </React.Fragment>
    )
}


export default PageListMenu