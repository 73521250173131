import CloseIcon from '@mui/icons-material/Close';
import DiscountIcon from '@mui/icons-material/Discount';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import Discount from './Discount';
const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    p: 4,
    ':focus-visible': {
        outline: 'none !important'
    }
};

const DiscountModal = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>

            <Button onClick={handleOpen} startIcon={<DiscountIcon />}>Use promo or discount code</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                keepMounted
            >
                <Box sx={style}>
                    <div style={{ position: 'absolute', top: '15px', right: '30px', cursor: 'pointer' }} onClick={handleClose} >
                        <CloseIcon />
                    </div>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Discount />
                    </Typography>
                </Box>
            </Modal>
        </div >
    );
}

export default DiscountModal;