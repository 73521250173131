import React, { useState } from 'react';
import { useHookstate } from '@hookstate/core';
import PropTypes from "prop-types";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


import GlobalStates from '../../utility/GlobalStates';
import ConfirmButton from './ConfirmButton';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    p: '16px 1px 1px 16px',
};


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  

// PROPS ***
// run : Function to run upon submit
// messages: object contains 
//              -title 
//              -message 
//              -note (italized text)
const ConfirmationModal = (props) => {
    const globalStates = useHookstate(GlobalStates) 
    const { run, messages, load, btnName } = props // props from parent component

    const handleClose = () => globalStates.modalOpenConfirm.set(false);

    return (
        <div>
            <BootstrapDialog
                open={globalStates.modalOpenConfirm.get()}
            >
                <BootstrapDialogTitle onClose={handleClose}>
                    {messages?.title}
                </BootstrapDialogTitle>
                <DialogContent dividers sx={{minHeigth: '140px'}}>
                    <Typography gutterBottom>
                        {messages?.message}
                    </Typography>
                    <Typography gutterBottom>
                        <i>{messages?.note}</i>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <ConfirmButton btnName={btnName} functions={run} close={handleClose} btnLoad={load} />
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}

ConfirmationModal.propTypes = {
    messages:PropTypes.object,
    run:PropTypes.func,
    load:PropTypes.bool,
};
ConfirmationModal.defaultProps = {
    messages:{},
    load: false,
};

export default ConfirmationModal