import { createState } from '@hookstate/core';

const GlobalStates = createState({
    isMobile: false,
    userEmail: "",
    isLoading: false,
    modalOpenCreate: false,
    modalOpenUpdate: false,
    modalOpenDelete: false,
    modalOpenConfirm: false,
    modalOpenBulk: false,
    modalOpen: false,
    confirmed: false,
    openAlert: false,
    statusAlert: "",
    msgAlert: "",
    snackBarMsg: "",
    snackBarStatus: false,
    openDiscountDrawer: false,
    openAddressDrawer: false,
    openTimeDrawer: false,
    openStoreDrawer: false,
    openStoreModal: false,
    openAddressModal: false,
    openLoginModal: false,
    selectedProduct: "{}",
    addressType: "",
    discountCode: "",
    isUpdated: Date.now()
});

export default GlobalStates;