import { useState as useHookState } from "@hookstate/core";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

import GlobalStates from "../../../utility/GlobalStates";
import Discount from "./Discount";

import { Button } from "@mui/material";
import ConstantStyles from '../../../utility/ConstantStyle.json';

const styles = {
    dragIcon: {
        textAlign: 'center',
        margin: 'auto',
        color: ConstantStyles['black-color'],
        position: 'fixed',
        width: '100%',
        background: 'white',
        borderRadius: '15px 15px 0px 0px',
        zIndex: '999'
    },
    button: {
        background: ConstantStyles['purple'],
        color: ConstantStyles['teal'],
        borderRadius: '30px',
        width: '50vw',
    },
    okayButton: {
        textAlign: 'center',
        color: ConstantStyles['black-color'],
        position: 'fixed',
        bottom: '20px',
        zIndex: '999',
        width: '100%'
    },
    line: {
        margin: '5px 0px'
    }
}

const DiscountDrawer = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    useEffect(() => {
        toggleDrawer('bottom', globalStates.openDiscountDrawer.get())
    }, [globalStates.openDiscountDrawer.get()])


    const toggleDrawer = (anchor, open) => {
        setState({ ...state, [anchor]: open });
    }

    return (
        <React.Fragment>
            <SwipeableDrawer
                sx={{
                    height: (window.innerHeight),
                    flexShrink: 0,

                    '& .MuiDrawer-paper': {
                        height: (window.innerHeight),
                        boxSizing: 'border-box',
                        borderRadius: '15px 15px 0px 0px',
                    },
                }}
                anchor={'bottom'}
                open={state['bottom']}
                onClose={() => {
                    toggleDrawer('bottom', false)
                    globalStates.openDiscountDrawer.set(false)
                }}
                onOpen={() => {
                    toggleDrawer('bottom', true)
                    globalStates.openDiscountDrawer.set(true)
                }}
            >
                <Box sx={styles.dragIcon}>
                    <DragHandleIcon /><br />
                </Box>
                <Grid container sx={{ overflow: 'auto', height: '100%' }}>
                    <Grid item xs={12} sx={{ mt: '40px', p: 2 }}>
                        <Discount />
                    </Grid>
                </Grid>
                <Box sx={styles.okayButton}>
                    <Button
                        style={styles.button}
                        onClick={() => {
                            toggleDrawer('bottom', false)
                            globalStates.openDiscountDrawer.set(false)
                        }}>
                        okay
                    </Button>
                </Box>
            </SwipeableDrawer>
        </React.Fragment >
    )
}


export default DiscountDrawer;