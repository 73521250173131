import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from "react-redux";
import { useHookstate } from '@hookstate/core';

// MUI imports
import { Box } from '@mui/system'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Button, Grid } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';

import api from '../../../../utility/Endpoints'
import GlobalStates from '../../../../utility/GlobalStates';
import CreateModal from './CreateModal';
import ConfirmationModal from '../../../shared/ConfirmationModal';
import EditModal from './EditModal';
import { type } from 'jquery';



const style = {
    DataGrid: {
        display: 'flex',
        height: "85vh",
        width: '100%',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            whiteSpace: 'normal',
            textOverflow: 'normal',
            lineHeight: '15px'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },

    },
}

const User = () => {
    const globalStates = useHookstate(GlobalStates)
    const [userList, setUserList] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [messages, setMessages] = useState({})
    const [editData, setEditData] = useState({})
    const [toRun, setToRun] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [btnLoad, setBtnLoad] = useState(false)

    useEffect(() => {
        FetchUser()
    }, [refresh])
    
    const refreshData = () => {
        setRefresh(!refresh)
    }
    const loadOff = () => {
        globalStates.isLoading.set(false)
        setInterval(() => {
            globalStates.isLoading.set(false)
        }, 1000)
    }
    const FetchUser = () => {
        globalStates.isLoading.set(true)
        api.user.getUser() // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                const user = result.map( item => {
                    return {
                        id: item.user_id,
                        first_name: item.first_name,
                        last_name: item.last_name,
                        email: item.email,
                        password: item.password,
                        status: item.status,
                        user_level: item.user_level
                    }
                })
                setUserList(user)
                loadOff()
            })
            .catch(error => {
                globalStates.isLoading.set(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
                setInterval(() => {
                    globalStates.openAlert.set(false)
                }, 2000)
            })
    }

    const disableUser = (e) => {
        e.preventDefault()
        globalStates.modalOpenConfirm.set(true)

        const id = e.target.dataset.id
        const name = e.target.name

        setToRun('disable')
        
        const msg = {
            title: "Disable User ",
            message: `Are you sure you want to disable user : ${name}`,
            note: "note: When a user is disabled, this user will permanently disabled.",
        }
        setMessages(msg)
        setSelectedData({id: id})
    }
    
    const onChangeStatus = (e) => {
        globalStates.modalOpenConfirm.set(true)
        const id = e.target.id
        const email = e.target.name
        const status = e.target.checked ? 1 : 0

        setToRun('changeStatus')

        const body = {
            id: id,
            name: email,
            status: status
        }
        const msg = {
            title: "Change user status",
            message: `Are you sure you want to change status of : ${email}`,
        }
        setMessages(msg)
        setSelectedData(body)
    }

    const isToRun = () => {
        if (toRun === 'disable') {
            api.user.disable({id: selectedData?.id})
            .then((response) => {
                setBtnLoad(false)
                globalStates.modalOpenConfirm.set(false)
                refreshData()
            })
            .catch((error) => {
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
                setInterval(() => {
                    globalStates.openAlert.set(false)
                }, 2000)
            })
        } else if (toRun === 'changeStatus') {
            api.user.changeStatus(selectedData)
            .then((response) => {
                setBtnLoad(false)
                globalStates.modalOpenConfirm.set(false)
                refreshData()
            })
            .catch((error) => {
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
                setInterval(() => {
                    globalStates.openAlert.set(false)
                }, 2000)
            })
        }
    }

    const statusChange = (props) => {
        const status = props.row.status 
        const name = props.row.first_name + ' ' + props.row.last_name 
        const id = props.row.id 
        return (
            <>
                <Switch
                    checked={status === 1 ? true : false}
                    id={id}
                    name={name}
                    onChange={onChangeStatus}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </>
        )
    }

    // setting up columns
    const columns = [
        {
            field: "id", // fetching from map
            headerName: "ID", // table header
            flex: 0,
            minWidth: 80
        },
        {
            field: "first_name",
            headerName: "First Name",
            flex: 0.2,
            minWidth: 100
        },
        {
            field: "last_name",
            headerName: "Last Name",
            flex: 0.2,
            minWidth: 100
        },
        {
            field: "email",
            headerName: "Email",
            flex: 0.3,
            minWidth: 100
        },
        {
            field: "user_level",
            headerName: "User Level",
            flex: 0.3,
            minWidth: 100
        },
        {
            field: "actions",
            headerName: "Action Buttons",
            flex: 0.3,
            minWidth: 100,
            type: 'actions',
            getActions: (params) => [
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={6}>
                            <EditModal 
                                refresh={refreshData} 
                                users={userList} 
                                id={params.id} 
                                data={params.row} />
                        </Grid>
                        <Grid item xs={6}>
                            <LoadingButton
                                size="small"
                                color="error"
                                data-id={params.id}
                                name={params.row.first_name + ' ' + params.row.last_name}
                                sx={{span: {pointerEvents: 'none'}}}
                                onClick={disableUser}
                                loadingPosition="start"
                                startIcon={<DeleteIcon/>}
                                variant="outlined"
                                >
                                Disable
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ],
        }
    ]

    const CustomToolbar = () => {
        return (
            <Grid container
                sx={{
                    p: 1,
                    pb: 0
                }}
            >
                <Grid item xs={6}>
                    <GridToolbarQuickFilter />
                </Grid>
                <Grid 
                    xs={6}
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                    >
                        <CreateModal 
                            refresh={refreshData} 
                            users={userList} 
                        />
                </Grid>
            </Grid>
        );
    }

    return (
        <Box sx={{marginLeft: "6%",marginRight: "3%", marginTop: "5%"}}>
            <Box sx={style.DataGrid}>
                <DataGrid
                    columns={columns}
                    rows={userList}
                    disableSelectionOnClick
                    loading={globalStates.isLoading.get()}
                    components={{ 
                        Toolbar: CustomToolbar, 
                        LoadingOverlay: LinearProgress, 
                    }}
                />  
                <ConfirmationModal 
                    messages={messages} 
                    run={e => isToRun()} 
                    load={btnLoad}   
                />
            </Box>  
        </Box>
    )
}

export default User