// react library
import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from "react";


//Global States, call funtion
import GlobalStates from "../../../utility/GlobalStates";

// mui components
//material ui library/components
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AllIcon from '../../../assets/icons/all.png';

// general components
import ConstantStyles from '../../../utility/ConstantStyle.json';
import ForwardIcon from '@mui/icons-material/Forward';
import api from "../../../utility/Endpoints";



const styles = {
    padding0: {
        paddingTop: 0,
        flexDirection: 'column'
    },
    paddingTop: {
        paddingTop: "5vh"
    },
    container: {
        position: "relative",
        color: "black",
        height: "100%"
    },
    franchiseTitle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 100
    }
}

const Home = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [banner, setBanner] = useState('')
    const announcement = localStorage.getItem('announcement') === "1"
    const [screen, setScreen] = useState([window.innerWidth]);
    let isMobile = screen <= 767

    const franchise_banner_img = `/banner/${isMobile ? 'Franchise_Mob.jpg' : 'Franchise_Web.jpg'}`
    const franchise_2_banner = "/banner/franchise_2_banner.jpg";
    const right_arrow = "/banner/right_arrow.png";
    const letter = "/banner/letter.png";
    const schedule = "/banner/Schedule.png";
    const franchise_form = "/banner/franchise form.png";

    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fetchBanner()
        document.title = 'Franchise';

    }, [isMobile])
    const fetchBanner = () => {
        api.banner.fetch('Franchise')
            .then((response) => {
                console.log(response);
                let bannerPath = response.data.result.filter(item => item.type === 'Desktop')[0]
                if (isMobile) {
                    bannerPath = response.data.result.filter(item => item.type === 'Mobile')[0]
                }
                console.log(bannerPath);
                setBanner(bannerPath ? process.env.REACT_APP_HOST + bannerPath?.image_path : '')
            })
            .catch((error) => {
                console.log(error.message);
            })
    }

    return (
        <Box style={{ backgroundColor: ConstantStyles['teal'] }}>
            {isMobile ? null : <Grid
                container
                spacing={0}
                style={{
                    position: "relative",
                    color: "black",
                    height: "100%",
                    marginTop: announcement ?
                        isMobile ?
                            '64px'
                            :
                            '64px'
                        :
                        '70px'
                }}
            >
            </Grid>}
            {isMobile ? null : <br />}
            <Box
                style={{ borderRadius: "0px", color: "#222" }}>
                <Grid container>

                    {isMobile ? <Grid item sm={12} xs={12}>
                        <img
                            style={{ paddingTop: "40px" }}
                            width={"100%"}
                            src={franchise_2_banner} />
                    </Grid>
                        : ""}
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} style={{ textAlign: "left", fontSize: isMobile ? "15px" : "20px", paddingLeft: isMobile ? "2%" : "1%", paddingRight: isMobile ? "2%" : "5%", paddingTop: isMobile ? "2%" : "10%" }}>
                        Daily by Bo’s Coffee is a new and growing digital coffee chain in the Philippines. With its unique visual identity and wide product mix, the brand is getting stronger by attracting various coffee drinkers around the country.
                        {isMobile ? <><br /> </> : <><br /><br /></>}
                        <Button
                            variant="contained"
                            sx={{
                                padding: isMobile ? 2 : "1rem",
                                marginLeft: isMobile ? "25%" : "",
                                marginTop: isMobile ? "2%" : "10px",
                                width: '10rem',
                                borderRadius: 15,
                                fontSize: '1rem',
                                color: ConstantStyles['teal'],
                                fontFamily: 'Bounce-haus-Sans'
                            }}
                            target="_blank"
                            href='https://forms.office.com/r/2sWQx64Dkk'
                        >
                            Franchise Now
                        </Button>
                    </Grid>
                    {isMobile ? <Grid item sm={3} xs={3}></Grid> : null}

                    {isMobile ? <br /> : null}
                    {isMobile ? null :
                        < Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                            <img
                                style={{ paddingTop: isMobile ? "40px" : "", marginTop: "15%", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                                width={"100%"}
                                src={franchise_2_banner} />
                        </Grid>
                    }
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}

                </Grid>
            </Box>
            <Box>
                <Grid
                    container
                    sx={{ textAlign: 'center', paddingTop: "2%" }}
                >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h1 style={{ color: ConstantStyles['purple'], textAlign: "center", fontFamily: 'Bounce-haus-Sans' }}>WHY FRANCHISE WITH DAILY?</h1>
                        {isMobile ? <br /> : null}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl={4} lg={4} />
                    <Grid item xl={4} lg={4} >
                        {/* <Box style={{ backgroundColor: "#EFF1ED" }}>
                        <Grid container>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Box style={{ color: ConstantStyles['purple'], backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                    <h1>*</h1>
                                </Box>
                            </Grid>
                            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Box style={{ paddingTop: '3%' }}>
                                    An affordable investment as low as Php 3 million!
                                </Box>

                            </Grid>
                        </Grid>
                    </Box> */}
                        <Box style={{ backgroundColor: "#EFF1ED", borderRadius: "250px", width: isMobile ? "100vw" : null }}>
                            <Grid container>
                                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box style={{ color: ConstantStyles['purple'], borderRadius: "250px", backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                        <h1>*</h1>
                                    </Box>
                                </Grid>
                                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                    <Box >
                                        An affordable investment as low as Php 3 million!
                                    </Box>

                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xl={4} lg={4} />
                    <Grid item xl={4} lg={4} >
                        <Box style={{ backgroundColor: "#EFF1ED", borderRadius: "250px", width: isMobile ? "100vw" : null }}>
                            <Grid container>
                                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box style={{ color: ConstantStyles['purple'], borderRadius: "250px", backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                        <h1>*</h1>
                                    </Box>
                                </Grid>
                                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                    <Box >
                                        Our concept is a Grab and Go Store, which is easy to build.
                                    </Box>

                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xl={4} lg={4} />
                    <Grid item xl={4} lg={4} >
                        <Box style={{ backgroundColor: "#EFF1ED", borderRadius: "250px", width: isMobile ? "100vw" : null }}>
                            <Grid container>
                                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box style={{ color: ConstantStyles['purple'], borderRadius: "250px", backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                        <h1>*</h1>
                                    </Box>
                                </Grid>
                                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                    <Box >
                                        We have high quality products and continuous product innovation.
                                    </Box>

                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xl={4} lg={4} />
                    <Grid item xl={4} lg={4} >
                        {/* <Box style={{ backgroundColor: "#EFF1ED", borderRadius: "250px" }}>
                        <Grid container>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Box style={{ color: ConstantStyles['purple'], borderRadius: "250px", backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                    <h1>*</h1>
                                </Box>
                            </Grid>
                            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Box style={{ paddingTop: "3%" }}>
                                    Trusted craftsmanship and extensive training
                                </Box>

                            </Grid>
                        </Grid>
                    </Box> */}
                        <Box style={{ backgroundColor: "#EFF1ED", borderRadius: "250px", width: isMobile ? "100vw" : null }}>
                            <Grid container>
                                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box style={{ color: ConstantStyles['purple'], borderRadius: "250px", backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                        <h1>*</h1>
                                    </Box>
                                </Grid>
                                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                    <Box >
                                        Trusted craftsmanship and extensive {"   "} training
                                    </Box>

                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xl={4} lg={4} />
                    <Grid item xl={4} lg={4} >
                        {/* <Box style={{ backgroundColor: "#EFF1ED", borderRadius: "250px" }}>
                        <Grid container>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Box style={{ color: ConstantStyles['purple'], borderRadius: "250px", backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                    <h1>*</h1>
                                </Box>
                            </Grid>
                            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Box style={{ paddingTop: "3%" }}>
                                    Led by one of the top coffee Chains, Bo’s Coffee
                                </Box>

                            </Grid>
                        </Grid>
                    </Box> */}
                        <Box style={{ backgroundColor: "#EFF1ED", borderRadius: "250px", width: isMobile ? "100vw" : null }}>
                            <Grid container>
                                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box style={{ color: ConstantStyles['purple'], borderRadius: "250px", backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                        <h1>*</h1>
                                    </Box>
                                </Grid>
                                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                    <Box >
                                        Led by one of the top coffee Chains, Bo’s Coffee
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xl={4} lg={4} />
                    <Grid item xl={4} lg={4} >
                        {/* <Box style={{ backgroundColor: "#EFF1ED", borderRadius: "250px" }}>
                        <Grid container>
                            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                <Box style={{ color: ConstantStyles['purple'], borderRadius: "250px", backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                    <h1>*</h1>
                                </Box>
                            </Grid>
                            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                <Box style={{ paddingTop: "3%" }}>
                                    Strong brand and marketing support
                                </Box>

                            </Grid>
                        </Grid>
                    </Box> */}
                        <Box style={{ backgroundColor: "#EFF1ED", borderRadius: "250px", width: isMobile ? "100vw" : null }}>
                            <Grid container>
                                <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                                    <Box style={{ color: ConstantStyles['purple'], borderRadius: "250px", backgroundColor: "#BEAD77", height: "50px", width: "50px", fontFamily: 'Bounce-haus-Sans', textAlign: "center" }}>
                                        <h1>*</h1>
                                    </Box>
                                </Grid>
                                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                                    <Box >
                                        Strong brand and marketing support
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <br />
                {/* <Box
                style={{ height: isMobile ? "900px" : "600px", color: "#222", paddingTop: "30px" }}>
                <Grid container>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h1 style={{ color: ConstantStyles['purple'], textAlign: "center", fontFamily: 'Bounce-haus-Sans' }}>Find the best spot!</h1>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
                    <Grid item xl={4} lg={4} md={4} sm={10} xs={10}>
                        <img
                            width={isMobile ? "300px" : "400px"}
                            height={isMobile ? "400px" : "500px"}
                            src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh_bOhLnyc3oNx3hVAZED0-IojH4ePUxGk8TjS5XVfDM7kiypRd-WgiS_ZskN0TUHBVeULd_gXfHE9FbN-OLKjWm0Bn91DK1MGbwvauq1UmO94cQH1qwENVPGzthKGMO7E_u-gAOgNUH1iM7GjpWSpmlYI39JlNGWuDqugLofxz9ertCpeL9FVEp0_I/s5362/Metro_Manila_in_the_Philippines.png" />
                    </Grid>
                    {isMobile ? <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid> : null}
                    <Grid item xl={7} lg={7} md={7} sm={10} xs={10} >

                        With Daily’s target market of young professionals and on-the-go coffee drinkers, we want to be present in all central business districts (CBDs) in the Philippines! <br /><br />
                        Strategically intercept our customers in these suggested locations:
                        <br /><br />
                        <Grid container>
                            <Grid item xl={6} lg={6} md={6} style={{ color: ConstantStyles['purple'], textAlign: "center", fontFamily: 'Bounce-haus-Sans' }}>
                                <h2 style={{ color: ConstantStyles['purple'] }}>SATURATE</h2>
                                <h2 style={{ color: "#BEAD77" }}>METRO MANILA</h2>

                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Alabang City</span></h3>
                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Pasay City</span> </h3>
                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Las Pinas City</span> </h3>
                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Makati City</span> </h3>        
                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Taguig City</span> </h3>                           <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Mandaluyong</span></h3>
                            
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} style={{ color: ConstantStyles['purple'], textAlign: "center", fontFamily: 'Bounce-haus-Sans' }}>
                                <h2 style={{ color: "#00FFFF" }}> METRO MANILA</h2>
                                <h2 style={{ color: "#00FFFF" }}> METRO MANILA</h2>
                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>San Juan City</span></h3>
                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Mandaluyong City</span> </h3>
                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Eastwood</span></h3>
                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Ortigas</span></h3>
                                <h3>* <span style={{ fontFamily: "RadnikaRegular" }}>Quezon City</span></h3>

                            </Grid>


                        </Grid>
                    </Grid>
                    <Grid item sm={1} xs={1}></Grid>
                </Grid>
            </Box> */}
            </Box>
            <br />
            <Box
                style={{ backgroundColor: "white", paddingTop: "2%" }}>
                <Grid
                    container
                    style={{ backgroundColor: "white", paddingTop: "2%" }}
                    sx={{ textAlign: 'center' }}
                >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <h1 style={{ color: ConstantStyles['purple'], textAlign: "center", fontFamily: 'Bounce-haus-Sans' }}>WHAT WE NEED FROM YOU</h1>
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent="center"
                    style={{ backgroundColor: "white" }}
                    sx={{ textAlign: 'center' }}
                >
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <div style={{ textAlign: "center" }}>
                            <img
                                width={isMobile ? "40%" : "300px"}
                                height={isMobile ? "" : "300px"}
                                src={letter} />
                        </div>
                        <h3 style={{ fontFamily: 'Bounce-haus-Sans', color: "#6700B9" }}>1. Letter of intent</h3>
                        {/* <Box style={{ marginLeft: "5%", backgroundColor: "#EFF1ED", textAlign: 'center', width: "80%", borderRadius: "100px", height: "200px", color: "#000", paddingTop: "30px" }}>

                    </Box> */}
                        {isMobile ? <br /> : null}

                    </Grid>
                    {isMobile ? null :
                        <Grid item xl={1} lg={1} md={1} sm={6} xs={12} style={{ paddingTop: "2%" }}>
                            {/* <img
                            style={{ paddingTop: "40px" }}
                            width={"50%"}
                            src={right_arrow} /> */}

                        </Grid>
                    }

                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <div style={{ textAlign: "center" }}>
                            <img
                                width={isMobile ? "40%" : "300px"}
                                height={isMobile ? "" : "300px"}
                                src={franchise_form} />
                        </div>
                        <h3 style={{ fontFamily: 'Bounce-haus-Sans', color: "#6700B9" }}>2. Franchise Form</h3>
                        {isMobile ? <br /> : null}

                    </Grid>
                    {isMobile ? null :
                        <Grid item xl={1} lg={1} md={1} sm={6} xs={12} style={{ paddingTop: "2%" }}>
                            {/* <img
                            style={{ paddingTop: "40px" }}
                            width={"50%"}
                            src={right_arrow} /> */}

                        </Grid>
                    }
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={12}>
                        <div style={{ textAlign: "center" }}>
                            <img
                                width={isMobile ? "40%" : "300px"}
                                height={isMobile ? "" : "300px"}
                                src={schedule} />
                        </div>
                        <h3 style={{ fontFamily: 'Bounce-haus-Sans', color: "#6700B9" }}>3. Schedule your discovery Call</h3>
                        {isMobile ? <br /> : null}
                    </Grid>
                </Grid>
                <br />
                {/* <Grid
                container
                sx={{ textAlign: 'center' }}
            >
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <h1 style={{ color: ConstantStyles['purple'], textAlign: "center", fontFamily: 'Bounce-haus-Sans' }}>ON BOARDING PROCESS</h1>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                sx={{ textAlign: 'center' }}
            >
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid container>
                        <Grid item xl={10} lg={10} md={10}>
                            <Box style={{ backgroundColor: "#B480DE", justifyContent: "center", textAlign: 'center', width: "200px", borderRadius: "50%", height: "200px", color: "#FFF", paddingTop: "30px" }}>
                                <h3 style={{ paddingTop: "20%" }}>Submit your requirements</h3>
                            </Box>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} style={{paddingLeft: "5px", paddingTop: "35%"}}>
                            <ForwardIcon sx={{ fontSize: 40, color: ConstantStyles['purple']}} />
                        </Grid>
                    </Grid>
                    {isMobile ? <br /> : null}
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid container>
                        <Grid item xl={10} lg={10} md={10}>
                            <Box style={{ backgroundColor: "#B480DE", justifyContent: "center", textAlign: 'center', width: "200px", borderRadius: "50%", height: "200px", color: "#FFF", paddingTop: "30px" }}>
                                <h3 style={{ paddingTop: "25%" }}>Discovery Call</h3>
                            </Box>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} style={{paddingLeft: "5px", paddingTop: "35%"}}>
                            <ForwardIcon sx={{ fontSize: 40, color: ConstantStyles['purple']}} />
                        </Grid>
                    </Grid>
                    {isMobile ? <br /> : null}
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid container>
                        <Grid item xl={10} lg={10} md={10}>
                            <Box style={{ backgroundColor: "#B480DE", justifyContent: "center", textAlign: 'center', width: "200px", borderRadius: "50%", height: "200px", color: "#FFF", paddingTop: "30px" }}>
                                <h3 style={{ paddingTop: "15%" }}>Contract Signing and Payment</h3>
                            </Box>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} style={{paddingLeft: "5px", paddingTop: "35%"}}>
                            <ForwardIcon sx={{ fontSize: 40, color: ConstantStyles['purple']}} />
                        </Grid>
                    </Grid>
                    {isMobile ? <br /> : null}
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid container>
                        <Grid item xl={10} lg={10} md={10}>
                            <Box style={{ backgroundColor: "#B480DE", justifyContent: "center", textAlign: 'center', width: "200px", borderRadius: "50%", height: "200px", color: "#FFF", paddingTop: "30px" }}>
                                <h3 style={{ paddingTop: "15%" }}>Training Delivery & Store Setup</h3>
                            </Box>
                        </Grid>
                        <Grid item xl={2} lg={2} md={2} style={{paddingLeft: "5px", paddingTop: "35%"}}>
                            <ForwardIcon sx={{ fontSize: 40, color: ConstantStyles['purple']}} />
                        </Grid>
                    </Grid>
                    {isMobile ? <br /> : null}
                </Grid>
                <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                    <Grid container>
                        <Grid item xl={10} lg={10} md={10}>
                            <Box style={{ backgroundColor: "#B480DE", justifyContent: "center", textAlign: 'center', width: "200px", borderRadius: "50%", height: "200px", color: "#FFF", paddingTop: "30px" }}>
                                <h3 style={{ paddingTop: "20%" }}>Open your store!</h3>
                            </Box>
                        </Grid>
                    </Grid>
                    {isMobile ? <br /> : null}
                </Grid>
 
            </Grid>
*/}
            </Box>

        </Box >
    );
}

export default Home;