import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'

import { IconButton, Button, TextField, Select, Menu, MenuItem, Typography, FormControl, InputLabel } from '@mui/material'
import { Box } from '@mui/system'

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import DailyLogo from '../../../../../assets/logo/default.webp'
import api from '../../../../../utility/Endpoints'

const style = {
    wrapper: {

        // position: 'relative', 
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid gray', 
        padding: '15px 5px 15px 5px', 
        borderRadius: '5px',
        marginBottom: 2,
        header: {
            position: 'absolute', 
            top: '-13px', 
            left: '15px', 
            backgroundColor: 'snow'
        }
    },
}

const Banner = () => {
    const [selectedImage, setSelectedImage] = useState({})
    const [bannerDesktop, setBannerDesktop] = useState([])
    const [bannerMobile, setBannerMobile] = useState([])
    const [banner, setBanner] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [title, setTitle] = useState('')
    const [selectedType, setSelectedType] = useState('Desktop') 

    useEffect(() => {
        fetchBanner()
    }, [refresh])

    const type = ['Mobile', 'Desktop']
    const page = ['Home', 'Franchise', 'About us']

    const fetchBanner = () => {
        api.banner.fetch('admin')
        .then((response) => {
            const result = response.data.result
            const mobile = result.filter(item => item.type === 'Mobile')
            const desktop = result.filter(item => item.type === 'Desktop')

            // console.log(result);
            setBannerDesktop(desktop)
            setBannerMobile(mobile)
            setBanner(result)

        })
        .catch((error) => {
            console.log(error.message);
        })
    }
    const file = (e) => {
        // console.log(e);
        // console.log(e);
        const selectedFiles = e.target.files
        const imageName = selectedFiles[0].name
        const imagePath = URL.createObjectURL(selectedFiles[0])
        setSelectedImage({   
            imageName: imageName || "",
            imagePath: imagePath,
            fileUpload: selectedFiles[0]
        })
    }

    const handleSave = () => {
        const formData = new FormData();
        formData.append('image', selectedImage.fileUpload);
        api.uploadImage.uploadImage(formData, 'banner')
        .then((response) => {
            const filePath = response.data.result
            const body = {
                image_path: filePath,
                title: title,
                type: selectedType
            }
            api.banner.addBanner(body)
            .then((res) => {
                console.log(res);
                setRefresh(!refresh)
                setSelectedImage({})
            })
            .catch((error) => {
                console.log(error.message);
            })
        })
        .catch((error) => {
            console.log(error.message);
        })
    }



    return (
        <div style={{margin: '5% 5% 5% 5%'}}>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', marginBottom: 1}}>
                <FormControl sx={{width: '150px'}}>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                        label="Type"
                        value={selectedType}
                        size='small'
                        onChange={e => setSelectedType(e.target.value)}
                        sx={{
                            marginRight: 1
                        }} 
                    >
                        {type.map(item => {
                            return <MenuItem value={item}>{item}</MenuItem>
                        })}
                    </Select>   
                </FormControl>
                <FormControl sx={{width: '150px'}}>
                    <InputLabel id="demo-simple-select-label">Page</InputLabel>
                    <Select
                        label="Page"
                        value={title}
                        size='small'
                        disabled={selectedImage.imagePath ? false : true}
                        onChange={e => setTitle(e.target.value)}
                        sx={{
                            marginRight: 1
                        }} 
                    >
                        {page.map(item => {
                            return <MenuItem value={item}>{item}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                {/* <TextField
                    label="Title"
                    size="small"
                    disabled={selectedImage.imagePath ? false : true}
                    sx={{
                        marginRight: 1
                    }} 
                    onChange={e => setTitle(e.target.value)}
                /> */}
                <IconButton 
                    sx={{
                        marginRight: 1
                    }} 
                    size="small" 
                    color="primary" 
                    component="label">
                    <input
                        style={{
                            cursor: 'pointer'
                        }}
                        required
                        name=''
                        type="file"
                        hidden
                        accept=".jpg , .png"
                        onChange={file}
                    />
                    <CameraAltIcon size="small" />
                </IconButton>
                <Button 
                    size='small'
                    disabled={selectedImage.imagePath ? false : true}
                    onClick={handleSave}
                    variant='contained'
                >
                    Save
                </Button>
            </Box>
            <Box sx={style.wrapper}> 
                <Box sx={{width: '50%', dislay: 'flex', justifyContent: 'center', overflow:'hidden'}}>
                    {
                        bannerDesktop.map(item => {
                            
                            return (
                                <Box sx={{padding: 2}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Box>
                                            <Typography>{item.title}</Typography>
                                            <Typography>{item.type}</Typography>
                                        </Box>
                                        <Box>
                                            <IconButton
                                                onClick={() => {
                                                    api.banner.remove({id: item.id})
                                                    .then((response) => {
                                                        console.log(response);
                                                        setRefresh(!refresh)
                                                    })
                                                    .catch((error) => {
                                                        console.log(error.message);
                                                    })
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box sx={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <img height={'100%'} width={'auto'} alt='preview' src={item?.image_path ? process.env.REACT_APP_HOST + item?.image_path  : DailyLogo} />
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
                <Box sx={{width: '50%', dislay: 'flex', justifyContent: 'center', overflow:'hidden'}}>
                    {
                        bannerMobile.map(item => {
                            return (
                                <Box sx={{padding: 2}}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <Box>
                                            <Typography>{item.title}</Typography>
                                            <Typography>{item.type}</Typography>
                                        </Box>
                                        <Box>
                                            <IconButton
                                                onClick={() => {
                                                    api.banner.remove({id: item.id})
                                                    .then((response) => {
                                                        setRefresh(!refresh)
                                                    })
                                                    .catch((error) => {
                                                        console.log(error.message);
                                                    })
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box sx={{height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <img height={'100%'} alt='preview' src={item?.image_path ? process.env.REACT_APP_HOST + item?.image_path : DailyLogo} />
                                    </Box>
                                </Box>
                            )
                        })
                    }
                </Box>
            </Box>
        </div>
    )
}

export default Banner