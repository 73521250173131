import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from "react-redux";
import { useHookstate } from '@hookstate/core';

// MUI imports
import { Box } from '@mui/system'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Button, Grid, Typography } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';

import api from '../../../../utility/Endpoints'
import GlobalStates from '../../../../utility/GlobalStates';
import CreateModal from './CreateModal';
import ConfirmationModal from '../../../shared/ConfirmationModal';
import EditModal from './EditModal';

// import CreateModal from './CreateModal';
// import ConfirmationModal from '../../../shared/ConfirmationModal';
// import EditModal from './EditModal';


const style = {
    DataGrid: {
        display: 'flex',
        height: "85vh",
        width: '100%',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            whiteSpace: 'normal',
            textOverflow: 'normal',
            lineHeight: '15px'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },

    },
}

const Branch = () => {
    const globalStates = useHookstate(GlobalStates) 
    const [branchList, setBranchList] = useState([])
    const [admin, setAdmin] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [messages, setMessages] = useState({})
    const [btnLoad, setBtnLoad] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [toRun, setToRun] = useState('')

    useEffect(() => {
        fetchBranch()
        fetchUsers()
    }, [refresh])
    
    const refreshData = () => {
        setRefresh(!refresh)
    }

    const loadOff = () => {
        globalStates.isLoading.set(false)
        setInterval(() => {
            globalStates.isLoading.set(false)
        }, 1000)
    }

    const fetchBranch = () => {
        globalStates.isLoading.set(true)
        api.branch.getBranch() // function from endpoint
            .then(response => {
                let result = response.data.result; // get result from api
                const branch = result.map( item => {
                    const sched = JSON.parse(item.time)
                    return {
                        id: item.branch_id,
                        name: item.name,
                        abbreviation: item.abbreviation,
                        address: item.address,
                        phone: item.phone,
                        admin: parseInt(item.admin),
                        owned_by_bos: parseInt(item.owned_by_bos),
                        pick_up: parseInt(item.pick_up),
                        delivery: parseInt(item.delivery),
                        latitude: item.latitude,
                        longitude: item.longitude,
                        monday: sched.monday,
                        tuesday: sched.tuesday,
                        wednesday: sched.wednesday,
                        thursday: sched.thursday,
                        friday: sched.friday,
                        saturday: sched.saturday,
                        sunday: sched.sunday,
                        status: parseInt(item.status),
                    }
                })
                setBranchList(branch)
                loadOff()
            })
            .catch(error => {
                console.log(error);
                loadOff()
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
            })
    }

    const fetchUsers = () => {
        api.user.getUser()
        .then((response) => {
            let result = response.data; // get result from api
            const user = result.map( item => {
                return {
                    id: item.user_id,
                    first_name: item.first_name,
                    last_name: item.last_name,
                    email: item.email,
                    password: item.password,
                    status: item.status,
                    user_level: item.user_level
                }
            })
            setAdmin(user)
        })
        .catch((error) => {
            console.log(error);
        })
    }

    const disableBranch = (e) => {
        e.preventDefault()
        globalStates.modalOpenConfirm.set(true)
        console.log(e);
        const id = e.target.dataset.id
        const name = e.target.name

        setToRun('disable')
        
        const msg = {
            title: "Disable Branch ",
            message: `Are you sure you want to disable branch : ${name}`,
            note: "note: When a branch is disabled, this branch will permanently disabled.",
        }
        setMessages(msg)
        setSelectedData({id: id})
    }
    
    const onChangeStatus = (e) => {
        globalStates.modalOpenConfirm.set(true)
        const id = e.target.id
        const branch = e.target.name
        const status = e.target.checked ? 1 : 0

        setToRun('changeStatus')

        const body = {
            id: id,
            name: branch,
            status: status
        }
        const msg = {
            title: "Change branch status",
            message: `Are you sure you want to change status of : ${branch}`,
        }
        setMessages(msg)
        setSelectedData(body)
    }

    const isToRun = () => {
        setBtnLoad(true)
        if (toRun === 'disable') {
            api.branch.disable({id: selectedData?.id})
            .then((response) => {
                setBtnLoad(false)
                globalStates.modalOpenConfirm.set(false)
                refreshData()
                
            })
            .catch((error) => {
                console.log(error);
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
                setInterval(() => {
                    globalStates.openAlert.set(false)
                }, 2000)
            })
        } else if (toRun === 'changeStatus') {
            api.branch.changeStatus(selectedData)
            .then((response) => {
                setBtnLoad(false)
                globalStates.modalOpenConfirm.set(false)
                refreshData()
            })
            .catch((error) => {
                console.log(error);
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
                setInterval(() => {
                    globalStates.openAlert.set(false)
                }, 2000)
            })
        }
    }

    const statusChange = (props) => {
        const status = props.row.status 
        const name = props.row.name 
        const id = props.row.id 
        return (
            <>
                <Switch
                    checked={status === 1 ? true : false}
                    id={id}
                    name={name}
                    onChange={onChangeStatus}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </>
        )
    }

    // setting up columns
    const columns = [
        {
            field: "id", // fetching from map
            headerName: "ID", // table header
            flex: 0,
            minWidth: 80
        },
        {
            field: "name",
            headerName: "Branch",
            flex: 0.2,
            minWidth: 100,
            renderCell: (params) => {
                const name = params.row.name
                const abbreviation = params.row.abbreviation
                const combined = name + (abbreviation ? `(${abbreviation})` : '')
                return combined
            }
        },
        {
            field: "address",
            headerName: "Address",
            flex: 0.3,
            minWidth: 100
        },
        {
            field: "admin",
            headerName: "Admin",
            flex: 0.3,
            minWidth: 100,
            renderCell: (params) => {
                const getName = admin?.filter( admin => parseInt(admin.id) === params.row.admin)   
                
                return (
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{display: 'flex'}}>
                            <Typography sx={{fontWeight: 'bold', width: '65px'}} fontSize={12}>Admin :</Typography>
                            <Typography fontSize={12}>{(getName[0]?.first_name || "")+ " " + (getName[0]?.last_name || "") }</Typography>
                        </Box>
                        <Box sx={{display: 'flex'}}>
                            <Typography sx={{fontWeight: 'bold', width: '65px'}} fontSize={12}>Contact #:</Typography>
                            <Typography fontSize={12}>{params.row.phone}</Typography>
                        </Box>
                    </Box>
                )
            }
        },
        {
            field: "owned_by_bos",
            headerName: "Bo's Store",
            flex: 0.1,
            minWidth: 80,
            renderCell: (params) => {
                const owned = params.row.owned_by_bos === 1 ? 'Yes' : 'No'
                return owned
            }
        },
        {
            field: "delivery",
            headerName: "Delivery",
            flex: 0.1,
            minWidth: 30,
            renderCell: (params) => {
                const delivery = params.row.delivery === 1 ? 'Yes' : 'No'
                return delivery
            }
        },
        {
            field: "pick_up",
            headerName: "Pick Up",
            flex: 0.1,
            minWidth: 30,
            renderCell: (params) => {
                const pick_up = params.row.pick_up === 1 ? 'Yes' : 'No'
                return pick_up
            }
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0,
            minWidth: 100,
            renderCell: statusChange
        },
        {
            field: "actions",
            headerName: "Action Buttons",
            flex: 0.3,
            minWidth: 100,
            type: 'actions',
            getActions: (params) => [
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={6}>
                            <EditModal 
                                refresh={refreshData} 
                                admin={admin} 
                                branches={branchList} 
                                id={params.id} 
                                data={params.row} />
                        </Grid>
                        <Grid item xs={6}>
                            <LoadingButton
                                size="small"
                                color="error"
                                data-id={params.id}
                                name={params.row.name}
                                sx={{span: {pointerEvents: 'none'}}}
                                onClick={disableBranch}
                                loadingPosition="start"
                                startIcon={<DeleteIcon/>}
                                variant="outlined"
                                >
                                Disable
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ],
        }
    ]

    const CustomToolbar = () => {
        return (
            <Grid container
                sx={{
                    p: 1,
                    pb: 0
                }}
            >
                <Grid item xs={6}>
                    <GridToolbarQuickFilter />
                </Grid>
                <Grid 
                    xs={6}
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                    >
                        <CreateModal 
                            refresh={refreshData} 
                            admin={admin} 
                            branches={branchList} 
                        />
                </Grid>
            </Grid>
        );
    }

    return (
        <Box sx={{marginLeft: "6%",marginRight: "3%", marginTop: "5%"}}>
            <Box sx={style.DataGrid}>
                <DataGrid
                    columns={columns}
                    rows={branchList}
                    disableSelectionOnClick
                    loading={globalStates.isLoading.get()}
                    components={{ 
                        Toolbar: CustomToolbar, 
                        LoadingOverlay: LinearProgress, 
                    }}
                />  
                <ConfirmationModal 
                    messages={messages} 
                    load={btnLoad} 
                    run={() => isToRun()} />   
            </Box>  
        </Box>
    )
}

export default Branch