/*******************************************************************************************
//  * File Name                : ContentList
//  * File Directory           : src/components/modules/ContentList
//  * Module                   : ContentList
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Contents
//  * Used In				   : Create List 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ContentList        -   ContentList for reading/fetching data from api
 * #How to call:
 *    <ContentList/>
 *
 *******************************************************************************************/
// react-components / library
import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";
// drag and drop component
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


//api endpoints
import api from "../../../utility/Endpoints";

//Global States, call funtion
import GlobalStates from "../../../utility/GlobalStates";

// mui components grid, cards
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

// mui components table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import SearchBar from "material-ui-search-bar";

// mui icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SortIcon from '@mui/icons-material/Sort';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
// import modal
import CreateSection from '../Contents/CreateSection';
import DeleteSection from '../Contents/DeleteSection';

const styles = {
    spacing: {
        width: "80%",
        margin: "10px auto",
        display: "block"
    },
    defaultHeight: {
        minHeight: "100vh"
    }
}

const ContentsList = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    //useStates
    const [list, setList] = useState([]);
    const [isDisabled, setDisabled] = useState(true);
    const [fetchData, setFetchData] = useState(null);

    useEffect(() => {
        getContentList(); // call function
    }, []);

    const getContentList = () => {
        api.sections.get() // function from endpoint
            .then(response => {
                globalStates.isLoading.set(true); // loading, false
                let result = response.data; // get result from api
                let contents = result.map(col => {
                    return {
                        section_id: col.section_id ?? "-",
                        title: col.title ?? "-",
                        type: col.type ?? "-",
                        sort: col.sort ?? "-",
                        status: col.status ?? "-",
                        content_id: col.content_id ?? null,
                        sectionId: col.sectionId ?? col.section_id
                    }
                });
                setList(contents)
            })
            .catch(error => {
                console.log(error);
            })
    } // fetch / get list from endpoints

    const openCreate = () => {
        globalStates.modalOpenCreate.set(true)
    } // modal, open;

    const deleteModal = (params) => {
        params.cancelBubble = true;
        params.stopPropagation();
        params.preventDefault();
        let updateDetails = {
            id: params.target.dataset.id,
            title: params.target.dataset.title,
            type: params.target.dataset.type
        }
        setFetchData(updateDetails);
        globalStates.modalOpenDelete.set(true);
    } // modal, open;

    const enableSort = () => {
        setDisabled(false)
    } // enable sort

    const cancelSort = () => {
        setDisabled(true)
        globalStates.openAlert.set(true);
        globalStates.statusAlert.set("info");
        globalStates.msgAlert.set("Cancelled / Change(s) not saved");
        globalStates.isLoading.set(false); // loading
        setTimeout(() => {
            globalStates.openAlert.set(false);
            globalStates.isLoading.set(true); // loading
        }, 750);
    } // cancel sorting

    const onDrag = (params) => {
        const srcI = params.source.index;
        const desI = params.destination?.index
        list.splice(desI, 0, list.splice(srcI, 1)[0])
    } // drag table rows

    const saveSort = () => {
        globalStates.openAlert.set(true);
        globalStates.statusAlert.set("info");
        globalStates.msgAlert.set("Saving...");
        list.forEach(async (data, index) => {
            api.sections.sort(data.section_id, index)// function from endpoint
                .then(response => {
                    if (response.status === 200) {
                        if (index === list.length - 1) {
                            setTimeout(() => {
                                globalStates.statusAlert.set("success");
                                globalStates.msgAlert.set("Section Successfully Sorted!");
                                globalStates.isLoading.set(false); // loading
                            }, 1500);
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                        globalStates.isLoading.set(true); // loading
                    }, 3000);
                });
        })

    } // save sort

    return (

        <Grid
            container
            spacing={0}
            style={styles.defaultHeight}
        >
            {/* modals start */}
            <CreateSection />
            <DeleteSection fetchData={fetchData} />
            {/* modals end */}
            <Grid item xs={12}>
                <Card
                    style={styles.spacing}>
                    <CardContent>
                        <strong>List Section/s</strong>
                        <CardActions sx={{ float: "right" }}>
                            {isDisabled ?

                                <Tooltip title="Sort Section">
                                    <IconButton onClick={enableSort}>
                                        <SortIcon />
                                    </IconButton>
                                </Tooltip>

                                :
                                <>
                                    <Tooltip title="Cancel Sorting">
                                        <IconButton onClick={cancelSort}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Save Change(s)">
                                        <IconButton onClick={saveSort}>
                                            <CheckCircleOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>

                            }
                            <Tooltip title="Create New">
                                <IconButton onClick={openCreate}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    </CardContent>
                    <DragDropContext onDragEnd={onDrag}>

                        <Droppable droppableId="droppable-1">
                            {(provided, _) => (
                                <>
                                    <TableContainer>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="left">Title</TableCell>
                                                    <TableCell align="left">Type</TableCell>
                                                    <TableCell align="center">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}>

                                                {list.map((data, index) => (
                                                    <Draggable key={data.section_id} draggableId={'dragable-' + data.section_id} index={index} isDragDisabled={isDisabled} >
                                                        {(provided, snapshot) => (
                                                            <TableRow key={data.section_id}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    ...provided.draggableProps.style,
                                                                    boxShadow: snapshot.isDragging ?
                                                                        "0 0 .4rem #666"
                                                                        :
                                                                        "none",
                                                                }} >
                                                                <TableCell component="th" scope="row">
                                                                    {data.title}
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">
                                                                    {data.type}
                                                                </TableCell>

                                                                {data.content_id === null ?
                                                                    <TableCell align="center">
                                                                        {data.type === "Single" ?
                                                                            <a href={`create-content?Single#${data.sectionId}`}>
                                                                                <Tooltip title="Modify Section">
                                                                                    <IconButton>
                                                                                        <EditRoundedIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </a>
                                                                            :
                                                                            <a href={`CreateMultipleContent?${data.title}#${data.sectionId}`}>
                                                                                <Tooltip title="Modify Section">
                                                                                    <IconButton>
                                                                                        <EditRoundedIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </a>
                                                                        }
                                                                        <Tooltip title="Delete Section" onClick={deleteModal} data-id={data.sectionId} data-title={data.title} data-type={data.type}>
                                                                            <IconButton>
                                                                                <DeleteOutlineIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell align="center">
                                                                        {data.type === "Single" ?
                                                                            <a href={`update-content?Single#${data.content_id}`}>
                                                                                <Tooltip title="Modify Section">
                                                                                    <IconButton>
                                                                                        <EditRoundedIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </a>
                                                                            :
                                                                            <a href={`create-multiple-content?${data.title}#${data.section_id}`}>
                                                                                <Tooltip title="Modify Section">
                                                                                    <IconButton>
                                                                                        <EditRoundedIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </a>
                                                                        }
                                                                        <Tooltip title="Delete Section" onClick={deleteModal} data-id={data.sectionId} data-title={data.title} data-type={data.type}>
                                                                            <IconButton>
                                                                                <DeleteOutlineIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                }
                                                            </TableRow>
                                                        )}
                                                    </Draggable >
                                                ))}

                                                {provided.placeholder}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </>
                            )}

                        </Droppable>
                    </DragDropContext>
                </Card>
            </Grid>

        </Grid >
    );
}

export default ContentsList;