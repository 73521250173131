import { useState as useHookState } from "@hookstate/core";
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/system';
import moment from "moment";
import Paymongo from 'paymongo';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import AddressIcon from '../../../assets/icons/Address.png';
import CartIcon35 from '../../../assets/icons/Cart35.png';
import DateIcon from '../../../assets/icons/Date35.png';
import PaymentIcon from '../../../assets/icons/Payment.png';
import TimeIcon from '../../../assets/icons/Time.png';
import ConstantStyles from '../../../utility/ConstantStyle.json';
import api from "../../../utility/Endpoints";
import GlobalStates from "../../../utility/GlobalStates";
import { addPayment, selectedType, setOrder } from "../../actions";
import AddressList from '../Address/AddressList';
import AddressModal from '../Address/AddressModal';
import CartCheckout from '../Cart/CartCheckout';
import Time from "../DeliveryTime/Time";
import DiscountModal from "../Discount/DiscountModal";
import PaymentList from "../Payment/PaymentList";
import PaymentSummary from '../Payment/PaymentSummary';
const announcement = localStorage.getItem('announcement') === "1"
const styles = {
    fontsize20: {
        fontSize: '22px',
        margin: 'auto',
    },
    icons: {
        fontSize: '35px',
        color: ConstantStyles['purple']
    },
    button: {
        fontSize: '18px',
        textAlign: 'right',
        paddingRight: '5px',
        color: ConstantStyles['purple'],
        justifyContent: 'center',
        margin: 'auto',
        cursor: 'pointer'
    },
    container: {
        background: "white",
        marginBottom: '30px',
        p: 2,
        borderRadius: '15px'
    }
}
const DesktopCheckoutPage = (props) => {
    const { checkout, cart, store } = props
    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY);
    const totalCartPrice = cart.map(item => item.item_price).reduce((a, b) => a + b, 0)
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [paymentError, setPaymentError] = useState(false)
    const [addressError, setAddressError] = useState(false)
    const [disableCheckout, setDisableCheckout] = useState(false)
    const [type, setType] = useState('Delivery')
    const customerInfo = JSON.parse(localStorage.getItem(["customerInfo"]))
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        if (cart.length === 0) {
            setDisableCheckout(true)
        } else {
            setDisableCheckout(false)
        }
    }, [cart])

    const onClickPlaceOrder = () => {
        setDisableCheckout(true)
        const payload = {
            deliveryTime: moment().format('DD-MM-YYYY') + " " + checkout.deliveryTime.label,
            cart: JSON.stringify(cart),
            customerInfo: JSON.stringify(customerInfo),
            totalAmount: checkout.totalAmount < 100 ? 100 : checkout.totalAmount,
            paymentMethod: checkout.paymentMethod,
            discountAmount: Number(checkout.discount.map((el) => el.discountAmount).reduce((partialSum, a) => partialSum + a, 0)),
            discountName: checkout.discount.map((el) => el.discountName).toString(),
            shippingFee: checkout.shipping_fee,
            shippingAddress: JSON.stringify(checkout.shipping_address),
            type: checkout.type,
            points: Number(checkout.subTotalAmount / 100),
            branch: parseInt(store.branch)
        }
        if (checkout.shipping_address.length === 0) {
            setAddressError(true)
            setDisableCheckout(false)
            window.scrollTo(0, 0)
            setTimeout(() => {
                setAddressError(false)
            }, [2500])
        } else if (checkout.paymentMethod === '') {
            setPaymentError(true)
            setDisableCheckout(false)
            setTimeout(() => {
                setPaymentError(false)
            }, [2500])
        } else {
            api.checkout.createOrder(payload)
                .then(async response => {
                    if (response.status === 200) {
                        dispatch(setOrder(response.data))
                        console.log(response);
                        const paymongoAmount = (checkout.totalAmount < 100 ? 100 : checkout.totalAmount) * 100
                        const data = {
                            data: {
                                attributes: {
                                    type: checkout.paymentMethod,
                                    amount: parseInt(paymongoAmount),
                                    currency: 'PHP',
                                    redirect: {
                                        success: window.location.origin + '/thank-you',
                                        failed: window.location.origin + '/payment-failed'
                                    }
                                }
                            }
                        }
                        if (checkout.paymentMethod === "gcash" || checkout.paymentMethod === "grab_pay") {
                            const result = await paymongo.sources.create(data);
                            const details = {
                                id: result.data.id,
                                redirect: result.data.attributes.redirect.checkout_url
                            }
                            dispatch(addPayment(details))
                            const body = {
                                payId: details.id,
                                orderId: response.data.order_id,
                                paymentMethod: checkout.paymentMethod,
                            }
                            api.payment.create(body)
                                .then((response) => {
                                    window.location.href = details.redirect
                                })

                        } else if (checkout.paymentMethod === "Debit/Credit") {
                            setDisableCheckout(false) //show error message
                            history.push('/payment/debit-credit')
                        } else if (checkout.paymentMethod === "COD") {
                            history.push('/thank-you')
                        }
                    }
                })
                .catch(error => {
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("error");
                    globalStates.msgAlert.set(error.message);
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                    }, 3000);
                });

        }
        window.gtag("event", "purchase", {
            transaction_id: "T_12345",
            affiliation: "Google Merchandise Store",
            value: totalCartPrice,
            currency: "PHP",
            items: cart.map((index, item) => {
                return {
                    item_id: item.item_id,
                    item_sku: item.item_sku,
                    item_name: item.item_name,
                    currency: "PHP",
                    index: index,
                    item_modifier: item.item_modifier,
                    price: item.original_price,
                    quantity: item.item_qty
                }
            })
        });

    }

    const onClickAddAddress = () => {
        globalStates.openAddressModal.set(true)
        globalStates.addressType.set('add')
    }
    const handleChange = (e, newValue) => {
        setType(newValue);
        dispatch(selectedType(newValue))
    };

    return (
        <React.Fragment>
            <Box sx={{ minHeight: '100vh', marginTop: `${announcement ? '130px' : '85px'}`, p: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={7} sx={{ p: 2 }} >
                        <Grid container sx={addressError ? { border: `1px solid ${ConstantStyles['red']}`, mb: 1 } : { mb: 1 }} id="address">
                            <Grid item xs={12}>
                                <TabContext value={type}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 1, textAlign: 'start' }}>
                                        <TabList onChange={handleChange} aria-label="Delivery and Pick Up" >
                                            <Tab label="Delivery" value="Delivery" sx={{ fontWeight: ConstantStyles['bold'], fontSize: '18px' }} />
                                            <Tab label="Pick Up" value="Pick Up" sx={{ fontWeight: ConstantStyles['bold'], fontSize: '18px' }} />
                                        </TabList>
                                    </Box>
                                    <TabPanel sx={{ p: 0 }} value="Pick Up">
                                        <Grid container>
                                            <Grid item xs={7} sx={styles.fontsize20}>
                                                <strong><img alt='address_icon' src={AddressIcon} style={styles.icons} /> Info {addressError ? <i style={{ fontSize: '12px', color: ConstantStyles['red'] }}> this field is required </i> : null}</strong>
                                            </Grid>
                                            <Grid item xs={5} sx={styles.button}>
                                                <label onClick={onClickAddAddress} style={{ cursor: 'pointer' }}>Add</label>
                                            </Grid>
                                            <Grid item xs={12} sx={{ p: 2 }}>
                                                <AddressList />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel sx={{ p: 0 }} value="Delivery">
                                        <Grid container>
                                            <Grid item xs={7} sx={styles.fontsize20}>
                                                <strong><img alt='address_icon' src={AddressIcon} style={styles.icons} /> Delivery Address {addressError ? <i style={{ fontSize: '12px', color: ConstantStyles['red'] }}> this field is required </i> : null}</strong>
                                            </Grid>
                                            <Grid item xs={5} sx={styles.button}>
                                                <label onClick={onClickAddAddress} style={{ cursor: 'pointer' }}>Add Address</label>
                                            </Grid>
                                            <Grid item xs={12} sx={{ p: 2 }}>
                                                <AddressList />
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ position: 'relative', cursor: 'pointer', mb: 1 }}>
                            <Grid item xs={1}>
                                <img alt='calendar' src={DateIcon} sx={{ fontSize: '80px', position: "absolute", top: 5, color: ConstantStyles['purple'] }} />
                                <img alt="time_icon" src={TimeIcon} style={{ width: '20px', position: "absolute", background: 'white', borderRadius: '30px', top: '15px', left: '20px' }} />
                            </Grid>
                            <Grid item xs={11}>
                                <strong style={styles.fontsize20} >Delivery Time</strong> <br />
                                <label>{moment().format('DD-MM-YYYY')}</label> &nbsp;
                                <label>{checkout.deliveryTime.label}</label>
                                <Time />
                            </Grid>
                        </Grid>
                        <Grid container sx={paymentError ? { border: `1px solid ${ConstantStyles['red']}`, p: 1 } : { p: 1 }} id="payment-method">
                            <Grid item xs={12} sx={styles.fontsize20}>
                                <strong><img alt='payment_logo' src={PaymentIcon} style={styles.icons} /> Payment Method {paymentError ? <i style={{ fontSize: '12px', color: ConstantStyles['red'] }}> this field is required </i> : null}</strong>
                            </Grid>
                            <Grid item xs={12} sx={{ p: 1 }}>
                                <PaymentList />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container sx={{ background: ConstantStyles['white-color'], p: 2, borderRadius: '15px', mb: 2 }}>
                            <Grid item xs={12} sx={styles.fontsize20}>
                                <strong><img alt='cart_icon' src={CartIcon35} style={styles.icons} /> Items</strong>
                            </Grid>
                            <Grid item xs={12} sx={{ height: '320px', p: 1 }}>
                                <Box sx={{ width: '450px', top: 0, alignItems: "center", justifyContent: "center", margin: 'auto', pb: '15px' }}>
                                    {cart.length > 0 ?
                                        <CartCheckout />
                                        :
                                        null
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ background: ConstantStyles['white-color'], p: 2, borderRadius: '15px', mb: 2 }}>
                            <Grid item xs={12} sx={styles.fontsize20}>
                                <strong><DiscountOutlinedIcon sx={styles.icons} /> Discount</strong>
                            </Grid>
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <DiscountModal />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ background: ConstantStyles['white-color'], p: 2, borderRadius: '15px', mb: 2 }}>
                            <Grid item xs={12} sx={styles.fontsize20}>
                                <strong><img alt='payment_logo' src={PaymentIcon} style={styles.icons} /> Payment Summary</strong>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ width: '450px', top: 0, alignItems: "center", justifyContent: "center", margin: 'auto' }}>
                                    <PaymentSummary />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container sx={{ p: '0px 22px' }}>
                                    <Grid item xs={12} sx={{ fontSize: '18px', marginBottom: '15px' }}>
                                        <Grid container>
                                            <Grid xs={6}>
                                                <strong> TOTAL:</strong>
                                            </Grid>
                                            <Grid xs={6} sx={{ textAlign: 'right' }}>
                                                <strong>PHP {checkout.totalAmount.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                }) < 100 ? 100 : checkout.totalAmount.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                                </strong>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                        <Button
                                            sx={{
                                                color: 'white',
                                                text: 'center',
                                                background: ConstantStyles['purple'],
                                                borderRadius: '30px',
                                                width: '100%',
                                                height: '40px',
                                                fontSize: '18px',
                                                ':hover': { backgroundColor: ConstantStyles['purple'] },
                                                ':disabled': {
                                                    background: 'unset',
                                                }
                                            }}
                                            disabled={disableCheckout}
                                            onClick={onClickPlaceOrder}
                                        >
                                            PLACE ORDER
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Box>
            <AddressModal />
        </React.Fragment >
    )


}

const mapStateToProps = (state) => {
    return {
        checkout: state.checkout,
        order: state.order,
        cart: state.cart.CartList,
        store: state.store.selectedStore,
    }
}

export default connect(mapStateToProps)(DesktopCheckoutPage);