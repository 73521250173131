/*******************************************************************************************
 * File Name                : DateFllter
 * File Directory           : src/components/shared/DateFilter/
 * Module                   : DateFilter
 * Author                   : Bryan
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : *currently for all tables to be filtered*
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   DateFilter        -   component for ItemList for listing items
 *
 * #How to call:
        <DateFilter 
            //props send useState = both value and setter
            dateRange={
                {
                    startDate:moment().clone().startOf('month'), //chosen start date , default- start date of current month
                    endDate:moment().clone().endOf('month') //chosen end date , default- end date of next month
                }
            },
            setDateRange
        />
 *******************************************************************************************/
import React, { useEffect, useState } from 'react';
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import './CustomizeCss.css'

const DateFilter = ({dateRange={startDate:moment().clone().startOf('month'),endDate:moment().clone().add(1,"months").endOf('month')}, setDateRange}) => {
    const [startDate, setStartDate] = useState(dateRange.startDate);//Default start date 
    const [endDate, setEndDate] = useState(dateRange.endDate);//Default end date
    const [focusedInput, setFocusedInput] = useState();//Default focused field either [startDate/endDate]
    const [closed, setClosed] = useState(false);//Default focused field either [startDate/endDate]
    const onClose = () => {
        setTimeout(() => {
            if (endDate === null) {
                setStartDate(startDate);setEndDate(startDate );
                setDateRange({startDate:startDate,endDate:startDate})
            }
        }, 2000);
    }
    return (
        <div>
            <DateRangePicker
                relativeSize={true}
                startDate={startDate} //set default selected start date
                endDate={endDate} //set default selected end date
                startDateId="defStartDate" //idk just required
                endDateId="defEndDate" //idk just required
                enableOutsideDays={true} //enable past days
                isOutsideRange={() => false} //enable past days
                onClose={onClose}
                numberOfMonths= {2} //number of months listed
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);setEndDate(endDate );
                    setDateRange({startDate:startDate,endDate:endDate})
                }} //onDateSelect save dates
                focusedInput={focusedInput}//setFocus on input field either startDate or endDate
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} //set focus
            />

        </div>
    );
};

export default DateFilter;