
export const addItem = (payload) => {
    return {
        type: "ADD_ITEM",
        newItem: payload
    }
}

export const addAddress = (payload) => {
    return {
        type: 'SET_ADDRESS',
        address: payload
    }
}

export const isUpdate = () => {
    return {
        type: "UPDATE",
    }
}

export const updateAddress = (payload) => {
    return {
        type: "UPDATE_VALUE",
        row: payload.id,
        value: payload.value
    }
}

export const addQuantity = (item_id, item_modifier) => {
    return {
        type: "ADD_QUANTITY",
        item_id: item_id,
        item_modifier: item_modifier
    }
};

export const minusQuantity = (item_id, item_modifier) => {
    return {
        type: "MINUS_QUANTITY",
        item_id: item_id,
        item_modifier: item_modifier
    }
};

export const setProducts = (products) => {
    return {
        type: "SET_PRODUCTS",
        products: products
    }
};

export const setCategory = (category) => {
    return {
        type: "SET_CATEGORY",
        category: category
    }
};

export const setRecommendedProducts = (products) => {
    return {
        type: "SET_RECOMMENDED",
        products: products
    }
};

export const setStore = (store) => {
    return {
        type: "SET_STORE",
        store: store
    }
};

export const selectedAddress = (payload) => {
    return {
        type: "SHIPPING_ADDRESS",
        selected: payload
    }
}

export const selectedTime = (payload) => {
    return {
        type: "DELIVERY_TIME",
        selected: payload
    }
}

export const selectedPayment = (payload) => {
    return {
        type: "PAYMENT_METHOD",
        selected: payload
    }
}

export const setSubtotal = (payload) => {
    return {
        type: "SUBTOTAL",
        amount: payload
    }
}
export const setShippingFee = (payload) => {
    return {
        type: "SHIPPING_FEE",
        amount: payload
    }
}

export const setTotalAmount = (payload) => {
    return {
        type: "TOTAL_AMOUNT",
        amount: payload
    }
}

export const setDiscountRedux = (payload) => {
    return {
        type: "ADD_DISCOUNT",
        discount: payload
    }
}

export const setOrder = (payload) => {
    return {
        type: "CREATED_ORDER",
        order: payload
    }
}
export const addPayment = (payload) => {
    return {
        type: "ADD_PAYMENT",
        payment: payload
    }
}
export const selectedType = (payload) => {
    return {
        type: "ORDER_TYPE",
        order_type: payload
    }
}
export const searchProduct = (payload) => {
    return {
        type: "SEARCH_PRODUCT",
        value: payload
    }
}

export const TriggerQtyClick = (e, productQuantity, setProductQuantity) => {
    if (e === "+") {
        setProductQuantity(productQuantity + 1)
    } else if (e === "-" && productQuantity > 1) {
        setProductQuantity(productQuantity - 1)
    }
}

export const selectRequired = (event, modifier_group_name, groupModifier, selectedModifier, setSelectedModifier) => {
    let id = parseInt(event.target.value)
    let modifier_name = '', modifier_price = 0
    //get modifier name and price
    groupModifier.forEach((group) => {
        group.modifiers.forEach((modifier) => {
            if (modifier.modifier_id === id) {
                modifier_name = modifier.modifier_name
                modifier_price = modifier.price
            }
        })
    })
    let newValue = selectedModifier
    newValue[modifier_group_name] = {
        modifier_name: modifier_name,
        price: modifier_price
    }
    setSelectedModifier(newValue);
};


export const selectOptional = (event, modifier_group_name, price, modifier_name, selectedModifier, setSelectedModifier) => {
    let newValue = selectedModifier
    //check if modifier_group already exist in optionalFields object
    if (!newValue[modifier_group_name])
        // if not exist create modifer name as object first
        newValue[modifier_group_name] = []
    if (event.target.checked) {
        //if checked
        newValue[modifier_group_name].push({
            modifier_name: modifier_name,
            price: price
        })
    } else {
        newValue[modifier_group_name] = newValue[modifier_group_name].filter(group => {
            return group.modifier_name !== modifier_name
        })
        //if uncheck
    }
    setSelectedModifier(newValue);
};

export const onChangeSpecialInstruction = (event, setSpecialInstruction) => {
    const timerId = setTimeout(() => {
        setSpecialInstruction(event.target.value)
    }, 1000);
    return () => {
        clearTimeout(timerId);
    };
};

export const orderList = (list) => {
    return {
        type: "SET_ORDER_LIST",
        orders: list
    }
};