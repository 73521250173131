import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useHistory, useParams } from 'react-router-dom';
import AllIcon from '../../../assets/icons/all.png';
import ConstantStyles from '../../../utility/ConstantStyle.json';
import allImage from '../../../assets/backgroundImage/iced_coffee.jpg'

const CategoryNavBar = (props) => {
    const { category, boxSize = 95, show = false } = props
    const params = useParams()
    const [categoryList, setCategoryList] = useState()
    const [selectedCategory, setSelectedCategory] = useState(params?.category)
    const history = useHistory()
    const [screen, setScreen] = useState([window.screen.width]);
    let isMobile = screen <= 767

    const styles = {
        fontSize: {
            fontSize: isMobile ? '10px' : '12px',
            color: ConstantStyles['purple'],
            fontWeight: ConstantStyles['bold']
        },
        gridStyle: {
            borderRadius: '100px',
            height: `${isMobile ? '75' : boxSize}px`,
            width: `${isMobile ? '75' : boxSize}px`,
            margin: '2px',
            cursor: 'pointer',
            pointerEvent: 'none',
            background: ConstantStyles['teal'],
            flexDirection: "column",
            justifyContent: 'center',
            alignItems: 'center',
            display: "flex"
        },
        selectedStyle: {
            border: '2px solid #6700B9',
            borderRadius: '100px',
            height: `${isMobile ? '75' : boxSize}px`,
            width: `${isMobile ? '75' : boxSize}px`,
            margin: '2px',
            cursor: 'pointer',
            pointerEvent: 'none',
            background: ConstantStyles['teal'],
            justifyContent: 'center',
            flexDirection: "column",
            alignItems: 'center',
            display: "flex"
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const allCategory = [{ name: 'ALL' }]
        setCategoryList(allCategory.concat(category))
        setSelectedCategory((params?.category === 'non-coffee' ? params?.category?.toUpperCase() : params?.category?.toUpperCase()?.replace(/-/g, ' ')))

    }, [category, params])


    const onClickCategory = (e) => {
        let value = e?.target?.id?.toLowerCase()?.replace(/ /g, '-')
        setSelectedCategory(e?.target?.id)
        window.location.pathname = `/menu/${value}`
    }

    return (
        <React.Fragment>
            <Box className='text-center'>
                {categoryList?.map((list, index) => (
                    <div key={index} id={list.name} style={{ display: 'inline-block', width: `${isMobile ? '75' : boxSize}px`, height: `${isMobile ? '75' : boxSize}px`, verticalAlign: "middle", margin: '5px', marginBottom: (show && isMobile) ? '30px' : index > 5 && screen <= 820 ? '5px' : screen <= 820 ? '35px' : null }} name={list.name} onClick={onClickCategory}>
                        <Grid container id={list.name}>
                            <Grid item xs={12} id={list.name}>
                                <Box id={list.name} height={50} width={50} sx={selectedCategory === list.name ? styles.selectedStyle : styles.gridStyle}>
                                    {list.name === 'ALL' ?
                                        <img id={list.name} alt='all_icon' src={allImage} style={{ pointerEvents: 'none', width: isMobile ? '70px' : `${(boxSize - 5)}px`, height: isMobile ? '70px' : `${(boxSize - 5)}px`, borderRadius: '100px' }} />
                                        :
                                        <img id={list.name} alt='all_icon' src={process.env.REACT_APP_HOST + list.image_path} style={{ pointerEvents: 'none', width: isMobile ? '70px' : `${(boxSize - 5)}px`, height: isMobile ? '70px' : `${(boxSize - 5)}px`, borderRadius: '100px' }} />
                                    }
                                </Box>
                            </Grid>
                            <Grid item id={list.name} xs={12} className='text-center' sx={{ lineHeight: '13px', marginBottom: "10px" }}>
                                <span id={list.name} style={styles.fontSize}>{list.name}</span>
                            </Grid>
                        </Grid>
                        <br />
                    </div>
                ))
                }
            </Box>
        </React.Fragment >
    )

}


const mapStateToProps = (state) => {
    return {
        category: state.category.categoryList
    }
}

export default connect(mapStateToProps)(CategoryNavBar);