const INTIAL_STATE = {
    productList: []
}

const ProductsReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_PRODUCTS':
            return {
                ...state,
                productList: action.products
            }

        default:
            return state;
    }

};

export default ProductsReducer;