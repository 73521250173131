/*******************************************************************************************
//  * File Name                : Section
//  * File Directory           : src/components/general/Section
//  * Module                   : Section
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Section
//  * Used In				   : Home 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Section        -   Section for displaying / fetching data from database
 * #How to call:
 *    <Section/>
 *
 *******************************************************************************************/
// react library
import { useState as useHookState } from "@hookstate/core";
import { useEffect, useState } from "react";

//api endpoints
import api from "../../utility/Endpoints";

//Global States, call funtion
import GlobalStates from "../../utility/GlobalStates";
import ConstantStyles from "../../utility/ConstantStyle.json";

//logo endpoints
// import DailyLogo from "../../assets/logo/daily-logo.png";

// mui components
//material ui library/components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';



const Section = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    //useStates

    const [announcement, setAnnouncement] = useState("");// data / value for announcement title
    const [show, setShow] = useState(0);// data / value for announcement title
    // const [attributes, setAttributes] = useState([]);// data / value for announcement attributes
    const [fonts, setFonts] = useState("");// data / value for announcement attributes
    const [background, setBackground] = useState("");// data / value for announcement attributes
    const [fontColor, setFontColor] = useState("");// data / value for announcement attributes
    const [screen, setScreen] = useState(window.innerWidth);
    let isMobile = screen <= 700
    const styles = {
        centerText: {
            textAlign: "center",
            padding: "8px 15px",
            zIndex: "999",
            fontSize: isMobile ? "3vw": "20px"
        }
    }
    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getAnnouncement(); // call function
    }, []);

    const getAnnouncement = () => {
        api.announcement.get() // function from endpoint
            .then(response => {
                globalStates.isLoading.set(true); // loading, false
                let result = response.data.result; // get result from api
                let attributes = JSON.parse(result[0].attribute)
                setAnnouncement(result[0].announcement); // set states
                setShow(result[0].status); // set states
                setFonts(attributes.fontSection); // set states
                setBackground(attributes.background); // set states
                setFontColor(ConstantStyles["teal"]); // set states
                localStorage.setItem('announcement', result[0].status)
            })
            .catch(error => {
                console.log(error);
            })
    } // fetch / get list from endpoints

    return (
        <>
            {show === "1" ?
                <Card style={{
                    position: "fixed",
                    width: "100%",
                    backgroundColor: background,
                    color: fontColor,
                    borderRadius: "0",
                    zIndex: "998"
                }}
                    className={`${fonts}`}>
                    <CardContent style={styles.centerText}>
                        <strong>{announcement}</strong>
                    </CardContent>
                </Card >
                :
                null
            }
        </>
    );
}

export default Section;