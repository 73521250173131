/*******************************************************************************************
//  * File Name                : CreateSection
//  * File Directory           : src/components/modules/CreateSection
//  * Module                   : CreateSection
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Contents
//  * Used In				   : ContentList 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   CreateSection        -   Modal and form for creating section
 * #How to call:
 *    <CreateSection/>
 *
 *******************************************************************************************/
import { useState } from "react";
import { useState as useHookState } from "@hookstate/core";

//api endpoints
import api from "../../../utility/Endpoints";

//Global States, call funtion
import GlobalStates from "../../../utility/GlobalStates";
import ConstantStyles from "../../../utility/ConstantStyle.json";

//material ui library/components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

//import from shareable
import InputAutocomplete from '../../shared/InputAutocomplete'

//style starts here
const styles = {
  btnPurple: {
    backgroundColor: ConstantStyles["purple"],
    color: ConstantStyles["white-color"]
  },
  modalStyle: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  }
}

//array for image positions
const optionType = [
  { label: 'Single', value: 'Single' },
  { label: 'Multiple', value: 'Multiple' }
];

const CreateSection = () => {
  const globalStates = useHookState(GlobalStates); // call globalstates
  const handleClose = () => globalStates.modalOpenCreate.set(false);
  const [title, setTitle] = useState("");// data / value for section title
  const [type, setType] = useState("Single");// data / value for section title

  const submitContents = () => {
    let payload = {
      user: globalStates.userEmail.get(),
      title: title,
      type: type || "Single"
    }

    if (title !== "") {
      api.sections.post(payload)
        .then(response => {
          let id = response.data.insertId;
          console.log(response.data)
          globalStates.openAlert.set(true);
          globalStates.statusAlert.set("success");
          globalStates.msgAlert.set("Success: Created New Section!");
          setTimeout(() => {
            // if type is single , the page will be redirect to create content
            if (type === "Single") {
              window.location.href = "/admin-backend/create-content?" + type + "#" + id;
              globalStates.isLoading.set(false); // loading
            }
            // if type is multiple , the page will be redirect to multiple content landing page
            else {
              window.location.href = "/admin-backend/create-multiple-content?" + title + "#" + id;
              globalStates.isLoading.set(false); // loading
            }
          }, 1500);
        })
        .catch(error => {
          console.log(error);
          globalStates.openAlert.set(true);
          globalStates.statusAlert.set("error");
          globalStates.msgAlert.set(error);
        })
        .finally(() => {
          setTimeout(() => {
            globalStates.openAlert.set(false);
            globalStates.modalOpen.set(false); // loading
            globalStates.isLoading.set(true); // loading
          }, 3000);
        });
    }
    else {
      globalStates.openAlert.set(true);
      globalStates.statusAlert.set("error");
      globalStates.msgAlert.set("Required: Please fillup all required Fields");
    }
  }
  
  return (
    <div>
      <Modal
        open={globalStates.modalOpenCreate.get()}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create New
          </Typography>
          <br />
          <form>
            <Grid container item xs={12} md={12} lg={12} spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  size="small"
                  required
                  sx={{ width: '100%' }}
                  defaultValue={title}
                  onChange={(e) => {
                    setTitle(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <InputAutocomplete
                  options={optionType}
                  value={type}
                  defaultValue={optionType[0]}
                  title="Section Type"
                  onChange={(e) => {
                    setType(e.target.innerHTML)
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <input type="button" value="Submit" className="btn float-end mt-2" style={styles.btnPurple} onClick={submitContents} />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );

}
export default CreateSection;