import { useState as useHookState } from "@hookstate/core";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import PickUpForm from "./PickUpForm";

import AddressForm from './AddressForm';

import GlobalStates from "../../../utility/GlobalStates";

import { connect } from "react-redux";
import ConstantStyles from '../../../utility/ConstantStyle.json';

const styles = {
    dragIcon: {
        textAlign: 'center',
        margin: 'auto',
        color: ConstantStyles['black-color'],
        position: 'fixed',
        width: '100%',
        background: 'white',
        borderRadius: '15px 15px 0px 0px',
        zIndex: '999'
    },
    line: {
        margin: '5px 0px'
    }
}

const AddressDrawer = (props) => {
    const { checkout } = props
    const globalStates = useHookState(GlobalStates); // call globalstates

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    useEffect(() => {
        toggleDrawer('bottom', globalStates.openAddressDrawer.get())
    }, [globalStates.openAddressDrawer.get()])


    const toggleDrawer = (anchor, open) => {
        setState({ ...state, [anchor]: open });
    }

    return (
        <React.Fragment>
            <SwipeableDrawer
                sx={{
                    height: (window.innerHeight - 122),
                    flexShrink: 0,

                    '& .MuiDrawer-paper': {
                        height: (window.innerHeight - 122),
                        boxSizing: 'border-box',
                        borderRadius: '15px 15px 0px 0px',
                    },
                }}
                anchor={'bottom'}
                open={state['bottom']}
                onClose={() => {
                    toggleDrawer('bottom', false)
                    globalStates.openAddressDrawer.set(false)
                }}
                onOpen={() => {
                    toggleDrawer('bottom', true)
                    globalStates.openAddressDrawer.set(true)
                }}
            >
                <Box sx={styles.dragIcon}>
                    <DragHandleIcon /><br />
                    <strong style={{ fontSize: '20px' }}>{globalStates.addressType.get() === 'add' ? 'Add Address' : 'Edit Address'}</strong>
                    <hr style={styles.line} />
                </Box>
                <Grid container sx={{ overflow: 'auto', height: '100%' }}>
                    <Grid item xs={12} sx={{ mt: '70px' }}>
                        {checkout.type === 'Delivery' ?
                            <AddressForm />
                            :
                            <PickUpForm />
                        }
                    </Grid>
                </Grid>
            </SwipeableDrawer>
        </React.Fragment >
    )
}
const mapStateToProps = (state) => {
    return {
        checkout: state.checkout,
    }
}

export default connect(mapStateToProps)(AddressDrawer);