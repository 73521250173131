
import { useState as useHookState } from "@hookstate/core";
import React, { useState } from 'react';

import AddressDrawer from '../Address/AddressDrawer';
import AddressList from '../Address/AddressList';
import TimeDrawer from "../DeliveryTime/TimeDrawer";

import Grid from '@mui/material/Grid';

import DiscountIcon from '@mui/icons-material/Discount';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Button from '@mui/material/Button';
import Tab from '@mui/material/Tab';
import { Box } from "@mui/system";
import moment from "moment";
import Paymongo from 'paymongo';
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AddressIcon from '../../../assets/icons/Address.png';
import DateIcon from '../../../assets/icons/Date.png';
import PaymentIcon from '../../../assets/icons/Payment.png';
import TimeIcon from '../../../assets/icons/Time.png';
import ConstantStyles from '../../../utility/ConstantStyle.json';
import api from "../../../utility/Endpoints";
import GlobalStates from "../../../utility/GlobalStates";
import { addAddress, addPayment, selectedType, setOrder } from "../../actions";
import DiscountDrawer from "../Discount/DiscountDrawer";
import PaymentList from "../Payment/PaymentList";
import PaymentSummary from "../Payment/PaymentSummary";

const styles = {
    fontsize16: {
        fontSize: '16px',
        margin: 'auto',
    },
    icons: {
        fontSize: '35px',
        color: ConstantStyles['purple']
    },
    button: {
        fontSize: '20px',
        textAlign: 'right',
        paddingRight: '5px',
        color: ConstantStyles['purple'],
        justifyContent: 'center',
        margin: 'auto'
    }
}


const MobileCheckoutPage = (props) => {
    const { checkout, cart, store } = props
    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY);
    const totalCartPrice = cart.map(item => item.item_price).reduce((a, b) => a + b, 0)
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [showTimeModal, setShowTimeModal] = useState(false)
    const [disableCheckout, setDisableCheckout] = useState(false)
    const [paymentError, setPaymentError] = useState(false)
    const [type, setType] = useState('Delivery')
    const [addressError, setAddressError] = useState(false)
    const customerInfo = JSON.parse(localStorage.getItem(["customerInfo"]))
    const history = useHistory()
    const dispatch = useDispatch()

    const onClickAddAddress = () => {
        dispatch(addAddress({}))
        globalStates.openAddressDrawer.set(true); // loading
        globalStates.addressType.set('add')
    }

    const onClickChange = () => {
        globalStates.openTimeDrawer.set(true); // loading
    }

    const onClickPlaceOrder = () => {
        setDisableCheckout(true)
        const payload = {
            deliveryTime: moment().format('DD-MM-YYYY') + " " + checkout.deliveryTime.label,
            cart: JSON.stringify(cart),
            customerInfo: JSON.stringify(customerInfo),
            totalAmount: checkout.totalAmount < 1 ? 0 : checkout.totalAmount,
            paymentMethod: checkout.paymentMethod,
            discountAmount: Number(checkout.discount.map((el) => el.discountAmount).reduce((partialSum, a) => partialSum + a, 0)),
            discountName: checkout.discount.map((el) => el.discountName).toString(),
            shippingFee: checkout.shipping_fee,
            shippingAddress: JSON.stringify(checkout.shipping_address),
            type: checkout.type,
            points: Number(checkout.subTotalAmount / 100),
            branch: parseInt(store.branch)
        }
        if (checkout.shipping_address.length === 0) {
            setDisableCheckout(false)
            setAddressError(true)
            window.scrollTo(0, 0)
            setTimeout(() => {
                setAddressError(false)
                window.location.hash = ''
            }, [2500])
        } else if (checkout.paymentMethod === '') {
            setDisableCheckout(false)
            setPaymentError(true)
            setTimeout(() => {
                setPaymentError(false)
            }, [2500])
        } else {
            api.checkout.createOrder(payload)
                .then(async response => {
                    if (response.status === 200) {
                        dispatch(setOrder(response.data))
                        const paymongoAmount = (checkout.totalAmount < 100 ? 100 : checkout.totalAmount) * 100
                        const data = {
                            data: {
                                attributes: {
                                    type: checkout.paymentMethod,
                                    amount: parseInt(paymongoAmount),
                                    currency: 'PHP',
                                    redirect: {
                                        success: window.location.origin + '/thank-you',
                                        failed: window.location.origin + '/payment-failed'
                                    }
                                }
                            }
                        }
                        if (checkout.paymentMethod === "gcash" || checkout.paymentMethod === "grab_pay") {
                            const result = await paymongo.sources.create(data);
                            const details = {
                                id: result.data.id,
                                redirect: result.data.attributes.redirect.checkout_url
                            }
                            dispatch(addPayment(details))
                            const body = {
                                payId: details.id,
                                orderId: response.data.order_id,
                                paymentMethod: checkout.paymentMethod,
                            }
                            api.payment.create(body)
                                .then((response) => {
                                    window.location.href = details.redirect
                                })

                        } else if (checkout.paymentMethod === "Debit/Credit") {
                            setDisableCheckout(false) //show error message
                            history.push('/payment/debit-credit')
                        } else if (checkout.paymentMethod === "COD") {
                            history.push('/thank-you')
                        }
                    }
                })
                .catch(error => {
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("error");
                    globalStates.msgAlert.set(error.message);
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                    }, 3000);
                });

        }

        window.gtag("event", "purchase", {
            transaction_id: "T_12345",
            affiliation: "Google Merchandise Store",
            value: totalCartPrice,
            currency: "PHP",
            items: cart.map((index, item) => {
                return {
                    item_id: item.item_id,
                    item_sku: item.item_sku,
                    item_name: item.item_name,
                    currency: "PHP",
                    index: index,
                    item_modifier: item.item_modifier,
                    price: item.original_price,
                    quantity: item.item_qty
                }
            })
        });

    }
    const handleChange = (e, newValue) => {
        setType(newValue);
        dispatch(selectedType(newValue))
    };

    return (
        <React.Fragment>
            <Box sx={{ background: ConstantStyles['white-color'] }}>
                <Grid container rowSpacing={1} sx={addressError ? { background: 'white', marginBottom: '10px', p: '0px 10px', border: `1px solid ${ConstantStyles['red']}` } : { background: 'white', marginBottom: '10px', p: '0px 10px' }} id='address'>
                    <Grid item xs={12}>
                        <TabContext value={type}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingBottom: 1, textAlign: 'start' }}>
                                <TabList onChange={handleChange} aria-label="Delivery and Pick Up" centered >
                                    <Tab label="Delivery" value="Delivery" sx={{ fontWeight: ConstantStyles['bold'], fontSize: '18px' }} />
                                    <Tab label="Pick Up" value="Pick Up" sx={{ fontWeight: ConstantStyles['bold'], fontSize: '18px' }} />
                                </TabList>
                            </Box>
                            <TabPanel sx={{ p: 0 }} value="Pick Up">
                                <Grid container>
                                    <Grid item xs={7} sx={styles.fontsize20}>
                                        <strong><img alt='address_icon' src={AddressIcon} style={styles.icons} /> Info {addressError ? <i style={{ fontSize: '12px', color: ConstantStyles['red'] }}> this field is required </i> : null}</strong>
                                    </Grid>
                                    <Grid item xs={5} sx={styles.button}>
                                        <label onClick={onClickAddAddress} style={{ cursor: 'pointer' }}>Add</label>
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 2 }}>
                                        <AddressList />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel sx={{ p: 0 }} value="Delivery">
                                <Grid container>
                                    <Grid item xs={7} sx={styles.fontsize20}>
                                        <strong><img alt='address_icon' src={AddressIcon} style={styles.icons} /> Delivery Address {addressError ? <i style={{ fontSize: '12px', color: ConstantStyles['red'] }}> this field is required </i> : null}</strong>
                                    </Grid>
                                    <Grid item xs={5} sx={styles.button}>
                                        <label onClick={onClickAddAddress} style={{ cursor: 'pointer' }}>Add Address</label>
                                    </Grid>
                                    <Grid item xs={12} sx={{ p: 2 }}>
                                        <AddressList />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                </Grid>
                <Grid container sx={{ background: 'white', marginBottom: '30px', p: '0px 10px' }} >
                    <Grid item xs={12} sx={{ position: "relative", height: '100px' }}>
                        <div style={{ height: '100px', margin: "auto" }}>
                            <Grid container sx={{ height: '100px' }}>
                                <Grid item xs={3}>
                                    <img alt='calendar_icon' src={DateIcon} sx={{ fontSize: '80px', position: "absolute", top: 5, color: ConstantStyles['purple'] }} />
                                    <img alt='time_icon' src={TimeIcon} style={{ fontSize: '40px', position: "absolute", background: 'white', borderRadius: '30px', bottom: '25px', left: '45px' }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <strong style={styles.fontsize16} >Delivery Time</strong><br />
                                    <label>{moment().format('DD-MM-YYYY')}</label><br />
                                    <label>{checkout.deliveryTime.label}</label>
                                </Grid>
                                <Grid item xs={3} sx={styles.button}>
                                    <label onClick={onClickChange}>Change</label>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>

                <Grid container rowSpacing={2} sx={paymentError ? { background: 'white', marginBottom: '30px', border: '1px solid red', p: '0px 10px' } : { background: 'white', marginBottom: '30px', p: '0px 10px' }} id='paymen-method' >
                    <Grid item xs={12} sx={styles.fontsize16}>
                        <strong><img alt='payment_icon' src={PaymentIcon} style={styles.icons} /> Payment Method {paymentError ? <i style={{ fontSize: '12px', color: ConstantStyles['red'] }}> this field is required </i> : null}</strong>
                    </Grid>
                    <Grid item xs={12} sx={{ p: 1 }}>
                        <PaymentList />
                    </Grid>
                </Grid>
                <Grid container sx={{ background: 'white', marginBottom: '30px', p: '10px 10px' }}>
                    <Grid item xs={12} sx={styles.fontsize16}>
                        <strong><DiscountOutlinedIcon sx={styles.icons} /> Discount</strong>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button onClick={() => globalStates.openDiscountDrawer.set(true)} startIcon={<DiscountIcon />}>Use promo or discount code</Button>
                    </Grid>
                </Grid>
                <Grid container rowSpacing={2} sx={{ background: 'white', marginBottom: '135px', p: '0px 10px' }} >
                    <Grid item xs={12} sx={styles.fontsize16}>
                        <strong><img alt='payment_icon' src={PaymentIcon} style={styles.icons} /> Payment Summary</strong>
                    </Grid>
                    <Grid item xs={12} sx={{ p: 1 }}>
                        <PaymentSummary />
                    </Grid>
                </Grid>

            </Box>
            <Box sx={{ width: '100%', background: '#A2F9F9', position: 'fixed', height: "110px", bottom: 0, left: 0, padding: "10px", borderTop: `2px solid ${ConstantStyles['white-color']}` }}>
                <Grid container>
                    <Grid item xs={12} sx={{ textAlign: "center", fontSize: '25px' }}>
                        <Grid container>
                            <Grid xs={5}>
                                TOTAL:
                            </Grid>
                            <Grid xs={7}>
                                PHP {checkout.totalAmount.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                }) < 1 ? 0 : checkout.totalAmount.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button
                            sx={{
                                color: 'white',
                                text: 'center',
                                background: ConstantStyles['purple'],
                                width: '90%',
                                height: '40px',
                                fontSize: '22px',
                                ':hover': { backgroundColor: ConstantStyles['purple'] },
                                ':disabled': {
                                    backgroundColor: ConstantStyles['white-color']
                                }

                            }}
                            disabled={disableCheckout}
                            onClick={onClickPlaceOrder}
                        >
                            PLACE ORDER
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <AddressDrawer />
            <TimeDrawer />
            <DiscountDrawer />
        </React.Fragment >
    )

}


const mapStateToProps = (state) => {
    return {
        checkout: state.checkout,
        cart: state.cart.CartList,
        store: state.store.selectedStore,
    }
}

export default connect(mapStateToProps)(MobileCheckoutPage);