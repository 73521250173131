import { useState as useHookState } from "@hookstate/core";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import GlobalStates from "../../../utility/GlobalStates";
import { selectedPayment } from '../../actions';
import PaymentMethod from '../Payment/PaymentMethod.json';

const styles = {
    fontSize: {
        fontSize: '10px',
        whiteSpace: 'normal',

    },
    gridStyle: {
        border: '1px solid black',
        borderRadius: '20px',
        height: '95px',
        width: '110px',
        margin: '2px 3px',
        cursor: 'pointer',
        padding: '2px',
        pointerEvent: 'none'
    },
    label: {
        textAlign: 'center',
        lineHeight: '8px'
    },
    selectedStyle: {
        border: '3px solid #6700B9',
        borderRadius: '20px',
        height: '98px',
        width: '110px',
        margin: '2px 3px',
        padding: '2px',
        cursor: 'pointer',
        pointerEvent: 'none',
    }
}


const PaymentList = (props) => {
    const { cart } = props
    const totalCartPrice = cart.map(item => item.item_price).reduce((a, b) => a + b, 0)
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [selected, setSelected] = useState()
    const dispatch = useDispatch()

    const onClickPayment = (e) => {
        dispatch(selectedPayment(e.target.id))
        setSelected(e.target.id)
        window.gtag("event", "add_payment_info", {
            currency: "PHP",
            value: totalCartPrice,
            payment_type: e.target.id,
            items: cart.map((index, item) => {
                return {
                    item_id: item.item_id,
                    item_sku: item.item_sku,
                    item_name: item.item_name,
                    currency: "PHP",
                    index: index,
                    item_modifier: item.item_modifier,
                    price: item.original_price,
                    quantity: item.item_qty
                }
            })
        });
    }

    return (
        <React.Fragment>
            <Box sx={globalStates.isMobile ? { width: "100%", minHeight: "120px", overflow: 'auto', whiteSpace: 'nowrap' } : null}>
                {PaymentMethod.map((payment, index) => (
                    <div key={index} id={payment.value} style={{ display: 'inline-block' }} name={payment.name} onClick={onClickPayment}>
                        <Grid container id={payment.value} sx={selected === payment.value ? styles.selectedStyle : styles.gridStyle}>
                            <Grid item xs={12} id={payment.value}>
                                <Box id={payment.value} sx={{ position: 'relative' }}>
                                    {selected === payment.value ?
                                        <CheckCircleOutlineIcon sx={{ position: 'absolute', top: 3, right: 2, fontSize: '18px', color: '#6700B9' }} />
                                        :
                                        null
                                    }
                                    <LocalAtmOutlinedIcon sx={{ fontSize: '70px', width: '100%' }} />
                                </Box>
                            </Grid>
                            <Grid item id={payment.value} xs={12} sx={styles.label}>
                                <strong id={payment.value} style={styles.fontSize}>{payment.name}</strong>
                            </Grid>
                        </Grid>

                    </div>
                ))}
            </Box>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.CartList
    }
}

export default connect(mapStateToProps)(PaymentList)