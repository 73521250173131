// react library
import { useState as useHookState } from "@hookstate/core";
import React, { useState } from "react";


//api endpoints
import api from "../../../utility/Endpoints";

//Global States, call funtion
import ConstantStyles from "../../../utility/ConstantStyle.json";
import GlobalStates from "../../../utility/GlobalStates";

// mui components
//material ui library/components
import TextField from '@mui/material/TextField';

//import from general folder

//style starts here
const styles = {
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"],
        width: "100%",
        borderRadius: "25px"
    },
    guestBtn: {
        backgroundColor: ConstantStyles["teal"],
        color: ConstantStyles["purple"],
        width: "100%",
        borderRadius: "25px",
        border: "2px solid" + ConstantStyles["purple"]
    },
    create: {
        textAlign: 'center',
        color: ConstantStyles["purple"],
    },
    textUnderline: {
        textDecoration: 'underline',
        cursor: 'pointer'
    }
}

const CustomerLogin = (props) => {
    const { setvalue } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    const isGuestSignIn = localStorage.getItem('guestSignIn') === 'true' || false
    //useStates
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const SignIn = () => {
        let body = { email: email, password: password };
        api.customerSignIn.post(body) // function from endpoint
            .then(response => {
                globalStates.isLoading.set(true);
                localStorage.setItem('customerSignedIn', true);
                localStorage.setItem('guestSignIn', false);
                localStorage.setItem('guestAddress', '[]');
                localStorage.setItem('customerInfo', (response.data.customer ?? null) === null ? null : JSON.stringify(response.data.customer));
                if (window.location.hash === '#checkout') {
                    window.location.href = '/checkout'
                } else {
                    window.location.href = window.location.pathname;
                }

                // google analytics
                window.gtag("event", "login", {
                    method: "Google"
                });
            })
            .catch(error => {
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set("Invalid email or password");
            })
            .finally(() => {
                setTimeout(() => {
                    globalStates.openAlert.set(false);
                    globalStates.isLoading.set(true); // loading
                }, 3000);
            });
    }

    const guestSignIn = () => {
        let dateNow = Date.now()
        localStorage.setItem('guestSignIn', true);
        localStorage.setItem('customerSignedIn', false);
        localStorage.setItem('guestAddress', '[]');
        localStorage.setItem('customerInfo', JSON.stringify({ "customer_id": dateNow, "email_address": "", "first_name": "Guest" + dateNow, "last_name": "", "birth_date": "", "phone_number": "", "gender": "" }));
        if (window.location.hash === '#checkout') {
            window.location.href = '/checkout'
        } else {
            window.location.href = window.location.pathname;
        }

    }

    const onClickCreate = () => {
        setvalue('2')
    }

    return (
        <React.Fragment>
            <div className="p-5 pt-2 pb-2">
                <p style={{ fontSize: "16px", marginBottom: "5px" }}>Email</p>
                <TextField
                    fullWidth
                    type="input"
                    id="fullWidth"
                    size="small"
                    sx={{ width: '100%' }}
                    defaultValue={email}
                    value={email}
                    required
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                />
                <p style={{ fontSize: "16px", marginBottom: "5px" }}>Password</p>
                <TextField
                    fullWidth
                    id="fullWidth"
                    size="small"
                    sx={{ width: '100%' }}
                    defaultValue={password}
                    value={password}
                    type="password"
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                />

                <input type="button" value="Login" className="btn mt-3 mb-3" style={styles.btnPurple} onClick={SignIn} />
                {isGuestSignIn ?
                    null :
                    <React.Fragment>
                        <br />
                        <label style={{ width: '100%', textAlign: 'center' }}>or</label>
                        <br />
                        <input type="button" value="Checkout as guest" className="btn mt-3 mb-3" style={styles.guestBtn} onClick={guestSignIn} />
                    </React.Fragment>
                }
                <div style={styles.create}>
                    <label style={styles.textUnderline} onClick={onClickCreate}>Create an account?</label>
                </div>

            </div>

        </React.Fragment>
    );
}

export default CustomerLogin;