export const TimeList = [
    { label: "8:00 AM - 9:00 AM", value: 8 },
    { label: "9:00 AM - 10:00 AM", value: 9 },
    { label: "10:00 AM - 11:00 AM", value: 10 },
    { label: "11:00 AM - 12:00 PM", value: 11 },
    { label: "12:00 PM - 1:00 PM", value: 12 },
    { label: "1:00 PM - 2:00 PM", value: 13 },
    { label: "2:00 PM - 3:00 PM", value: 14 },
    { label: "3:00 PM - 4:00 PM", value: 15 },
    { label: "4:00 PM - 5:00 PM", value: 24 },
]