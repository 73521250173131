import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import api from "../../../utility/Endpoints";


const style = {
    center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: '100vh',
        width: '100vw'
    },
    link: {
        textDecoration: 'none'
    }
}


const Failed = (props) => {
    const { order } = props

    const body = useMemo(() => {
        const body = {
            orderId : order.createdOrder.order_id
        }
        return body
    }, [order.createdOrder.order_id])

    useEffect(() => {
        //record payment intent id to database
        api.payment.failed(body)
    }, [body])

    return (
        <React.Fragment>
            <div className="container mt-5" style={style.center}>
                <span
                    className=" border rounded p-5 alert alert-danger"
                >
                    <div className="row text-center">
                        <h1>Payment Failed</h1>
                    </div>
                    <div className="row text-center">
                        <span>
                            Selected Payment method <br /> is not available at this moment.
                        </span>
                    </div>
                    <div className="row text-center">
                        <Link
                            to="/checkout"
                            style={style.link}
                        >
                            Please try again.
                        </Link>
                    </div>
                </span>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        order: state.order,
        cart: state.cart.CartList
    }
}

export default connect(mapStateToProps)(Failed);