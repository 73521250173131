import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import api from '../../utility/Endpoints';


//material ui library/components
const HomeBanner = (props) => {
    const [banner, setBanner] = useState('')
    const [screen, setScreen] = useState([window.innerWidth]);
    let isMobile = screen <= 767

    const banner_image = `/banner/${ isMobile?'welcome banner mobile.jpg':'welcome banner desktop.jpg'}` 
    
    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fetchBanner()
    }, [])

    const fetchBanner = () => {
        api.banner.fetch('Home') 
        .then((response) => {
            let bannerPath = response.data.result.filter(item => item.type === 'Desktop')[0]
            if (isMobile) {
                bannerPath = response.data.result.filter(item => item.type === 'Mobile')[0]
            }
            setBanner(bannerPath ? process.env.REACT_APP_HOST + bannerPath?.image_path : '')
        })
        .catch((error) => {
            console.log(error.message);
        })
    }

    return (
        <React.Fragment>
            <img style={{display: banner ? 'block': 'none', width:screen-7 }} src={banner} alt='Banner' />
        </React.Fragment>
    );


}


export default HomeBanner;