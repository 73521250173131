import React, { useState } from 'react';
import ConstantStyles from "../../../utility/ConstantStyle.json";

import _ from 'lodash';

import gridSize from "./CardGridSize.json";
//material ui library/components
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '../../../assets/icons/+ icon hi res.svg';
import defaultImage from '../../../assets/logo/default.webp';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ProductView from './ProductView';

//import grabFood from '../../../assets/logo/grab-food-logo.jpg';
//import foodPanda from '../../../assets/logo/food-panda.png'
//const defaultImage = `https://scontent.fmnl17-1.fna.fbcdn.net/v/t39.30808-6/295432466_583897046697296_7947127860492652402_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeGOJU3LIGrKW5uTgiH1Euf80-U8IW-1FbXT5Twhb7UVtVVa_nhvg_yUn2x2P4tQyBi7iLFmwSoSBPiZNbaqb_gq&_nc_ohc=wmNSwXOFQZsAX_CY7Uq&_nc_ht=scontent.fmnl17-1.fna&oh=00_AfCatGERp951GJrAJdSx90xUnw-JLo0MWJIwVL5n68YhwQ&oe=637949D8`

const styles = {
    productTextStrong: {
        fontWeight: ConstantStyles['bold'],
        color: ConstantStyles['purple']
    },
    imagepPrev: {
        borderRadius: '30px'
    },
    card: {
        borderRadius: '30px',
        position: 'relative'
    }
}

const Card = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: window.innerWidth <= 640 ? theme.spacing(1) : '15px'
}));


const ClassicCard = (props) => {

    const [productDetails, setProductDetails] = useState(props?.item || {});
    let isMobile = window.innerWidth <= 640
    const [viewProduct, setViewProduct] = useState({});
    let toggleSide = isMobile ? "bottom" : "right"
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const image_url = productDetails.image_src ? process.env.REACT_APP_HOST + productDetails.image_src : defaultImage


    //FUNCTIONS
    const toggleDrawer = (anchor, open, item, event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
        if (item)
            setViewProduct(item)
    }

    return (
        <React.Fragment>
            <Card className="itemCard" style={styles.card}>
                <Grid
                    container
                    item
                    columnSpacing={window.innerWidth <= 640 ? 1 : 6}
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                // onClick={(event) => {
                //     toggleDrawer(toggleSide, true, props?.item, event)
                //     window.gtag("event", "view_item", {
                //         currency: "PHP",
                //         value: props?.item.price,
                //         items: [
                //             {
                //                 item_id: props?.item.product_id,
                //                 item_name: props?.item.name,
                //                 currency: "PHP",
                //                 index: props?.item.product_id,
                //                 item_category: props?.item.category[0],
                //                 item_category2: props?.item.category[1],
                //                 item_category3: props?.item.category[2],
                //                 item_category4: props?.item.category[3],
                //                 item_category5: props?.item.category[4],
                //                 price: props?.item.price,
                //                 quantity: 1
                //             }
                //         ]
                //     });
                // }}
                >
                    <Grid
                        item
                        xs={gridSize[props.viewType].image.sm}
                        sm={gridSize[props.viewType].image.sm}
                        md={gridSize[props.viewType].image.md}
                        lg={gridSize[props.viewType].image.lg}
                    >
                        <div className='imageContainer'>
                            <img
                                alt={`product-${props.index}`}
                                src={image_url}
                                width="100%"
                                style={styles.imagepPrev}
                            />
                        </div>
                    </Grid>
                    <Grid item
                        xs={gridSize[props.viewType].details.sm}
                        sm={gridSize[props.viewType].details.sm}
                        md={gridSize[props.viewType].details.md}
                        lg={gridSize[props.viewType].details.lg}
                    >
                        <div className='itemDetails'>
                            <Typography variant="h5" style={styles.productTextStrong} className='itemName'>{productDetails?.name}</Typography>
                            {
                                productDetails?.inCart &&
                                <IconButton
                                    sx={{ fontSize: '16px', padding: '0', border: '1px solid', fontWeight: 'bold', border: 'unset', color: ConstantStyles['purple'], width: '27px', height: '27px', position: 'absolute', top: '15px', right: '15px', ':hover': { background: 'unset' } }}
                                    variant="text"
                                >
                                    x{productDetails?.inCartQty}
                                </IconButton  >
                            }
                            <Typography
                                variant="body1"
                                display={
                                    props.viewType === "recommended" ?
                                        { xs: "none", lg: "block" }
                                        :
                                        {}
                                }
                                style={{ lineHeight: "1", width: '173px' }}
                                className='itemDescription mb-0'
                            >
                                {_.get(productDetails, ['description'], '').length >= 57 ? `${productDetails.description.substring(0, 57)}...` : productDetails.description}
                            </Typography>
                            <Typography variant="body1" style={styles.productTextStrong} className="itemPrice">PHP {(productDetails?.price?.toFixed(2))?.toLocaleString()}</Typography>
                            <IconButton
                                sx={{ width: '24px', height: '24px', marginBlock: '3px', position: 'absolute', bottom: '15px', right: '15px', ':hover': { background: 'unset' } }}
                                variant="text"
                            >
                                <a href='https://order.dailybyboscoffee.com/page/dailybyboscoffee/index.html'>                                    
                                <img alt='add_icon' src={AddIcon} style={{ width: '30px', height: '30px' }} title='Order Item' />
                                </a>
                            </IconButton  >
                        </div>
                    </Grid>
                </Grid>
                {/* <IconButton
                    sx={{ width: '24px', height: '24px', marginBlock: '3px', position: 'absolute', bottom: '15px', right: '46px', ':hover': { background: 'unset' } }}
                    variant="text"
                >
                    <a href='https://order.dailybyboscoffee.com/page/dailybyboscoffee/index.html'>
                        <img alt='add_icon' src={AddIcon} style={{ width: '30px', height: '30px' }} title='Grab food' />
                    </a>
                    
                </IconButton  >
                {/* <IconButton
                    sx={{ width: '24px', height: '24px', marginBlock: '3px', position: 'absolute', bottom: '15px', right: '17px', ':hover': { background: 'unset' } }}
                    variant="text"
                >
                    <a href='https://www.foodpanda.ph/chain/cw6ug/bos-coffee-daily-1'>
                        <img alt='add_icon' src={foodPanda} style={{ width: '35px', height: '30px' }} title='food Panda' />
                    </a>
                </IconButton  > */}
            </Card>
            <SwipeableDrawer
                sx={{
                    height: window.innerHeight,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        height: window.innerHeight,
                        boxSizing: 'border-box',
                    },
                }}
                disableBackdropTransition={true}
                anchor={toggleSide}
                open={state[toggleSide]}
                onClose={() => toggleDrawer(toggleSide, false)}
                onOpen={() => toggleDrawer(toggleSide, true)}
            >
                <ProductView item={viewProduct} index={1} state={state} setstate={setState} />
            </SwipeableDrawer>
        </React.Fragment>
    );


}


export default ClassicCard;