// react
import React, { useEffect, useState } from 'react';

import { useState as useHookState } from "@hookstate/core";
import { connect, useDispatch } from "react-redux";

//Global States, call funtion
import ConstantStyles from "../../utility/ConstantStyle.json";


//mui components
import Box from '@mui/material/Box';

import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

import { searchProduct } from '../actions';

const SearchBoxMobile = () => {
    const [search, setSearch] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        const timeOutId = setTimeout(() => dispatch(searchProduct(search)), 800);
        return () => clearTimeout(timeOutId)
    }, [search]);

    const handleSearch = (e) => {
        const value = e.target.value
        setSearch(value)
    }
    return (
        <Box
            sx={{
                p: '2px 4px',
                margin: 'auto',
                display: 'flex',
                alignItems: 'center',
                width: '95%',
                border: `2px solid ${ConstantStyles['purple']}`,
                borderRadius: 5,
                height: '45px',
                zIndex: 2
            }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1, color: ConstantStyles['purple'] }}
                placeholder="Search"
                value={search}
                onChange={handleSearch}
            />
            <SearchIcon sx={{ color: ConstantStyles['purple'] }} />
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.CartList,
        store: state.store.selectedStore
    }
}


export default connect(mapStateToProps)(SearchBoxMobile);