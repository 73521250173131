import { useState as useHookState } from "@hookstate/core";
import Card from '@mui/material/Card';
import { Box } from '@mui/system';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ConstantStyles from '../../../utility/ConstantStyle.json';
import GlobalStates from "../../../utility/GlobalStates";
import { selectedTime } from '../../actions';
import { TimeList } from './TimeList';

const styles = {
    selected: {
        p: 0.8,
        m: 0.5,
        border: `2px solid ${ConstantStyles['purple']}`,
        cursor: 'pointer',
        borderRadius: '20px'
    },
    card: {
        p: 0.8,
        m: 0.5,
        height: '35px',
        cursor: 'pointer',
        borderRadius: '20px'
    },
    label: {
        fontSize: '20px'
    }
}


const Time = (props) => {
    const { deliveryTime, currentvalue } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    let currentTimezone = momentTimezone().tz("Asia/Manila")
    const currentTimeSplit = moment(currentTimezone).format('HH:mm').split(":")
    const currentTimeValue = parseInt(currentTimeSplit[0])
    const [selected, setSelected] = useState()
    const [timeList, setTimeList] = useState([])
    const dispatch = useDispatch()


    useEffect(() => {

        const scheduleTime = TimeList.filter((list) => list.value > currentTimeValue)
        setTimeList(scheduleTime)
        setSelected(currentTimeValue + 1)
        dispatch(selectedTime(scheduleTime[0]))

    }, [])

    useEffect(() => {
        setSelected(deliveryTime.value)
    }, [deliveryTime])

    const onClickCard = (list) => {
        dispatch(selectedTime(list))
    }

    return (
        <React.Fragment>
            <Box sx={{ p: 2, margin: 'auto', justifyContent: 'center', display: 'grid', gridTemplateColumns: globalStates.isMobile.get() ? 'repeat(2,minmax(0,1fr))' : 'repeat(3,minmax(0,1fr))', gap: '2px' }}>
                {timeList.map((list, index) => {
                    return (
                        <Card key={index} sx={selected === list.value ? styles.selected : styles.card} onClick={() => onClickCard(list)}>
                            <span>{list.label}</span>
                        </Card>
                    )
                })}
            </Box>
        </React.Fragment >
    )

}

const mapStateToProps = (state) => {
    return {
        deliveryTime: state.checkout.deliveryTime
    }
}

export default connect(mapStateToProps)(Time);