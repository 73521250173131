/*******************************************************************************************
//  * File Name                : Login
//  * File Directory           : src/components/modules/Login
//  * Module                   : Login
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Login
//  * Used In				   : Login 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Login        -   User Login
 * #How to call:
 *    <Login/>
 *
 *******************************************************************************************/
// react library
import { useState as useHookState } from "@hookstate/core";
import { useState } from "react";

//api endpoints
import api from "../../../utility/Endpoints";

//Global States, call funtion
import ConstantStyles from "../../../utility/ConstantStyle.json";
import GlobalStates from "../../../utility/GlobalStates";

// mui components
//material ui library/components
import TextField from '@mui/material/TextField';


import CryptoJS from 'crypto-js';
//import from general folder

//style starts here
const styles = {
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"],
        borderRadius: '50%'
    }
}

const Login = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    //useStates
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const SignIn = () => {
        const hashedPassword = CryptoJS.SHA512(password).toString(CryptoJS.enc.Hex);
    
        let body = { email: email, password: hashedPassword };
        api.login.post(body) // function from endpoint
            .then(response => {
                globalStates.isLoading.set(true);
                localStorage.setItem('isSignedIn', true);
                localStorage.setItem('userInfo', (response.data.user ?? null) === null ? null : JSON.stringify(response.data.user));
                window.location.href = "/admin-backend/list-of-contents";
            })
            .catch(error => {
                console.log(error);
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set("Error: Failed to log in, please try again later.");
            })
            .finally(() => {
                setTimeout(() => {
                    globalStates.openAlert.set(false);
                    globalStates.isLoading.set(true); // loading
                }, 3000);
            });
    }

    return (
        <div className="login">
            <div className="login-container">
                <div className="login-form">
                    <p style={{ fontSize: "26px", fontWeight: 700, textAlign: "center" }}>Daily's Admin Account</p>
                    <p style={{ fontSize: "16px", marginBottom: "5px" }}>Email</p>
                    <TextField
                        fullWidth
                        id="fullWidth"
                        size="small"
                        sx={{ width: '100%' }}
                        defaultValue={email}
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />
                    <p style={{ fontSize: "16px", marginBottom: "5px" }}>Password</p>
                    <TextField
                        fullWidth
                        id="fullWidth"
                        size="small"
                        sx={{ width: '100%' }}
                        defaultValue={password}
                        value={password}
                        type="password"
                        onChange={(e) => {
                            setPassword(e.target.value)
                        }}
                    />

                    <input type="button" value="Login" className="btn float-end mt-2" style={styles.btnPurple} onClick={SignIn} />
                </div>
            </div>
        </div>
    );
}

export default Login;