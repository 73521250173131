import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useHookstate } from '@hookstate/core';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// MUI icons
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

// utility
import GlobalStates from '../../../../utility/GlobalStates';
import api from '../../../../utility/Endpoints';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    }
};

const EditModal = (props) => {
    const globalStates = useHookstate(GlobalStates) // globalstate variable
    const { refresh, users, id, data } = props // data from parent component 

    const [open, setOpen] = useState(false)  // variable for modal
    const [duplicate, setDuplicate] = useState('')
    const [btnLoad, setBtnLoad] = useState(false)
    const [level, setLevel] = useState(props.data.user_level);

    useEffect(() => {
        setLevel(data.user_level)
    }, [data, open])
    
    
    const handleClose = () => setOpen(false) // close modal
    const handleOpen = () => setOpen(true); // open modal
    const handleChange = (event) => {
        const selected = event.target.value
        setLevel(selected);
      };

    const handleSubmit = (e) => {
        e.preventDefault()
        setBtnLoad(true)

        const body = {
            id: data.id,
            first_name: e.target.first_name.value,
            last_name: e.target.last_name.value,
            email: e.target.email.value,
            password: e.target.password.value,
            old: data.password,
            user_level: e.target.user_level.value,
            status: data.status
        }

        if (duplicate === '') {
            // run api
            api.user.update(body)
            .then((res) => {
                setBtnLoad(false)
                handleClose()
                refresh()
            })
            .catch((error) => {
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
                setInterval(() => {
                    globalStates.openAlert.set(false)
                }, 2000)
            })
        } else {
            setBtnLoad(false)
        }
    }

    const onChangeEmail = (e) => { // onChange text branch
        e.preventDefault()

        const typed = e.target.value

        // filter duplicate branch
        const filterUser = users.filter( user => user.email === typed && user.email !== data.email)
        if (filterUser.length > 0) {
            setDuplicate("Email already exist")
        }else{
            setDuplicate('')
        }
    }
    return (
        <div>
            <LoadingButton 
                size="small"
                color="success"
                id={id}
                loadingPosition="start"
                onClick={handleOpen}
                startIcon={<EditIcon />}
                variant="outlined"
                >
                Edit
            </LoadingButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{padding: 0, margin: 0}}
            >   
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{margin: 1.5}}>
                        Update User
                    </Typography>
                    <hr style={{marginTop: 1, marginBottom: 1}}/>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{padding: 2}} >
                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        First Name:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='first_name' size='small' defaultValue={data.first_name} label="First Name" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        Last Name:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='last_name' size='small' defaultValue={data.last_name} label="Last Name" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        Email:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField type='email' onChange={onChangeEmail} name='email' size='small' defaultValue={data.email} label="Email" sx={{ width: '100%'}} variant="outlined" required/>
                                    <FormHelperText sx={{display : duplicate ? '': 'none', color: 'red'}}>{duplicate}</FormHelperText>
                                
                                </Box>
                            </Box>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        Password:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField type='password' name='password' size='small' defaultValue={data.password} label="Password" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>

                            <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: 2}}>
                                <Box>
                                    <Typography>
                                        User Level:
                                    </Typography>
                                </Box>
                                <Box width={"60%"} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <FormControl sx={{ minWidth: 120, width: '100%' }}  size="small">
                                        <InputLabel id="demo-simple-select-helper-label">User level *</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={level}
                                        label="User level *"
                                        name='user_level'
                                        onChange={handleChange}
                                        required
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                            <MenuItem value={'Super Admin'}>Super Admin</MenuItem>
                                            <MenuItem value={'Hub Officer'}>Hub Officer</MenuItem>
                                            <MenuItem value={'Sales and Marketing'}>Sales and Marketing</MenuItem>
                                        </Select>
                                        <FormHelperText></FormHelperText>
                                    </FormControl>
                                </Box>
                            </Box>

                        </Box>


                        <Box sx={{width: '100%'}}>
                            <hr style={{marginTop: 1, marginBottom: 1}}/>
                            <Box sx={{display: 'flex', justifyContent:'flex-end', padding: 1.5}}>
                                <LoadingButton
                                    onClick={() => handleClose()} 
                                    sx={{marginRight: 1}} 
                                    size="small"
                                    loadingPosition="start"
                                    disabled={btnLoad}
                                    startIcon={<CloseIcon /> }
                                    >
                                    Close
                                </LoadingButton>
                                <LoadingButton
                                    size="small"
                                    type="submit"
                                    loadingPosition="start"
                                    loading={btnLoad}
                                    variant="contained" 
                                    startIcon={<SaveIcon /> }
                                    >
                                    Save
                                </LoadingButton>
                            </Box>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

EditModal.propTypes = {
    messages:PropTypes.object,
    run:PropTypes.func,
    btnLoad:PropTypes.bool,
};
EditModal.defaultProps = {
    messages:{},
    run: undefined,
    btnLoad: false,
};

export default EditModal