// react library
import { useState as useHookState } from "@hookstate/core";
import React, { useState } from "react";

//api endpoints
import api from "../../../utility/Endpoints";

//Global States, call funtion
import ConstantStyles from "../../../utility/ConstantStyle.json";
import GlobalStates from "../../../utility/GlobalStates";

import './index.scss';

// mui components
//material ui library/components
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';

//import from general folder
import FormattedField from './FormattedField';
import InputField from './InputField';
import RadioOption from './RadioOption';
//style starts here
const styles = {
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"],
        borderRadius: "25px"
    },
    fieldLabel: {
        color: ConstantStyles["purple"]
    },
}
const defaultFields = {
    "firstName": {
        field: "First Name",
        type: "text",
        errorMsg: "Please enter your First Name"
    },
    "lastName": {
        field: "Last Name",
        type: "text",
        errorMsg: "Please enter your Last Name"
    },
    "birthDate": {
        field: "Birthdate",
        type: "date",
        errorMsg: "Please enter a valid date"
    },
    "emailAddress": {
        field: "Email Address",
        type: "email",
        errorMsg: "Please enter your Email",
        emailExistMsg: "Email already exist"
    },
    "phoneNumber": {
        field: "Phone Number",
        type: "text",
        errorMsg: "Format Should be (+63)XXX XXX XXXX",
        formatted: true

    },
    "password": {
        field: "Password",
        type: "password",
        errorMsg: "Password should be atleast 8 characters"
    },
    "confirmPassword": {
        field: "Confirm Password",
        type: "password",
        errorMsg: "Password do not match"
    },
    "gender": {
        field: "Gender",
        type: "radio",
        errorMsg: "Please choose a gender"
    },
}
const newsLetter = "Be the first to know about exclusive deals and discounts! Tick the box to subscribe to our email newsletter."

const termsAndAgreement = "By continuing, you agree to Daily's Terms of Service, Privacy Policy when a customer register through their website."
const Register = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    // field variables
    const [fields, setFields] = useState(defaultFields)
    const [newsLetterField, setNewsLetterField] = useState({
        value: true
    })
    const [submitSignUpBtn, setSubmitSignUpBtn] = useState(false);
    const [errorTriggered, setErrorTriggered] = useState(false)
    const fieldList = Object.keys(fields)

    const clickCheckbox = (e, field) => {
        setNewsLetterField({
            value: e.target.checked,
            error: false
        })
    }

    const SignUp = () => {
        // console.table(fields)
        // if(emptyError)
        let errCount = 0;
        let cloneField = fields
        fieldList.forEach((row, index) => {
            // console.table(fields[row])
            // console.log(fields[row].error)
            if (fields[row] && fields[row].error === false) {

            } else {
                console.log("ERROR")
                errCount = 1
                cloneField[row].error = true
            }
        })
        setFields(cloneField)
        if (errCount === 0) {
            setSubmitSignUpBtn(true)
            console.log(fields)
            const cloneHolder = JSON.parse(JSON.stringify(fields))
            const body = {
                ...cloneHolder,
                newsLetterField
            }

            fieldList.forEach((row, index) => {
                delete body[row].error
                delete body[row].field
                delete body[row].type
                delete body[row].errorMsg
                delete body[row].formatted
            })
            //api submit
            api.customerSignup.post(body) // function from endpoint
                .then(response => {
                    console.log(response.data.customer_id)
                    globalStates.isLoading.set(true);
                    let data = {
                        customer_id: response.data.customer_id,
                        email_address: fields.emailAddress['value'],
                        first_name: fields.firstName['value'],
                        last_name: fields.lastName['value'],
                        birth_date: fields.birthDate['value'],
                        phone_number: fields.phoneNumber['value'],
                        gender: fields.gender['value']
                    }
                    localStorage.setItem('customerSignedIn', true);
                    localStorage.setItem('guestSignIn', false);
                    localStorage.setItem('customerInfo', (data ?? null) === null ? null : JSON.stringify(data));
                    window.location.href = window.location.pathname;


                    // google analytics
                    window.gtag("event", "sign_up", {
                        method: "Google"
                    });

                })
                .catch(error => {
                    console.log(error.response?.data?.code);
                    if (error.response?.data?.code === "ER_DUP_ENTRY") {
                        fields['emailAddress'].emailExist = true
                        setErrorTriggered(!errorTriggered)

                    }
                    // globalStates.openAlert.set(true);
                    // globalStates.statusAlert.set("error");
                    // globalStates.msgAlert.set("Error: Failed to log in, please try again later.");
                })
                .finally(() => {
                    // setTimeout(() => {
                    //     globalStates.openAlert.set(false);
                    // globalStates.isLoading.set(true); // loading
                    // }, 3000);
                });
        } else {
            setErrorTriggered(!errorTriggered)
        }
    }

    return (
        <Container style={{ padding: '10px' }}>

            <p style={{ fontSize: "26px", fontWeight: 700, textAlign: "center" }}>Create Account </p>
            <Box sx={{ width: '100%' }}>
                <Grid container item md={12} lg={12} className="register-container" spacing={1} >
                    {
                        fieldList.map((row, index) =>
                        (<React.Fragment>
                            <Grid
                                key={`col1-${index}`}
                                item
                                xs={4}
                                columnSpacing={4}
                                sx={styles.fieldLabel}
                            >
                                {fields[row].field}
                            </Grid>
                            <Grid
                                key={`col2-${index}`}
                                item
                                xs={8}
                            >
                                {
                                    fields[row].type === "radio" ?
                                        // RADIO FIELD
                                        <RadioOption
                                            row={row}
                                            field={fields[row]}
                                            fieldsValue={fields}
                                            setFieldsValue={setFields}
                                            type={fields[row].type}
                                            fwidth={true}
                                        />
                                        :
                                        // INPUT FIELDS EITHER FORMATTED OR NON - FORMATTED INPUT
                                        fields[row].formatted ?
                                            <FormattedField
                                                row={row}
                                                field={fields[row]}
                                                type={fields[row].type}
                                                fieldsValue={fields}
                                                errorTriggered={errorTriggered}
                                                setFieldsValue={setFields}
                                                fwidth={true}
                                            />
                                            :
                                            <InputField
                                                row={row}
                                                field={fields[row]}
                                                fieldsList={fields}
                                                setFields={setFields}
                                                fwidth={true}
                                            />
                                }
                            </Grid>
                        </React.Fragment>)
                        )
                    }
                    <Grid
                        key={`newsletter`}
                        item
                        xs={12}
                    >
                        <FormControlLabel control={<Checkbox defaultChecked onChange={(e) => clickCheckbox(e, 'newsLetter')} />} label={newsLetter} />
                    </Grid>
                    <Grid
                        key={`termsAndAgreement`}
                        item
                        xs={12}
                    >
                        <FormControlLabel control={<Checkbox defaultChecked disabled />} label={termsAndAgreement} />

                    </Grid>
                </Grid>
            </Box>


            <input type="button"
                value="Register"
                className="btn float-end mt-2"
                style={styles.btnPurple}
                onClick={SignUp}
            // disabled={submitSignUpBtn}   
            />
        </Container>
    );
}

export default Register;