import { combineReducers } from "redux";
import { persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import AddressReducer from "./AddressReducer";
import CartReducer from './CartReducer';
import CategoryReducer from "./CategoryReducer";
import CheckoutReducer from "./CheckoutReducer";
import OrderListReducer from "./OrderListReducer";
import OrderReducer from "./OrderReducer";
import productList from "./ProductListReducer";
import ProductsReducer from "./ProductsReducer";
import RecommendedReducer from "./RecommendedReducer";
import SelectedStoreReducer from "./SelectedStoreReducer";
import UpdateReducer from "./UpdateReducer";
import SearchReducer from "./SearchReducer";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'cart',
    'products',
    'category',
    'store',
    'order',
    'orders',
    'recommended'
  ],
  transform : [
    encryptTransform({
      secretKey : 'root',
      onError : function(error) {
        console.log(error)
      }
    })
  ]
}

const reducers = combineReducers({
  cart: CartReducer,
  products: ProductsReducer,
  recommended: RecommendedReducer,
  fetch : productList,
  category: CategoryReducer,
  store: SelectedStoreReducer,
  address: AddressReducer,
  update: UpdateReducer,
  checkout : CheckoutReducer,
  order : OrderReducer,
  orders : OrderListReducer,
  search: SearchReducer
});

export default persistReducer(persistConfig, reducers);
