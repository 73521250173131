/*******************************************************************************************
//  * File Name                : Section
//  * File Directory           : src/components/general/Section
//  * Module                   : Section
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Section
//  * Used In				   : Home 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Section        -   Section for displaying / fetching data from database
 * #How to call:
 *    <Section/>
 *
 *******************************************************************************************/
// react library
import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from 'react';

//api endpoints
import api from "../../utility/Endpoints";

//Global States, call funtion
import ConstantStyles from "../../utility/ConstantStyle.json";
import GlobalStates from "../../utility/GlobalStates";

//logo endpoints
import DailyLogo from "../../assets/logo/daily-logo.png";

// mui components
//material ui library/components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';

const Section = (props) => {
    const { show, hide } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    //useStates
    const [list, setList] = useState([]);
    const [renderedList, setRenderedList] = useState([]);
    const [renderContent, setRenderContent] = useState([]);
    // const [renderSection, setRenderSection] = useState([]);
    let cardSize = globalStates.isMobile.get() ? 12 : 6;
    const [screen, setScreen] = useState([window.innerWidth]);
    let isMobile = screen <= 767

    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getSection(); // call function
    }, []);

    useEffect(() => {
        if (show) {
            let cloneList = JSON.parse(JSON.stringify(list))
            let newList = cloneList.filter((item, index) => {
                // console.log(index,' - ', show.includes(index))
                return show.includes(index)
            })
            setRenderContent(newList)
            // setRendered(newList)
        }
        if (hide) {
            let cloneList = JSON.parse(JSON.stringify(list))

            let listIndex = cloneList.filter((item, index) => {
                return !hide.includes(item.index)
            })
            // let newList = cloneList
            // newList.splice(listIndex, 1)
            // setRendered(newList)
            setRenderContent(listIndex)
        }
    }, [show, hide, list])

    const getSection = () => {
        api.sections.getHome() // function from endpoint
            .then(response => {
                globalStates.isLoading.set(true); // loading, false
                let result = response.data; // get result from api
                let contents = result.map((col, index) => {
                    return {
                        index: index,
                        section_id: col.section_id ?? "-",
                        title: col.title ?? "-",
                        type: col.type ?? "-",
                        sort: col.sort ?? "-",
                        status: col.status ?? "-",
                        content_id: col.content_id ?? null,
                        sectionId: col.sectionId ?? "-",
                        text: col.text,
                        attribute: col.attribute,
                        image: col.image,
                        button: col.button
                    }
                });
                setList(contents)
            })
            .catch(error => {
                console.log(error);
            })
    }
    const renderSection = renderContent.map((item, index) => {
        let attributes = JSON.parse(item.attribute);
        // console.log(attributes)
        let images = JSON.parse(item.image);
        let buttons = JSON.parse(item.button);
        let image_position = images.imagePlace === "Left/Bottom" ? 1 : 2; // image position 1= left, 2 = right
        let text_position = image_position === 2 ? 1 : 2; // image position 1= left, 2 = right
        let inputBgColor = attributes.background === "" ? ConstantStyles['purple'] : attributes.background; // image position 1= left, 2 = right

        let radiusbottom = image_position === 2 ? "bottomRadiusZero" : ""; // radius if image postion = 2, bottom left and right radius assign to 0px
        let radiusTop = image_position === 1 ? "topRadiusZero" : "    "; // radius if image postion = 2, bottom left and right radius assign to 0px

        setTimeout(() => {
            let element = document.getElementById("preview_" + item.content_id)
            if (element)
                element.innerHTML = item.text // show string to html
        }, 200);

        return (
            <React.Fragment key={index}>
                {item.type === "Single" ?
                    < Grid container item xs={12} md={12} lg={12} spacing={0} style={{ margin: "1rem 0.5rem", backgroundColor: inputBgColor, borderRadius: attributes.inputRadius, height: attributes.heightSection }}>
                        <Grid item xs={12} md={12} lg={6} order={isMobile ? {} : { xs: text_position, md: text_position, lg: text_position }}>
                            <div className="preview_img">
                                <img
                                    className={`${isMobile ? 'bottomRadiusZero' : ''}`}
                                    src={process.env.REACT_APP_HOST + images.imageUpload}
                                    style={{ borderRadius: attributes.inputRadius, height: attributes.heightSection, minHeight: '272px', }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} order={{ xs: image_position, md: image_position, lg: image_position }}>
                            <div className="textPrev" style={isMobile ? { transform: 'scale(0.85)' } : { padding: "30px" }} >
                                <div id={`preview_${item.content_id}`} className={`${attributes.fontSection}`}>
                                </div>
                                {attributes.showBtns === "Show" ?
                                    <Button
                                        style={{
                                            borderColor: buttons.btnBorder,
                                            backgroundColor: buttons.btnBackground,
                                            color: buttons.btnColor,
                                            borderRadius: buttons.btnRadius,
                                            margin: "10px auto",
                                            width: isMobile ? "60%" : "30%",
                                            display: "block",
                                            textAlign: "center",
                                            ':hover': {
                                                border: ConstantStyles['default-border'], // theme.palette.primary.main
                                                color: 'white',
                                            },
                                        }}
                                        variant="outlined"
                                        href={buttons.btnLink}
                                        className={`${buttons.btnFont}`}
                                    >
                                        {buttons.btnTitle}
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                        </Grid>

                    </Grid>
                    :
                    <Grid item xs={cardSize} className={`${attributes.fontSection}`} style={{ margin: "1rem 0", padding: "0.5rem" }}  >
                        <Card style={{ background: inputBgColor, borderRadius: attributes.inputRadius }} >
                            <CardMedia
                                component="img"
                                alt="Daily Logo"
                                height="100%"
                                sx={{ minHeight: '272px' }}
                                image={ process.env.REACT_APP_HOST +images.imageUpload || DailyLogo}
                            />
                            <CardContent >
                                <div id={`preview_${item.content_id}`} style={{ padding: "30px" }}>
                                </div>
                                {attributes.showBtns === "Show" ?
                                    <Button
                                        style={{
                                            borderColor: buttons.btnBorder,
                                            backgroundColor: buttons.btnBackground,
                                            color: buttons.btnColor,
                                            borderRadius: buttons.btnRadius,
                                            margin: "10px auto",
                                            width: "30%",
                                            display: "block",
                                            textAlign: "center",
                                            ':hover': {
                                                border: ConstantStyles['default-border'], // theme.palette.primary.main
                                                color: 'white',
                                            },
                                        }}
                                        variant="outlined"
                                        href={buttons.btnLink}
                                        className={`${buttons.btnFont}`}
                                    >
                                        {buttons.btnTitle}
                                    </Button>
                                    :
                                    null
                                }
                            </CardContent>
                        </Card>
                    </Grid>
                }
            </React.Fragment >
        );

    });
    // setRenderSection(renderSection)
    // const renderContents = (contents) => {
    //     console.log(contents)
    //     //render, this can be a sharaeable component
    //     const renderSection = renderContent.map((item, index) => {
    //         let attributes = JSON.parse(list[index].attribute);
    //         // console.log(attributes)
    //         let images = JSON.parse(list[index].image);
    //         let buttons = JSON.parse(list[index].button);

    //         let image_position = images.imagePlace === "Left/Bottom" ? 1 : 2; // image position 1= left, 2 = right
    //         let text_position = image_position === 2 ? 1 : 2; // image position 1= left, 2 = right
    //         let inputBgColor = attributes.background === "" ? ConstantStyles['purple'] : attributes.background; // image position 1= left, 2 = right

    //         let radiusbottom = image_position === 2 ? "bottomRadiusZero topRadiusZero" : ""; // radius if image postion = 2, bottom left and right radius assign to 0px
    //         let radiusTop = image_position === 1 ? "" : "bottomRadiusZero topRadiusZero "; // radius if image postion = 2, bottom left and right radius assign to 0px

    //         setTimeout(() => {
    //             let element = document.getElementById("preview_" + item.content_id)
    //             if(element)
    //                 element.innerHTML = item.text // show string to html
    //         }, 200);

    //         return (
    //             <React.Fragment key={index}>
    //                 {item.type === "Single" ?
    //                     < Grid container item xs={12} md={12} lg={12} spacing={0} style={{ margin: "1rem 0.5rem", backgroundColor: inputBgColor, borderRadius: attributes.inputRadius, height: attributes.heightSection }}>
    //                         <Grid item xs={12} md={12} lg={6} order={{ xs: image_position, md: image_position, lg: image_position }}>
    //                             <div className="textPrev">
    //                                 <div id={`preview_${item.content_id}`} className={`${attributes.fontSection}`}>
    //                                 </div>
    //                                 {attributes.showBtns === "Show" ?
    //                                     <Button
    //                                         style={{
    //                                             borderColor: buttons.btnBorder,
    //                                             backgroundColor: buttons.btnBackground,
    //                                             color: buttons.btnColor,
    //                                             borderRadius: buttons.btnRadius,
    //                                             margin: "10px auto",
    //                                             width: "30%",
    //                                             display: "block",
    //                                             textAlign: "center",
    //                                             ':hover': {
    //                                                 border: ConstantStyles['default-border'], // theme.palette.primary.main
    //                                                 color: 'white',
    //                                             },
    //                                         }}
    //                                         variant="outlined"
    //                                         href={buttons.btnLink}
    //                                         className={`${buttons.btnFont}`}
    //                                     >
    //                                         {buttons.btnTitle}
    //                                     </Button>
    //                                     :
    //                                     null
    //                                 }
    //                             </div>

    //                         </Grid>
    //                         <Grid item xs={12} md={12} lg={6} order={{ xs: text_position, md: text_position, lg: text_position }}>
    //                             <div className="preview_img">
    //                                 <img
    //                                     className={`${radiusTop} ${radiusbottom}`}
    //                                     src={images.imageUpload}
    //                                     style={{ borderRadius: attributes.inputRadius, height: attributes.heightSection, minHeight: '272px' }}
    //                                 />
    //                             </div>
    //                         </Grid>
    //                     </Grid>
    //                     :
    //                     <Grid item xs={cardSize} className={`${attributes.fontSection}`} style={{margin: "1rem 0", padding: "0.5rem" }}  >
    //                         <Card style={{ background: inputBgColor, borderRadius: attributes.inputRadius }} >
    //                             <CardMedia
    //                                 component="img"
    //                                 alt="Daily Logo"
    //                                 height="100%"
    //                                 sx={{minHeight: '272px'}}
    //                                 image={images.imageUpload || DailyLogo}
    //                             />
    //                             <CardContent >
    //                                 <div id={`preview_${item.content_id}`}>
    //                                 </div>
    //                                 {attributes.showBtns === "Show" ?
    //                                     <Button
    //                                         style={{
    //                                             borderColor: buttons.btnBorder,
    //                                             backgroundColor: buttons.btnBackground,
    //                                             color: buttons.btnColor,
    //                                             borderRadius: buttons.btnRadius,
    //                                             margin: "10px auto",
    //                                             width: "30%",
    //                                             display: "block",
    //                                             textAlign: "center",
    //                                             ':hover': {
    //                                                 border: ConstantStyles['default-border'], // theme.palette.primary.main
    //                                                 color: 'white',
    //                                             },
    //                                         }}
    //                                         variant="outlined"
    //                                         href={buttons.btnLink}
    //                                         className={`${buttons.btnFont}`}
    //                                     >
    //                                         {buttons.btnTitle}
    //                                     </Button>
    //                                     :
    //                                     null
    //                                 }
    //                             </CardContent>
    //                         </Card>
    //                     </Grid>
    //                 }
    //             </React.Fragment >
    //         );

    //     });
    //     setRenderSection(renderSection)
    // }

    return (
        <Box width={'100%'} display={'block'}>
            <Grid
                container
                spacing={0}

            >
                {renderSection}
            </Grid >
        </Box >
    );
}

export default Section;