
import Paymongo from 'paymongo';
import React from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";

const style = {
    height: {
        height: '98vh',
        width: '100%'
    }
}

const NextAction = (props) => {
    const { order } = props
    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY);
    const history = useHistory()


    //detect customer response in 3DS
    window.addEventListener(
        'message',
        ev => {
            if (ev.data === '3DS-authentication-complete') {
                //get payment status
                const getPaymongoStatus = async () => {
                    const result = await paymongo.paymentIntents.retrieve(order.payment.id);
                    const status = result.data.attributes.status
                    if (status === 'succeeded') {
                        //You already received your customer's payment. You can show a success message from this condition.
                        history.push('/thank-you')
                    } else if (status === 'processing') {
                        //You need to requery the PaymentIntent after a second or two. This is a transitory status and should resolve to `succeeded` or `awaiting_payment_method` quickly.
                        setTimeout(async () => {
                            const result = await paymongo.paymentIntents.retrieve(order.payment.id);
                            const status = result.data.attributes.status
                            if (status === 'succeeded') {
                                history.push('/thank-you')
                            } else if (status === 'processing') {
                                //You need to requery the PaymentIntent after a second or two. This is a transitory status and should resolve to `succeeded` or `awaiting_payment_method` quickly.
                                setTimeout(async () => {
                                    const result = await paymongo.paymentIntents.retrieve(order.payment.id);
                                    const status = result.data.attributes.status
                                    if (status === 'succeeded') {
                                        history.push('/thank-you')
                                    }
                                }, 2000);
                            } else if (status === 'awaiting_payment_method') {
                                // The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message.
                                history.push('/payment-failed')
                            }

                        }, 2000);
                    } else if (status === 'awaiting_payment_method') {
                        // The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message.
                        history.push('/payment-failed')
                    }

                }
                getPaymongoStatus()
            }
        },
        false
    );


    return (
        <React.Fragment>
            <iframe title="paymongo" style={style.height} src={order.payment.redirect} />
        </React.Fragment>
    )

}
const mapStateToProps = (state) => {
    return {
        order: state.order,
    }
}


export default connect(mapStateToProps)(NextAction);