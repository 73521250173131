import React, { useEffect, useState } from 'react'
import { useHookstate } from '@hookstate/core';
import moment, { now } from "moment";

// MUI imports
import { Box } from '@mui/system'
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Button, FormControl, FormHelperText, Grid, MenuItem, Select, Typography } from '@mui/material'
import Link from '@mui/material/Link';
import LinearProgress from '@mui/material/LinearProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

import api from '../../../../utility/Endpoints'
import DateFilter from '../../../shared/DateFilter';
import GlobalStates from '../../../../utility/GlobalStates';
import { useDispatch } from 'react-redux';
import { orderList } from '../../../actions';
import ConfirmationModal from '../../../shared/ConfirmationModal';


const style = {
    DataGrid: {
        display: 'flex',
        height: "85vh",
        width: '100%',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            whiteSpace: 'normal',
            textOverflow: 'normal',
            lineHeight: '15px'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },

    },
}

const OrderList = () => {
    const globalStates = useHookstate(GlobalStates) 
    const [orders, setOrders] = useState([])
    const [status, setStatus] = useState('All')
    const [branch, setBranch] = useState('All')
    const [branchList, setBranchList] = useState([])
    const [btnLoad, setBtnLoad] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [selectedData, setSelectedData] = useState({}) // selected data for confirmation modal
    const [messages, setMessages] = useState({}) // messages for confirmation modal
    const [dateRange, setDateRange] = useState({
        startDate: moment()
            .clone()
            .subtract(1, "months")
            .startOf("month"),
        endDate: moment().clone().endOf("month"),
    }); // for date filter
    const user = JSON.parse(localStorage.getItem('userInfo'))
    const pathname = window.location.pathname
    const display = pathname.includes('all')
    const dispatch = useDispatch()

    useEffect(() => {
        fetchOrders()
        fetchBranch()
    }, [refresh, status, dateRange, branch])
    
    const branchFilter = (e) => {
        e.preventDefault()
        const filter = e.target.value
        // console.log(e);
        setBranch(filter)
    }
    const refreshData = () => setRefresh(!refresh)
    const loadOff = () => {
        globalStates.isLoading.set(false)
        setInterval(() => {
            globalStates.isLoading.set(false)
        }, 1000)
    }

    const fetchOrders = () => {
        globalStates.isLoading.set(true)

        let newStatus = status

        if (pathname.includes('For-payment')) {
            newStatus = 'For payment'
        }
        if (pathname.includes('To-fulfill')) {
            newStatus = 'To Fulfill'
        }
        if (pathname.includes('Ready-to-ship')) {
            newStatus = 'Ready to ship'
        }
        if (pathname.includes('Order-fulfilled')) {
            newStatus = 'Order fulfilled'
        }
        if (pathname.includes('Cancelled')) {
            newStatus = 'Cancelled'
        }
        let filters = {
            status: newStatus,
            branch: branch,
            dateRange: dateRange
        }


        api.fulfillment.getOrdersWithFilters(filters) // function from endpoint
        .then(response => {
            let result = response.data.result; // get result from api
            const orders = result?.map( item => {
                return {
                    id: item.order_id,
                    order_number: item.order_number,
                    branch: item.branch_name,
                    branch_id: item.branch_id,
                    abbreviation: item.abbreviation,
                    customer_info: JSON.parse(item.customer_info),
                    shipping_address: JSON.parse(item.shipping_address)[0],
                    shipping_fee: item.shipping_fee,
                    cart: JSON.parse(item.cart),
                    total_amount: item.total_amount,
                    discount_amount: item.discount_amount,
                    discount_name: item.discount_name,
                    payment_method: item.payment_method,
                    date_created: item.date_created,
                    date_schedule: item.date_schedule,
                    status: item.status,
                }
            })
            // .sort((a, b) => {
            //     let x = a.order_number.toLowerCase();
            //     let y = b.order_number.toLowerCase();
            //     if (x < y) {return -1;}
            //     if (x > y) {return 1;}
            //     return 0;
            // })
            
            setOrders(orders) 
            dispatch(orderList(orders))
            loadOff()
        })
        .catch(error => {
            console.log(error);
            globalStates.isLoading.set(false)
            globalStates.openAlert.set(true)
            globalStates.statusAlert.set('error')
            globalStates.msgAlert.set(error.message)
            setInterval(() => {
                globalStates.openAlert.set(false)
            }, 2000)
        })
    }

    const fetchBranch = () => {
        api.branch.getBranch() // function from endpoint
            .then(response => {
                let result = response.data.result; // get result from api
                const data = result?.map( item => {
                    const sched = item.time
                    return {
                        id: item.branch_id,
                        value: item.branch_id,
                        label: item.name,
                        name: item.name,
                        abbreviation: item.abbreviation,
                        address: item.address,
                        phone: item.phone,
                        admin: parseInt(item.admin),
                        owned_by_bos: parseInt(item.owned_by_bos),
                        latitude: item.latitude,
                        longitude: item.longitude,
                        monday: sched.monday,
                        tuesday: sched.tuesday,
                        wednesday: sched.wednesday,
                        thursday: sched.thursday,
                        friday: sched.friday,
                        saturday: sched.saturday,
                        sunday: sched.sunday,
                        status: parseInt(item.status),
                    }
                })
                setBranchList(data)
            })
            .catch(error => {
                console.log(error);
                loadOff()
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
            })
    }

    const alert = (props) => {
        const { open, severity, message } = props
        globalStates.openAlert.set(open)
        globalStates.statusAlert.set(severity)
        globalStates.msgAlert.set(message)
    }

    const cancelOrder = (e) => {
        e.preventDefault()
        globalStates.modalOpenConfirm.set(true)
        const id = e.target.dataset.id
        const status = e.target.name
        
        const msg = {
            title: "Cancel order ",
            message: `Are you sure you want to cancel this order: Order# ${id}`,
            // note: "note: When a category is disabled, all the products that belong to the category will not appear on the storefront.",
        }
        setMessages(msg)
        setSelectedData({
            order_number: id,
            status: "Cancelled",
            old_status: status,
            email: user[0]?.email || ""
        })
    }

    const isToRun = () => {
        api.fulfillment.updateStatus(selectedData)
        .then((response) => {
            setBtnLoad(false)
            globalStates.modalOpenConfirm.set(false)
            refreshData()
        })
        .catch((error) => {
            console.log(error);
            setBtnLoad(false)
            alert({
                open: true,
                severity: 'error',
                message: error.message
            })
        })
    }

    // setting up columns
    const columns = [
        {
            field: "id", // fetching from map
            hide: true
        },
        {
            field: "order_number",
            headerName: "Order #",
            flex: 0.3,
            minWidth: 100,
            renderCell: (params) => {
                const date_schedule = params.row.date_schedule?.split(" ")[0]
                const time_schedule = params.row.date_schedule?.split(" ")[4] + params.row.date_schedule?.split(" ")[5] 
                const scheduled_date = moment(date_schedule + ' ' + time_schedule, 'DD-MM-YYYY hh:mma').format("YYYY-MM-DD hh:mma")
                const current_date_time = moment().format("YYYY-MM-DD hh:mma")
                const order_number = params.row.order_number
                const origin = window.location.origin + '/admin-backend/fulfillment/'
                const late = scheduled_date < current_date_time
                
                return (
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center'}}>
                            <Link sx={{fontSize: '13px'}} href={`${origin}view/${order_number}`} underline="always">
                                {order_number}
                            </Link>
                        <Typography align='center' sx={{display: late ? '' : 'none', fontSize: '13px', backgroundColor: 'red', width: '100%', borderRadius: '5px', color: 'white'}}>
                            LATE
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: "customer_info",
            hide: true
        },
        {
            field: "shipping_address",
            hide: true
        },
        {
            field: "cart",
            hide: true
        },
        {
            headerName: "Name",
            flex: 0.3,
            minWidth: 200,
            renderCell: (params) => {
                const customer_info = params.row.customer_info
                const shipping_address = params.row.shipping_address
                return (
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography sx={{fontSize: '13px', fontWeight: 'bold'}}>
                            {customer_info?.first_name} {customer_info?.last_name}
                        </Typography>
                        <Typography sx={{fontSize: '11px'}}>
                            {shipping_address?.street} {shipping_address?.brgy} {shipping_address?.city}  
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: "contact",
            headerName: "Phone #",
            flex: 0.3,
            minWidth: 150,
            renderCell: (params) => {
                const phone = params.row.customer_info?.phone_number
                return phone
            }
        },
        {
            field: "branch",
            headerName: "Branch",
            flex: 0.3,
            minWidth: 150,
        },
        {
            field: "total_amount",
            headerName: "Amount",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "discount_amount",
            headerName: "Discount",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "payment_method",
            headerName: "Payment Method",
            flex: 0.3,
            minWidth: 150,
        },
        {
            field: "date_created",
            headerName: "Created Date",
            flex: 0.3,
            minWidth: 150,
        },
        {
            field: "date_schedule",
            headerName: "Deliver Date",
            flex: 0.3,
            minWidth: 150,
        },
        {
            field: "status",
            headerName: "Status",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "actions",
            headerName: "",
            flex: 0.3,
            minWidth: 100,
            type: 'actions',
            hide: pathname.includes('order-fulfilled') ? true : false,
            getActions: (params) => [
                <React.Fragment>
                            <LoadingButton
                                size="small"
                                color="error"
                                disabled={params.row.status === 'Order fulfilled' || params.row.status === 'Cancelled' ? true : false}
                                data-id={params.row.order_number}
                                name={params.row.status}
                                sx={{span: {pointerEvents: 'none'}}}
                                onClick={cancelOrder}
                                loadingPosition="start"
                                startIcon={<DeleteIcon/>}
                                variant="outlined"
                                >
                                Cancel
                            </LoadingButton>
                </React.Fragment>
            ],
        }
    ]

    const CustomToolbar = () => {
        return (
            <Grid container
                sx={{
                    p: 1,
                    pb: 0
                }}
            >
                <Grid item xs={6}>
                    <GridToolbarQuickFilter />
                </Grid>
                <Grid 
                    xs={6}
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                    >
                </Grid>
            </Grid>
        );
    }

    return (
        <Box sx={{marginLeft: "6%",marginRight: "3%", marginTop: "5%"}}>
            
            <Grid container sx={{marginBottom: "10px"}}>
                <Grid item xs={4}>
                    <FormControl sx={{ minWidth: 120, width: '100%', padding: '0px 10px 0px 0px' }}>
                        <Typography sx={{fontSize: '13px', fontWeight: 'bold'}}>Filter Date:</Typography>
                        <DateFilter dateRange={dateRange} setDateRange={setDateRange} />
                    </FormControl>
                </Grid>
                <Grid item xs={2} sx={{display: display ? '' : 'none'}}>
                    <FormControl sx={{ minWidth: 120, width: '100%', padding: '0px 10px 0px 0px' }}>
                        <Typography sx={{fontSize: '13px', fontWeight: 'bold'}}>Filter Status:</Typography>
                        <Select
                        sx={{width: '100%', margin: '0px 10px 0px 0px'}}
                        size='small'
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        >
                            <MenuItem value="All">
                                All    
                            </MenuItem>
                            <MenuItem value={"To Fulfill"}>To fulfill</MenuItem>
                            <MenuItem value={"For Payment"}>For payment</MenuItem>
                            <MenuItem value={"Ready To Ship"}>Ready to ship</MenuItem>
                            <MenuItem value={"Order Fulfilled"}>Order fulfilled</MenuItem>
                            <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl sx={{ minWidth: 120, width: '100%', padding: '0px 10px 0px 0px' }}>
                        <Typography sx={{fontSize: '13px', fontWeight: 'bold'}}>Filter Branch:</Typography>
                        <Select
                        sx={{width: '100%', margin: '0px 10px 0px 0px'}}
                        size='small'
                        value={branch}
                        onChange={(e) => setBranch(e.target.value)}
                        >
                        <MenuItem value="All">
                            All    
                        </MenuItem>
                        {
                            branchList.map( b => <MenuItem value={b.value}>{b.label}</MenuItem>)
                        }
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
                        
            <Box sx={style.DataGrid}>
                <DataGrid
                    columns={columns}
                    rows={orders}
                    disableSelectionOnClick
                    loading={globalStates.isLoading.get()}
                    components={{ 
                        Toolbar: CustomToolbar, 
                        LoadingOverlay: LinearProgress, 
                    }}
                />    
            </Box>  
            <ConfirmationModal 
                btnName={'Cancel'}
                messages={messages} 
                load={btnLoad} 
                run={() => isToRun()} />   
        </Box>
    )
}

export default OrderList