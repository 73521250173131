import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import Paymongo from 'paymongo';
import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import SuccessIcon from "../../../assets/icons/Succesful-icon.png";
import api from '../../../utility/Endpoints';

const ThankYouPage = (props) => {
    const { checkout, cart, order } = props
    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY);
    const totalCartPrice = cart.map(item => item.item_price * item.item_qty).reduce((a, b) => a + b, 0)
    const dispatch = useDispatch()
    const totalAmount = order.createdOrder.totalAmount * 100
    const data = useMemo(() => {
        const data = {
            data: {
                attributes: {
                    amount: parseInt(totalAmount),
                    currency: 'PHP',
                    description: 'DAILY ORDER ' + order.createdOrder.order_number,
                    source: {
                        id: order.payment.id, // Id of the Source resource.
                        type: 'source', // 
                    }
                }
            }
        }
        return data
    }, [totalAmount, order.createdOrder.order_number, order.payment.id])


    useEffect(() => {
        purchaseGtag()
        if (order.createdOrder.paymentMethod === 'gcash' || order.createdOrder.paymentMethod === 'grab_pay') {
            updatePaymongo()
        } else if (order.createdOrder.paymentMethod === 'Debit/Credit') {
            const payload = {
                id: order.payment.id,
                orderId: order.createdOrder.order_id
            }
            api.payment.success(payload)
        }
        setTimeout(() => {
            dispatch({ type: 'SET_CART', selected: [] })
        }, 1000)

    }, [])

    const updatePaymongo = async () => {
        try {
            const result = await paymongo.payments.create(data);
            const payload = {
                id: result.data.id,
                orderId: order.createdOrder.order_id
            }
            api.payment.success(payload)
        } catch (err) {
            console.log(err)
        }
    }

    const purchaseGtag = () => {
        window.gtag("event", "purchase", {
            transaction_id: "T_12345",
            value: totalCartPrice,
            currency: "PHP",
            items: cart.map((index, item) => {
                return {
                    item_id: item.item_id,
                    item_sku: item.item_sku,
                    item_name: item.item_name,
                    currency: "PHP",
                    index: index,
                    item_modifier: item.item_modifier,
                    price: item.original_price,
                    quantity: item.item_qty
                }
            })
        });
    }

    return (
        <React.Fragment>
            <div>
                <Box sx={{ height: '100vh', display: 'flex' }}>
                    <Grid container sx={{ alignItems: 'center', textAlign: 'center', margin: 'auto' }}>
                        <Grid xs={12}>
                            <img alt="success_icon" src={SuccessIcon} />
                        </Grid>
                        <Grid xs={12}>
                            <strong style={{ fontSize: '30px' }}>Thank You!</strong><br />
                        </Grid>
                        <Grid xs={12}>
                            <span style={{ fontSize: '28px' }}>For Your Order {order?.createdOrder?.order_number}.</span>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </React.Fragment>
    )

}

const mapStateToProps = (state) => {
    return {
        checkout: state.checkout,
        order: state.order,
        cart: state.cart.CartList
    }
}

export default connect(mapStateToProps)(ThankYouPage);