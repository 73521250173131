// react library
import { useEffect, useState } from "react";

//api endpoints

//Global States, call funtion
import ConstantStyles from "../../../utility/ConstantStyle.json";

// mui components
//material ui library/components
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';


import PropTypes from "prop-types";
//import from general folder

const CssTextField = styled(TextField)({
    color: ConstantStyles['purple'],
    '& label.Mui-focused': {
      color: ConstantStyles['purple'],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: ConstantStyles['teal'],
    },
    '& .MuiOutlinedInput-root': {
        color: ConstantStyles['purple'],
        '& fieldset': {
            borderColor: ConstantStyles['purple'],
        },
        '&:hover fieldset': {
            borderColor: ConstantStyles['teal'],
        },
        '&.Mui-focused fieldset': {
            borderColor: ConstantStyles['teal'],
        },
    },
  });
const InputField = (props) => {
    const rowId = props.row
    const fieldsList = props.fieldsList // list of all fields
    const field = props.field // specific field
    const setFields = props.setFields
    const type = field?.type
    const variant = field?.variant
    const fwidth = props.fwidth
    const currentValue = props.currentValue || ''
    const [error, setError] = useState(field?.error)
    const [errorMsg, setErrorMsg] = useState(field?.errorMsg)
    const [emailExist, setEmailExist] = useState(field?.emailExist)
    const [emailExistMsg, setEmailExistMsg] = useState(field?.emailExistMsg)
    console.log(currentValue)
    // update error status if changes
    useEffect(()=>{
        setError(field.error)
        setEmailExistMsg(field.emailExistMsg)
        setEmailExist(field.emailExist)
        setErrorMsg(field?.errorMsg)
    },[field.error, field.emailExist])

    // check string is valid email
    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    //onChange text field
    const textChange = (e) => {
        let error = false       // for checking of field on change
        let errorConfirmPass = false       // for checking of confirm password field on change
        let isSamePassword = true// for password and confirm password validation

        if(type==="email"){      // if field type is email check if 
            if (!isValidEmail(e.target.value)) { // email invalid
                error = true
                setError(true);
            } else {        // email valid
                setError(false);
            }
        } else if (rowId === "password" || rowId === "confirmPassword" || rowId === "currentPassword") {    // for password and confirmpassword validation
            if (rowId === "password" || rowId === "confirmPassword")
                if(fieldsList[rowId==='password'?'confirmPassword':'password']?.value !== e.target.value){     // password and confirm password doesn't match
                    errorConfirmPass = true
                    fieldsList['confirmPassword'].error=true
                }else{   // password and confirm password match
                    errorConfirmPass = false
                    fieldsList['confirmPassword'].error=false
                }
            
            if(rowId==="password" || rowId==="currentPassword"){     // check only if current field is password
                if((e.target.value).replace(/ /g,'')==="" || (e.target.value).length<8){ // check if password is blank or length is less than 8 characters
                    error = true
                    fieldsList[rowId].error=true
                }
                else{
                    error = false
                    fieldsList[rowId].error=false
                }
            }
        }else{      // for gerneral fields
            if((e.target.value).replace(/ /g,'')===""){     // if empty                                                  
                fieldsList[rowId].error=true
                error = true
            }
            else
                fieldsList[rowId].error=false
        }
        let newField = fieldsList
        // if password doesn't match with confirm password set error true
        if(errorConfirmPass){
            newField["confirmPassword"].error = errorConfirmPass
        }
        // setFields
        setFields({
            ...newField,
            [rowId]: {
                field: field?.field,
                type: type,
                errorMsg:errorMsg,
                formatted: field?.formatted,
                emailExist:error,
                defValue:field.defValue,
                emailExistMsg:field.emailExistMsg,
                value: e.target.value,
                error:rowId==='confirmPassword'?errorConfirmPass:error
            }
        })
    }

    return (
        <CssTextField
            defaultValue={currentValue}
            error={error||emailExist} //either error is true or emailexist is true show error
            id={`input${rowId}`}
            name={`input${rowId}`}
            helperText={
                error? //if error (empty fields)
                errorMsg //show error msg 
                    :
                emailExist ? //if email error (already exist)
                    emailExistMsg // email already exist
                    :
                    ''
                }
            onChange={(e)=>textChange(e)}
            onBlur={(e)=>textChange(e)}
            variant={variant}
            type={type}
            fullWidth = {fwidth}
            size="small"

        />
    );
}

InputField.propTypes = {
    field: PropTypes.object,
    type: PropTypes.string,
    variant: PropTypes.string,
    fwidth: PropTypes.bool
};
InputField.defaultProps = {
    field: {},
    type: "text",
    variant: "outlined",
    fwidth: false,
}

export default InputField;