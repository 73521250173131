import { useHookstate } from '@hookstate/core';
import React, { useEffect, useState } from 'react';


// MUI imports
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from '@mui/system';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
// MUI icons
import AddCircleIcon from '@mui/icons-material/AddCircle';

import api from '../../../../utility/Endpoints';
import GlobalStates from '../../../../utility/GlobalStates';
import BranchStatus from '../../../shared/BranchStatus';
import ConfirmationModal from '../../../shared/ConfirmationModal';

// import CreateSale from './CreateSale';



const style = {
    
    DataGrid: {
        display: 'flex',
        height: "85vh",
        width: '100%',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            whiteSpace: 'normal',
            textOverflow: 'normal',
            lineHeight: '15px'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },

    },
}

const OnSale = () => {
    const globalStates = useHookstate(GlobalStates) 
    const [sale, setSale] = useState([])
    const [refresh, setRefresh] = useState(false) // loading trigger
    const [branchOpen, setBranchOpen] = useState(false) // button loading trigger
    const [selectedBranches, setSelectedBranches] = useState([]) // button loading trigger
    const [branchStatusData, setBranchStatusData] = useState({}) // button loading trigger
    const [toRun, setToRun] = useState('') // what type of function is to run
    const [btnLoad, setBtnLoad] = useState(false) // button loading trigger
    const [messages, setMessages] = useState({}) // messages for confirmation modal
    const [selectedData, setSelectedData] = useState({}) // selected data for confirmation modal


    // console.log(sale);
    // console.log(selectedBranches);
    // console.log(branchStatusData);
    useEffect(() => {
        fetchOnSale()
    }, [refresh])
    
    const alert = (props) => {
        const { open, severity, message } = props
        globalStates.openAlert.set(open)
        globalStates.statusAlert.set(severity)
        globalStates.msgAlert.set(message)
    }

    const offLoad = () => {
        globalStates.isLoading.set(false)
        setInterval(() => {
            globalStates.isLoading.set(false)
        }, 1000)
    }

    const refreshData = () => {
        setRefresh(!refresh)
    }
    
    const closeBranchStatus = () => {
        setBranchOpen(false)
    }
    
    const fetchOnSale = () => {
        globalStates.isLoading.set(true)
        api.onSale.getOnSale() // function from endpoint
        .then(response => {
            let result = response.data.result; // get result from api
            const onSale = result?.map((item, index) => {
                const branches = JSON.parse(item.branch)
                const sortedBranch = branches.sort((a, b) => {
                    return a.branch_id - b.branch_id;
                })
                const data = {
                    index: index,
                    id: item.id,
                    sku: item.sku,
                    name: item.name,
                    branch: sortedBranch,
                    originalPrice: item.originalPrice,
                    salePrice: item.salePrice,
                    startDate: item.start_date,
                    endDate: item.end_date
                }
                if (item.id === branchStatusData?.id) {
                    setSelectedBranches(sortedBranch)
                    setBranchStatusData(data)
                }
                return data
            })
            setSale(onSale)
            offLoad()
        })
        .catch(error => {
            console.log(error);
            alert({
                open: true,
                severity: 'error',
                message:error.message
            })
            offLoad()
        })
    }

    const disableOnSale = (e) => { // set data for disable category and open confirmation
        e.preventDefault()
        globalStates.modalOpenConfirm.set(true)
        const id = e.target.dataset.id
        const name = e.target.name

        setToRun('disable')
        
        const msg = {
            title: "Remove On Sale",
            message: `Are you sure you want to remove OnSale : ${name}`,
            note: "note: When an OnSale is removed, product price will return to original price",
        }
        setMessages(msg)
        setSelectedData({id: id})
    }

    const isToRun = () => { // functions to run after confirming
        setBtnLoad(true)
        if (toRun === 'disable') {
            api.onSale.disable(selectedData)
            .then((response) => {
                setBtnLoad(false)
                globalStates.modalOpenConfirm.set(false)
                refreshData()
            })
            .catch((error) => {
                console.log(error);
                setBtnLoad(false)
                alert({
                    open: true,
                    severity: 'error',
                    message: error.message
                })
            })
        }
    }
    const handleOnCellClick = (params) => {
        if (params.field === 'branch') {
            setBranchOpen(true)

            setBranchStatusData(params.row)
            setSelectedBranches(params.row.branch)
        }
    }

    // setting up columns
    const columns = [
        {
            field: "id", // fetching from map
            hide: true
        },
        {
            field: "sku",
            headerName: "Product SKU",
            flex: 0.1,
            minWidth: 100,
        },
        {
            field: "name",
            headerName: "Product name",
            flex: 0.3,
            minWidth: 100
        },
        {
            field: "branch",
            headerName: "Branches",
            flex: 0.3,
            minWidth: 100,
            
            renderCell: (params) => {
                const branch = params.row.branch
                const activeBranch = branch.filter( row => row.product_status === "1" || row.product_status === 1)
                const branches = activeBranch?.map( b => b.abbreviation).join(", ")
                return activeBranch.length === branch.length ? 'All' : branches
            },
            
        },
        {
            field: "originalPrice",
            headerName: "Original Price",
            flex: 0.3,
            minWidth: 30,
        },
        {
            field: "salePrice",
            headerName: "Sale Price",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "startDate",
            headerName: "Start Date",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "endDate",
            headerName: "End Date",
            flex: 0.3,
            minWidth: 100,
        },
        {
            field: "actions",
            headerName: "",
            flex: 0.3,
            minWidth: 100,
            type: 'actions',
            getActions: (params) => [
                <React.Fragment>
                    <Grid container>
                        <Grid item xs={6}>
                            <LoadingButton
                                size="small"
                                color="error"
                                data-id={params.id}
                                name={params.row.name}
                                sx={{span: {pointerEvents: 'none'}}}
                                onClick={disableOnSale}
                                loadingPosition="start"
                                startIcon={<DeleteIcon/>}
                                variant="outlined"
                                >
                                Remove
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ],
        }
    ]

    const CustomToolbar = () => {
        return (
            <Grid container
                sx={{
                    p: 1,
                    pb: 0
                }}
            >
                <Grid item xs={6}>
                    <GridToolbarQuickFilter />
                </Grid>
                <Grid 
                    xs={6}
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                    >
                        <Button 
                            size='small' 
                            variant="contained" 
                            href='/admin-backend/add-onsale'
                            startIcon={<AddCircleIcon />}>
                            Add Onsale
                        </Button>
                </Grid>
            </Grid>
        );
    }

    return (
        <Box sx={{marginLeft: "6%",marginRight: "3%", marginTop: "5%"}}>
            <Box sx={style.DataGrid}>
                <DataGrid
                    columns={columns}
                    rows={sale}
                    disableSelectionOnClick
                    loading={globalStates.isLoading.get()}
                    onCellClick={handleOnCellClick}
                    components={{ 
                        Toolbar: CustomToolbar, 
                        LoadingOverlay: LinearProgress, 
                    }}
                />    
                <ConfirmationModal 
                    messages={messages} 
                    load={btnLoad} 
                    run={() => isToRun()} />  
                <BranchStatus 
                    data={branchStatusData}
                    branches={selectedBranches} 
                    open={branchOpen} 
                    close={closeBranchStatus} 
                    api={api.onSale} refresh={refreshData} /> 
            </Box>  
        </Box>
    )
}

export default OnSale