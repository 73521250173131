/*******************************************************************************************
//  * File Name                : CreatePageModal
//  * File Directory           : src/components/modules/CreatePageModal
//  * Module                   : CreatePageModal
//  * Author                   : Ryan
//  * Version                  : 1
//  * Purpose                  : CreatePageModal
//  * Used In				   : CreatePageModal 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   CreatePageModal        -   Modal and form for creating PageModal
 * #How to call:
 *    <CreatePageModal/>
 *
 *******************************************************************************************/
import { useState } from "react";
import { useState as useHookState } from "@hookstate/core";

//api endpoints
import api from "../../../../utility/Endpoints";

//Global States, call funtion
import GlobalStates from "../../../../utility/GlobalStates";
import ConstantStyles from "../../../../utility/ConstantStyle.json";

//material ui library/components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

//style starts here
const styles = {
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"]
    },
    modalStyle: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    }
}

const CreatePage = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    const handleClose = () => globalStates.modalOpenCreate.set(false);
    const [title, setTitle] = useState("");// data / value for section title

    const submitContents = () => {
        let payload = {
            user: globalStates.userEmail.get(),
            title: title,
        }

        if (title !== "") {
            api.page.create(payload)
                .then(response => {
                    let id = response.data.insertId;
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("success");
                    globalStates.msgAlert.set("Success: Created New Page!");
                    setTimeout(() => {
                        globalStates.isLoading.set(false); // loading
                        globalStates.isUpdated.set(Date.now())
                    }, 1500);
                })
                .catch(error => {
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("error");
                    globalStates.msgAlert.set(error);
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                        globalStates.modalOpenCreate.set(false)
                        globalStates.isLoading.set(true); // loading
                    }, 2000);
                });
        }
        else {
            globalStates.openAlert.set(true);
            globalStates.statusAlert.set("error");
            globalStates.msgAlert.set("Required: Please fillup all required Fields");
        }
    }

    return (
        <div>
            <Modal
                open={globalStates.modalOpenCreate.get()}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Create New
                    </Typography>
                    <br />
                    <form>
                        <Grid container item xs={12} md={12} lg={12} spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    id="outlined-basic"
                                    label="Title"
                                    variant="outlined"
                                    size="small"
                                    required
                                    sx={{ width: '100%' }}
                                    defaultValue={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <input type="button" value="Submit" className="btn float-end mt-2" style={styles.btnPurple} onClick={submitContents} />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );

}
export default CreatePage;