import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';



function ComboBox(props) {
    // console.log(props.value);
    return (
        <Autocomplete
            disablePortal
            disableClearable
            id="combo-box-demo"
            options={props.options}
            sx={{ width: '100%' }}
            disabled={props.disabled}
            defaultValue={props.value}
            value={props.value}
            onChange={(event) => props.onChange(event)}
            renderInput={(params) => <TextField {...params} size="small"
                label={props.title}
                value={props.value}
            />}
        />
    );
}

export default ComboBox;