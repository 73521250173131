import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { setSubtotal, setTotalAmount } from '../../actions';

const PaymentSummary = (props) => {
    const { checkout, cart } = props
    const dispatch = useDispatch()

    useEffect(() => {
        const totalDiscount = Number(checkout.discount.map((el) => el.discountAmount).reduce((partialSum, a) => partialSum + a, 0))
        const totalAmount = Number(((Number(checkout.subTotalAmount) + Number(checkout.shipping_fee)) - Number(totalDiscount)).toFixed(2))
        dispatch(setSubtotal(Number(cart?.map((item) => ((item.item_price).toFixed(2) * (item.item_qty).toFixed(2))).reduce((partialSum, a) => partialSum + a, 0).toFixed(2))))
        dispatch(setTotalAmount(totalAmount))
    }, [cart, checkout.subTotalAmount, checkout.shipping_fee, checkout.discount])
    const summaryList = [
        {
            label: 'Voucher',
            value: `${checkout.discount[0].isDiscounted ? checkout.discount.map((el) => " " + el.discountName).toString() : 'Select Voucher'}`,
            hide: !checkout.discount[0].isDiscounted
        },
        {
            label: 'Subtotal',
            value: `PHP ${((checkout.subTotalAmount)).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`,
            hide: false
        },
        {
            label: 'Delivery Fee',
            value: `PHP ${(checkout.shipping_fee).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`,
            hide: false
        },

        {
            label: 'Discount',
            value: `-PHP ${(Number(checkout.discount.map((el) => el.discountAmount).reduce((partialSum, a) => partialSum + a, 0))).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })}`,
            hide: !checkout.discount[0].isDiscounted
        },
        {
            label: 'Daily Points',
            value: `${(Number(checkout.subTotalAmount) / 100)?.toFixed(2) || 0}`,
            hide: false
        },
    ]


    return (
        <React.Fragment>
            <Grid container sx={{ fontSize: '16px', p: '10px 0px' }} >
                {summaryList.map((list, index) =>
                (
                    !list.hide ?
                        <React.Fragment key={index}>
                            <Grid item xs={6} sx={{ textAlign: 'left' }}>
                                <span>{list.label}</span>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <span>{list.value}</span>
                            </Grid>
                        </React.Fragment>
                        :
                        null
                ))}
            </Grid>
        </React.Fragment>
    )

}

const mapStateToProps = (state) => {
    return {
        checkout: state.checkout,
        cart: state.cart.CartList
    }
}

export default connect(mapStateToProps)(PaymentSummary);