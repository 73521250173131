import axios from 'axios';

let rootUrl = process.env.REACT_APP_URL;
// let apiKey = "b966a377-4631-4c33-83c8-fbd44a274d0e";

const ax = axios.create({
    baseURL: process.env.REACT_APP_URL,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    // withCredentials: true,
});


const api = {
    movingImages: {
        get() {
            return ax.get(`/moving-banner`);
        }
    },
    sections: {
        get() {
            return ax.get(`/contents/list/`);
        },
        post(body) {
            return ax.post(`/contents/create/`, body);
        },
        patch(body) {
            return ax.patch(`/contents/delete/`, body);
        },
        put(body) {
            return ax.put(`/contents/update/`, body);
        },
        sort(id, index) {
            return ax.put(`/contents/sort/${id}`, { index: index });

        },
        getHome() {
            return ax.get(`/contents/list-home/`);
        }
    },
    pageSectionContent: {
        get(id) {
            return ax.get(`/page/page-content-list/${id}`);
        },
        multiple(id) {
            return ax.get(`/page/page-content-list-multiple/${id}`);
        },
        put(id, body) {
            return ax.put(`/page/page-content-update/${id}`, body);
        },
        post(body) {
            return ax.post("/page/page-content-create/", body);
        },
    },
    contents: {
        get(id) {
            return ax.get(`/contents/content-list/${id}`);
        },
        getMultiple(id) {
            return ax.get(`/contents/content-list-multiple/${id}`);
        },
        post(body) {
            return ax.post("/contents/content-create/", body);
        },
        put(id, body) {
            return ax.put(`/contents/content-update/${id}`, body);
        }
    },
    page: {
        get() {
            return ax.get(`/page/list`);
        },
        selectPage(id) {
            return ax.get(`/page/select-page/${id}`);
        },
        patch(body) {
            return ax.patch(`/page/delete`, body)
        },
        create(body) {
            return ax.post('/page/create', body)
        },
        deleteSection(body) {
            return ax.patch(`/page/delete-section`, body)
        }
    },
    pageSection: {
        get(id) {
            return ax.get(`/page/page-section-list/${id}`);
        },
        create(body) {
            return ax.post(`/page/page-section-create`, body);
        },
    },
    login: {
        post(body) {
            return ax.post(`/login/`, body);
        }
    },
    store: {
        get() {
            return ax.get(`/store/store-list`);
        }
    },
    brgy: {
        get(city) {
            return ax.get(`/barangay/barangay-list/${city}`);
        }
    },
    announcement: {
        get() {
            return ax.get(`/announcement/list`);
        },
        put(id, body) {
            return ax.put(`/announcement/update/${id}`, body);
        }
    },
    customerOrderList(id) {
        return ax.get(`/fulfillment/fetch-customer-orders/${id}`)
    },
    customerSignup: {
        post(body) {
            return ax.post(`/customer/sign-up/`, body);
        }
    },
    customerSignIn: {
        post(body) {
            return ax.post(`/customer/sign-in`, body);
        }
    },
    customerUpdateInfo: {
        post(id, body) {
            return ax.post(`/customer/fetch-customer-orders/${id}`, body);
        }
    },
    customerUpdatePassword: {
        post(id, body) {
            return ax.post(`/customer/update-password/${id}`, body);
        }
    },
    category: {
        get(body) {
            return ax.get(`/category/list/1`, body);
        },
        fetch_category(body) {
            return ax.get(`/category/fetch/1`, body);
        },
        disable(id) {
            return ax.post(`/category/disable/${id}`);
        },
        upload_image(data, paths) {
            return ax.post(`/category/upload_image`, data)
        },
        create(body) {
            return ax.post(`/category/create`, body)
        },
        update(body) {
            return ax.post(`/category/update`, body)
        },
        changeStatus(body) {
            return ax.post(`/category/status`, body)
        }
    },
    product: {
        getItem(payload, body) {
            return ax.post(`/product/view/${payload.branch}/${payload.sku}`, body)
        },
        getAllProduct() {
            return ax.get(`/product/all`)
        },
        fetch_modifiers() {
            return ax.get(`/product/modifier-list`)
        },
        fetch_list(id) {
            return ax.get(`/product/list/${id}`)
        },
        updateInfo(body) {
            return ax.post(`/product/update-info`, body)
        },
        updateBranchStatus(body) {
            return ax.post(`/product/update-status`, body)
        },
        createMultiple(body) {
            return ax.post(`/product/create-multiple`, body)
        }
    },
    customerFeedback: {
        getFeedback(uid) {
            return ax.get(`/feedback/${uid}`)
        },
        insertFeedback(order_id, payload) {
            return ax.post(`/feedback/${order_id}`, payload)
        }
    },
    address: {
        addAddress(payload) {
            return ax.post('/address/create-address', payload)
        },
        updateAddress(payload) {
            return ax.post('/address/update-address', payload)
        },
        addressList(payload) {
            return ax.get(`/address/address-list/${payload.city}/${payload.customer}/${payload.type}`)
        },
        selectedAddress(payload) {
            return ax.get(`/address/address-select/${payload.customer_id}/${payload.address_id}/${payload.city_id}`)
        }
    },
    discount: {
        addDiscount(payload) {
            return ax.post('/discount/add-discount', payload)
        },
        publicList(payload) {
            return ax.get(`/discount/list-public-discount/${payload}`)
        },
        getDiscount() {
            return ax.get('/discount/')
        },
        getUsedDiscount(code) {
            return ax.get(`/discount/used-discount/${code}`)
        },
        createDiscount(payload) {
            return ax.post('/discount/create-discount', payload)
        },
        updateDiscount(payload) {
            return ax.post('/discount/update', payload)
        },
        disableDiscount(id) {
            return ax.post(`/discount/disable/${id}`)
        },
    },
    user: {
        getUser() {
            return ax.get(`/user/`)
        },
        create(body) {
            return ax.post(`/user/create`, body)
        },
        disable(body) {
            return ax.post(`/user/disable`, body)
        },
        changeStatus(body) {
            return ax.post(`/user/status`, body)
        },
        update(body) {
            return ax.post(`/user/update`, body)
        }
    },
    checkout: {
        createOrder(payload) {
            return ax.post('/checkout/create-order', payload)
        }
    },
    branch: {
        getBranch() {
            return ax.get(`/branch/`)
        },
        create(body) {
            return ax.post(`/branch/create`, body)
        },
        update(body) {
            return ax.post(`/branch/update`, body)
        },
        disable(body) {
            return ax.post(`/branch/disable`, body)
        },
        changeStatus(body) {
            return ax.post(`/branch/status`, body)
        },
    },
    fulfillment: {
        getOrders() {
            return ax.get(`/fulfillment/`)
        },
        getOrdersWithFilters(body) {
            return ax.post(`/fulfillment/`, body)
        },
        getOrderByOrderNumber(order_number) {
            return ax.get(`/fulfillment/${order_number}`)
        },
        updateStatus(body) {
            return ax.post(`/fulfillment/updateStatus`, body)
        },
        updateDiscount(body) {
            return ax.post(`/fulfillment/updateDiscount`, body)
        },
        getHistory(body) {
            return ax.post(`/fulfillment/history`, body)
        },
        addItem(payload) {
            return ax.post(`/fulfillment/addItem`, payload)
        },
        editItem(payload) {
            return ax.post(`/fulfillment/editItem`, payload)
        },
        removeItem(body) {
            return ax.post(`/fulfillment/removeItem`, body)
        },
        changeBranch(body) {
            return ax.post(`/fulfillment/changeBranch`, body)
        }
    },
    onSale: {
        getOnSale() {
            return ax.get(`/on-sale/`)
        },
        create(body) {
            return ax.post(`/on-sale/create`, body)
        },
        disable(body) {
            return ax.post(`/on-sale/disable`, body)
        },
        changeStatus(body) {
            return ax.post(`/on-sale/changeStatus`, body)
        }
    },
    payment: {
        create(body) {
            return ax.post(`/payment/create-payment`, body)
        },
        success(body) {
            return ax.put(`/payment/success-payment/${body.orderId}/${body.id}`)
        },
        failed(body) {
            return ax.patch(`/payment/failed-payment/${body.orderId}`)
        }
    },
    modifier: {
        group() {
            return ax.get('/modifier/fetch-modifier')

        },
        options() {
            return ax.get('/modifier/fetch-modifier-options')
        },
        on(body) {
            return ax.post('/modifier/modifier-on', body)
        },
        off(body) {
            return ax.post('/modifier/modifier-off', body)
        },
        oos(branch) {
            return ax.get(`/modifier/fetch-modifier-oos/${branch}`)
        }
    },
    uploadImage: {
        uploadImage(data, savingPath) {
            return ax.post(`/uploadImage/upload/${savingPath}`, data)
        },
    },
    footer: {
        editContent(body) {
            return ax.post(`/footer/editContent`, body)
        },
        fetch() {
            return ax.get(`/footer/fetch`)
        },
    },
    links: {
        addLink(body) {
            return ax.post(`/links/addLink`, body)
        },
        fetchLink(body) {
            return ax.get(`/links/fetch`, body)
        },
        remove(body) {
            return ax.post(`/links/remove`, body)
        }
    },
    banner: {
        fetch(location) {
            return ax.get(`/banner/${location}`)
        },
        addBanner(body) {
            return ax.post('/banner/add-banner', body)
        },
        remove(body) {
            return ax.post('/banner/remove', body)
        }
    }
}
export default api;

export function GetCategory() {
    const authOptions = {
        method: "get",
        url: `${rootUrl}/category/`,
        headers: {
            "Content-Type": "application/json"
        }
    };

    return axios(authOptions);
}

// GET Category
export function GetCategoryList() {
    const authOptions = {
        method: "get",
        url: `${process.env.REACT_APP_URL}/category/list/1`,
        headers: {
            "Content-Type": "application/json"
        }
    };

    return axios(authOptions);
}
// GET Product
export function GetProduct(branch_id) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}/product/list/${1}`,
        headers: {
            "Content-Type": "application/json"
        }
    };

    return axios(authOptions);
}
export function GetBestSeller(branch_id) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}/product/fetch-best-sellers/${1}`,
        headers: {
            "Content-Type": "application/json"
        }
    };

    return axios(authOptions);
}
export function GetRecommended(branch_id) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}/product/fetch-recommended/${1}`,
        headers: {
            "Content-Type": "application/json"
        }
    };

    return axios(authOptions);
}
// POST Create Product
export function CreateProduct(body) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}/product/create-single`,
        data: body,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    return axios(authOptions);
}
// POST add image
export function addImage(body, productId) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}product/add-image/${productId}`,
        data: body,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    return axios(authOptions);
}


//content image
export function contentImage(body) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}contents/content-image/upload`,
        data: body,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    return axios(authOptions);
}

export function contentImageRemove(body) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}contents/content-image/remove`,
        data: body,
        headers: {
            "Content-Type": "application/json"
        }
    };

    return axios(authOptions);
}

export function removeImage(body, imageId) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}product/remove-image/${imageId}`,
        data: body,
        headers: {
            "Content-Type": "application/json"
        }
    };

    return axios(authOptions);
}

// POST Create Product
export function updateMovingImage(body, src) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}/moving-banner/update-image/${src}`,
        data: body,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };

    return axios(authOptions);
}
// GET Item
export function getItem(payload) {
    const authOptions = {
        method: "GET",
        url: `${process.env.REACT_APP_URL}/product/view/1/${payload.sku}`,
        headers: {
            "Content-Type": "application/json"
        }
    };

    return axios(authOptions);
}

// cart logs
export function cartLogs(payload) {
    const authOptions = {
        method: "POST",
        url: `${process.env.REACT_APP_URL}/logs/cart`,
        headers: {
            "Content-Type": "application/json"
        },
        data: payload
    };

    return axios(authOptions);
}
