import { useState as useHookState } from "@hookstate/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';

import api from '../../../utility/Endpoints';
import GlobalStates from '../../../utility/GlobalStates';


import { connect, useDispatch } from "react-redux";
import ConstantStyles from '../../../utility/ConstantStyle.json';
import { selectedAddress, setStore } from "../../actions";

import WhereToVoteSharpIcon from '@mui/icons-material/WhereToVoteSharp';

import '../../shared/CustomizeCss.css';

const styles = {
    container: {
        padding: '5px',
        width: '100%',
        marginTop: '70px',
        overflow: 'auto',
    },
    containerDesktop: {
        padding: '5px',
        width: '100%',
        marginTop: '70px',
        marginBottom: '5px',
        overflow: 'auto',
        height: '300px'
    },
    title: {
        background: 'white',
        Height: '20px',
        padding: '20px',
        borderBottom: ConstantStyles['default-border'],
        boxShadow: '0 3px 10px rgb(0 0 0 / 0,2)',
        borderRadius: '15px 15px 0px 0px',
        position: 'fixed',
        zIndex: '999',
        width: '100%',
    },
    infoIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#D0D0D0',
    },
    pointerEvent: {
        pointerEvents: 'none'
    },
    box: {
        cursor: 'pointer',
        width: '100%',
        padding: '10px'
    },
    selected: {
        width: '100%',
        padding: '10px',
        background: '#A2F9F9'
    }
}

const SelectStoreForm = (props) => {
    const { store } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [storeList, setStoreList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [selected, setSelected] = useState('')
    const dispatch = useDispatch()
    useEffect(() => {
        getStore()
        setSelected(store.branchName + store.cityName)
    }, [])

    const getUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    const getStore = () => {
        api.store.get()
            .then(response => {
                const result = response.data.result
                setStoreList(result || [])
                const regions = result.map(region => region.cityName).filter(getUnique)
                setRegionList(regions)
            })
            .catch(error => {
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set(error.message);
            })
            .finally(() => {
                setTimeout(() => {
                    globalStates.openAlert.set(false);
                    globalStates.isLoading.set(true); // loading
                }, 3000);
            });
    }


    const onClickStore = (e) => {
        const data = e.target.dataset
        const selectedStore = {
            branch: data.branch,
            city: data.city,
            branchName: data.branchname,
            cityName: data.cityname
        }
        setSelected(e.target.id)
        dispatch(setStore(selectedStore))
        dispatch(selectedAddress([]))
        globalStates.openAlert.set(true);
        globalStates.statusAlert.set("success");
        globalStates.msgAlert.set('Store changed');
        setTimeout(() => {
            globalStates.openAlert.set(false);
            if (window.location.hash === '#products')
                window.location.href = '/menu/all'
            else {
                window.location.reload()
                globalStates.openStoreModal.set(false)
            }
        }, 700);
    }

    return (
        <React.Fragment>
            <Card sx={styles.title}>
                <strong>Location</strong>
            </Card>

            <Grid container sx={globalStates.isMobile.get() ? styles.container : styles.containerDesktop}>
                {
                    regionList?.map(region => {
                        // console.log(region);
                        return (
                            <>
                                <Accordion sx={{
                                    margin: '5px auto',
                                    borderRadius: '10px',
                                    width: '100%',
                                    // ".css-yw020d-MuiAccordionSummary-expandIconWrapper": {
                                    //     color: ConstantStyles['teal'] + " !important"
                                    // }
                                }}>
                                    <AccordionSummary
                                        sx={{
                                            width: '100%', display: 'flex',
                                            alignItems: 'center',
                                            backgroundColor: ConstantStyles['purple'],
                                            borderRadius: '10px',
                                            ".css-yw020d-MuiAccordionSummary-expandIconWrapper": {
                                                color: ConstantStyles['teal'] + " !important"
                                            }
                                        }}
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography sx={{ width: '100%', padding: 'auto', color: ConstantStyles['teal'] }}>{region}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: 0, color: ConstantStyles['teal'] + " !important", }}>
                                        {
                                            storeList.sort((a, b) => {
                                                let x = a.branchName.toLowerCase();
                                                let y = b.branchName.toLowerCase();
                                                if (x < y) { return -1; }
                                                if (x > y) { return 1; }
                                                return 0;
                                            }).filter(store => store.cityName === region).map((store, index) => {
                                                return (
                                                    <Box
                                                        sx={
                                                            selected === store.branchName + store.cityName ?
                                                                styles.selected
                                                                :
                                                                styles.box}
                                                        className="select-store"
                                                        key={index}
                                                        id={store.branchName + store.cityName}
                                                        data-branch={store.branchId}
                                                        data-city={store.cityId}
                                                        data-branchname={store.branchName}
                                                        data-cityname={store.cityName}
                                                        onClick={onClickStore}
                                                    >
                                                        <Grid item xs={12} id={store.branchName + store.cityName} sx={styles.pointerEvent}>
                                                            <Grid container id={store.branchName + store.cityName} sx={styles.pointerEvent} spacing={1}>
                                                                <Grid item id={store.branchName + store.cityName} xs={10} sx={styles.pointerEvent} >
                                                                    <Grid item xs={12} >
                                                                        <Typography
                                                                            color='primary'
                                                                        >
                                                                            {store.branchName}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={2} sx={styles.infoIcon} >
                                                                    <WhereToVoteSharpIcon
                                                                        sx={{
                                                                            display: selected === store.branchName + store.cityName ? "" : "none",
                                                                            color: ConstantStyles['purple']
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            </>
                        )
                    })
                }
            </Grid>
        </React.Fragment >
    )
};

const mapStateToProps = (state) => {
    return {
        store: state.store.selectedStore
    }
}


export default connect(mapStateToProps)(SelectStoreForm);