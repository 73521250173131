// react imports
import React, {useState} from 'react';
import PropTypes from "prop-types";

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useHookstate } from '@hookstate/core';
import { TextField, Button, Grid, Radio, Switch } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';

// MUI icons
import EditIcon from '@mui/icons-material/Edit';

// api access
import api from '../../../../utility/Endpoints';

// import global states
import GlobalStates from '../../../../utility/GlobalStates';

// reusable components
import ConfirmButton from '../../../shared/ConfirmButton';
import { useEffect } from 'react';

// styles
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    },
    formRow: {
        display: 'flex', 
        justifyContent: 'space-between', 
        marginBottom: 2
    },
    wrapper: {
        position: 'relative', 
        border: '1px solid gray', 
        padding: '15px 5px 15px 5px', 
        borderRadius: '5px',
        marginBottom: 2,
        header: {
            position: 'absolute', 
            top: '-13px', 
            left: '15px', 
            backgroundColor: 'snow'
        }
    },
    checkBox: {
        display: 'flex', 
        justifyContent: 'end', 
        alignItems: 'center'
    },
    formInputs: {
        padding: '10px 16px 16px 16px', 
        maxHeight: 500, 
        overflow: "auto" 
    },
    required: {
        color: "red", 
        fontSize: "25px"
    }
};

const EditModal = (props) => {
    const globalStates = useHookstate(GlobalStates) // globalstate variable
    const { refresh, branches, admin, id, data } = props // data from parent component 

    const [open, setOpen] = useState(false)  // variable for modal
    const [sameSched, setSameSched] = useState(false) // same schedule trigger
    const [btnLoad, setBtnLoad] = useState(false) // loading button on process
    const [owned, setOwned] = useState(true) // owned by bos form check box
    const [pickUp, setPickUp] = useState(false) // pick up form check box
    const [delivery, setDelivery] = useState(false) // deliver form check box
    const [branchDuplicate, setBranchDuplicate] = useState('') // error message
    const [selected, setSelected] = useState('') // selected admin 
    const [getData, setGetData] = useState([])
    const weekDays = [
        {
            day: 'monday',
            store: getData.monday,
        },
        {
            day: 'tuesday',
            store: getData.tuesday,
        },
        {
            day: 'wednesday',
            store: getData.wednesday,
        },
        {
            day: 'thursday',
            store: getData.thursday,
        },
        {
            day: 'friday',
            store: getData.friday,
        },
        {
            day: 'saturday',
            store: getData.saturday,
        },
        {
            day: 'sunday',
            store: getData.sunday,
        },
    ]

    useEffect(() => { // set default data onload
        setGetData(data)
        setSelected(data.admin)
        setOwned(data.owned_by_bos === 1 ? true : false )
        setPickUp(data.pick_up === 1 ? true : false )
        setDelivery(data.delivery === 1 ? true : false )
        setBranchDuplicate('')
    }, [data, open])

    const handleClose = () => setOpen(false) // close modal
    const handleOpen = () => setOpen(true); // open modal
    const handleSwitch = (e) => setSameSched(!sameSched) // switch same schedule
    
    const handleSubmit = (e) => { // submit form
        e.preventDefault()
        setBtnLoad(true)

        // variables from form
        const form = e.target
        const owned_by_bos = owned ? 1 : 0
        const pickup = pickUp ? 1 : 0
        const status = getData.status
        const branch = form.branch.value
        const abbreviation = form.abbreviation.value
        const address = form.address.value
        const latitude = form.latitude.value
        const longitude = form.longitude.value
        const admin_id = form.admin.value
        const contact = form.contact.value
        let schedule

        if (sameSched) {
            schedule = {
                monday: {
                    opening: form.opening.value,
                    closing: form.closing.value
                },
                tuesday: {
                    opening: form.opening.value,
                    closing: form.closing.value
                },
                wednesday: {
                    opening: form.opening.value,
                    closing: form.closing.value
                },
                thursday: {
                    opening: form.opening.value,
                    closing: form.closing.value
                },
                friday: {
                    opening: form.opening.value,
                    closing: form.closing.value
                },
                saturday: {
                    opening: form.opening.value,
                    closing: form.closing.value
                },
                sunday: {
                    opening: form.opening.value,
                    closing: form.closing.value
                },
            }
        }
        else{
            schedule = {
                monday: {
                    opening: form.mondayOpening.value,
                    closing: form.mondayClosing.value
                },
                tuesday: {
                    opening: form.tuesdayOpening.value,
                    closing: form.tuesdayClosing.value
                },
                wednesday: {
                    opening: form.wednesdayOpening.value,
                    closing: form.wednesdayClosing.value
                },
                thursday: {
                    opening: form.thursdayOpening.value,
                    closing: form.thursdayClosing.value
                },
                friday: {
                    opening: form.fridayOpening.value,
                    closing: form.fridayClosing.value
                },
                saturday: {
                    opening: form.saturdayOpening.value,
                    closing: form.saturdayClosing.value
                },
                sunday: {
                    opening: form.sundayOpening.value,
                    closing: form.sundayClosing.value
                },
            }
        }
        const body = {
            id: getData.id,
            pickup: pickup,
            owned_by_bos: owned_by_bos,
            delivery: delivery ? 1 : 0,
            branch: branch,
            abbreviation: abbreviation,
            address: address,
            latitude: latitude,
            longitude: longitude,
            admin: admin_id,
            contact: contact,
            schedule: schedule,
            status: status
        }

        // get duplicate branch
        const filterBranch = branches.filter(branch => branch.name === body.branch && branch.name !== data.name)
        if (filterBranch.length === 0) {
            // run api
            api.branch.update(body)
            .then((response) => {
                setBtnLoad(false)
                handleClose()
                refresh()
            })
            .catch((error) => {
                // alert error
                console.log(error);
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
                setInterval(() => {
                    globalStates.openAlert.set(false)
                }, 2000)
            })
        }else{
            setBtnLoad(false)
        }
    }

    const handleChange = (e) => { // change selected admin
        e.preventDefault()
        const admin_id = e.target.value
        setSelected(admin_id)
    }
    const onChangeBranch = (e) => { // onChange text branch
        e.preventDefault()

        const typedBranch = e.target.value
        const branches = props?.branches

        // filter duplicate branch
        const filterBranch = branches.filter( branch => branch.name === typedBranch && branch.name !== data.name)
        if (filterBranch.length > 0) {
            setBranchDuplicate("Branch already exist")
        }else{
            setBranchDuplicate('')
        }
    }

    const renderWeeklySched = weekDays.map( week => {
        return (
            <>
                <Grid item xs={4}>
                    <Typography sx={{textTransform: 'capitalize'}}>{week.day} <span style={style.required}>*</span> :</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        id={week.day}
                        size='small'
                        name={week.day+'Opening'}
                        label="Opening"
                        type="time"
                        defaultValue={week.store?.opening || ""}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        sx={{width: 200}}    
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        id={week.day}
                        size='small'
                        name={week.day+'Closing'}
                        label="Closing"
                        type="time"
                        defaultValue={week.store?.closing || ""}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        sx={{width: 200}}  
                        required
                        />
                </Grid>
            </>
        )
    } )

    return (
        <div>
            <LoadingButton 
                size="small"
                color="success"
                id={id}
                loadingPosition="start"
                onClick={handleOpen}
                startIcon={<EditIcon />}
                variant="outlined"
                >
                Edit
            </LoadingButton>
            <Modal
                key={id}
                open={open}
                sx={{padding: 0, margin: 0}}
            >   
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{margin: 1.5}}>
                        Edit Branch {data.name}
                    </Typography>
                    <hr style={{marginTop: 1, marginBottom: 1}}/>
                    <form onSubmit={handleSubmit}>
                        <Box sx={style.formInputs} >

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Branch Name <span style={style.required}>*</span> :
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='branch' defaultValue={getData.name} onChange={onChangeBranch} size='small' label="Branch Name" placeholder='Quezon City' sx={{ width: '100%'}} variant="outlined" required/>
                                
                                    <FormHelperText sx={{display : branchDuplicate ? '': 'none', color: 'red'}}>{branchDuplicate}</FormHelperText>
                                </Box>
                            </Box>

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Branch Abbreviation :
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='abbreviation' defaultValue={getData.abbreviation} size='small' label="Abbreviation" placeholder='QC' sx={{ width: '100%'}} variant="outlined"/>
                                </Box>
                            </Box>

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Address <span style={style.required}>*</span> :
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='address' defaultValue={getData.address} size='small' label="Address" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Longitude <span style={style.required}>*</span> :
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='longitude' defaultValue={getData.longitude} size='small' label="Longitude" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Latitude <span style={style.required}>*</span> :
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='latitude' defaultValue={getData.latitude} size='small' label="Latitude" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>

                            <Box sx={style.formRow}>
                                <Box></Box>
                                <Box width={'60%'}>
                                    <Box sx={{display: 'flex'}}>
                                        <Box sx={style.checkBox}>
                                            <Checkbox
                                                value={owned ? 1 : 0}
                                                name='owned_by_bos'
                                                checked={owned}
                                                onChange={(e) => setOwned(e.target.checked)}
                                            />
                                            <Typography align='center'>
                                                Owned by Bo's 
                                            </Typography>
                                        </Box>
                                        <Box sx={style.checkBox}>
                                            <Checkbox
                                                value={pickUp ? 1 : 0}
                                                name='pickUp'
                                                checked={pickUp}
                                                onChange={(e) => setPickUp(e.target.checked)}
                                            />
                                            <Typography align='center'>
                                                Pick up 
                                            </Typography>
                                        </Box>
                                        <Box sx={style.checkBox}>
                                            <Checkbox
                                                value={delivery ? 1 : 0}
                                                name='delivery'
                                                checked={delivery}
                                                onChange={(e) => setDelivery(e.target.checked)}
                                            />
                                            <Typography align='center'>
                                                Deliver 
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={style.wrapper}>
                                <Typography sx={style.wrapper.header} >Assigned Admin</Typography>
                                <Box sx={style.formRow}>
                                    <Box>
                                        <Typography>
                                            Admin <span style={style.required}>*</span> :
                                        </Typography>
                                    </Box>
                                    <Box width={"60%"}>
                                        <FormControl sx={{ minWidth: 120, width: '100%' }}  size="small">
                                            <InputLabel id="demo-simple-select-helper-label">Admin *</InputLabel>
                                            <Select
                                                value={selected}
                                                defaultValue={getData?.admin}
                                                label="Admin"
                                                name='admin'
                                                onChange={handleChange}
                                                required
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                admin.filter(a => a.status === 1 || a.status === "1").map((a, index) => {
                                                    const name = a.first_name + ' ' +a.last_name || ""
                                                    return <MenuItem key={index} value={a.id}>{name}</MenuItem>
                                                })
                                            }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>
                                        <Typography>
                                            Contact Number <span style={style.required}>*</span> :
                                        </Typography>
                                    </Box>
                                    <Box width={"60%"}>
                                        <TextField name='contact' defaultValue={getData.phone} type='number' size='small' label="Contact Number" sx={{ width: '100%'}} variant="outlined" required/>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={style.wrapper}>
                                <Typography sx={style.wrapper.header} >Schedule</Typography>
                                <Grid container rowSpacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Schedule per day :</Typography>
                                    </Grid>
                                    <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center'}}>
                                        <Switch checked={sameSched} onChange={handleSwitch} size="small" />
                                        <Typography fontSize={13} sx={{fontStyle: 'italic'}} >
                                            {
                                                sameSched ? 
                                                'Set scheduled time according to specific day.'
                                                :
                                                'Note: Monday - Sunday has same scheduled time.'
                                            }
                                        </Typography>
                                    </Grid>
                                    {
                                        sameSched ? 
                                        <>
                                            <Grid item xs={4}>
                                                <Typography sx={{textTransform: 'capitalize'}}>Schedule Time <span style={style.required}>*</span> :</Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField 
                                                    id='time'
                                                    size='small'
                                                    name='opening'
                                                    label="Opening"
                                                    type="time"
                                                    defaultValue='08:00'
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    sx={{width: 200}}    
                                                    required
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField 
                                                    id='time'
                                                    size='small'
                                                    name='closing'
                                                    label="Closing"
                                                    type="time"
                                                    defaultValue='17:00'
                                                    InputLabelProps={{
                                                    shrink: true,
                                                    }}
                                                    sx={{width: 200}}  
                                                    required
                                                    />
                                            </Grid>
                                        </>
                                        :
                                        renderWeeklySched
                                    }
                                    
                                </Grid>
                            </Box>
                        </Box>
                        <Box sx={{width: '100%'}}>
                            <hr style={{marginTop: 1, marginBottom: 1}}/>
                            <ConfirmButton
                                margin={1} 
                                close={handleClose} 
                                btnLoad={btnLoad} />
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

EditModal.propTypes = {
    id:PropTypes.number,
    admin:PropTypes.array,
    data:PropTypes.object,
    refresh:PropTypes.func,
    branches:PropTypes.array,
    btnLoad:PropTypes.bool,
};
EditModal.defaultProps = {
    btnLoad: false,
    branches: [],
    admin: [],
    data: {}
};

export default EditModal