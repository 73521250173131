import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHookstate } from '@hookstate/core';
import GlobalStates from '../../../../utility/GlobalStates';

import api from '../../../../utility/Endpoints';

import ProductTable from "./ProductTable";
import CreateProductModal from "./CreateProductModal";
import DeleteProductModal from "./DeleteProductModal";
import UpdateImageModal from "./UpdateImageModal";
import BulkCreateProduct from "./BulkCreateProduct";

// MUI imports
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { GridToolbarQuickFilter} from '@mui/x-data-grid';


const style = {
    
    DataGrid: {
        display: 'flex',
        height: "85vh",
        width: '100%',
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            whiteSpace: 'normal',
            textOverflow: 'normal',
            lineHeight: '15px'
        },
        '& .MuiDataGrid-cell': {
            fontSize: '12px',
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important',
        },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important',
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important',
        },

    },
}
const Products = () => {
    const globalStates = useHookstate(GlobalStates)
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let branchId = userInfo[0].branchId || 1
    const [itemList, setItemList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [modifiers, setModifiers] = useState([])
    console.log(userInfo)
    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        globalStates.openAlert.set(false);
    };

    useEffect(() => {
        globalStates.isLoading.set(true)
        FetchCategory()
        FetchProducts()
    }, [])
    
    const FetchCategory = () => {
        globalStates.isLoading.set(true)
        api.category.fetch_category() // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                const category = result.map( item => {
                    return item.name
                })
                setCategoryList(category)
                setTimeout(() => {
                    // globalStates.isLoading.set(false)
                }, 1000)
            })
            .catch(error => {
                globalStates.isLoading.set(false)
                console.log(error);
            })
    }
    const FetchProducts = () => {
        api.product.fetch_list(branchId) // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                setItemList(result)
                FetchModifiers()
            })
            .catch(error => {
                globalStates.isLoading.set(false)
                console.log(error);
            })
    }
    
    const FetchModifiers = () => {
        api.product.fetch_modifiers() // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                setModifiers(result)
                
                setTimeout(() => {
                    globalStates.isLoading.set(false)
                }, 500);
            })
            .catch(error => {
                globalStates.isLoading.set(false)
                console.log(error);
            })
    }

    const RenderEditToggle = (props) => {
        const { id, value, api, colDef, field, row } = props;
        const [valueState, setValueState] = useState(value);
        const [anchorEl, setAnchorEl] = useState() || null;

        // setting position of the popper
        const handleRef = (el) => {
            setAnchorEl(el);
        };

        //handling onchange value of the textarea
        const handleChange = useCallback((event) => {
            const newValue = event.target.checked;
            setValueState(newValue);
            api.setEditCellValue({ id, field, value: newValue }, event);
        },
            [api, field, id]
        );

        // rendering textarea
        return (
            <div>
                <div
                    ref={handleRef}
                    // style={style.marginTop150}
                />
                <Checkbox
                    checked={value}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </div>
        );
    }
    
    const CustomToolbar = () => {
        return (
            <Grid container
                sx={{
                    p: 1,
                    pb: 0
                }}
            >
                <Grid item xs={6}>
                    <GridToolbarQuickFilter />
                </Grid>
                <Grid 
                    xs={6}
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                    >
                    <Button size='small' sx={{marginRight:'5px'}} onClick={() => globalStates.modalOpenBulk.set(true)} variant="contained" startIcon={<AddCircleIcon />}>
                        Bulk Create Product
                    </Button>
                    <Button size='small' onClick={() => globalStates.modalOpenCreate.set(true)} variant="contained" startIcon={<AddCircleIcon />}>
                        New Product
                    </Button>
                </Grid>
            </Grid>
        );
    }
    return (
        <Box sx={{marginLeft: "6%",marginRight: "3%", marginTop: "5%"}}>
            <Box sx={style.DataGrid}> 
                {
                    !globalStates.isLoading.get() &&
                    <ProductTable data={itemList} modifiers={modifiers.reverse()} CustomToolbar={CustomToolbar} categoryList={categoryList}/>
                }
                <CreateProductModal modifiers={modifiers} categoryList={categoryList}/>  
                <UpdateImageModal />  
                <DeleteProductModal/>  
                <BulkCreateProduct modifiers={modifiers} />  
                <Snackbar
                    open={globalStates.openAlert.get()}
                    autoHideDuration={4000}
                    onClose={handleSnackBarClose}
                >
                    <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>
                        {globalStates.snackBarMsg.get()}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
  );
}

const mapStateToProps = (state) => {
    return {
        category: state.category.categoryList
    }
}

export default connect(mapStateToProps)(Products);