const INTIAL_STATE = {
    CartList: []
}

const CartReducer = (state = INTIAL_STATE, action) => {
    let limit = 50
    switch (action.type) {
        case 'SET_CART':
            return {
                ...state,
                CartList: action.selected
            };
        case 'ADD_ITEM':
            let list = state.CartList
            let newItem = action.newItem
            let sameItemChecker = list.filter((item) => item.item_id === newItem.item_id && JSON.stringify(item.item_modifier) === JSON.stringify(newItem.item_modifier))
            let removeSameItem = list.filter((item) => JSON.stringify(item.item_modifier) + item.item_id !== JSON.stringify(newItem.item_modifier) + newItem.item_id)
            if (sameItemChecker.length > 0) {
                let cartQty = sameItemChecker[0]
                newItem.item_qty = Number(cartQty.item_qty) + Number(newItem.item_qty)
            }
            return {
                ...state,
                CartList: [...removeSameItem, newItem]
            }
        case 'UPDATE_QUANTITY': {
            let list = state.CartList
            const cloneList = [...list];
            return {
                ...state,
                CartList: cloneList.filter((item) => {
                    if (item.item_id === action.id) {
                        return action.value > 0 ? true : false
                    } else
                        return true
                }).map((el) => {
                    if (limit > el.item_limit)
                        limit = el.item_limit
                    if (action.value > limit)
                        action.value = limit
                    return el.item_id === action.id ?
                        { ...el, cart_qty: action.value > 0 ? action.value : 0 }
                        :
                        el
                })
            }
        }
        case 'ADD_QUANTITY': {
            let list = state.CartList
            const cloneList = [...list];
            return {
                ...state,
                CartList: cloneList.map((el) => {
                    return el.item_id === action.item_id && JSON.stringify(el.item_modifier) === JSON.stringify(action.item_modifier) ?
                        { ...el, item_qty: ++el.item_qty }
                        :
                        el
                })
            }
        }
        case 'MINUS_QUANTITY': {
            let list = state.CartList
            const cloneList = [...list];
            return {
                ...state,
                CartList: cloneList.filter((item) => {
                    if (item.item_id === action.item_id && JSON.stringify(item.item_modifier) === JSON.stringify(action.item_modifier)) {
                        return --item.item_qty > 0 ? true : false
                    } else
                        return true
                }).map((el) => {
                    return el.item_id === action.item_id && JSON.stringify(el.item_modifier) === JSON.stringify(action.item_modifier) ?
                        { ...el, item_qty: el.item_qty > 0 ? el.item_qty : 0 }
                        :
                        el
                })
            }
        }
        case 'REMOVE_ITEM': {
            let list = state.CartList
            const cloneList = [...list];
            return {
                ...state,
                CartList: cloneList.filter((item) => {
                    if (item.item_id === action.id) {
                        return false
                    } else
                        return true
                })
            }
        }
        default:
            return state;
    }

};

export default CartReducer;