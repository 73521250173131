import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import { useState as useHookState } from "@hookstate/core";
import React, { useState } from 'react';

import ConstantStyles from "../../../utility/ConstantStyle.json";
import GlobalStates from "../../../utility/GlobalStates";

import CustomerLogin from '../Login/CustomerLogin';
import Register from '../Register/Register';

//logo
import DailyLogo from "../../../assets/logo/daily-logo.png";
import BackgroundImage from "../../../assets/backgroundImage/pattern.jpg"

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    overflow: 'auto',
    paddingBottom: '20px'
};

const LoginModal = () => {
    const banner_image = `/banner/pattern.jpg` 

    const globalStates = useHookState(GlobalStates); // call globalstates
    const [open, setOpen] = useState(globalStates.showLoginModal.get());
    const [value, setValue] = useState('1');

    const handleClose = () => {
        setOpen(false)
        globalStates.showLoginModal.set(false)
    };
    const handleChange = (e, newValue) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        setOpen(globalStates.showLoginModal.get())
    }, [globalStates.showLoginModal.get()])

    return (
        <React.Fragment>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box id="loginModal" sx={style} height={`${value === '1' ? "unset" : "100%"}`}>
                        <Typography id="transition-modal-description" >
                            <Box sx={{ width: '100%', typography: 'body1' }} >
                                <TabContext value={value}>
                                    <div id={'modal-header'} style={{ backgroundImage: `url(${BackgroundImage})`, paddingTop: '15px' }}>
                                        <div style={{ textAlign: "center"}}>
                                            <img src={DailyLogo} width="180" />
                                        </div>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' ,  paddingBottom: 1}}>
                                            <TabList onChange={handleChange} aria-label="login and register" centered>
                                                <Tab label="Login" value="1" />
                                                <Tab label="Register" value="2" />
                                            </TabList>
                                        </Box>
                                    </div>
                                    <TabPanel sx={{ p: 6 }} value="1"><CustomerLogin setvalue={setValue} /></TabPanel>
                                    <TabPanel sx={{ p: 3 }} value="2"><Register /></TabPanel>
                                </TabContext>
                            </Box>
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </React.Fragment >
    )
}

export default LoginModal;