/*******************************************************************************************
//  * File Name                : DeleteSection
//  * File Directory           : src/components/modules/DeleteSection
//  * Module                   : DeleteSection
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Contents
//  * Used In				   : ContentList 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   DeleteSection        -   Modal and for deleting section
 * #How to call:
 *    <DeleteSection/>
 *
 *******************************************************************************************/
import { useState, useEffect } from "react";
import { useState as useHookState } from "@hookstate/core";

//api endpoints
import api from "../../../utility/Endpoints";

//Global States, call funtion and style
import GlobalStates from "../../../utility/GlobalStates";
import ConstantStyles from "../../../utility/ConstantStyle.json";

//material ui library/components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'; 
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


//style starts here
const styles = {
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"]
    },
    btnMustard: {
        backgroundColor: ConstantStyles["mustard"],
        color: ConstantStyles["white-color"]
    },
    modalStyle: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 350,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    }
}
//array for image positions

const DeleteSection = (props) => {
    let data = props.fetchData;
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [id, setId] = useState(data?.id);// data / value for section title
    const [title, setTitle] = useState(data?.title);// data / value for section title
    const [type, setType] = useState(data?.type);// data / value for section title

    useEffect(() => {
        setId(data?.id)
        setTitle(data?.title)
        setType(data?.type)
    }, [data]);

    const handleClose = () => {
        globalStates.modalOpenDelete.set(false)
    };

    const submitContents = () => {
        let payload = {
            id: id,
            user: globalStates.userEmail.get(),
            title: title,
            type: type || "Single"
        }

        // globalStates.isLoading.set(false); // loading
        if (title !== "") {
            api.sections.patch(payload)
                .then(response => {
                    // let id = response.data.insertId;
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("warning");
                    globalStates.msgAlert.set("Section: " + title + " is Deleted!");
                    setTimeout(() => {
                        globalStates.isLoading.set(false); // loading
                        globalStates.modalOpenDelete.set(false);
                    }, 1500);
                })
                .catch(error => {
                    console.log(error);
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("error");
                    globalStates.msgAlert.set("Error: Please contact Admin");
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                        globalStates.modalOpen.set(false); // loading
                        globalStates.isLoading.set(true); // loading
                    }, 3000);
                });
        }
        else {
            globalStates.openAlert.set(true);
            globalStates.statusAlert.set("error");
            globalStates.msgAlert.set("Required: Please fillup all required Fields");

        }
    };

    return (
        <div>
            <Modal
                open={globalStates.modalOpenDelete.get()}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styles.modalStyle}>
                    <br />
                    <form>
                        <Grid container item xs={12} md={12} lg={12} spacing={2}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    <center>
                                        Are you sure you want to delete {title} ?
                                    </center>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <input type="button" value="Cancel" className="btn float-start mt-2" style={styles.btnMustard} onClick={handleClose} />
                            </Grid>
                            <Grid item xs={6} md={6} lg={6}>
                                <input type="button" value="Delete" className="btn float-end mt-2" style={styles.btnPurple} onClick={submitContents} />
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
export default DeleteSection;