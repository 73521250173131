/*******************************************************************************************
//  * File Name                : CreatePageContent
//  * File Directory           : src/components/modules/CreatePageContent
//  * Module                   : CreatePageContent
//  * Author                   : Ryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : Create Page Content 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   CreateContent        -   CreatePageContent for creating new content
 * #How to call:
 *    <CreatePageContent/>
 *
 *******************************************************************************************/
// react-components / library
import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
//api endpoints
import api from "../../../../utility/Endpoints";
import GlobalStates from "../../../../utility/GlobalStates";

//material ui library/components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

//created components
import ConstantStyles from "../../../../utility/ConstantStyle.json";
import InputAutocomplete from '../../../shared/InputAutocomplete';
import QuillEditor from "../../../shared/QuillEditor";

const content_type = window.location.search;
//style starts here
const styles = {
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"],
    },
    bgStyle: {
        backgroundColor: ConstantStyles["purple"],
        borderRadius: "120px"
    },
    formEditor: {
        display: "inline",
        width: "220px",
        float: "left",
        marginTop: "5%"
    },
    formPreview: {
        display: "none"
    },
    contentEditor: {
        position: 'absolute',
        display: "inline",
        width: "98%",
        float: "right",
        right: "-1vw",
        top: content_type === 'Single' ? '-20vh' : '5vh',
        transform: 'scale(0.7)',
        paddingBottom: '15px'
    },
    contentPreview: {
        position: 'absolute',
        right: '12vw',
        width: "98%",
        top: content_type === 'Single' ? '-5vh' : '5vh',
        transform: 'scale(0.9)'
    },
    formStyles: {
        position: "fixed",
        width: "300px",
        height: '80vh',
        overflowY: "auto",
        padding: "10px 5px 0px 10px"
    },
    margin20: {
        marginTop: "20px"
    },
    spacing: {
        padding: "0 5px 0 5vw",
        margin: "10% 1%;"
    },
    mobileView: {
        m: "3rem",
        width: "414px",
        margin: "0 auto"
    }
}
//style ends here

//array for image positions
const postions = [
    { label: 'Left/Bottom', value: 1 },
    { label: 'Right/Top', value: 2 }
];

// options for font selection
const fonts = [
    { label: 'RadnikaMedium', value: "RadnikaMedium" },
    { label: 'RadnikaRegular', value: "RadnikaRegular" },
    { label: 'PoppinsItalic', value: "PoppinsItalic" },
    { label: 'BouncehausSans', value: "BouncehausSans" }
];


const CreatePageContent = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    const params = useParams()
    console.log(params)
    const name = params.name
    let hash_url = window.location.hash,
        formType = window.location.pathname,
        search = window.location.search,
        section_id = hash_url.replace(/#/g, ""); // get section id
    console.log(formType)
    const [title, setTitle] = useState("");// data / value for section title
    const [updateId, setUpdateId] = useState("");// data / value for section title
    const [background, setBackground] = useState("");// data / value for section backgorund
    const [imageUpload, setImageUpload] = useState("");// data / value for for section radius / image radius
    const [inputRadius, setInputRadius] = useState("");// data / value for for section radius / input radius
    const [contents, setContents] = useState(""); // data / value from text / quill editor
    const [imagePlace, setImagePlace] = useState("Left/Bottom") // data / value for image position
    const [showBtns, setShowBtns] = useState(""); //  toggle btn
    const [btnLink, setBtnLink] = useState("");// data / value for href link
    const [btnTitle, setBtnTitle] = useState("");// data / value for btn title
    const [btnBorder, setBtnBorder] = useState(""); // data / value for button border
    const [btnBackground, setBtnBackground] = useState(""); // data / value for button border
    const [btnColor, setBtnColor] = useState(""); // data / value for button color
    const [btnRadius, setBtnRadius] = useState(""); // data / value for button border-radius
    const [heightSection, setHeightSection] = useState(""); // data / value for height section
    const [fontSection, setFontSection] = useState(""); // data / value for font section
    const [fontSize, setFontSize] = useState(""); // data / value for font section
    const [textAlign, setTextAlign] = useState(""); // data / value for font section
    const [quilText, setQuilText] = useState("")
    const [previewFull, setPreviewFull] = useState(false); // data / value for button preview toggle to show in full
    const [btnFont, setBtnFont] = useState(""); // data / value for button font
    const [startDateTime, setStartDateTime] = useState(dayjs(null))
    const [endDateTime, setEndDateTime] = useState(dayjs(null))

    let image_position = imagePlace === "Left/Bottom" ? 1 : 2; // image position 1= left, 2 = right
    let text_position = image_position === 2 ? 1 : 2; // image position 1= left, 2 = right
    let inputBgColor = background === "" ? ConstantStyles['purple'] : background; // image position 1= left, 2 = right
    let radiustImg = image_position === 2 ? "bottomRadiusZero" : "topRadiusZero"; // radius if image postion = 2, bottom left and right radius assign to 0px
    let radiusText = text_position === 2 ? "bottomRadiusZero" : "topRadiusZero"; // radius if image postion = 2, bottom left and right radius assign to 0px

    useEffect(() => {
        if (content_type === "?Single") {
            document.getElementById("preview_mobile").innerHTML = contents.value || ""; // loads state= contents / db to preview quill editor
        }
        document.getElementById("preview").innerHTML = contents.value || ""; // loads state= contents / db to preview quill editor
        if (formType === `/admin-backend/update-page-content/${name}/`) {
            getContents();
        }
    }, []);


    //set new value of quill editor
    const quillEditorChange = value => {
        console.log(value)
        setContents({ value });

        setTimeout(() => {
            // save all attributes for preview data
            document.getElementById("preview").innerHTML = value // show and save all changes from FORM (REALTIME)
            if (content_type === "?Single") {
                document.getElementById("preview_mobile").innerHTML = value // loads data to preview section for mobile
            }
        }, 500);
    };

    //set new value of quill editor
    const togglePreview = () => {
        setPreviewFull(!previewFull);
    };

    //get / fetching data from database
    const getContents = () => {
        api.pageSectionContent.get(section_id) // function from endpoint
            .then(response => {
                globalStates.isLoading.set(true); // loading, false
                let result = response.data; // get result from api
                console.log(result)
                // console.log(result);
                let contents = result.map(col => {
                    return {
                        section_id: col.page_section_id,
                        title: col.title,
                        text: col.text ?? "-",
                        attribute: col.attribute ?? "-",
                        image: col.image ?? "-",
                        button: col.button ?? "-",
                        startDate: col.startDate,
                        endDate: col.endDate
                    }
                });
                // setting all values to state (all data from database)
                let attributes = JSON.parse(contents[0].attribute);
                let images = JSON.parse(contents[0].image);
                let buttons = JSON.parse(contents[0].button);
                let editor = contents[0].text
                setFontSection(attributes.fontSection);
                setFontSize(attributes.fontSize);
                setBackground(attributes.background);
                setInputRadius(attributes.inputRadius);
                setHeightSection(attributes.heightSection);
                setShowBtns(attributes.showBtns);
                setImageUpload(images.imageUpload);
                setImagePlace(images.imagePlace);
                setBtnLink(buttons.btnLink);
                setBtnTitle(buttons.btnTitle);
                setBtnBorder(buttons.btnRadius);
                setBtnBackground(buttons.btnBackground);
                setBtnColor(buttons.btnColor);
                setBtnRadius(buttons.btnRadius);
                setBtnFont(buttons.btnFont);
                setContents({ value: editor });
                setTitle(contents[0].title);
                setUpdateId(contents[0].section_id);
                setStartDateTime(dayjs(contents[0].startDate))
                setEndDateTime(dayjs(contents[0].endDate))
            })
            .catch(error => {
                console.log(error);
            })
    }

    // update contents / section function
    const updateContents = (body) => {
        api.pageSectionContent.put(section_id, body) // from endpoints
            .then(response => {
                let id = response.data.insertId;
                // shows alert, alert status, msg
                // definition of alert status = [success, warning, error]
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("success");
                globalStates.msgAlert.set("Success: Content Updated !");
                setTimeout(() => {
                    globalStates.isLoading.set(false); // loading
                }, 1500);
            })
            .catch(error => {
                console.log(error);
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set("Error: Please contact Admin");
            })
            .finally(() => {
                setTimeout(() => {
                    // once process is done alert, modal create and loading is back to iniatial value
                    globalStates.openAlert.set(false);
                    globalStates.statusAlert.set("");
                    globalStates.msgAlert.set("");
                    globalStates.modalOpenCreate.set(false); // loading
                    globalStates.isLoading.set(true); // loading
                }, 3000);
            });
    }

    const insertContents = (body) => {
        let type = content_type?.replace("?", "");
        api.pageSectionContent.post(body) // POSTING FROM ENDPOINT
            .then(response => {
                let id = response.data.insertId;
                console.log(id);
                console.log(response);
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("success");
                globalStates.msgAlert.set("Success: Created New Content!");
                setTimeout(() => {
                    window.location.href = `/admin-backend/update-page-content/${name}/?` + type + "#" + id;
                    globalStates.isLoading.set(false); // loading
                }, 1500);
            })
            .catch(error => {
                console.log(error);
                globalStates.openAlert.set(true);
                globalStates.statusAlert.set("error");
                globalStates.msgAlert.set("Error: Please contact Admin");
            })
            .finally(() => {
                setTimeout(() => {
                    globalStates.openAlert.set(false);
                    globalStates.modalOpenCreate.set(false); // closed
                    globalStates.isLoading.set(true); // loading
                }, 3000);
            });
    } // save changes to db

    const updateSection = () => {
        // payloads for updating the section
        let payload = {
            id: updateId,
            user: globalStates.userEmail.get(),
            title: title
        }

        // globalStates.isLoading.set(false); // loading
        if (title !== "") {
            api.sections.put(payload)
                .then(response => {
                    // let id = response.data.insertId;
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("success");
                    globalStates.msgAlert.set("Success: Updated!");
                    setTimeout(() => {
                        globalStates.isLoading.set(false); // loading
                        globalStates.modalOpenUpdate.set(false);
                    }, 1500);
                })
                .catch(error => {
                    console.log(error);
                    globalStates.openAlert.set(true);
                    globalStates.statusAlert.set("error");
                    globalStates.msgAlert.set("Error: Please contact Admin");
                })
                .finally(() => {
                    setTimeout(() => {
                        globalStates.openAlert.set(false);
                        globalStates.modalOpen.set(false); // loading
                        globalStates.isLoading.set(true); // loading
                    }, 3000);
                });
        }
        else {
            globalStates.openAlert.set(true);
            globalStates.statusAlert.set("error");
            globalStates.msgAlert.set("Required: Please fillup all required Fields");
        }
    }

    const submitContents = () => {

        let type = content_type.replace("?", "");
        let new_text = (contents.value || "")?.replaceAll(`"`, `'`)
        let attribute = {
            background: background,
            inputRadius: inputRadius,
            showBtns: showBtns,
            heightSection: heightSection,
            fontSection: fontSection,
            fontSize: fontSize
        }
        let button = {
            btnLink: btnLink,
            btnTitle: btnTitle,
            btnBorder: btnBorder,
            btnBackground: btnBackground,
            btnColor: btnColor,
            btnRadius: btnRadius,
            btnFont: btnFont
        }
        let image = {
            imageUpload: imageUpload,
            imagePlace: imagePlace
        }
        let body = {
            section_id: section_id,
            section_type: type,
            text: new_text,
            attribute: attribute,
            image: image,
            button: button,
            section_status: 1,
            startDate: startDateTime,
            endDate: endDateTime
        }

        // globalStates.isLoading.set(false); // loading
        if (formType === `/admin-backend/update-page-content/${name}/`) {
            updateContents(body)
            updateSection()
        }
        else {
            insertContents(body)
        }
    }

    const goBack = () => {
        window.history.back();
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    height: '100vh',
                    width: '100%',
                    overflowY: 'auto'
                }}
            >
                <div style={{ padding: "0 5vw" }}>
                    <h2>Create Page Content</h2>

                    <a href={`#`} onClick={goBack}>Back to List</a>
                    <input type="button" value={previewFull ? "Edit" : "Preview"} className="btn float-end" style={styles.btnPurple} onClick={togglePreview} />
                </div>
                <div style={styles.spacing}>
                    <Box sx={{ display: 'inline' }} style={previewFull ? styles.formPreview : styles.formEditor} >
                        <form style={styles.formStyles} id="createContentForm" >
                            {formType === "/admin-backend/update-page-content" ?
                                <Grid container item xs={12} md={12} lg={12} spacing={1} style={styles.margin10}>
                                    <Grid item xs={12} md={12} lg={12} >
                                        <TextField
                                            id="outlined-basic"
                                            label="Title" Section
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '100%' }}
                                            defaultValue={title}
                                            value={title}
                                            onChange={(e) => {
                                                setTitle(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                null
                            }


                            {/* Height section, font section , border section */}
                            <Grid container item xs={12} md={12} lg={12} spacing={1} style={{ marginTop: "10px" }}>
                                <Grid item xs={12} md={12} lg={12} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker
                                                label="Start Date"
                                                value={startDateTime} onChange={(newValue) => setStartDateTime(newValue)}
                                            />


                                        </DemoContainer>
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateTimePicker']}>
                                            <DateTimePicker
                                                label="End Date"
                                                value={endDateTime} onChange={(newValue) => setEndDateTime(newValue)}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} md={4} lg={content_type !== "?Whole%20Photo" ? 6 : 12} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Height Section"
                                        variant="outlined"
                                        size="small"
                                        sx={{ width: '100%' }}
                                        value={heightSection}
                                        defaultValue={heightSection}
                                        onChange={(e) => {
                                            setHeightSection(e.target.value)
                                        }}
                                    />
                                </Grid>

                                {content_type !== "?Whole%20Photo" ?
                                    <Grid item xs={12} md={4} lg={6} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                        <InputAutocomplete
                                            options={fonts}
                                            value={fontSection}
                                            title="Font Section"
                                            onChange={(e) => {
                                                setFontSection(e.target.innerHTML)
                                            }}
                                        />
                                    </Grid>
                                    : null
                                }
                                <Grid item xs={12} md={4} lg={12} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Border Radius"
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        size="small"
                                        value={inputRadius}
                                        defaultValue={inputRadius}
                                        onChange={(e) => {
                                            setInputRadius(e.target.value)
                                        }}
                                    />
                                </Grid>

                            </Grid>

                            {/* Bg Section, Image Section , Image position */}
                            <Grid container item xs={12} md={12} lg={12} spacing={1} style={content_type !== "?Whole%20Photo" ? styles.margin20 : { marginTop: '2px' }}>

                                {content_type !== "?Whole%20Photo" ?
                                    <Grid item sx={12} md={4} lg={6} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                        <TextField
                                            id="outlined-basic"
                                            label="Background Color"
                                            type="color"
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '100%' }}
                                            value={background}
                                            defaultValue={background}
                                            onChange={(e) => {
                                                setBackground(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    : null
                                }
                                <Grid item xs={12} md={4} lg={content_type !== "?Whole%20Photo" ? 6 : 12} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                    <TextField
                                        id="outlined-basic"
                                        label="Image"
                                        variant="outlined"
                                        sx={{ width: '100%' }}
                                        size="small"
                                        value={imageUpload}
                                        defaultValue={imageUpload}
                                        onChange={(e) => {
                                            setImageUpload(e.target.value)
                                        }}
                                    />
                                </Grid>
                                {content_type !== "?Whole%20Photo" ?
                                    <Grid item xs={12} md={4} lg={12} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                        <InputAutocomplete
                                            options={postions}
                                            value={imagePlace}
                                            title="Image Position"
                                            onChange={(e) => {
                                                setImagePlace(e.target.innerHTML)
                                            }}
                                        />
                                    </Grid>
                                    : null
                                }
                            </Grid>

                            {/* Button visibility toggle */}
                            {content_type !== "?Whole%20Photo" ?
                                <Grid container item xs={12} md={12} lg={12} spacing={1} style={styles.margin20}>
                                    <Grid item xs={12} md={12} lg={12} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label">Button Visibility</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={showBtns}
                                                defaultValue={showBtns}
                                                onChange={(e) => {
                                                    setShowBtns(e.target.value)
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="Show"
                                                    control={<Radio
                                                        size="small" />}
                                                    label="Show"
                                                    size="small"
                                                />
                                                <FormControlLabel
                                                    value="Hide"
                                                    control={<Radio
                                                        size="small" />}
                                                    label="Hide"
                                                    size="small"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid> : null
                            }

                            {/* Button Settings */}
                            <Grid container item xs={12} md={12} lg={12} spacing={1} style={styles.margin20}>
                                <Grid item xs={12} md={12} lg={12} order={{ xs: image_position, md: image_position, lg: image_position }} >
                                    {showBtns === "Show" ?
                                        < TextField
                                            id="outlined-basic"
                                            label="Button Title"
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '100%' }}
                                            value={btnTitle}
                                            defaultValue={btnTitle}
                                            onChange={(e) => {
                                                setBtnTitle(e.target.value)
                                            }} />
                                        : null}
                                </Grid>

                                <Grid item xs={12} md={12} lg={4}  >
                                    {showBtns === "Show" ?
                                        < TextField
                                            id="outlined-basic"
                                            label="Button Radius"
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '100%' }}
                                            value={btnRadius}
                                            defaultValue={btnRadius}
                                            onChange={(e) => {
                                                setBtnRadius(e.target.value)
                                            }} />
                                        : null}
                                </Grid>

                                <Grid item xs={12} md={12} lg={8}  >
                                    {showBtns === "Show" ?
                                        < TextField
                                            id="outlined-basic"
                                            label="Button Link"
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '100%' }}
                                            defaultValue={btnLink}
                                            value={btnLink}
                                            onChange={(e) => {
                                                setBtnLink(e.target.value)
                                            }} />
                                        : null}
                                </Grid>

                                <Grid item xs={12} md={12} lg={6} order={{ md: 2, lg: 2 }} style={styles.margin20} >
                                    {showBtns === "Show" ?
                                        < TextField
                                            id="outlined-basic"
                                            label="Button Background"
                                            variant="outlined"
                                            size="small"
                                            type="color"
                                            sx={{ width: '100%' }}
                                            defaultValue={btnBackground}
                                            value={btnBackground}
                                            onChange={(e) => {
                                                setBtnBackground(e.target.value)
                                            }} />
                                        : null}
                                </Grid>

                                <Grid item xs={12} md={12} lg={6} order={{ md: 2, lg: 2 }} style={styles.margin20} >
                                    {showBtns === "Show" ?
                                        < TextField
                                            id="outlined-basic"
                                            label="Button Border Color"
                                            type="color"
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '100%' }}
                                            defaultValue={btnBorder}
                                            value={btnBorder}
                                            onChange={(e) => {
                                                setBtnBorder(e.target.value)
                                            }} />
                                        : null}
                                </Grid>

                                <Grid item xs={12} md={12} lg={6} order={{ md: 2, lg: 2 }} style={styles.margin20}>
                                    {showBtns === "Show" ?
                                        < TextField
                                            id="outlined-basic"
                                            label="Button Font Color"
                                            variant="outlined"
                                            size="small"
                                            type="color"
                                            sx={{ width: '100%' }}
                                            defaultValue={btnColor}
                                            value={btnColor}
                                            onChange={(e) => {
                                                setBtnColor(e.target.value)
                                            }} />
                                        : null}
                                </Grid>

                                <Grid item xs={12} md={4} lg={6} order={{ md: 2, lg: 2 }} style={styles.margin20}>
                                    {showBtns === "Show" ?
                                        <InputAutocomplete
                                            options={fonts}
                                            value={btnFont}
                                            title="Font Section"
                                            onChange={(e) => {
                                                setBtnFont(e.target.innerHTML)
                                            }}
                                        />
                                        : null}
                                </Grid>

                            </Grid>
                            {/* Quill editor Settings */}

                            {content_type !== "?Whole%20Photo" ?
                                <Grid container item xs={12} md={12} lg={12} spacing={1} style={styles.margin20}>
                                    <Grid item xs={12} md={12} lg={12} order={{ xs: image_position, md: image_position, lg: image_position }} >
                                        {/* quill editor */}
                                        <QuillEditor
                                            value={contents.value || ""}
                                            onChange={quillEditorChange}
                                            fontSize={fontSize}
                                        />

                                    </Grid>
                                </Grid> : null
                            }
                            <Grid container item xs={12} md={12} lg={12} className='mb-3'>
                                <Grid item xs={12} md={12} lg={12}>
                                    <input type="button" value="Submit" className="btn mt-2" style={styles.btnPurple} onClick={submitContents} />
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                    {/* Please take note that fontsection is depends on the selected option on the from. Please see line 83 for reference */}
                    <Box sx={{
                        width: '100vw',
                        position: 'relative'
                    }}>
                        {
                            content_type === "?Single" ?
                                <Box style={previewFull ? styles.contentPreview : styles.contentEditor} >
                                    <center>
                                        <h2>Desktop View</h2>
                                    </center>
                                    <Grid container item xs={12} md={12} lg={12} spacing={0} style={{ m: "3rem", backgroundColor: inputBgColor, borderRadius: inputRadius, height: heightSection, transform: "scale(0.7)" }}>
                                        <Grid item xs={12} md={12} lg={6} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                            <div className="textPrev" style={{ padding: '30px' }}>
                                                {/* Please take ntoe that this is required since dito magsho-show html from editor please check line 79 for reference */}
                                                <div id="preview" className={`${fontSection} ${radiusText}`}>
                                                </div>
                                                {/* 
                                    Please take note that the value for the ff are from useState :
                                        showBtns
                                        buttonLink
                                        btnTitle
                                        btnBorder
                                        btnBackground
                                        btnColor
                                        btnRadius
                                    */}
                                                {showBtns === "Show" ?
                                                    <Button
                                                        sx={{
                                                            borderColor: btnBorder,
                                                            backgroundColor: btnBackground,
                                                            color: btnColor,
                                                            borderRadius: btnRadius,
                                                            margin: "10px auto",
                                                            width: "30%",
                                                            display: "block",
                                                            textAlign: "center",
                                                            ':hover': {
                                                                border: ConstantStyles['default-border'], // theme.palette.primary.main
                                                                color: 'white',
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        href={btnLink}
                                                        className={`${btnFont}`}
                                                    >
                                                        {btnTitle}
                                                    </Button>
                                                    :
                                                    null
                                                }
                                            </div>

                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6} order={{ md: text_position, lg: text_position }}>
                                            <div className="preview_img">
                                                <img
                                                    src={imageUpload}
                                                    style={{ borderRadius: inputRadius, height: heightSection, minHeight: "272px" }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <br />
                                    <center>
                                        <h2>Mobile View</h2>
                                    </center>
                                    <Grid container item xs={12} md={12} lg={12} spacing={0} style={styles.mobileView}>
                                        <Grid item xs={12} md={12} lg={12}
                                            order={{ xs: 12, md: 12, lg: 12 }}
                                            className={`topRadiusZero`}
                                            style={{ m: "3rem", backgroundColor: inputBgColor, borderRadius: inputRadius, height: heightSection }}>
                                            <div className="textPrev" style={{ transform: 'scale(0.85)' }}>
                                                {/* Please take ntoe that this is required since dito magsho-show html from editor please check line 79 for reference */}
                                                <div id="preview_mobile" className={` ${fontSection} `}>
                                                </div>
                                                {/* 
                                        Please take note that the value for the ff are from useState :
                                            showBtns
                                            buttonLink
                                            btnTitle
                                            btnBorder
                                            btnBackground
                                            btnColor
                                            btnRadius
                                        */}
                                                {showBtns === "Show" ?
                                                    <Button
                                                        sx={{
                                                            borderColor: btnBorder,
                                                            backgroundColor: btnBackground,
                                                            color: btnColor,
                                                            borderRadius: btnRadius,
                                                            margin: "10px auto",
                                                            width: "60%",
                                                            display: "block",
                                                            textAlign: "center",
                                                            ':hover': {
                                                                border: ConstantStyles['default-border'], // theme.palette.primary.main
                                                                color: 'white',
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        href={btnLink}
                                                        className={`${btnFont}`}
                                                    >
                                                        {btnTitle}
                                                    </Button>
                                                    :
                                                    null
                                                }
                                            </div>

                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} order={{ md: text_position, lg: text_position }}>
                                            <div className="preview_img">
                                                <img
                                                    src={imageUpload}
                                                    className={`bottomRadiusZero`}
                                                    style={{ borderRadius: inputRadius, height: heightSection, minHeight: "272px" }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>

                                :

                                <Box style={previewFull ? styles.contentPreview : styles.contentEditor} >
                                    {content_type !== "?Whole%20Photo" ?
                                        <Grid container xs={12} md={12} lg={12} spacing={0} style={{ m: "3rem", backgroundColor: inputBgColor, borderRadius: inputRadius, height: heightSection, width: "50%", margin: "0 auto" }}>
                                            <Grid item xs={12} md={12} lg={12} order={{ xs: image_position, md: image_position, lg: image_position }}>
                                                <div className="textPrev" style={{ borderRadius: inputRadius, height: heightSection, minHeight: "372px" }}>
                                                    {/* Please take ntoe that this is required since dito magsho-show html from editor please check line 79 for reference */}
                                                    <div id="preview" className={`${fontSection}`}>
                                                    </div>
                                                    {/* 
                                                Please take note that the value for the ff are from useState :
                                                    showBtns
                                                    buttonLink
                                                    btnTitle
                                                    btnBorder
                                                    btnBackground
                                                    btnColor
                                                    btnRadius
                                                */}
                                                    {showBtns === "Show" ?
                                                        <Button
                                                            sx={{
                                                                borderColor: btnBorder,
                                                                backgroundColor: btnBackground,
                                                                color: btnColor,
                                                                borderRadius: btnRadius,
                                                                margin: "10px auto",
                                                                width: "30%",
                                                                display: "block",
                                                                textAlign: "center",
                                                                ':hover': {
                                                                    border: ConstantStyles['default-border'], // theme.palette.primary.main
                                                                    color: 'white',
                                                                },
                                                            }}
                                                            variant="outlined"
                                                            href={btnLink}
                                                            className={`${btnFont}`}
                                                        >
                                                            {btnTitle}
                                                        </Button>
                                                        :
                                                        null
                                                    }
                                                </div>

                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12} order={{ md: text_position, lg: text_position }}>
                                                <div className="preview_img">
                                                    <img
                                                        src={imageUpload}
                                                        className={`${radiustImg}`}
                                                        style={{ borderRadius: inputRadius, height: heightSection }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid container xs={12} md={12} lg={12} spacing={0} style={{ m: "3rem", backgroundColor: inputBgColor, borderRadius: inputRadius, height: heightSection, width: "50%", margin: "0 auto" }}>
                                            <Grid item xs={12} md={12} lg={12} order={{ md: text_position, lg: text_position }}>
                                                <div className="preview_img">
                                                    <div id="preview" className={`${fontSection}`}>
                                                    </div>
                                                    <img
                                                        src={imageUpload}
                                                        style={{ borderRadius: inputRadius, height: heightSection }}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    }
                                </Box>
                        }
                    </Box>
                </div>
            </Box >
        </React.Fragment >
    )


}


export default CreatePageContent;