/*******************************************************************************************
//  * File Name                : FeedbackForm
//  * File Directory           : src/components/modules/FeedbackForm
//  * Module                   : FeedbackForm
//  * Author                   : bryan
//  * Version                  : 1
//  * Purpose                  : Feedback
//  * Used In				   : Customer order feedback
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   FeedbackForm        -   FeedbackForm for customers to get their feedback
 * #How to call:
 *    <FeedbackForm/>
 *
 *******************************************************************************************/

// react-components / library
import React, { useState, useEffect } from 'react'
import { useHookstate } from '@hookstate/core';

// daily's logo
import Logo from '../../../assets/logo/daily-logo.png'
import BackgroundImage from "../../../assets/backgroundImage/pattern.jpg"
import HandCoffee from "../../../assets/icons/hand coffee.png"

// MUI components
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormLabel from '@mui/material/FormLabel';
import Rating from '@mui/material/Rating';
import { Alert, Grid } from '@mui/material';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
// utility
import GlobalStates from "../../../utility/GlobalStates";
import ConstantStyles from "../../../utility/ConstantStyle.json"
import api from '../../../utility/Endpoints';
import defaultImage from '../../../assets/logo/default.webp'


// internal css
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    width: "inherit",
    // p: 3,
    rate: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        flexDirection: "column",
        // backgroundImage: `url(${BackgroundImage})`,
        borderRadius: '10px 10px 0px 0px'

    },
    cardHeader: {
        fontSize: "13px", 
        fontWeight: "bold"
    }
};

// Rating labels
const labels = {
    1: 'Poor',
    2: 'No good',
    3: 'Ok',
    4: 'Good',
    5: 'Excellent',
  };


// FeedbackForm Param
// - modal ( boolean ) : for modal to show or hide 
const FeedbackForm = ({ modal }) => {
    const globalStates = useHookstate(GlobalStates); // call globalstates
    const [open, setOpen] = useState(false); // for modal show
    const [value, setValue] = useState(0); // star rating value
    const [hover, setHover] = useState(-1); // star rating label
    const [done, setDone] = useState(false) // show thankyou for feedback
    const [disable, setDisable] = useState(false) // show thankyou for feedback
    const [feedback, setFeedback] = useState([]) // get orders 
    const [orders, setOrders] = useState([])
    const [thankyouMsg, setThankyouMsg] = useState("")
    let user = JSON.parse(localStorage.getItem('customerInfo'))

    // console.log(user);
    useEffect(() => {
        if (feedback.length > 0) {
            setOpen(true)
            const renderOrders = JSON.parse(feedback[0].cart)
            setOrders(renderOrders)
        }
    }, [feedback])

    useEffect(() => {
        getFeedback()
    }, [])
    const getFeedback = async () => {
        await api.customerFeedback.getFeedback(user?.customer_id || 0)
        .then((response) => {
            const data = response.data
            setFeedback(data)
        })
        .catch((error) => {
            console.log(error);
            globalStates.openAlert.set(true)
            globalStates.statusAlert.set("error")
            globalStates.msgAlert.set(error)
        })
    }

    // get Rating label
    const getLabelText = (value) => {
        return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
    }

    // onSubmit 
    const handleSubmit =  async (e) => {
        e.preventDefault();

        const order_id = feedback[0]?.order_id
        const comment = e.target.comment.value
        const starRating = value
        const payload = {
            feedback: comment,
            rate: starRating,
            rate_date: new Date()
        }

        setDisable(true)
        await api.customerFeedback.insertFeedback(order_id, payload)
        .then((response) => {
            setDone(true)
            setThankyouMsg("Thank you for your feedback!!")
            setTimeout(() => {
                setOpen(false)
                setDisable(false)
            }, 2000);
        })
        .catch((error) => {
            console.log(error);
            globalStates.openAlert.set(true)
            globalStates.statusAlert.set("error")
            globalStates.msgAlert.set(error)
        })

    }

    // onClick maybe later button 
    const onClickLater = async (e) => {
        e.preventDefault()

        const order_id = feedback[0]?.order_id
        const comment = "-"
        const starRating = "-"
        const payload = {
            feedback: comment,
            rate: starRating,
            rate_date: new Date()
        }

        // disable button upon click
        setDisable(true)
        setThankyouMsg("Thank you!!")

        // execute api request
        await api.customerFeedback.insertFeedback(order_id, payload)
        .then((response) => {
            setDone(true)
            setTimeout(() => {
                setOpen(false) // hide feedback modal

            }, 2000);
        })
        .catch((error) => {
            console.log(error);
            globalStates.openAlert.set(true)
            globalStates.statusAlert.set("error")
            globalStates.msgAlert.set(error)
        })
    }
    return (
        <React.Fragment>
            <Modal
                open={open}
                // onClose={handleClose}
            >
                <Box sx={{width: globalStates.isMobile.get() ? "95%" : 500}}>
                    <Box sx={style}>
                    {   
                        done ? 
                        <Box sx={{borderRadius: '10px',backgroundImage: `url(${BackgroundImage})`, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "auto"}}>
                            {/* <EmojiFoodBeverageIcon sx={{color : ConstantStyles["purple"], height: "60px", fontSize: "20rem"}}/> */}
                            <img src={HandCoffee} style={{height: '200px', width: '200px'}} alt="feedback" />
                            <Typography align='center' sx={{fontSize: '2rem', fontFamily: 'Bounce-haus-Sans',color : ConstantStyles["purple"], lineHeight: 'normal', padding: 2}}>
                                {thankyouMsg}
                            </Typography>
                        </Box>
                        :
                        <Box>
                            <form onSubmit={handleSubmit} >
                                <Box style={style.rate}>
                                    <Box sx={{backgroundImage: `url(${BackgroundImage})`, width: '100%', borderRadius: '10px 10px 0px 0px',display: "flex", justifyContent: "center"}}>
                                        <img src={Logo} alt='daily' style={{height: "75px",margin: '10px'}}/>
                                    </Box>
                                    <Box style={{display: 'flex',height: "100px", flexDirection: "column", alignItems: "center",}}>
                                        <Typography sx={{fontSize: "25px", color: ConstantStyles['purple'], fontFamily: 'Bounce-haus-Sans'}}>Rate us</Typography>
                                        <Rating 
                                            sx={{span: {fontSize: "40px"}}}
                                            name="starRating"
                                            value={value}
                                            getLabelText={getLabelText}
                                            onChange={(event, newValue) => {
                                                setValue(newValue);
                                            }}
                                            onChangeActive={(event, newHover) => {
                                                setHover(newHover);
                                            }}
                                            size="large"
                                        />
                                        {value !== null && (
                                        <Box sx={{ ml: 2, color: ConstantStyles["purple"]}}>{labels[hover !== -1 ? hover : value]}</Box>
                                        )}
                                    </Box>
                                </Box>
                                <Box padding={2}>
                                    <FormLabel>Comments</FormLabel>
                                    <TextareaAutosize
                                        name="comment"
                                        aria-label="minimum height"
                                        minRows={3}
                                        style={{ width: "100%", maxHeight: globalStates.isMobile.get() ? "150px" : "250px" }}
                                    />
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid item xs={12} sx={{display: "flex", alignItems: "center", marginTop: "10px"}}>
                                                <Grid item sm={6} md={6} xs={6}>
                                                    <Typography sx={{fontWeight: "bold"}} >List of orders</Typography>
                                                </Grid>
                                                <Grid item sm={6} md={6} xs={6}>
                                                    <Typography align='right' >Order #: {feedback[0]?.order_id}</Typography>
                                                </Grid>
                                            </Grid>
                                        <hr style={{marginTop: 0, marginBottom: "10px"}} />
                                        </Grid>
                                        <Grid item xs={12} sx={{maxHeight: globalStates.isMobile.get() ? "150px" : "250px", overflow: "auto"}}>
                                            {
                                                orders.map( order => {
                                                    console.log(order);
                                                    const image_url = order.item_image ? process.env.REACT_APP_HOST + order.item_image : defaultImage
                                                    return (
                                                        // list of orders
                                                        <Grid item sx={{ paddingTop: "5px", paddingBottom: "5px"}}>
                                                            <Grid item sx={{display: "flex", margin: "auto"}}>   
                                                                <Grid item xs={3} md={3} sx={{margin: "10px"}} >
                                                                    <img src={image_url} alt={order.item_id} style={{height: "auto", width: "100%"}} />
                                                                </Grid>
                                                                
                                                                <Grid item xs={9} md={9} sx={{margin: "10px"}} >
                                                                    <Typography sx={{fontSize: "1.14285714rem", lineHeight: 1, color: ConstantStyles["purple"]}}>{order.item_name}</Typography>
                                                                    <Grid item xs={12} >

                                                                        <Grid item sx={{display: "flex", flexBasis: 0}} >
                                                                            <Typography sx={{fontSize: "15px", paddingRight: "5px"}}>Qty: </Typography>
                                                                            <Typography sx={{fontStyle: "italic", fontSize: "15px", paddingRight: "5px"}}>{order.item_qty}</Typography>
                                                                        </Grid>

                                                                        <Grid item sx={{display: "flex", flexBasis: 0}} >
                                                                            <Typography sx={{fontSize: "15px", paddingRight: "5px"}}>Price: </Typography>
                                                                            <Typography sx={{fontStyle: "italic", fontSize: "15px", paddingRight: "5px"}}>₱{order.original_price}</Typography>
                                                                        </Grid>

                                                                        <Grid item sx={{display: "flex", flexBasis: 0}}  >
                                                                            <Typography sx={{fontSize: "15px", paddingRight: "5px"}}>Remarks: </Typography>
                                                                            <Typography sx={{fontSize: "15px", paddingRight: "5px"}}>{order.item_remarks}</Typography>
                                                                        </Grid>
                                                                    
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            <hr/>
                                            <Grid item xs={12} sx={{display: "flex", justifyContent: "flex-end"}}>
                                                <Button onClick={onClickLater}>maybe later </Button>
                                                <Button variant="contained" disabled={ disable || value === 0 || value === null ? true : false } type='submit'>{ disable? "...Saving" : "Submit"}</Button>
                                                
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        </Box>
                    }
                    </Box>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

export default FeedbackForm