import { useState as useHookState } from "@hookstate/core";
import { useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import api from "../../../utility/Endpoints";
import GlobalStates from "../../../utility/GlobalStates";
import ConstantStyles from "../../../utility/ConstantStyle.json";

import DailyLogo from "../../../assets/logo/daily-logo.png";

// mui components
//material ui library/components
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { addPayment } from "../../actions";


const Page = (props) => {
    const { order } = props
    const params = useParams()
    const globalStates = useHookState(GlobalStates); // call globalstates
    const isMobile = globalStates.isMobile.get()
    let cardSize = globalStates.isMobile.get() ? 12 : 6;
    const [list, setList] = useState([])
    const id = parseInt(params.id)

    useEffect(() => {
//        getPageContent()
    }, [])

    const getPageContent = () => {
        api.page.selectPage(id)
            .then(response => {
                let result = response.data; // get result from api
                let contents = result.map((col, index) => {
                    return {
                        index: index,
                        section_id: col.section_id ?? "-",
                        title: col.title ?? "-",
                        type: col.type ?? "-",
                        sort: col.sort ?? "-",
                        status: col.status ?? "-",
                        content_id: col.content_id ?? null,
                        sectionId: col.sectionId ?? "-",
                        text: col.text,
                        attribute: col.attribute,
                        image: col.image,
                        button: col.button
                    }
                });
                setList(contents)
            })
            .catch(error => {
                console.log(error);
            })
    }


    const renderSection = list.map((item, index) => {
        let attributes = JSON.parse(item.attribute);
        // console.log(attributes)
        let images = JSON.parse(item.image);
        let buttons = JSON.parse(item.button);
        let image_position = images.imagePlace === "Left/Bottom" ? 1 : 2; // image position 1= left, 2 = right
        let text_position = image_position === 2 ? 1 : 2; // image position 1= left, 2 = right
        let inputBgColor = attributes.background === "" ? ConstantStyles['purple'] : attributes.background; // image position 1= left, 2 = right

        let radiusbottom = image_position === 2 ? "bottomRadiusZero" : ""; // radius if image postion = 2, bottom left and right radius assign to 0px
        let radiusTop = image_position === 1 ? "topRadiusZero" : "    "; // radius if image postion = 2, bottom left and right radius assign to 0px

        setTimeout(() => {
            let element = document.getElementById("preview_" + item.content_id)
            if (element)
                element.innerHTML = item.text // show string to html
        }, 200);

        return (
            <React.Fragment key={index}>
                {item.type === "Single" ?
                    < Grid container item xs={12} md={12} lg={12} spacing={0} style={{ margin: "1rem 0.5rem", backgroundColor: inputBgColor, borderRadius: attributes.inputRadius, height: attributes.heightSection }}>
                        <Grid item xs={12} md={12} lg={6} order={isMobile ? {} : { xs: text_position, md: text_position, lg: text_position }}>
                            <div className="preview_img">
                                <img
                                    className={`${isMobile ? 'bottomRadiusZero' : ''}`}
                                    src={images.imageUpload}
                                    style={{ borderRadius: attributes.inputRadius, height: attributes.heightSection, minHeight: '272px' }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} order={{ xs: image_position, md: image_position, lg: image_position }}>
                            <div className="textPrev" style={isMobile ? { transform: 'scale(0.85)' } : { padding: "30px" }} >
                                <div id={`preview_${item.content_id}`} className={`${attributes.fontSection}`}>
                                </div>
                                {attributes.showBtns === "Show" ?
                                    <Button
                                        style={{
                                            borderColor: buttons.btnBorder,
                                            backgroundColor: buttons.btnBackground,
                                            color: buttons.btnColor,
                                            borderRadius: buttons.btnRadius,
                                            margin: "10px auto",
                                            width: isMobile ? "60%" : "30%",
                                            display: "block",
                                            textAlign: "center",
                                            ':hover': {
                                                border: ConstantStyles['default-border'], // theme.palette.primary.main
                                                color: 'white',
                                            },
                                        }}
                                        variant="outlined"
                                        href={buttons.btnLink}
                                        className={`${buttons.btnFont}`}
                                    >
                                        {buttons.btnTitle}
                                    </Button>
                                    :
                                    null
                                }
                            </div>
                        </Grid>

                    </Grid>
                    :

                    item.type !== "Whole Photo" ?
                        <Grid item xs={cardSize} className={`${attributes.fontSection}`} style={{ margin: "1rem 0", padding: "0.5rem" }}  >

                            <Card style={{ background: inputBgColor, borderRadius: attributes.inputRadius }} >
                                <CardMedia
                                    component="img"
                                    alt="Daily Logo"
                                    height="100%"
                                    sx={{ minHeight: '272px' }}
                                    image={images.imageUpload || DailyLogo}
                                />
                                <CardContent >
                                    <div id={`preview_${item.content_id}`} style={{ padding: "30px" }}>
                                    </div>
                                    {attributes.showBtns === "Show" ?
                                        <Button
                                            style={{
                                                borderColor: buttons.btnBorder,
                                                backgroundColor: buttons.btnBackground,
                                                color: buttons.btnColor,
                                                borderRadius: buttons.btnRadius,
                                                margin: "10px auto",
                                                width: "30%",
                                                display: "block",
                                                textAlign: "center",
                                                ':hover': {
                                                    border: ConstantStyles['default-border'], // theme.palette.primary.main
                                                    color: 'white',
                                                },
                                            }}
                                            variant="outlined"
                                            href={buttons.btnLink}
                                            className={`${buttons.btnFont}`}
                                        >
                                            {buttons.btnTitle}
                                        </Button>
                                        :
                                        null
                                    }
                                </CardContent>
                            </Card>



                        </Grid>
                        :
                        <Grid item xs={12} className={`${attributes.fontSection}`} style={{ margin: "1rem 0", padding: "0.5rem" }}  >
                            <Card style={{ background: inputBgColor, borderRadius: attributes.inputRadius }} >
                                <CardMedia
                                    component="img"
                                    alt="Daily Logo"
                                    height={attributes.heightSection}
                                    sx={{ minHeight: '272px' }}
                                    image={images.imageUpload || DailyLogo}
                                />
                            </Card>
                        </Grid>
                }
            </React.Fragment >
        );

    });

    return (
        <React.Fragment>
            <div style={globalStates.isMobile.get() ? { marginTop: '150px' } : { marginTop: '120px' }}>
                <Box width={'100%'} display={'block'}>
                    <Grid
                        container
                        spacing={0}

                    >
                        {renderSection}
                    </Grid >
                </Box >
            </div>
        </React.Fragment>
    )


}


export default Page;