import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';


function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const style = {
    container: {
        width: 400
    },
    row: {
        display: 'flex',
        heigth: 50,
        alignItems: 'center',
        width: '100%',
        paddingTop: '5px',
        paddingBottom: '5px',
        borderBottom: '1px solid #f2dbdb',
    },
    name: {
        display: 'flex',
        justifyContent: 'center',
        width: '60%'
    },
    switch: {
        display: 'flex',
        justifyContent: 'center',
        width: '40%'
    }
  }

  
const BranchStatus = (props) => {
    const { data, branches, open, close, api, refresh } = props
    
    const onChangeStatus = (e) => {
        const branch_id = e.target.id
        const branches = data.branch
        const selectedBranch = branches.filter( b => parseInt(b.branch_id) === parseInt(branch_id) )
        const newBranches = branches.filter( b => parseInt(b.branch_id) !== parseInt(branch_id) )
        const status = e.target.checked ? 1 : 0
        const body = {
            branch_id: branch_id,
            branch_name: selectedBranch[0]?.branch_name,
            abbreviation: selectedBranch[0]?.abbreviation,
            product_status: status
        }
        newBranches.push(body)

        const payload = {
            id: data.id,
            branch: JSON.stringify(newBranches)
        }
        api.changeStatus(payload)
        .then((response) => {
            console.log(response);
            refresh()
        })
        .catch((error) => {
            console.log(error.message);
        })
    }

    console.log(branches);
    const renderBranches = branches?.map( (branch,index) => {
        const status = branch.product_status === 1 || branch.product_status === '1' ? true : false
        return (
            <Box key={branch.branch_id} sx={style.row}>
                <Box sx={style.name}>
                    <Typography>
                        {branch.branch_name} ({branch.abbreviation})
                    </Typography>
                </Box>
                <Box sx={style.switch}>
                    <Switch
                        size='small'
                        checked={status}
                        id={branch.branch_id}
                        onChange={onChangeStatus}
                    />
                </Box>
            </Box>
        )
    })
    
    return (
        <>
            <React.Fragment>
                <BootstrapDialog
                    open={open}
                >
                    <BootstrapDialogTitle id="customized-dialog-title" onClose={() => close()}>
                        {data.name}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Box sx={style.container}>
                            {renderBranches}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => close()}>
                            Close
                        </Button>
                    </DialogActions>
                </BootstrapDialog>
            </React.Fragment>
        </>
    )
}

export default BranchStatus