import { useState as useHookState } from "@hookstate/core";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { useEffect, useState } from 'react';
import GlobalStates from "../../../utility/GlobalStates";
import SelectStoreForm from './SelectStoreForm';

const SelectStoreDrawer = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: globalStates.openStoreDrawer.get(),
        right: false,
    });

    useEffect(() => {
        toggleDrawer('bottom', globalStates.openStoreDrawer.get())
    }, [globalStates.openStoreDrawer.get()])

    const toggleDrawer = (anchor, open) => {
        setState({ ...state, [anchor]: open });
    }

    return (
        <React.Fragment>
            <SwipeableDrawer
                sx={{
                    height: (window.innerHeight * 0.7),
                    flexShrink: 0,

                    '& .MuiDrawer-paper': {
                        height: (window.innerHeight * 0.7),
                        boxSizing: 'border-box',
                        borderRadius: '15px 15px 0px 0px',
                    },
                }}
                disableBackdropTransition={true}
                anchor={'bottom'}
                open={state['bottom']}
                onClose={() => {
                    toggleDrawer('bottom', false)
                    globalStates.openStoreDrawer.set(false)
                }}
                onOpen={() => {
                    toggleDrawer('bottom', true)
                    globalStates.openStoreDrawer.set(true)
                }}
            >
                <SelectStoreForm />
            </SwipeableDrawer>
        </React.Fragment>
    )

}


export default SelectStoreDrawer;