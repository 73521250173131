// react
import React, { useEffect, useState } from 'react';

import { useState as useHookState } from "@hookstate/core";
import { connect } from "react-redux";

//Global States, call funtion
import ConstantStyles from "../../utility/ConstantStyle.json";
import GlobalStates from "../../utility/GlobalStates";

//img
import Address from '../../assets/icons/Address.png';
import DailyLogo from "../../assets/logo/daily-logo.png";
import navItems from "../../utility/Home/NavItems.json";

//mui components
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


// icons
import CartIcon from '../../assets/icons/Cart.png';
import UserIcon from '../../assets/icons/User.png';
//cart
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Cart from '../modules/Cart/Cart';
import LoginModal from "../modules/Login/LoginModal";
import SelectStoreDrawer from "../modules/SelectStore/SelectStoreDrawer";
import SelectStoreModal from "../modules/SelectStore/SelectStoreModal";
import PageListMenu from './PageListMenu';
const drawerWidth = 240;
const announcement = localStorage.getItem('announcement') === "1"



// const SearchBoxDesktop = () => {
//     const [search, setSearch] = useState('')
//     const dispatch = useDispatch()

//     useEffect(() => {
//         const timeOutId = setTimeout(() => dispatch(searchProduct(search)), 800);
//         return () => clearTimeout(timeOutId)
//     }, [search]);

//     const handleSearch = (e) => {
//         const value = e.target.value
//         setSearch(value)
//     }
//     return (
//         <Box
//             sx={{
//                 p: '2px 4px',
//                 display: 'flex',
//                 alignItems: 'center',
//                 width: 350,
//                 border: `1px solid ${ConstantStyles['purple']}`,
//                 borderRadius: 5,
//                 height: '45px'
//             }}
//         >
//             <InputBase
//                 sx={{ ml: 1, flex: 1, color: ConstantStyles['purple'] }}
//                 placeholder="Search"
//                 value={search}
//                 onChange={handleSearch}
//             />
//             <SearchIcon sx={{ color: ConstantStyles['purple'] }} />
//         </Box>
//     )
// }


// const navItems = ['Home', 'Products', 'About us'];

const styles = {
    desktopHeader: { marginTop: `${announcement ? '40px' : '0px'}`, background: "#FFF" },
    mobileHeader: { marginTop: `${announcement ? '40px' : '0px'}`, background: "#FFF" },
    orderCount: {
        width: '15px',
        height: '15px',
        position: 'absolute',
        right: '0px',
        top: '0px',
        color: 'white',
        borderRadius: '20px',
        fontSize: '10px',
        background: ConstantStyles['purple'],

    }
}

const Navbar = (props) => {
    let customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
    const guestSignIn = localStorage.getItem('guestSignIn') === 'true'
    const globalStates = useHookState(GlobalStates); // call globalstates
    const { cart, store } = props; // mui default value , need tong props 
    const [mobileOpen, setMobileOpen] = useState(false); // toggle ng sidebar
    const [linksList, setLinksList] = useState([])
    let openSnackbar = globalStates.snackBarStatus.get(); // style ng header for margin-top 
    let snackbarMsg = globalStates.snackBarMsg.get() // style ng header for margin-top 
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [cartState, setCartState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [screen, setScreen] = useState(window.innerWidth);
    let isMobile = screen <= 767
    const path = window.location.pathname
    let newStyle = isMobile ? styles.mobileHeader : styles.desktopHeader; // style ng header for margin-top 
    let toggleSide = isMobile ? "bottom" : "right" // cart toggle view 
    
    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const drawerlinks = navItems.map((item, index) => {
            const onClickList = () => {
                if (item.title.toUpperCase() === 'PRODUCTS')
                    if (Object.keys(store).length > 0) {
                        window.location.href = item.value
                    } else {
                        if (isMobile)
                            globalStates.openStoreDrawer.set(true)
                        else
                            globalStates.openStoreModal.set(true)
                        window.location.hash = 'products'
                    }
                else {
                    window.location.href = item.value
                }
            }
            return (
                <Box key={index} sx={{ display: { xs: 'block', sm: 'unset' } }}>
                    <Button

                        style={{
                            color: ConstantStyles['purple']
                        }}

                        onClick={onClickList}
                    >
                        {item.title}
                    </Button>
                </Box>
            )
        })
        setLinksList(drawerlinks)
    }, [store, globalStates.openStoreModal.get(), globalStates.openStoreDrawer.get()])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen); //toggle sidebar / drawer
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSignOut = (event) => {
        localStorage.setItem('customerSignedIn', false);
        localStorage.setItem('customerInfo', null);
        window.location.href = '/';
    };
    const handleAccountClick = (e) => {
        window.location.href = `/account${e ? '#' + e : ''}`
    };

    // const onClickCity = () => {
    //     if (isMobile)
    //         globalStates.openStoreDrawer.set(true)
    //     else
    //         globalStates.openStoreModal.set(true)

    // }

    const toggleCartDrawer = (anchor, open, event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setCartState({ ...cartState, [anchor]: open });
    }

    const onClickLogin = () => {

        globalStates.showLoginModal.set(true)
    }

    const onClickLogo = () => {
        window.location.href = `/`
    }



    // drawer contents
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                <img src={DailyLogo} width="180" />
            </Typography>
            <Divider />
            {/* list ng links from json */}
            <List>
                <PageListMenu />
                {linksList}
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }} >
            <AppBar component="nav" style={newStyle}>
                <Toolbar>
                    {/* menu icon start*/}
                    {isMobile ? 
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ width: '40%', flexGrow: 2, display: { sm: 'flex', xs: 'flex' }, textAlign: { xs: 'center', sm: 'center' } }}
                        sm={{ width: '40%', flexGrow: 2, display: { sm: 'flex', xs: 'flex' }, textAlign: { xs: 'center', sm: 'center' } }}
                    >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                        // sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon style={{ color: ConstantStyles['black-color'] }} />
                        </IconButton>
                    </Typography> : null }
                    {/* menu icon end*/}
                    {/* menu logo start*/}
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ width: '40%', flexGrow: 2, display: isMobile ? "none" : { xs: 'flex', sm: 'flex', md: 'flex' }, alignItems: 'center', textAlign: { xs: 'center', sm: 'unset', md: 'unset' } }}
                    >
                        {path.includes('menu') ?
                            // <SearchBoxDesktop />
                            ''
                            :
                            <Button
                                variant='contained'
                                href='https://order.dailybyboscoffee.com/page/dailybyboscoffee/index.html'
                                sx={{
                                    padding: 1,
                                    margin: 1,
                                    color: ConstantStyles['teal'],
                                    width: '200px',
                                    fontSize: '1.2rem',
                                    borderRadius: 15,
                                    display: { xs: 'block', sm: 'block' },
                                    textAlign: 'center',
                                    fontFamily: 'Bounce-haus-Sans'
                                }}
                            >
                                Buy Coffee
                            </Button>
                        }
                        <Typography
                            sx={{
                                color: ConstantStyles['purple'],
                            }}
                        >
                            {/* <Button
                                style={{
                                    color: ConstantStyles['purple'],
                                    lineHeight: 'normal'
                                }}
                                onClick={onClickCity}
                            >
                                <img src={Address} width='24px' alt="location icon" style={{ marginRight: '5px' }} />
                                {
                                    store.branchName ?
                                        store?.branchName
                                        :
                                        "Location"
                                }
                            </Button> */}

                        </Typography>
                    </Typography>
                    {/* menu logo end*/}

                    <Typography
                        component="div"
                        sx={{
                            width: '20%',
                            flexGrow: 3,
                            textAlign: { xs: 'center', sm: 'unset' },
                            margin: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img
                            style={{ cursor: 'pointer' }}
                            src={DailyLogo}
                            alt='dailyLogo'
                            onClick={onClickLogo}
                            width={isMobile ? "130" : "150"}
                        />
                    </Typography>

                    {isMobile ? <SelectStoreDrawer /> : <SelectStoreModal />}

                    <Box sx={{
                        width: '40%',
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                    >

                        {/* {isMobile ? null : path.includes('menu') ? <SearchBoxDesktop /> : null} */}
                        {/* <Button
                            style={{
                                display: isMobile ? "" : "none",
                                color: ConstantStyles['purple'],
                                lineHeight: 'normal'
                            }}
                            onClick={onClickCity}
                        >
                            <img src={Address} width='24px' alt="location icon" />
                        </Button> */}
                        {!isMobile && <PageListMenu first={0} last={5} />}
                        {!isMobile && linksList}
                        {/* <Link underline="none" sx={{ display: { xs: 'block', sm: 'unset' } }}
                            style={{
                                color: ConstantStyles['purple']
                            }}>
                            <Tooltip title="Cart">
                                <IconButton
                                    sx={{ position: 'relative' }}
                                    onClick={(event) => {
                                        toggleCartDrawer(toggleSide, true, event)
                                        window.gtag("event", "view_cart", {
                                            currency: "PHP",
                                            value: cart?.map(item => item.item_price * item.item_qty).reduce((a, b) => a + b, 0),
                                            items: [
                                                cart?.map((item, index) => {
                                                    return {
                                                        item_id: item.item_sku,
                                                        item_name: item.item_name,
                                                        currency: "PHP",
                                                        price: item.item_price,
                                                        quantity: item.item_qty,
                                                        index: index,
                                                    }
                                                })
                                            ]
                                        });
                                    }}>
                                    {cart.length > 0 ?
                                        <Box sx={styles.orderCount}>
                                            {cart?.map((item) => (item.item_qty)).reduce((partialSum, a) => partialSum + a, 0)}
                                        </Box>
                                        : null
                                    }
                                    <img alt='cart_icon' src={CartIcon} />
                                </IconButton>
                            </Tooltip>
                        </Link> */}
                        {/* {
                            !isMobile &&
                            <React.Fragment>
                                {
                                    customerInfo === null ?
                                        <Link underline="none" sx={{ display: { xs: 'none', sm: 'unset' } }}
                                            style={{
                                                color: ConstantStyles['purple']
                                            }}>
                                            <Tooltip title="Login">
                                                <IconButton onClick={onClickLogin}>
                                                    <img alt='user_icon' src={UserIcon} />
                                                </IconButton>
                                            </Tooltip>
                                        </Link>
                                        :
                                        <div style={{ display: "unset" }}>
                                            <IconButton
                                                size="large"
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={handleMenu}
                                                color="inherit"
                                                style={{
                                                    color: ConstantStyles['purple']
                                                }}
                                            >
                                                <img alt='user_icon' src={UserIcon} />
                                            </IconButton>
                                            <Menu
                                                id="menu-appbar"
                                                anchorEl={anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                keepMounted
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                <MenuItem >{customerInfo.first_name + ' ' + customerInfo.last_name}</MenuItem>
                                                {guestSignIn ? null :
                                                    <MenuItem onClick={() => handleAccountClick()}>My Account</MenuItem>
                                                }
                                                <MenuItem onClick={() => handleAccountClick('orders')}>My Orders</MenuItem>
                                                {!guestSignIn ? null :
                                                    <MenuItem onClick={onClickLogin}>Sign In</MenuItem>
                                                }
                                                {guestSignIn ? null :
                                                    <React.Fragment>
                                                        <MenuItem onClick={() => handleAccountClick('address')}>My Address</MenuItem>
                                                        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                                                    </React.Fragment>
                                                }
                                            </Menu>
                                        </div>
                                }

                            </React.Fragment>
                        } */}
                        {
                        }
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none', md:'none' },
                        height: '100%',
                        '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                    }}
                >
                    <div style={{ width: '100%' }}>
                        {drawer}
                    </div>
                </Drawer>
            </Box>
            <SwipeableDrawer
                sx={{
                    height: isMobile? (window.innerHeight - 100) : "100%",
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: isMobile ? 'unset' : '350px',
                        height: isMobile ? (window.innerHeight - 100) : "100%",
                        boxSizing: 'border-box',
                    }
                }}
                disableBackdropTransition={true}
                anchor={toggleSide}
                open={cartState[toggleSide]}
                onClose={() => toggleCartDrawer(toggleSide, false)}
                onOpen={() => toggleCartDrawer(toggleSide, true)}
            >
                <Cart cartstate={cartState} setcartstate={setCartState} />
            </SwipeableDrawer>
            <LoginModal />
        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.CartList,
        store: state.store.selectedStore
    }
}


export default connect(mapStateToProps)(Navbar);