import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useHistory, useParams } from 'react-router-dom';
import ProductBanner from '../../../assets/All_Product.jpg';
import AllIcon from '../../../assets/icons/all.png';
import ConstantStyles from '../../../utility/ConstantStyle.json';
import SearchBoxMobile from '../../general/SearchBoxMobile';
import allImage from '../../../assets/backgroundImage/iced_coffee.jpg'
const allBanner = 'https://scontent.fmnl3-1.fna.fbcdn.net/v/t39.30808-6/295029853_583925610027773_5737607783299515964_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=19026a&_nc_ohc=If5ZgYZznDsAX8awjdI&_nc_ht=scontent.fmnl3-1.fna&oh=00_AfA-evp9VQ__Qrp9F3cqFjhlIzT-cVOsDB5QHaVT3AcErw&oe=63CA7090'
const announcement = localStorage.getItem('announcement') === "1"
const styles = {
    fontSize: {
        fontSize: '10px',
        color: ConstantStyles['purple'],
        width: '96%'

    },
    fontSizeSelected: {
        fontSize: '11px',
        color: ConstantStyles['purple'],
        textDecoration: 'underline',
        width: '96%'
    },
    gridStyle: {
        width: '96%',
        margin: '0px 2px 15px',
        cursor: 'pointer',
        pointerEvent: 'none',

    },
    selectedStyle: {
        border: '2px solid #6700B9',
        borderRadius: '50px',
        background: ConstantStyles['teal'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        width: '50px',
        height: '50px'
    },
    icon: {
        borderRadius: '50px',
        background: ConstantStyles['teal'],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        width: '50px',
        height: '50px'
    }
}

const CategoryNavBarMobile = (props) => {
    const { category } = props
    const params = useParams()
    const [categoryList, setCategoryList] = useState()
    const [selectedCategory, setSelectedCategory] = useState(params?.category)
    const [scrollDirection, setScrollDirection] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [checkerCateg, setCheckerCateg] = useState(false);
    const [visible, setVisible] = useState(true)
    const [banner, setBanner] = useState('')
    const [count, setCount] = useState(9)
    const history = useHistory()

    useEffect(() => {
        const allCategory = [{ name: 'ALL' }]
        setCategoryList(category.concat(allCategory))
        setBanner(category?.filter((el) => el.name.toLowerCase() === (params?.category === 'non-coffee' ? params?.category : params?.category?.replace(/-/g, ' ')))[0]?.banner_path || '')
        setSelectedCategory((params?.category === 'non-coffee' ? params?.category?.toUpperCase() : params?.category?.toUpperCase()?.replace(/-/g, ' ')))

    }, [category, params])


    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            setScrollPosition(scrollY);
            const direction = scrollY > lastScrollY ? "down" : "up";
            if (direction === "up") {
                setVisible(true)
            } else {
                setVisible(false)
            }
            if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
                setScrollDirection(direction);
            }
            if (scrollY > 80) {
                setCount(5)
                setCheckerCateg(true)
            }
            else {
                setCount(10)
                setCheckerCateg(false)

            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        window.addEventListener("scroll", updateScrollDirection); // add event listener
        return () => {
            window.removeEventListener("scroll", updateScrollDirection); // clean up
        }
    }, [scrollDirection])


    const onClickCategory = (e) => {
        let value = e?.target?.id?.toLowerCase()?.replace(/ /g, '-')
        setSelectedCategory(e?.target?.id)
        history.push(`/menu/${value}`)
    }




    const toggleCategories = (e) => {
        if (count > 5) {
            setCount(5)
            setCheckerCateg(true)
        } else {
            setCount(10)
            setCheckerCateg(false)
        }
    }

    // console.log(scrollPosition)
    return (
        <React.Fragment>
            <Box
                sx={{
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    padding: '0px',
                    height: '70px',
                    position: 'fixed',
                    top: announcement ? '95px' : '55px',
                    zIndex: '999',
                }}>
                <SearchBoxMobile />
            </Box>
            <img
                alt='banner_category'
                src={ProductBanner}
                style={{ width: '100%', position: 'fixed', top: announcement ? '160px' : '120px', visibility: scrollPosition < 180 ? 'visible' : 'hidden', zIndex: '999', }}
            />
            <Box className='text-center' style={{
                width: '100%',
                position: 'fixed',
                display: scrollDirection === "down" ? 'none' : 'grid',
                gridTemplateColumns: 'repeat(5,minmax(0,1fr))',
                gridGap: '1px',
                top: scrollPosition < 160 ? '340px' : '160px',
                paddingTop: announcement ? '25px' : '0px',
                zIndex: '999',
                background: '#f7f7f7'
            }}>
                {categoryList?.map((list, index) => (

                    index < count ?
                        <div key={index} id={list.name} name={list.name} onClick={onClickCategory}>
                            <Grid container id={list.name} sx={styles.gridStyle}>
                                <Grid item xs={12} id={list.name}>
                                    <Box id={list.name} sx={selectedCategory === list.name ? styles.selectedStyle : styles.icon}>
                                        {list.name === 'ALL' ?
                                            <img id={list.name} alt='all_icon' src={allImage} style={{ width: '47px', height: '47px', pointerEvents: 'none', borderRadius: '50px' }} />
                                            :
                                            <img id={list.name} alt='all_icon' src={process.env.REACT_APP_HOST + list.image_path} style={{ width: '47px', height: '47px', pointerEvents: 'none', borderRadius: '50px' }} />
                                        }
                                    </Box>
                                </Grid>
                                <Grid item id={list.name} xs={12} className='text-center' sx={{ lineHeight: '12px' }}>
                                    <span id={list.name} style={selectedCategory === list.name ? styles.fontSizeSelected : styles.fontSize}>{list.name}</span>
                                </Grid>
                            </Grid>

                        </div>
                        :
                        null
                ))
                }
                <div onClick={toggleCategories}>
                    <Grid container sx={styles.gridStyle}>
                        <Grid item xs={12} >
                            <Box sx={styles.icon}>
                                <MoreHorizIcon sx={{ color: ConstantStyles['purple'] }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} className='text-center'>
                            <span style={styles.fontSize}>{checkerCateg ? "MORE" : "LESS"}</span>
                        </Grid>
                    </Grid>

                </div>
            </Box>
        </React.Fragment >
    )

}


const mapStateToProps = (state) => {
    return {
        category: state.category.categoryList
    }
}

export default connect(mapStateToProps)(CategoryNavBarMobile);