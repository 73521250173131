import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from 'react';
import ProductBanner from '../../../assets/All_Product.jpg';
import ConstantStyles from "../../../utility/ConstantStyle.json";
import api, { GetCategoryList, GetProduct } from "../../../utility/Endpoints";
import GlobalStates from "../../../utility/GlobalStates";
import { setCategory, setProducts } from "../../actions";
import LoadingModal from '../../general/LoadingModal';
import SearchBoxDesktop from "../../general/SearchBoxDesktop";
import CheckoutButtonFixed from "../../shared/CheckoutButtonFixed";
import CategoryNavbar from "../Category/CategoryNavbar";
import CategoryNavBarMobile from "../Category/CategoryNavBarMobile";
import gridSize from "./CardGridSize.json";
import ClassicCard from './ClassicCard';
import './index.scss';
import ProductView from './ProductView';
import RecommendedCard from './RecommendedCard';
//material ui library/components
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import FeedbackForm from "../Feedback/FeedbackForm";


const announcement = localStorage.getItem('announcement') === "1"
const styles = {
    categoryName: {
        marginBottom: '30px',
        fontWeight: 300,
        padding: '12px',
        fontSize: '25px',
        fontFamily: 'Bounce-haus-Sans',
        color: '#00FFFF',
        background: ConstantStyles['purple'],
        borderRadius: '10px'
    }
}

const defaultImage = 'https://d1sag4ddilekf6.azureedge.net/compressed_webp/items/PHITE20220830072619042072/detail/menueditor_item_eed3a594f161472985cd0680df451439_1666566692484852972.webp'
const allBanner = 'https://scontent.fmnl3-1.fna.fbcdn.net/v/t39.30808-6/295029853_583925610027773_5737607783299515964_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=19026a&_nc_ohc=If5ZgYZznDsAX8awjdI&_nc_ht=scontent.fmnl3-1.fna&oh=00_AfA-evp9VQ__Qrp9F3cqFjhlIzT-cVOsDB5QHaVT3AcErw&oe=63CA7090'

const ProductList = (props) => {
    const { products, category, store, categoryView, search, cartList } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    const params = useParams()
    const [screen, setScreen] = useState(window.innerWidth);
    let isMobile = screen <= 767
    let toggleSide = isMobile ? "bottom" : "right"
    const [productList, setProductList] = useState([]);
    const [bannerPath, setBannerPath] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [viewProduct, setViewProduct] = useState({});
    const [isLoadingModal, setIsLoadingModal] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [banner, setBanner] = useState('')
    const dispatch = useDispatch()
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    //DATA FETCHING
    useEffect(() => {
        FetchProduct(); // call function
        FetchCategory(); // call function
        // if (Object.keys(store).length === 0) {
        //     if (globalStates.isMobile.get())
        //         globalStates.openStoreDrawer.set(true)
        //     else
        //         globalStates.openStoreModal.set(true)
        // }
    }, []);

    useEffect(() => {
        FetchProduct()
        document.title = 'Menu';
    }, [search])


    useEffect(() => {
        products.forEach(item => {
            let inCart = cartList.filter((cart) => {
                return cart.item_id === item.product_id
            })
            item.inCart = inCart.length > 0;
            let itemCount = 0
            inCart.forEach(element => {
                itemCount += element.item_qty
            });
            item.inCartQty = itemCount
        });
        if (params.category !== 'all' && categoryView !== 'all') {
            const categoryList = category.filter((category) => (
                category.name.toLowerCase() === (params?.category === 'non-coffee' ? 'non-coffee' : params?.category?.replace(/-/g, ' '))
            ))
            const productList = products.filter((item) => (
                item.category.includes((params?.category === 'non-coffee' ? ('non-coffee').toUpperCase() : params?.category?.replace(/-/g, ' ').toUpperCase()))
            ))
            let bannerPath = categoryList.map((el) => el.banner_path)[0]
            setBanner(process.env.REACT_APP_HOST + bannerPath)
            setCategoryList(categoryList)
            setProductList(productList)
            FetchBanner()
            // setIsLoading(true)
            // setTimeout(() => {
            //     setIsLoading(false)
            // }, 320)
        } else {
            setCategoryList(category)
            setProductList(products)
            // setIsLoading(true)
            // setTimeout(() => {
            //     setIsLoading(false)
            // }, 320)
        }
    }, [params, params.category, products, category, categoryView, cartList])

    const FetchBanner = () => {
        api.banner.fetch()
            .then((response) => {
                let bannerPath = response.data.result.filter(item => item.type === 'Desktop')[0]
                if (isMobile) {
                    bannerPath = response.data.result.filter(item => item.type === 'Mobile')[0]
                }
                setBannerPath(process.env.REACT_APP_HOST + bannerPath?.image_path)
            })
    }

    const FetchCategory = () => {
        GetCategoryList() // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                setCategoryList(result)
                dispatch(setCategory(result))
            })
            .catch(error => {
                console.log(error);
            })
    }

    const FetchProduct = () => {
        GetProduct(store?.branch) // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                if (search !== '') {
                    // console.log('filtered');
                    result = response.data.filter(product =>
                        product.name.toLowerCase().includes(search.toLowerCase())
                    )
                }
                // console.log(result);
                setProductList(result)
                setIsLoadingModal(false)
                dispatch(setProducts(result))
            })
            .catch(error => {
                console.log(error);
            })
    }

    const load = () => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 320)
    }

    //FUNCTIONS
    const toggleDrawer = (anchor, open, item, event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
        if (item)
            setViewProduct(item)
    }


    //set grid size
    const viewType = (category) => {
        return category === "Recommended" ? "recommended" : "classic"
    }
    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    background: "#f7f7f7",
                    minHeight: '100vh',
                    marginTop: `${announcement ?
                        isMobile ?
                            '0px'
                            :
                            '64px'
                        :
                        '60px'}`
                }}
                id='product-container'
            >
                {isMobile ?
                    null
                    :
                    <img
                        alt='banner_category'
                        src={banner || ProductBanner}
                        style={{ width: '100%', height: '381 px', marginTop: announcement ? `105px` : `0px` }}
                    />}
                {isMobile ?
                    <CategoryNavBarMobile />
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Box padding={6} sx={{ height: 'auto' }}>
                            <CategoryNavbar />
                        </Box>
                        <Box margin={2}>
                            <SearchBoxDesktop />
                        </Box>
                    </div>

                }
                {isLoading ?
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100vh"
                    }}>
                        <CircularProgress />
                    </Box>
                    :
                    <Container maxWidth="lg" sx={isMobile ? { paddingTop: "450px", paddingBottom: "10px" } : { minHeight: '500px' }}>
                        <FeedbackForm />
                        {
                            categoryList.map((category, categoryIndex) => {
                                const findCategory = productList.filter(product => product.category.includes(category.name))
                                return (
                                    <div key={categoryIndex} className='mb-5' style={{ nmarginTop: '10px', display: findCategory.length === 0 ? 'none' : '' }}>

                                        <Typography variant="h2" style={styles.categoryName}>{category.name}</Typography>

                                        <React.Fragment>
                                            <Box sx={{ width: '100%' }}>
                                                <Grid container md={12} lg={12} spacing={{ xs: 1, md: 2 }} rows={{ xs: 4, sm: 6, md: 12 }}>
                                                    {
                                                        productList.map((item, i) => (
                                                            item.category.includes(category.name) &&
                                                            <Grid
                                                                key={i + 100}
                                                                item
                                                                xs={gridSize[viewType(category.name)].grid.sm}
                                                                sm={gridSize[viewType(category.name)].grid.sm}
                                                                md={gridSize[viewType(category.name)].grid.md}
                                                                lg={gridSize[viewType(category.name)].grid.lg}
                                                                columnSpacing={4}
                                                            // onClick={(event) => {
                                                            //     toggleDrawer(toggleSide, true, item, event)
                                                            //     window.gtag("event", "view_item", {
                                                            //         currency: "PHP",
                                                            //         value: item.price,
                                                            //         items: [
                                                            //             {
                                                            //                 item_id: item.product_id,
                                                            //                 item_name: item.name,
                                                            //                 currency: "PHP",
                                                            //                 index: i,
                                                            //                 item_category: item.category[0],
                                                            //                 item_category2: item.category[1],
                                                            //                 item_category3: item.category[2],
                                                            //                 item_category4: item.category[3],
                                                            //                 item_category5: item.category[4],
                                                            //                 price: item.price,
                                                            //                 quantity: 1
                                                            //             }
                                                            //         ]
                                                            //     });
                                                            // }}
                                                            >
                                                                {
                                                                    viewType(category.name) === 'recommended' ?
                                                                        <RecommendedCard item={item} index={i} viewType={viewType(category.name)} />
                                                                        :
                                                                        <ClassicCard item={item} index={i} viewType={viewType(category.name)} />
                                                                }
                                                            </Grid>
                                                        )
                                                        )
                                                    }
                                                </Grid>
                                            </Box>
                                        </React.Fragment>
                                    </div>
                                )
                            })
                        }
                        {productList.length === 0 ?

                            <Box sx={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                                <Typography align='center'>
                                    No Products found
                                </Typography>
                            </Box>
                            :
                            ''
                        }
                    </Container>}
                {/* <Button onClick={toggleDrawer(toggleSide, true)}>{anchor}</Button> */}
                <SwipeableDrawer
                    sx={{
                        height: window.innerHeight,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            height: window.innerHeight,
                            boxSizing: 'border-box',
                        },
                    }}
                    disableBackdropTransition={true}
                    anchor={toggleSide}
                    open={state[toggleSide]}
                    onClose={() => toggleDrawer(toggleSide, false)}
                    onOpen={() => toggleDrawer(toggleSide, true)}
                >
                    <ProductView item={viewProduct} index={1} state={state} setstate={setState} />
                </SwipeableDrawer>
                {/* {cartList.length > 0 ?
                    < CheckoutButtonFixed />
                    :
                    null
                } */}
                <LoadingModal loading={isLoadingModal} />

            </Box>
        </>
    )


}

const mapStateToProps = (state) => {
    return {
        category: state.category.categoryList,
        products: state.products.productList,
        store: state.store.selectedStore,
        cartList: state.cart.CartList,
        search: state.search.search_value,
    }
}

export default connect(mapStateToProps)(ProductList);