import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useHookstate } from '@hookstate/core';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Container from '@mui/material/Container';
import { Alert, IconButton, TextField } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { PhotoCamera } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

import GlobalStates from '../../../../utility/GlobalStates';
import api, { addImage, removeImage } from '../../../../utility/Endpoints';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const UpdateImageModal = (props) => {
    const theme = useTheme();
    const globalStates = useHookstate(GlobalStates)
    // let { modifiers, categoryList } = props
    const [fileName, setFileName] = useState('No File Chosen')
    const [errMsg, setErrMsg] = useState('')
    const [btnLoad, setBtnLoad] = useState(false)
    const [newImage, setNewImage] = useState(false) // if there is new image uploaded true
    const [fileUpload, setFileUpload] = useState()
    let item = JSON.parse(globalStates.selectedProduct.get())
    console.log(item)
    const file = (e) => {
        setFileName(e.target.files[0].name || "")
        setFileUpload(e.target.files[0] || "")
    }
    const handleClose = () => {
        // if (newImage)
        //     window.location.href = window.location.pathname;
        // else
            globalStates.modalOpenUpdate.set(false);
    };

    const handleDeleteImage = (data) => {
        removeImage(data, data.id)
            .then((res) => {
                setNewImage(true)
                let newItem = item
                const updatedImages = newItem?.images?.filter((image) => image.id !== data.id)
                newItem = { ...newItem, images: updatedImages }
                globalStates.selectedProduct.set(JSON.stringify(newItem))
                // globalStates.modalOpenUpdate.set(false)
                setBtnLoad(false)
            })
            .catch((err) => {
                console.log(err?.response?.data?.message)
                setErrMsg(err?.response?.data?.message)
                setBtnLoad(false)
            })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // setBtnLoad(true)
        const formData = new FormData();
        formData.append('image', fileUpload);
        const paths = {
            dir: __dirname,
            path: '../../../../../public/uploads/image'
        }

        addImage(formData, item.id)
            .then((res) => {
                setNewImage(true)
                let newItem = item
                newItem?.images?.push({
                    id: res?.data?.id,
                    src: res?.data?.image_path
                })
                globalStates.selectedProduct.set(JSON.stringify(newItem))
                // globalStates.modalOpenUpdate.set(false)
                setBtnLoad(false)
            })
            .catch((err) => {
                console.log(err?.response?.data?.message)
                setErrMsg(err?.response?.data?.message)
                setBtnLoad(false)
            })
    }
    return (
        <div>
            <Modal
                open={globalStates.modalOpenUpdate.get()}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ padding: 0, margin: 0 }}
            >
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{ margin: 1.5 }}>
                        Product Image Update
                    </Typography>
                    <hr style={{ marginTop: 1, marginBottom: 1 }} />
                    <Box sx={{ padding: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2 }}>
                                <Alert sx={{ width: "100%" }} severity="error" hidden={errMsg ? false : true}>{errMsg}</Alert>
                            </Box>
                            <Container fixed>
                                <Grid container spacing={2}>
                                    <Grid item md={4}>
                                        Image:
                                    </Grid>
                                    <Grid container item md={8}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <IconButton size="large" color="primary" aria-label="upload picture" component="label">
                                                <input
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                    name='category_image'
                                                    type="file"
                                                    hidden
                                                    accept=".jpg , .png"
                                                    onChange={file}
                                                />
                                                <PhotoCamera size="large" />
                                            </IconButton>
                                            <Typography sx={{ color: 'black', fontSize: '13px' }} align='center'>
                                                {fileName}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid container item md={12} spacing={2}>
                                        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                                            {item?.images?.map((item) => (
                                                <ImageListItem key={item.id}>
                                                    <img
                                                        src={`${process.env.REACT_APP_HOST}${item.src}?w=164&h=164&fit=crop&auto=format`}
                                                        srcSet={`${item.src}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                        style={{ "position": "relative" }}
                                                    />
                                                    <IconButton
                                                        sx={{
                                                            position: "absolute", zIndex: "999",
                                                            right: '2px',
                                                            borderRadius: '10px',
                                                            '&:hover': {
                                                                backgroundColor: 'white', // Change the background color here when hovered
                                                            },
                                                        }}
                                                        onClick={() => handleDeleteImage(item)} aria-label="delete">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ImageListItem>
                                            ))}
                                        </ImageList>

                                    </Grid>
                                </Grid>
                            </Container>
                            <Box sx={{ width: '100%' }}>
                                <hr style={{ marginTop: 1, marginBottom: 1 }} />
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 1.5 }}>
                                    <LoadingButton
                                        onClick={handleClose}
                                        sx={{ marginRight: 1 }}
                                        size="small"
                                        type="button"
                                        loadingPosition="start"
                                        // disabled={btnLoad}
                                        startIcon={<CloseIcon />}
                                    >
                                        Close
                                    </LoadingButton>
                                    <LoadingButton
                                        size="small"
                                        type="submit"
                                        loadingPosition="start"
                                        loading={btnLoad}
                                        variant="contained"
                                        startIcon={<SaveIcon />}
                                    >
                                        Save
                                    </LoadingButton>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}

UpdateImageModal.propTypes = {
    messages: PropTypes.object,
    run: PropTypes.func,
    btnLoad: PropTypes.bool,
};
UpdateImageModal.defaultProps = {
    messages: {},
    run: undefined,
    btnLoad: false,
};

export default UpdateImageModal