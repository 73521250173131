// react library
import { useState as useHookState } from "@hookstate/core";
import React from "react";


//Global States, call funtion
import GlobalStates from "../../../utility/GlobalStates";

// mui components
//material ui library/components
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ConstantStyles from '../../../utility/ConstantStyle.json';
import FAQs from "./FAQs"
import { useEffect } from "react";
import { useState } from "react";
import api from "../../../utility/Endpoints";



const styles = {
    titles: {
        fontColor: ConstantStyles['purple']
    }

}

const AboutUs = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [banner, setBanner] = useState('')
    const announcement = localStorage.getItem('announcement') === "1"
    const [screen, setScreen] = useState([window.innerWidth]);
    let isMobile = screen <= 767

    //Photos
    const about_us_banner_img = `/banner/${isMobile ? 'about_us_banner.jpg' : 'about_us_banner.jpg'}`
    const about_us_banner_img_half_width = `/banner/about_us_half.jpg`
    const our_coffee = `/banner/Our Coffee.jpg`
    const our_space = `/banner/Our Space.jpg`
    const our_customer = "/banner/our_customers.jpg"

    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        fetchBanner()
        document.title = 'About Us';
    }, [isMobile])
    const fetchBanner = () => {
        api.banner.fetch('About us')
            .then((response) => {
                console.log(response);
                let bannerPath = response.data.result.filter(item => item.type === 'Desktop')[0]
                if (isMobile) {
                    bannerPath = response.data.result.filter(item => item.type === 'Mobile')[0]
                }
                console.log(bannerPath);
                setBanner(bannerPath ? process.env.REACT_APP_HOST + bannerPath?.image_path : '')
            })
            .catch((error) => {
                console.log(error.message);
            })
    }


    return (
        <Box>
            <Grid
                container
                spacing={0}
                sx={{
                    marginTop: announcement ?
                        isMobile ?
                            '64px'
                            :
                            '64px'
                        :
                        '70px'
                }}
            >
                <img
                    alt={`about-us-banner`}
                    src={banner}
                    style={{
                        width: screen - 7,
                        display: banner ? "block" : 'none'
                    }}
                />
            </Grid>
            <Box
                style={{ backgroundColor: ConstantStyles['purple'], borderRadius: "0px", color: "#FFF", paddingBottom: "2%" }}>
                <Grid container>
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                        <img
                            style={{ paddingTop: isMobile ? "40px" : "", marginTop: isMobile ? "1%" : "13%", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                            width={"100%"}
                            src={about_us_banner_img_half_width} />

                    </Grid>
                    {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}
                    <Grid item xl={4} lg={4} md={4} sm={10} xs={10} style={{ paddingLeft: isMobile ? "" : "5%", paddingTop: isMobile ? "" : "5%" }}>
                        {isMobile ? <br /> : null}
                        <h1 style={{ color: ConstantStyles['teal'], textAlign: "left", fontFamily: 'Bounce-haus-Sans' }}>About Us</h1>

                        We’re a young and fun brand that serves quick, accessible, handcrafted coffee for busy or on-the-go lifestyles since 2020! <br /><br />
                        Our parent company, <b><a href="https://www.boscoffee.com/" target="_blank" style={{ all: "unset", cursor: "pointer" }} >Bo’s Coffee</a></b>, which has been serving high-quality Filipino coffee for over 25 years, helps to make sure that all products are made with high craftsmanship and coffee expertise, served with a warm customer experience. <br /><br />
                        In Daily, we want to give you the energy boost you need to bounce back into your work with enough pep to brighten up your hectic day.<br />
                    </Grid>
                    {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}


                </Grid>
            </Box>
            <Box
                style={{ backgroundColor: ConstantStyles['purple'], borderRadius: "0px", color: "#FFF" }}>
                <Grid container>
                    {isMobile ? <Grid item sm={12} xs={12}>
                        <img
                            style={{ paddingTop: isMobile ? "40px" : "", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                            width={"100%"}
                            src={our_customer} />
                    </Grid>
                        : null}
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                    {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}
                    <Grid item xl={4} lg={4} md={4} sm={10} xs={10} style={{ paddingLeft: isMobile ? "" : "1%", paddingRight: isMobile ? "" : "5%", paddingTop: isMobile ? "" : "10%" }}>
                        {isMobile ? <br /> : null}
                        <h1 style={{ color: ConstantStyles['teal'], textAlign: "left", fontFamily: 'Bounce-haus-Sans' }}>Our Customers</h1>

                        We are the go-to fast coffee brand for young professionals that love to grind and hustle! <br /><br />
                        Delivering strong coffee to the major cities of the Philippines, we want to make sure that we’re part of every working Filipino’s daily routine. <br /><br />
                    </Grid>
                    {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}

                    {isMobile ? null :
                        < Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                            <img
                                style={{ paddingTop: isMobile ? "40px" : "", marginTop: "15%", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                                width={"100%"}
                                src={our_customer} />
                        </Grid>
                    }
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                </Grid>
            </Box>
            <Box
                style={{ backgroundColor: ConstantStyles['purple'], borderRadius: "0px", color: "#FFF", paddingBottom: "2%" }}>
                <Grid container>
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                    <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                        <img
                            style={{ paddingTop: isMobile ? "40px" : "", marginTop: isMobile ? "1%" : "15%", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                            width={"100%"}
                            src={our_coffee} />

                    </Grid>

                    {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}
                    <Grid item xl={4} lg={4} md={4} sm={10} xs={10} style={{ paddingLeft: isMobile ? "" : "5%", paddingTop: isMobile ? "" : "6%" }}>
                        {isMobile ? <br /> : null}
                        <h1 style={{ color: ConstantStyles['teal'], textAlign: "left", fontFamily: 'Bounce-haus-Sans' }}>Our Coffee</h1>

                        Start your day strong – Daily.<br /><br />

                        Offering a wide mix of classics and unique drinks, we only use hand-poured coffee, which creates a strong and bold drink that busy-bees and coffee lovers will love.<br /><br />

                        Brewing mostly iced drinks, our hand-poured coffee complements its strength with robust flavors, making each of their beverages the ultimate pick-me-up at any time of day.<br /><br />

                        Wanna give it a try? Buy your strong coffee <b><a href="/menu/all" style={{ all: "unset", cursor: "pointer" }}>here!</a></b><br /><br />
                    </Grid>
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                    {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}

                </Grid>
            </Box>
            <Box
                style={{ backgroundColor: ConstantStyles['purple'], borderRadius: "0px", color: "#FFF", paddingBottom: "2%", }}>
                <Grid container>
                    {isMobile ? <Grid item sm={12} xs={12}>
                        <img
                            style={{ paddingTop: isMobile ? "40px" : "", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                            width={"100%"}
                            src={our_space} />
                    </Grid> : null}

                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}

                    {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}
                    <Grid item xl={4} lg={4} md={4} sm={10} xs={10} style={{ paddingLeft: isMobile ? "" : "1%", paddingRight: isMobile ? "" : "5%", paddingTop: isMobile ? "" : "10%" }}>
                        {isMobile ? <br /> : null}
                        <h1 style={{ color: ConstantStyles['teal'], textAlign: "left", fontFamily: 'Bounce-haus-Sans' }}>Our Space</h1>
                        We are the go-to fast coffee brand for young professionals that love to grind and hustle! <br /><br />
                        Delivering strong coffee to the major cities of the Philippines, we want to make sure that we’re part of every working Filipino’s daily routine. <br /><br />
                    </Grid>
                    {isMobile ? <Grid item sm={1} xs={1}></Grid> : null}

                    {isMobile ? null :
                        < Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
                            <img
                                style={{ paddingTop: isMobile ? "40px" : "", marginTop: "15%", borderRadius: "50px", borderTopLeftRadius: "50px", borderTopRightRadius: "50px" }}
                                width={"100%"}
                                src={our_space} />
                        </Grid>
                    }
                    {isMobile ? null : <Grid item xl={1} lg={1} md={1}></Grid>}


                </Grid>
            </Box>
            <br />
            <h1 style={{ color: ConstantStyles['purple'], textAlign: "center", fontFamily: 'Bounce-haus-Sans' }}>FAQs</h1>
            <FAQs />


        </Box>
    );
}

export default AboutUs;