import React, { useEffect, useState } from 'react';
import { useState as useHookState } from "@hookstate/core";
import ConstantStyles from "../../../utility/ConstantStyle.json";

//material ui library/components
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Grid from '@mui/material/Unstable_Grid2';


//api endpoints
import api from "../../../utility/Endpoints";

//import from general folder
import AddressList from '../Address/AddressList';
import AddressDrawer from '../Address/AddressDrawer';
import GlobalStates from "../../../utility/GlobalStates";
import { useDispatch } from "react-redux";
import { addAddress } from "../../actions";
//const defaultImage = `https://scontent.fmnl17-1.fna.fbcdn.net/v/t39.30808-6/295432466_583897046697296_7947127860492652402_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=09cbfe&_nc_eui2=AeGOJU3LIGrKW5uTgiH1Euf80-U8IW-1FbXT5Twhb7UVtVVa_nhvg_yUn2x2P4tQyBi7iLFmwSoSBPiZNbaqb_gq&_nc_ohc=wmNSwXOFQZsAX_CY7Uq&_nc_ht=scontent.fmnl17-1.fna&oh=00_AfCatGERp951GJrAJdSx90xUnw-JLo0MWJIwVL5n68YhwQ&oe=637949D8`

const styles = {
    imageContainer: {
        borderRadius: ConstantStyles["default-border-radius"],
    }, productTextStrong: {
        fontWeight: ConstantStyles['bold']
    },
    btnPurple: {
        backgroundColor: ConstantStyles["purple"],
        color: ConstantStyles["white-color"],
    },
    header: {
        fontWeight: ConstantStyles['bold'],
        marginBottom: '30px',
        padding: '12px',
        fontSize: '25px',
        color: '#A2F9F9',
        background: ConstantStyles['purple'],
        borderRadius: '10px'
    }
}
const Card = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
    padding: window.innerWidth <= 640 ? theme.spacing(1) : '15px'
}));

const MyAddressTab = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    let customerInfo = JSON.parse(localStorage.getItem('customerInfo'))
    const customerId = customerInfo['customer_id']
    const dispatch = useDispatch()
    const onClickAddAddress = () => {
        dispatch(addAddress({}))
        globalStates.openAddressDrawer.set(true); // loading
        globalStates.addressType.set('add')
    }
    return (
        <React.Fragment>
            <Grid container item md={12} lg={12}  spacing={1} >
                <Grid item md={12} lg={12} >
                    <h3 style={styles.header}>My Addresses </h3>  
                </Grid>
                <Grid item md={6} lg={3} >
                    <label
                        style={{float:'right'}}
                        onClick={onClickAddAddress}
                    >Add Address</label>
                </Grid>
            </Grid>
            <Grid container item md={12} lg={12} className="register-container" spacing={1} >
                <AddressList/>
            </Grid>
            <AddressDrawer />
        </React.Fragment>
    );
}


export default MyAddressTab;