
import { connect, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';

import AddIcon from '../../../assets/icons/+ icon hi res.svg';
import MinusIcon from '../../../assets/icons/Minus.png';
import ConstantStyles from '../../../utility/ConstantStyle.json';
import { addQuantity, minusQuantity } from '../../actions';

import defaultImage from '../../../assets/logo/default.webp';
const styles = {
    IconButton: {
        float: "right",
        width: '24px',
        height: '24px',
        marginBlock: '3px',
        ':hover': {
            background: 'unset'
        }
    }
}
const CartCard = (props) => {
    const { cart, checkout } = props
    const dispatch = useDispatch()


    return (
        <React.Fragment>
            <div style={{ margin: `65px 0px 300px 0px`, padding: "0px 5px", height: '100%', overflow: "auto" }}>
                {cart?.map((product, index) => (
                    < Card key={index} id={`item${index}`} sx={{ width: "100%", height: '110px', display: 'flex', overflow: "unset", marginBottom: '5px', backgroundColor: '#E9FDFD', borderRadius: '30px' }}>
                        <CardMedia
                            component="img"
                            sx={{ width: "40%", height: " 100%", borderRadius: '30px' }}
                            image={product?.item_image ? process.env.REACT_APP_HOST + product?.item_image : defaultImage}
                            alt={product.item_sku + "_img"}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%", position: 'relative' }}>
                            <CardContent sx={{ flex: '1 0 auto', padding: "10px 5px", width: '80%' }}>
                                <Typography component="div" variant="h6" sx={{ fontSize: "14px", lineHeight: 1.3, color: ConstantStyles['purple'] }}>
                                    <strong>{product.item_name}</strong>
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontSize: "10px", lineHeight: 1 }}>
                                    {(product?.item_modifier["UPSIZE"]?.modifier_name || "") + (product?.item_modifier["UPSIZE"] ? ", " : "") + (product?.item_modifier["SUGAR LEVEL"]?.modifier_name || "") + (product?.item_modifier["MILK ALTERNATIVES"] ? ", " : "") + (product?.item_modifier["MILK ALTERNATIVES"]?.modifier_name || "")}
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontSize: "10px", lineHeight: 1 }}>
                                    {(product?.item_modifier["UPGRADES"]?.length > 0 ? "Add Ons: " : "") + (product?.item_modifier["UPGRADES"]?.map((upgrade) => (upgrade.modifier_name)) || "")}
                                </Typography>
                                <Typography component="div" variant="h6" sx={{ fontSize: "14px", fontWeight: ConstantStyles['bold'], position: 'absolute', bottom: '12px', color: ConstantStyles['purple'] }}>
                                    <strong>PHP {product.item_price?.toFixed(2)}</strong>
                                </Typography>
                            </CardContent>
                            <Box sx={{ alignItems: 'end', bottom: '6px', right: '10px', width: "100%", position: 'absolute' }}>
                                {/* <IconButton
                                    sx={styles.IconButton}
                                    variant="text"
                                    onClick={() => dispatch(addQuantity(product.item_id, product.item_modifier))}
                                >
                                    <img alt='add_icon' src={AddIcon} />
                                </IconButton  > */}
                                <TextField id="standard-basic" variant="standard" sx={{ float: "right", width: '25px', margin: "5px", height: '25px', border: 0 }} value={product.item_qty} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'center', padding: "0px", fontWeight: ConstantStyles['bold'], color: ConstantStyles['purple'] } }} />
                                <IconButton
                                    sx={styles.IconButton}
                                    variant="text"
                                    onClick={() => dispatch(minusQuantity(product.item_id, product.item_modifier))}
                                >
                                    <img alt='minus_icon' src={MinusIcon} />
                                </IconButton  >
                            </Box>
                        </Box>
                    </Card >
                ))
                }
            </div>
        </React.Fragment >
    )
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.CartList,
        checkout: state.checkout
    }
}

export default connect(mapStateToProps)(CartCard)