import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import * as React from 'react';

const CustomAlert = (props) => {

    return (
        <React.Fragment>

            <Fade in={props.in}>
                <Stack style={{ width: '50%', position: 'fixed', zIndex: "10000", transform: 'translate(50%, 50%)' }} spacing={2}>
                    <Alert variant="filled" onClose={props.close} severity={props.severity}>{props.msg}</Alert>
                </Stack>
            </Fade>

        </React.Fragment>
    )

}

export default CustomAlert;