const INTIAL_STATE = {
    selectedStore : {}
}

const SelectedStoreReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_STORE':
            return {
                ...state,
                selectedStore: action.store
            }

        default:
            return state;
    }

};

export default SelectedStoreReducer;