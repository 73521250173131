import { Box } from '@mui/system';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import ConstantStyles from '../../../utility/ConstantStyle.json';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddIcon from '../../../assets/icons/+ icon hi res.svg';
import MinusIcon from '../../../assets/icons/Minus.png';

import defaultImage from '../../../assets/logo/default.webp';
import { addQuantity, minusQuantity } from '../../actions';



const styles = {
    IconButton: {
        float: 'right',
        width: '25px',
        height: '25px',
        marginBlock: '3px',
        ':hover': {
            background: 'unset'
        }
    }
}
const CartCheckout = (props) => {
    const { cart } = props
    const dispatch = useDispatch()
    return (
        <React.Fragment>
            <Box sx={{ background: ConstantStyles['white-color'], height: '300px', overflow: 'auto' }}>
                {cart.map((product, index) => (
                    <React.Fragment key={index}>
                        < Card key={index} id={`item${index}`} sx={{ width: "100%", display: 'flex', overflow: "unset", marginBottom: '5px', backgroundColor: '#E9FDFD', borderRadius: '30px' }}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: "100%", height: "100%", borderRadius: '30px' }}
                                        image={product?.item_image ? process.env.REACT_APP_HOST + product?.item_image : defaultImage}
                                        alt={product.item_sku + "_img"}
                                    />
                                </Grid>
                                <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'column', width: "100%", position: 'relative', height: '100%' }}>
                                    <Grid container sx={{ height: '100%' }}>
                                        <Grid item xs={8}>
                                            <CardContent sx={{ padding: "10px 5px" }}>
                                                <Typography component="div" variant="h6" sx={{ fontSize: "16px", lineHeight: 1.3, color: ConstantStyles['purple'] }}>
                                                    <strong>{product.item_name}</strong>
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontSize: "12px", lineHeight: 1 }}>
                                                    {(product?.item_modifier["UPSIZE"]?.modifier_name || "") + (product?.item_modifier["UPSIZE"] ? ", " : "") + (product?.item_modifier["SUGAR LEVEL"]?.modifier_name || "") + (product?.item_modifier["MILK ALTERNATIVES"] ? ", " : "") + (product?.item_modifier["MILK ALTERNATIVES"]?.modifier_name || "")}
                                                </Typography>
                                                <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ fontSize: "12px", lineHeight: 1 }}>
                                                    {(product?.item_modifier["UPGRADES"]?.length > 0 ? "Add Ons: " : "") + (product?.item_modifier["UPGRADES"]?.map((upgrade) => (upgrade.modifier_name)) || "")}
                                                </Typography>
                                                <Typography component="div" variant="h6" sx={{ fontSize: "16px", position: 'absolute', bottom: '5px', color: ConstantStyles['purple'] }}>
                                                    <strong>PHP {product.item_price?.toFixed(2)}</strong>
                                                </Typography>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={4} sx={{ margin: 'auto', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                            <Box sx={{ width: '100%', pr: 2 }}>
                                                {/* <IconButton
                                                    sx={styles.IconButton}
                                                    variant="text"
                                                    onClick={() => dispatch(addQuantity(product.item_id, product.item_modifier))}
                                                >
                                                    <img alt='add_icon' src={AddIcon} />
                                                </IconButton  > */}
                                                <TextField id="standard-basic" variant="standard" sx={{ float: "right", width: '25px', margin: "5px", height: '25px', border: 0 }} value={product.item_qty} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { textAlign: 'center', padding: "0px" } }} />
                                                <IconButton
                                                    sx={styles.IconButton}
                                                    variant="text"
                                                    onClick={() => dispatch(minusQuantity(product.item_id, product.item_modifier))}
                                                >
                                                    <img alt='' src={MinusIcon} />
                                                </IconButton  >
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card >
                    </React.Fragment>
                ))
                }
            </Box >
        </React.Fragment >
    )


}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.CartList
    }
}


export default connect(mapStateToProps)(CartCheckout);