import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHookstate } from '@hookstate/core';
import GlobalStates from '../../../../utility/GlobalStates';

import api from '../../../../utility/Endpoints';

import MovingImages from '../../../general/MovingImages';
import MovingBannerTable from './MovingBannerTable';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const MovingBannerList = () => {
    return (
        <Box sx={{marginLeft: "6%",marginRight: "3%", marginTop: "5%"}}>
            <MovingBannerTable/>
            <MovingImages/>
        </Box>
  );
}
export default MovingBannerList;