import { useState as useHookState } from "@hookstate/core";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import ConstantStyles from '../../../utility/ConstantStyle.json';
import GlobalStates from "../../../utility/GlobalStates";
import AddressForm from './AddressForm';
import PickUpForm from "./PickUpForm";
const style = {
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        height: 450,
        bgcolor: 'background.paper',
        borderRadius: '15px',
        boxShadow: 24,
        p: '5px 0px',
        ':focus-visible': {
            outline: 'none !important'
        }
    },
    title: {
        textAlign: 'center',
        margin: 'auto',
        color: ConstantStyles['black-color'],
        position: 'fixed',
        width: '100%',
        top: 0,
        background: 'white',
        borderRadius: '15px 15px 0px 0px',
        padding: '10px',
        boxShadow: 2,
        zIndex: '999'
    },
}

const AddressModal = (props) => {
    const { checkout } = props
    const globalStates = useHookState(GlobalStates); // call globalstates
    const [open, setOpen] = useState(globalStates.openAddressModal.get());


    const handleClose = () => {
        setOpen(false)
        globalStates.openAddressModal.set(false)
    };

    useEffect(() => {
        setOpen(globalStates.openAddressModal.get())
    }, [globalStates.openAddressModal.get()])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={
                    {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 450,
                        height: checkout.type === 'Delivery' ? 450 : 340,
                        bgcolor: 'background.paper',
                        borderRadius: '15px',
                        boxShadow: 24,
                        p: '5px 0px',
                        ':focus-visible': {
                            outline: 'none !important'
                        }
                    }
                }>
                    <Box sx={style.title} >
                        <strong style={{ fontSize: '20px' }}>{globalStates.addressType.get() === 'add' ? 'Add Address' : 'Edit Address'}</strong>
                    </Box>
                    <Grid container sx={{ overflow: 'auto', height: '100%' }}>
                        <Grid item xs={12} sx={{ p: 2, mt: '40px' }}>
                            {checkout.type === 'Delivery' ?
                                <AddressForm />
                                :
                                <PickUpForm />
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        checkout: state.checkout,
    }
}

export default connect(mapStateToProps)(AddressModal);