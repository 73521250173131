import React from 'react';

import Box from '@mui/material/Box';
import CartIcon from '../../../assets/icons/cart100.png';

const style = {
    center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: '100%',
        paddingBottom: '20vh',
        width: '100%',
    }
}


const EmptyCart = () => {
    return (
        <React.Fragment>
            <Box
                sx={style.center}
            >
                <img alt="cart_icon" src={CartIcon} />
                <span>Your cart is empty.</span>
            </Box>
        </React.Fragment>
    )
}

export default EmptyCart;