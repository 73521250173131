// react imports
import React, {useState} from 'react';
import PropTypes from "prop-types";

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useHookstate } from '@hookstate/core';
import { TextField, Button, Grid } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Checkbox from '@mui/material/Checkbox';

// MUI icons
import AddCircleIcon from '@mui/icons-material/AddCircle';

// api access
import api from '../../../../utility/Endpoints';

// import global states
import GlobalStates from '../../../../utility/GlobalStates';

// reusable components
import ConfirmButton from '../../../shared/ConfirmButton';
import { useEffect } from 'react';

// styles
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    },
    formRow: {
        display: 'flex', 
        justifyContent: 'space-between', 
        marginBottom: 2
    },
    wrapper: {
        position: 'relative', 
        border: '1px solid gray', 
        padding: '15px 5px 15px 5px', 
        borderRadius: '5px',
        marginBottom: 2,
        header: {
            position: 'absolute', 
            top: '-13px', 
            left: '15px', 
            backgroundColor: 'snow'
        }
    },
    checkBox: {
        display: 'flex', 
        // justifyContent: 'end', 
        alignItems: 'center'
    },
    formInputs: {
        padding: '16px 16px 16px 16px', 
        maxHeight: 500, 
        overflow: "auto" 
    }
};

const CreateModal = (props) => {
    const globalStates = useHookstate(GlobalStates) // globalstate variable
    const { refresh, branches, admin } = props // data from parent component 
    
    const [open, setOpen] = useState(false)  // variable for modal
    const [btnLoad, setBtnLoad] = useState(false) // loading button on process
    const [owned, setOwned] = useState(true) // owned by bos form check box
    const [pickUp, setPickUp] = useState(false) // pick up form check box
    const [delivery, setDelivery] = useState(false) // deliver form check box
    const [selected, setSelected] = useState('') // selected admin 
    const [branchDuplicate, setBranchDuplicate] = useState('') // error message
    
    useEffect(() => {
        setBranchDuplicate('')
        setSelected('')
    }, [open])

    const handleClose = () => setOpen(false) // close modal
    const handleOpen = () => setOpen(true); // open modal
    
    const handleSubmit = (e) => { // submit form
        e.preventDefault()
        setBtnLoad(true)

        // variables from form
        const form = e.target
        const owned_by_bos = owned ? 1 : 0
        const pickup = pickUp ? 1 : 0
        const branch = form.branch.value
        const abbreviation = form.abbreviation.value
        const address = form.address.value
        const latitude = form.latitude.value
        const longitude = form.longitude.value
        const admin_id = form.admin.value
        const contact = form.contact.value
        const schedule = {
            monday: {
                opening: form.opening.value,
                closing: form.closing.value
            },
            tuesday: {
                opening: form.opening.value,
                closing: form.closing.value
            },
            wednesday: {
                opening: form.opening.value,
                closing: form.closing.value
            },
            thursday: {
                opening: form.opening.value,
                closing: form.closing.value
            },
            friday: {
                opening: form.opening.value,
                closing: form.closing.value
            },
            saturday: {
                opening: form.opening.value,
                closing: form.closing.value
            },
            sunday: {
                opening: form.opening.value,
                closing: form.closing.value
            },
        }

        const body = {
            owned_by_bos: owned_by_bos,
            pickup: pickup,
            delivery: delivery ? 1 : 0,
            branch: branch,
            abbreviation: abbreviation,
            address: address,
            latitude: latitude,
            longitude: longitude,
            admin: admin_id,
            contact: contact,
            schedule: schedule,
        }

        // get duplicate branch
        const filterBranch = branches.filter(branch => branch.name === body.branch)
        if (filterBranch.length === 0) {
            // run api
            api.branch.create(body)
            .then((response) => {
                setBtnLoad(false)
                setOpen(false)
                refresh()
            })
            .catch((error) => {
                // alert error
                console.log(error);
                setBtnLoad(false)
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
            })
        }else{
            setBtnLoad(false)
        }
    }

    const handleChange = (e) => { // change selected admin
        e.preventDefault()
        const admin_id = e.target.value
        setSelected(admin_id)
    }

    const onChangeBranch = (e) => { // onChange text branch
        e.preventDefault()

        const typedBranch = e.target.value

        // filter duplicate branch
        const filterBranch = branches.filter( branch => branch.name === typedBranch)
        
        if (filterBranch.length > 0) {
            setBranchDuplicate("Branch already exist")
        }else{
            setBranchDuplicate('')
        }
    }

    return (
        <div>
            <Button 
                size='small' 
                onClick={handleOpen} 
                variant="contained" 
                startIcon={<AddCircleIcon />}>
                Create
            </Button>
            <Modal
                open={open}
                sx={{padding: 0, margin: 0}}
            >   
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{margin: 1.5}}>
                        Create Branch
                    </Typography>
                    <hr style={{marginTop: 1, marginBottom: 1}}/>
                    <form onSubmit={handleSubmit}>
                        <Box sx={style.formInputs} >

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Branch Name <span style={{color: "red", fontSize: "25px"}}>*</span> :
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='branch' onChange={onChangeBranch} size='small' label="Branch Name" placeholder='Quezon City' sx={{ width: '100%'}} variant="outlined" required/>
                                
                                    <FormHelperText sx={{display : branchDuplicate ? '': 'none', color: 'red'}}>{branchDuplicate}</FormHelperText>
                                </Box>
                            </Box>

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Branch Abbreviation:
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='abbreviation' size='small' label="Abbreviation" placeholder='QC' sx={{ width: '100%'}} variant="outlined"/>
                                </Box>
                            </Box>

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Address <span style={{color: "red", fontSize: "25px"}}>*</span> :
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='address' size='small' label="Address" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Longitude <span style={{color: "red", fontSize: "25px"}}>*</span> :
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='longitude' size='small' label="Longitude" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>

                            <Box sx={style.formRow}>
                                <Box>
                                    <Typography>
                                        Latitude <span style={{color: "red", fontSize: "25px"}}>*</span> :
                                    </Typography>
                                </Box>
                                <Box width={"60%"}>
                                    <TextField name='latitude' size='small' label="Latitude" sx={{ width: '100%'}} variant="outlined" required/>
                                </Box>
                            </Box>
                            <Box sx={style.formRow}>
                                <Box></Box>
                                <Box width={'60%'}>
                                    <Box sx={{display: 'flex'}}>
                                        <Box sx={style.checkBox}>
                                            <Checkbox
                                                value={owned ? 1 : 0}
                                                name='owned_by_bos'
                                                checked={owned}
                                                onChange={(e) => setOwned(e.target.checked)}
                                            />
                                            <Typography align='center'>
                                                Owned by Bo's 
                                            </Typography>
                                        </Box>
                                        <Box sx={style.checkBox}>
                                            <Checkbox
                                                value={pickUp ? 1 : 0}
                                                name='pickUp'
                                                checked={pickUp}
                                                onChange={(e) => setPickUp(e.target.checked)}
                                            />
                                            <Typography align='center'>
                                                Pick up 
                                            </Typography>
                                        </Box>
                                        <Box sx={style.checkBox}>
                                            <Checkbox
                                                value={delivery ? 1 : 0}
                                                name='delivery'
                                                checked={delivery}
                                                onChange={(e) => setDelivery(e.target.checked)}
                                            />
                                            <Typography align='center'>
                                                Deliver 
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box sx={style.wrapper}>
                                <Typography sx={style.wrapper.header} >Assigned Admin</Typography>
                                <Box sx={style.formRow}>
                                    <Box>
                                        <Typography>
                                            Admin <span style={{color: "red", fontSize: "25px"}}>*</span> :
                                        </Typography>
                                    </Box>
                                    <Box width={"60%"}>
                                        <FormControl sx={{ minWidth: 120, width: '100%' }}  size="small">
                                            <InputLabel id="demo-simple-select-helper-label">Admin *</InputLabel>
                                            <Select
                                                value={selected}
                                                label="Admin "
                                                name='admin'
                                                onChange={handleChange}
                                                required
                                            >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                admin.map((admin, index) => {
                                                    const name = admin.first_name + ' ' +admin.last_name || ""
                                                    return <MenuItem key={index} value={admin.id}>{name}</MenuItem>
                                                })
                                            }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box>
                                        <Typography>
                                            Contact Number <span style={{color: "red", fontSize: "25px"}}>*</span> :
                                        </Typography>
                                    </Box>
                                    <Box width={"60%"}>
                                        <TextField name='contact' type='number' size='small' label="Contact Number" sx={{ width: '100%'}} variant="outlined" required/>
                                    </Box>
                                </Box>
                            </Box>


                            <Box sx={style.wrapper}>
                                <Typography sx={style.wrapper.header} >Schedule</Typography>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography>Scheduled Time <span style={{color: "red", fontSize: "25px"}}>*</span> :</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField 
                                            id="time" 
                                            size='small'
                                            name='opening'
                                            label="Opening"
                                            type="time"
                                            defaultValue="08:00"
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                            sx={{width: 200}}    
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField 
                                            id="time" 
                                            size='small'
                                            name='closing'
                                            label="Closing"
                                            type="time"
                                            defaultValue="17:00"
                                            sx={{width: 200}}  
                                            required
                                            />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box sx={{width: '100%'}}>
                            <hr style={{marginTop: 1, marginBottom: 1}}/>
                            <ConfirmButton 
                                margin={1}
                                close={handleClose} 
                                btnLoad={btnLoad} 
                            />
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}

CreateModal.propTypes = {
    refresh:PropTypes.func,
    branches:PropTypes.array,
    btnLoad:PropTypes.bool,
};
CreateModal.defaultProps = {
    branches: [],
    admin: [],
};

export default CreateModal