
import React, { useEffect } from 'react'

import { Grid, IconButton, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import DailyLogo from '../../../../../assets/logo/daily-logo.png'
import { useState } from 'react';
import GlobalStates from '../../../../../utility/GlobalStates';
import { useHookstate } from '@hookstate/core';
import api from '../../../../../utility/Endpoints';

const style = {
    Footer: {
        margin: '5% 5% 0% 6%',
    },
    column: {
        padding: '10px'
    },
    gridContainer: {
        backgroundColor: "#00FFFF",
        fontSize: '14px',
        // width: window.innerWidth - 7
    },
    links: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'skyblue',
        }
    },
    flexRow: {
        // display: 'flex',
        // width: 'inherit'
    },
    icons: {
        img: {
            width: '50px'
        }
    },
    editButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    inputs: {
        width: "100%",
        marginBottom: 2
    },
    wrapper: {
        position: 'relative',
        border: '1px solid gray',
        padding: '15px 5px 15px 5px',
        borderRadius: '5px',
        marginBottom: 2,
        header: {
            position: 'absolute',
            top: '-13px',
            left: '15px',
            backgroundColor: 'snow'
        }
    },
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const FooterContents = (props) => {
    const { content, refresh } = props
    const globalStates = useHookstate(GlobalStates)
    const [selectedImage, setSelectedImage] = useState({})
    const [siteCopyRight, setSiteCopyRight] = useState('')
    const [siteDescription, setSiteDescription] = useState('')
    const [column2Header, setColumn2Header] = useState('')
    const [column3Header, setColumn3Header] = useState('')
    const [disableBtn, setDisableBtn] = useState(false)
    const [contentImage, setContentImage] = useState({})

    useEffect(() => {
        const imagePath = process.env.REACT_APP_HOST + content?.site_logo
        const setImage = {
            imageName: content.site_logo_alt || "",
            imagePath: imagePath,
            fileUpload: {}
        }
        setContentImage(setImage)
        setSiteCopyRight(content.site_copyright)
        setSiteDescription(content.site_description)
        setColumn2Header(content.footer_headers?.column2)
        setColumn3Header(content.footer_headers?.column3)
    }, [content])

    const handleSaveFooterContent = () => {
        setDisableBtn(true)
        const formData = new FormData();
        formData.append('image', selectedImage.fileUpload);
        if (siteCopyRight && siteDescription && column2Header && column3Header) {
            if (selectedImage.fileUpload) {
                api.uploadImage.uploadImage(formData, 'icons')
                    .then((response) => {
                        const filePath = response.data.result
                        const footerHeaders = {
                            column2: column2Header,
                            column3: column3Header
                        }
                        const body = {
                            siteLogoPath: filePath,
                            siteLogoAlt: selectedImage.imageName,
                            siteCopyRight: siteCopyRight,
                            siteDescription: siteDescription,
                            footerHeaders: JSON.stringify(footerHeaders)
                        }
                        api.footer.editContent(body)
                            .then((res) => {
                                console.log(res);
                                setDisableBtn(false)
                                refresh(!refresh)
                                setDisableBtn(false)
                                globalStates.openAlert.set(true)
                                globalStates.statusAlert.set('success')
                                globalStates.msgAlert.set('Successfully save')
                                setInterval(() => {
                                    globalStates.openAlert.set(false)
                                }, 2000)
                            })
                            .catch((err) => {
                                console.log(err);
                                setDisableBtn(false)
                                globalStates.openAlert.set(true)
                                globalStates.statusAlert.set('error')
                                globalStates.msgAlert.set(err.message)
                                setInterval(() => {
                                    globalStates.openAlert.set(false)
                                }, 2000)
                            })
                    })
                    .catch((error) => {
                        console.log(error.message);
                        setDisableBtn(false)
                        globalStates.openAlert.set(true)
                        globalStates.statusAlert.set('error')
                        globalStates.msgAlert.set(error.message)
                        setInterval(() => {
                            globalStates.openAlert.set(false)
                        }, 2000)
                    })
            } else {
                const footerHeaders = {
                    column2: column2Header,
                    column3: column3Header
                }
                const body = {
                    siteLogoPath: content.site_logo,
                    siteLogoAlt: content.site_logo_alt,
                    siteCopyRight: siteCopyRight,
                    siteDescription: siteDescription,
                    footerHeaders: JSON.stringify(footerHeaders)
                }
                api.footer.editContent(body)
                    .then((res) => {
                        refresh(!refresh)
                        setDisableBtn(false)
                        globalStates.openAlert.set(true)
                        globalStates.statusAlert.set('success')
                        globalStates.msgAlert.set('Successfully save')
                        setInterval(() => {
                            globalStates.openAlert.set(false)
                        }, 2000)
                    })
                    .catch((err) => {
                        console.log(err);
                        setDisableBtn(false)
                        globalStates.openAlert.set(true)
                        globalStates.statusAlert.set('error')
                        globalStates.msgAlert.set(err.message)
                        setInterval(() => {
                            globalStates.openAlert.set(false)
                        }, 2000)
                    })
            }
        } else {
            globalStates.openAlert.set(true)
            globalStates.statusAlert.set('warning')
            globalStates.msgAlert.set('Fill up other Text Links fields')
            setInterval(() => {
                globalStates.openAlert.set(false)
                setDisableBtn(true)
            }, 2000)
        }
    }


    const file = (e) => {
        // console.log(e);
        const selectedFiles = e.target.files
        const imageName = selectedFiles[0].name
        const imagePath = URL.createObjectURL(selectedFiles[0])
        setSelectedImage({
            imageName: imageName || "",
            imagePath: imagePath,
            fileUpload: selectedFiles[0]
        })
    }
    // console.log(content);
    return (
        <Grid item style={style.column} xs={4}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <img alt={contentImage?.imageName || 'defaultImage'} src={contentImage.imagePath || DailyLogo} width='100' />
                <Box>
                    <IconButton sx={{ width: '100%' }} size="large" color="primary" aria-label="upload picture" component="label">
                        <input
                            style={{
                                cursor: 'pointer'
                            }}
                            name='category_image'
                            type="file"
                            hidden
                            accept=".jpg , .png"
                            onChange={file}
                        />
                        <CameraAltIcon size="large" />
                    </IconButton>
                </Box>
            </Box>
            <Box>
                <TextField sx={style.inputs} value={siteDescription} onChange={e => setSiteDescription(e.target.value)} multiline rows={4} size='small' label="Site Description" variant="outlined" />
            </Box>
            <Box>
                <TextField sx={style.inputs} value={siteCopyRight} onChange={e => setSiteCopyRight(e.target.value)} size='small' label="Site CopyRight" variant="outlined" />
            </Box>
            <Box>  {/* Footer Column Header */}
                <TextField sx={style.inputs} value={column2Header} onChange={e => setColumn2Header(e.target.value)} size='small' label="Column 2 Header" variant="outlined" />
            </Box>
            <Box>  {/* Links input*/}
                <TextField sx={style.inputs} value={column3Header} onChange={e => setColumn3Header(e.target.value)} size='small' label="Column 3 header" variant="outlined" />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <Button
                    disabled={disableBtn}
                    variant='contained'
                    size='small'
                    onClick={handleSaveFooterContent}
                >
                    Save
                </Button>
            </Box>
        </Grid>
    )
}

const FooterLink = (props) => {
    const { links, refresh } = props
    const globalStates = useHookstate(GlobalStates)
    const [linkList, setLinkList] = useState([])
    const [link, setLink] = useState('')
    const [linkTitle, setLinkTitle] = useState('')

    useEffect(() => {
        const newLinks = links.filter(item => item.link_type === 'text')
        setLinkList(newLinks)
    }, [links])

    const handleAddLink = () => {
        const newLink = {
            linkTitle: linkTitle,
            link: link,
            linkIcon: '',
            linkType: 'text'
        }
        api.links.addLink(newLink)
            .then((response) => {
                refresh()
            })
            .catch((error) => {
                console.log(error.message);
                console.log(error.message);
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
                setInterval(() => {
                    globalStates.openAlert.set(false)
                }, 2000)
            })
    }

    return (
        <Grid item style={style.column} xs={4}>
            <Box sx={style.wrapper}>
                <Typography sx={style.wrapper.header} >Text Links</Typography>
                {/* <AddLink /> */}
                <Box>
                    <Box>  {/* Links input*/}
                        <TextField required sx={style.inputs} value={linkTitle} onChange={e => setLinkTitle(e.target.value)} size='small' label="Link Title" variant="outlined" />
                    </Box>
                    <Box>  {/* Links input*/}
                        <TextField required sx={style.inputs} value={link} onChange={e => setLink(e.target.value)} size='small' label="Link" variant="outlined" />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <IconButton
                            size='small'
                            onClick={handleAddLink}
                        >
                            <AddIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{ display: linkList.length > 0 ? 'flex' : 'none', flexDirection: 'column' }}>
                    <Typography>
                        Order:
                    </Typography>
                    {
                        linkList?.map((link, index) => {
                            return (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ p: { lineHieght: 'normal' } }}>
                                        {link.link_title} (<Typography component={'a'} href={link.link}>{link.link}</Typography>)
                                    </Typography>
                                    <IconButton onClick={e => {
                                        api.links.remove({ id: link.id })
                                            .then((response) => {
                                                refresh()
                                            })
                                            .catch((error) => {
                                                console.log(error.message);
                                            })
                                    }}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            )
                        })
                    }
                </Box>
                {/* <Box
                    sx={{display: linkList.length>0 ? 'flex' : 'none', justifyContent: 'flex-end'}}
                >
                    <Button 
                        size='small'
                        variant='contained'
                        onClick={handleSaveLink}
                    >
                        Save
                    </Button>
                </Box> */}
            </Box>
        </Grid>
    )
}

const FooterLinkIcon = (props) => {
    const { links, refresh } = props
    const globalStates = useHookstate(GlobalStates)
    const [linkList, setLinkList] = useState([])
    const [link, setLink] = useState('')
    const [linkTitle, setLinkTitle] = useState('')
    const [selectedImage, setSelectedImage] = useState({})

    useEffect(() => {
        const newLinks = links.filter(item => item.link_type === 'icon')
        setLinkList(newLinks)
    }, [links])

    const file = (e) => {
        // console.log(e);
        // console.log(e);
        const selectedFiles = e.target.files
        const imageName = selectedFiles[0].name
        const imagePath = URL.createObjectURL(selectedFiles[0])
        setSelectedImage({
            imageName: imageName || "",
            imagePath: imagePath,
            fileUpload: selectedFiles[0]
        })
    }
    const handleAddLinkIcon = () => {
        const formData = new FormData();
        formData.append('image', selectedImage.fileUpload);
        if (selectedImage !== '{}' && link && linkTitle) {
            api.uploadImage.uploadImage(formData, 'icons')
                .then((response) => {
                    const filePath = response.data.result
                    const newLink = {
                        linkTitle: linkTitle,
                        link: link,
                        linkIcon: filePath,
                        linkType: 'icon'
                    }
                    api.links.addLink(newLink)
                        .then((response) => {
                            refresh()
                        })
                        .catch((error) => {
                            console.log(error.message);
                            globalStates.openAlert.set(true)
                            globalStates.statusAlert.set('error')
                            globalStates.msgAlert.set(error.message)
                            setInterval(() => {
                                globalStates.openAlert.set(false)
                            }, 2000)
                        })
                })
                .catch((error) => {
                    console.log(error.message);
                    globalStates.openAlert.set(true)
                    globalStates.statusAlert.set('error')
                    globalStates.msgAlert.set(error.message)
                    setInterval(() => {
                        globalStates.openAlert.set(false)
                    }, 2000)
                })
        } else {
            globalStates.openAlert.set(true)
            globalStates.statusAlert.set('warning')
            globalStates.msgAlert.set('Fill up other Icon Links fields')
            setInterval(() => {
                globalStates.openAlert.set(false)
            }, 2000)
        }
    }

    return (

        <Grid item style={style.column} xs={4}>
            <Box>  {/* Footer Column Header */}
                <Box sx={style.wrapper}>
                    <Typography sx={style.wrapper.header} >Icon Links</Typography>
                    <Box>  {/* Links input*/}
                        <TextField required sx={style.inputs} value={linkTitle} onChange={e => setLinkTitle(e.target.value)} size='small' label="Link Title" variant="outlined" />
                    </Box>
                    <Box>  {/* Links input*/}
                        <TextField required sx={style.inputs} value={link} onChange={e => setLink(e.target.value)} size='small' label="Link" variant="outlined" />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: selectedImage?.imagePath ? 'space-between' : 'end', alignItems: 'center' }}>
                        <img alt='preview' src={selectedImage.imagePath} style={{ display: selectedImage?.imagePath ? 'block' : 'none', width: '60px' }} />
                        <Box>
                            <IconButton size="small" color="primary" aria-label="upload picture" component="label">
                                <input
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    required
                                    name=''
                                    type="file"
                                    hidden
                                    accept=".jpg , .png"
                                    onChange={file}
                                />
                                <CameraAltIcon size="small" />
                            </IconButton>
                            <IconButton
                                disabled={selectedImage.imagePath && link && linkTitle ? false : true}
                                size='small'
                                onClick={handleAddLinkIcon}
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: linkList.length > 0 ? 'block' : 'none' }}>
                        <Typography>
                            Order:
                        </Typography>
                        {
                            linkList.map(link => {
                                return (
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box>
                                            <img alt={link.link_title} src={process.env.REACT_APP_HOST + link.link_icon} style={{ width: '60px', height: 'auto' }} />
                                            <Typography component={'a'} align='center'>
                                                {link.link}
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            size='small'
                                            onClick={() => {
                                                api.links.remove({ id: link.id })
                                                    .then((response) => {
                                                        refresh()
                                                    })
                                                    .catch((error) => {
                                                        console.log(error.message);
                                                    })
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </Box>
            </Box>
        </Grid>
    )
}

const Footer = () => {
    const globalStates = useHookstate(GlobalStates)
    const [content, setContent] = useState({})
    const [linkList, setLinkList] = useState([])
    const [refresh, setRefresh] = useState(false)

    //     <Grid container sx={style.gridContainer}>
    //     <Grid item style={style.column} xs={4}>
    //         <Box sx={style.editButton}>  {/* Site Logo */}
    //             <img style={{width: 100}} alt='test' src={DailyLogo} />
    //             {/* <FirstColumn /> */}
    //         </Box>            
    //         <Box>  {/* Site Description */}
    //             <Typography>
    //                 "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
    //             </Typography>
    //         </Box>
    //         <Box>  {/* Site Copyrights */}
    //             <Typography>
    //                 © 2023 Daily by Bo's Coffee. All rights reserved
    //             </Typography>
    //         </Box>  
    //     </Grid>
    //     <Grid item style={style.column} xs={4}>
    //         <Box sx={style.editButton}>  {/* Footer Column Header */}
    //             <Typography variant='h6'>
    //                 Site
    //             </Typography>
    //             {/* <IconButton
    //                 size='small'   
    //                 sx={{width: 45, height: 45}} 
    //             >

    //                 <EditIcon />
    //             </IconButton> */}
    //         </Box>            
    //         <Box>  {/* Links */}
    //             <Typography sx={style.links}>
    //                 Test
    //             </Typography>
    //             <Typography sx={style.links}>
    //                 Test
    //             </Typography>
    //             <Typography sx={style.links}>
    //                 Test
    //             </Typography>
    //             <Typography sx={style.links}>
    //                 Test
    //             </Typography>
    //         </Box>
    //     </Grid>
    //     <Grid item style={style.column} xs={4}>
    //         <Box sx={style.editButton}>  {/* Footer Column Header */}
    //             <Typography variant='h6'>
    //                 Follow us
    //             </Typography>
    //             {/* <IconButton
    //                 size='small'   
    //                 sx={{width: 45, height: 45}} 
    //             >

    //                 <EditIcon />
    //             </IconButton> */}
    //         </Box>          
    //         <Box>
    //             <IconButton sx={style.icons}>
    //                 <img alt='test' src={fb_logo_img} />
    //             </IconButton>
    //             <IconButton sx={style.icons}>
    //                 <img alt='test' src={linkedin_logo_img} />
    //             </IconButton>
    //             <IconButton sx={style.icons}>
    //                 <img alt='test' src={ig_logo_img} />
    //             </IconButton>
    //         </Box>  
    //     </Grid>
    // </Grid>

    useEffect(() => {
        fetchData()
        fetchLinks()
    }, [refresh])

    const refreshData = () => setRefresh(!refresh)

    const fetchLinks = () => {
        api.links.fetchLink()
            .then((response) => {
                const result = response.data
                setLinkList(result)
            })
            .catch((error) => {
                console.log(error.message);
            })
    }

    const fetchData = () => {
        api.footer.fetch()
            .then((response) => {
                const result = response.data
                if (response.data.length > 0) {
                    const data = result.map(data => {
                        return {
                            id: data.id,
                            footer_headers: JSON.parse(data.footer_headers),
                            site_copyright: data.site_copyright,
                            site_description: data.site_description,
                            site_logo: data.site_logo,
                            site_logo_alt: data.site_logo_alt,

                        }
                    })
                    setContent(data[0])
                }
            })
            .catch((error) => {
                console.log(error);
                globalStates.openAlert.set(true)
                globalStates.statusAlert.set('error')
                globalStates.msgAlert.set(error.message)
                setInterval(() => {
                    globalStates.openAlert.set(false)
                }, 2000)
            })
    }

    const fb_logo_img = `/fb.svg`
    const linkedin_logo_img = `/linkedin.svg`
    const ig_logo_img = "ig.svg"

    return (
        <div style={style.Footer}>
            <Grid container>
                <FooterContents content={content} refresh={refreshData} />
                <FooterLink links={linkList} refresh={refreshData} />
                <FooterLinkIcon links={linkList} refresh={refreshData} />
            </Grid>
        </div>
    )
}

export default Footer