import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditorToolbar, { formats, modules } from "../shared/QuillToolbar";

function QuillEditor(props) {
    return (
        <>
            <EditorToolbar />
            <ReactQuill
                theme="snow"
                value={props.value}
                onChange={(event) => props.onChange(event)}
                placeholder={"Write something awesome..."}
                modules={modules}
                formats={formats}
            />
        </>
    );
}


export default QuillEditor;