import { useState as useHookState } from "@hookstate/core";
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import './index.scss';

import { GetBestSeller } from "../../../utility/Endpoints";

import GlobalStates from "../../../utility/GlobalStates";
import { setProducts } from "../../actions";

import { cartLogs } from '../../../utility/Endpoints';
import { addItem } from '../../actions';
import LoadingModal from '../../general/LoadingModal';
import ProductView from '../Product/ProductView';

//material ui library/components
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import AddIcon from '../../../assets/icons/+ icon hi res.svg';
import defaultImage from '../../../assets/logo/default.webp';
import ConstantStyles from '../../../utility/ConstantStyle.json';

const CategoryCarousel = (params) => {
    const globalStates = useHookState(GlobalStates); // call globalstates
    const dispatch = useDispatch()
    let { categoryList, store } = params

    const [screen, setScreen] = useState([window.innerWidth]);
    let isMobile = screen <= 767
    let toggleSide = isMobile ? "bottom" : "right"
    const [productList, setProductList] = useState([])
    const [viewProduct, setViewProduct] = useState({});
    const [isLoading, setIsLoading] = useState(true)
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    useEffect(() => {
        const handleResize = () => {
            setScreen(window.innerWidth)
        };

        //is loadingset global state
        setTimeout(() => {
        }, 400);
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    //DATA FETCHING
    useEffect(() => {
        FetchProduct(); // call function
        // if (Object.keys(store).length === 0) {
        //     if (globalStates.isMobile.get())
        //         globalStates.openStoreDrawer.set(true)
        //     else
        //         globalStates.openStoreModal.set(true)
        // }
    }, [store, params]);

    const FetchProduct = () => {
        GetBestSeller(store?.branch) // function from endpoint
            .then(response => {
                let result = response.data; // get result from api
                setProductList(result || [])
                dispatch(setProducts(result))
                setIsLoading(false)
            })
            .catch(error => {
                console.log(error);
            })
    }

    const handleClick = (product) => {
        let selectedModifier = product.preselected_modifier
        const sorted = Object.keys(selectedModifier)
            .sort()
            .reduce((accumulator, key) => {
                accumulator[key] = selectedModifier[key];
                return accumulator;
            }, {});
        const modifierPrice = Number(sorted["UPSIZE"]?.price || 0) + Number(sorted["SUGAR LEVEL"]?.price || 0) + Number(sorted["MILK ALTERNATIVES"]?.price || 0)
        const upgradePrice = Number(sorted["UPGRADES"]?.map((upgrades) => (upgrades.price))?.reduce((partialSum, a) => partialSum + a, 0) || 0)

        const insertCart = {
            item_id: product.product_id,
            item_name: product.name,
            item_sku: product.sku,
            item_qty: 1,
            item_price: Number(product.price) + Number(modifierPrice) + Number(upgradePrice),
            original_price: product.price,
            item_image: product.image_src,
            item_category: product.category,
            item_modifier: sorted,
            item_remarks: ''
        }
        console.table(insertCart)
        const payloadLogs = {
            item: insertCart,
            action: 'added to cart',
            accesskey: 'sample accesskey',
            userEmail: 'useremail@email.com'
        }


        window.gtag("event", "add_to_cart", {
            currency: "PHP",
            value: Number(product.price) + Number(modifierPrice) + Number(upgradePrice),
            items: [
                {
                    item_id: product.product_id,
                    item_name: product.name,
                    currency: "PHP",
                    item_category: product.category[0],
                    item_category2: product.category[1],
                    item_category3: product.category[2],
                    item_category4: product.category[3],
                    item_category5: product.category[4],
                    item_modifier: sorted,
                    price: Number(product.price) + Number(modifierPrice) + Number(upgradePrice),
                    original_price: product.price,
                    quantity: 1
                }
            ]
        });

        // globalStates.snackBarMsg.set(`${product.name} Added`);
        globalStates.snackBarStatus.set(true);
        cartLogs(payloadLogs)
        dispatch(addItem(insertCart));
    }
    //FUNCTIONS
    const toggleDrawer = (anchor, open, item, event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
        if (item)
            setViewProduct(item)
    }
    return (
        <React.Fragment>
            <ImageList sx={{
                width: (screen * .95),
                height: isMobile ? '350px' : '500px !important',
                margin: 'auto',
                gridAutoFlow: 'column',
                overflowX: "auto",
                overflowY: "hidden",
            }}
            >
                {productList.map((product) => (
                    <ImageListItem sx={{
                        width: isMobile ? '300px' : '350px !important',
                        height: isMobile ? '350px !important' : '500px !important',
                        padding: '10px',
                        overflow: 'hidden'
                    }} key={product.product_id}
                    >
                        <div style={{
                            padding: isMobile ? null : '25px',
                            width: isMobile ? '250px' : '300px !important',
                            height: isMobile ? '250px' : '300px !important',
                            borderRadius: '25px',
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            minHeight: isMobile ? '250px' : '300px !important',
                            position: 'relative',
                            cursor: 'pointer'
                            // marginBottom: '20px'
                        }}

                            // onClick={() => {
                            //     handleClick(product)
                            // }}
                            >
                            <img className='zoomImage' alt={product.name}
                                src={product?.image_src ? process.env.REACT_APP_HOST + product?.image_src : defaultImage}
                                width="300px" style={
                                    {
                                        height: isMobile ? '250px' : '300px !important',
                                        width: isMobile ? '250px' : '300px !important',
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        scale: '1',
                                        borderRadius: '25px'
                                    }} />
                            {/* <IconButton
                                sx={{ width: '24px',
                                height: '24px',
                                marginBlock: '3px',
                                position: 'absolute',
                                bottom: isMobile?'0px':'25px',
                                right: isMobile?'8px':'20px',
                                ':hover': { background: 'unset' } }}
                                variant="text"
                            >
                                <img alt='add_icon' src={AddIcon} />
                            </IconButton  > */}
                        </div>
                        <div style={{
                            cursor: 'pointer'
                        }}
                            // onClick={() => {
                            //     handleClick(product)
                            // }}
                            >
                            <p

                                title={product.name}
                                position="below"
                                style={{
                                    textAlign: "center",
                                    fontSize: isMobile ? '14px' : '1.5vw',
                                    lineHeight: '1',
                                    fontWeight: 'bold',
                                    height: 'unset',
                                    margin: 0,
                                    color: ConstantStyles['purple']
                                }}
                            >
                                {isMobile ? <br /> : null}
                                {product.name}</p>
                            <p

                                title={product.name}
                                position="below"
                                style={{
                                    paddingTop: isMobile ? null : "2%",
                                    textAlign: "center",
                                    lineHeight: '1',
                                    fontSize: isMobile ? '3.2vw' : '1.1vw',
                                    margin: 0,
                                    color: ConstantStyles['purple']
                                }}
                            >PHP {product.price}</p>

                        </div>
                    </ImageListItem>
                ))}
            </ImageList>
            <SwipeableDrawer
                sx={{
                    height: window.innerHeight,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        height: window.innerHeight,
                        boxSizing: 'border-box',
                    },
                }}
                disableBackdropTransition={true}
                anchor={toggleSide}
                open={state[toggleSide]}
                onClose={() => toggleDrawer(toggleSide, false)}
                onOpen={() => toggleDrawer(toggleSide, true)}
            >
                <ProductView item={viewProduct} index={1} state={state} setstate={setState} />
            </SwipeableDrawer>

            <LoadingModal loading={isLoading} />
        </React.Fragment>
    );


}


const mapStateToProps = (state) => {
    return {
        categoryList: state.category.categoryList,
        store: state.store.selectedStore,
    };
}

export default connect(mapStateToProps)(CategoryCarousel);