import { useState as useHookState } from "@hookstate/core";
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { useEffect, useState } from 'react';

import Time from "./Time";

import GlobalStates from "../../../utility/GlobalStates";

const TimeDrawer = () => {
    const globalStates = useHookState(GlobalStates); // call globalstates

    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    useEffect(() => {
        toggleDrawer('bottom', globalStates.openTimeDrawer.get())
    }, [globalStates.openTimeDrawer.get()])


    const toggleDrawer = (anchor, open) => {
        setState({ ...state, [anchor]: open });
    }

    return (
        <React.Fragment>
            <SwipeableDrawer
                sx={{
                    height: (window.innerHeight * 0.6),
                    flexShrink: 0,

                    '& .MuiDrawer-paper': {
                        height: (window.innerHeight * 0.6),
                        boxSizing: 'border-box',
                        borderRadius: '15px 15px 0px 0px',
                    },
                }}
                anchor={'bottom'}
                open={state['bottom']}
                onClose={() => {
                    toggleDrawer('bottom', false)
                    globalStates.openTimeDrawer.set(false)
                }}
                onOpen={() => {
                    toggleDrawer('bottom', true)
                    globalStates.openTimeDrawer.set(true)
                }}
            >
                <Grid container sx={{ marginTop: '23px' }}>
                    <Grid item xs={12}>
                        <Time />
                    </Grid>
                </Grid>
            </SwipeableDrawer>
        </React.Fragment >
    )
}


export default TimeDrawer;