import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useHookstate } from '@hookstate/core';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import { useDropzone } from 'react-dropzone' //DragNDrop function        -      https://react-dropzone.js.
import { parse } from "papaparse"; //                 -      https://www.npmjs.com/package/papaparse
import { CSVLink } from "react-csv";  //         -      https://www.npmjs.com/package/react-csv

//Global States, call funtion
import ConstantStyles from "../../../../utility/ConstantStyle.json";
import GlobalStates from '../../../../utility/GlobalStates';
import api,{ CreateProduct } from '../../../../utility/Endpoints';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    borderRadius: "5px",
    boxShadow: 24,
    warning: {
        fontSize: "13px"
    },
    textField: {
        width: "100%"
    },
    requiredWarning: {
        width: "100%",
    }
};
const css = {
    
    baseStyle: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    focusedStyle: {
        borderColor: '#2196f3'
    }, acceptStyle: {
        borderColor: '#00e676'
    }, rejectStyle: {
        borderColor: '#ff1744'
    },
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const header = [
    { label: "SKU", key: "SKU" },
    { label: "Name", key: "Name" },
    { label: "Price", key: "Price" },
    { label: "Category", key: "Category" },
    { label: "Description", key: "Description" },
    { label: "Modifiers", key: "Modifiers" }
], row = [

]
const BulkCreateProduct = (props) => {
    const theme = useTheme();
    const globalStates = useHookstate(GlobalStates)
    // let { modifiers, categoryList } = props
    const [fileName, setFileName] = useState('No File Chosen')
    const [errMsg, setErrMsg] = useState('')
    const [btnLoad, setBtnLoad] = useState(false)
    const [fileUpload, setFileUpload] = useState()
    let item = JSON.parse(globalStates.selectedProduct.get())
    const fileQuantity = props.fileQuantity || 1  //default value for max file quantity
    const filetype = props.filetype || "file/csv" //default value for accepted file type
    const modifiers = props.modifiers|| [] //default value for accepted file type
    const [upload, setUpload] = React.useState([]); //storage for files uploaded
    // const [info, setInfo] = useState(item)
    // useEffect(() => {
    //     setInfo(item)
    // },[item])

    const file = (e) => {
        setFileName(e.target.files[0].name || "")
        setFileUpload(e.target.files[0] || "")
    }

    const handleClose = () => globalStates.modalOpenBulk.set(false);


    // Declare functions for drag'n'Drop
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: filetype,
        maxFiles: fileQuantity,
        onDrop: useCallback((acceptedFiles) => {
            acceptedFiles.forEach(async (file) => {
                if (file.type === "application/vnd.ms-excel" || file.type === "text/csv") {
                    const text = await file.text();
                    const result = parse(text, { header: true });
                    setUpload(result.data)
                } else {
                    //if jpg, png, etc.
                }
            })


        })
    });
    // use for styling drag'n'Drop
    const dropBoxStyle = useMemo(() => ({
        ...css.baseStyle,
        ...(isFocused ? css.focusedStyle : {}),
        ...(isDragAccept ? css.acceptStyle : {}),
        ...(isDragReject ? css.rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    // if file accepted show success
    const acceptedFileItems = acceptedFiles.map((file) => (
        <li key={file.path}>
            File Ready For Upload
            ({file.path} - {file.size} bytes : {file.type})
        </li>
    ));

    // if file rejected show error message
    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes : {file.type}
            <ul>
                {errors.map(e => (
                    <li key={e.code}>{e.message}</li>
                ))}
            </ul>
        </li>
    ));

    const handleSubmit = (e) => {
        setErrMsg('')
        setBtnLoad(true)
        e.preventDefault()
        let rows = upload.map((item) => {
            let modifer = (item['Modifiers'].replace(/ /gi, '')).split('/')
            let selectedModifiers = []
            modifiers.forEach(element => {
                if (modifer.includes(element.group_name.replace(/ /g, ''))) {
                    selectedModifiers.push({
                        id:element.group_id
                    })
                }
            });
            return {
                sku: item['SKU'],
                name: item['Name'],
                price: item['Price'],
                category: item['Category'].split('/'),
                description: item['Description'].replace(/\//gi,','),
                modifiers: selectedModifiers
            }
        })
        api.product.createMultiple(rows)
        .then((res) => {
            setBtnLoad(false)
            globalStates.openAlert.set(true);
            globalStates.snackBarMsg.set('Items Created Successfully');
            setTimeout(() => {
                window.location.href = window.location.pathname;
            },3000);
        })
        .catch((err) => {
            console.log(err.response.data.message)
            setErrMsg(err.response.data.message)
            setBtnLoad(false)
        })
    }
    return (
        <div>
            <Modal
                open={globalStates.modalOpenBulk.get()}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{padding: 0, margin: 0}}
            >   
                <Box sx={style}>
                    <Typography align='center' variant='h5' sx={{margin: 1.5}}>
                        Bulk Create Product
                    </Typography>
                    <hr style={{ marginTop: 1, marginBottom: 1 }} />
                    <Box sx={{margin: '10px'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 2}}>
                            <Alert sx={{width: "100%"}} severity="error" hidden={errMsg ? false : true}>{errMsg}</Alert>
                        </Box>
                        <Grid container>
                            <Grid item md={12}>
                                <li>
                                    <small >Maximum of 50 rows</small>
                                </li>
                                <li>
                                    <small >Use Alt + Enter for new line</small>
                                </li>
                                <li>
                                    <small >Kindly replace all commas with '/'  </small>
                                </li>
                                <li>
                                    <small >Kindly remove all double quotes </small>
                                </li>
                            </Grid>
                        </Grid>
                    </Box>
                    <CSVLink headers={header} data={row} //set header and rows for csv download
                    filename='bulk-create-product'
                    className="btn btn-transparent text-success">
                        Download CSV - Sample
                    </CSVLink>
                    <TableContainer >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>SKU</TableCell>
                                    <TableCell align="right">Name</TableCell>
                                    <TableCell align="right">Price</TableCell>
                                    <TableCell align="right">Category</TableCell>
                                    <TableCell align="right">Description</TableCell>
                                    <TableCell align="right">Modifiers</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                    key={1}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell component="th" scope="row">
                                        DD000000
                                    </TableCell>
                                    <TableCell align="right">  Ice Coffee   </TableCell>
                                    <TableCell align="right">  130.00   </TableCell>
                                    <TableCell align="right">  CLASSICS/DAILY SIGNATURES  </TableCell>
                                    <TableCell align="right">  Iced Coffee/ Milk/ Brown Sugar  </TableCell>
                                    <TableCell align="right">  UPSIZE/SUGAR LEVEL   </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ padding: 2 }}>
                        <div {...getRootProps({ style:dropBoxStyle })}>
                            <input {...getInputProps()} />
                            <p style={style.dragDrop}>Drag 'n' drop csv file here, or click to select file</p>
                            <em>(Maximum of {fileQuantity} file{fileQuantity > 1 ? 's' : ''})</em>
                        </div>
                        <aside>
                            <p className="text-success">{acceptedFileItems}</p>
                            <p className="text-danger">{fileRejectionItems}</p>
                        </aside>
                    </Box>
                    <Box sx={{width: '100%'}}>
                        <hr style={{marginTop: 1, marginBottom: 1}}/>
                        <Box sx={{display: 'flex', justifyContent:'flex-end', padding: 1.5}}>
                            <LoadingButton
                                onClick={handleClose} 
                                sx={{marginRight: 1}} 
                                size="small"
                                type="submit"
                                loadingPosition="start"
                                // disabled={btnLoad}
                                startIcon={<CloseIcon /> }
                                >
                                Close
                            </LoadingButton>
                            <LoadingButton
                                onClick={handleSubmit} 
                                size="small"
                                type="submit"
                                loadingPosition="start"
                                loading={btnLoad}
                                variant="contained" 
                                startIcon={<SaveIcon /> }
                                >
                                Save
                            </LoadingButton>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

BulkCreateProduct.propTypes = {
    messages:PropTypes.object,
    run:PropTypes.func,
    btnLoad:PropTypes.bool,
};
BulkCreateProduct.defaultProps = {
    messages:{},
    run: undefined,
    btnLoad: false,
};

export default BulkCreateProduct